const constants = {
    ADD: 'HMN/COOLINARIKA/WEB/SEARCH/ADD_INGREDIENT',
    ADD_ARRAY: 'HMN/COOLINARIKA/WEB/SEARCH/ADD_INGREDIENT_ARRAY',
    ADD_IGNORE: 'HMN/COOLINARIKA/WEB/SEARCH/ADD_INGREDIENT_IGNORE',
    ADD_IGNORE_ARRAY: 'HMN/COOLINARIKA/WEB/SEARCH/ADD_IGNORE_ARRAY',
    ADD_MEALS: 'HMN/COOLINARIKA/WEB/SEARCH/ADD_MEALS',
    ADD_MEALS_ARRAY: 'HMN/COOLINARIKA/WEB/SEARCH/ADD_MEALS_ARRAY',
    CLEAR: 'HMN/COOLINARIKA/WEB/SEARCH/CLEAR_INGREDIENTS'
}

const actions = {
    add: ingredient => ({ type: constants.ADD, ingredient }),
    addArray: ingredient => ({ type: constants.ADD_ARRAY, ingredient }),
    addMeals: ingredient => ({ type: constants.ADD_MEALS, ingredient }),
    addMealsArray: ingredient => ({ type: constants.ADD_MEALS_ARRAY, ingredient }),
    addIgnore: ingredient => ({ type: constants.ADD_IGNORE, ingredient }),
    addIgnoreArray: ingredient => ({ type: constants.ADD_IGNORE_ARRAY, ingredient }),
    clear: () => ({ type: constants.CLEAR })
}

export { constants as TasteProfileActionTypes, actions as TasteProfileActions }
