import { useContext, useRef } from 'react'

import { WindowContext } from '../controllers'
import * as easings from '../helpers/easings'

export const { linear, quad, cubic, quart, quint, sine, expo, circ, back, elastic, bounce } = easings

const getTargetY = target => {
    if (typeof target === 'number') {
        return target
    }
    const targetEl = typeof target === 'string' ? document.querySelector(target) : target
    if (targetEl && targetEl.getBoundingClientRect) {
        const targetY = targetEl.getBoundingClientRect().y
        const windowY = window.pageYOffset
        return targetY + windowY
    }
    return -1
}

const scrollToTarget = (target, options = {}) => {
    const { duration, speed, offset = 0, ease = linear.easeNone } = options
    const targetY = getTargetY(target)
    if (targetY < 0) {
        return {
            promise: Promise.resolve(),
            cancel: () => {}
        }
    }

    const startY = window.pageYOffset
    const distance = targetY - startY + offset
    let totalTime = 250
    if (duration) {
        totalTime = duration
    } else if (speed) {
        totalTime = Math.abs(distance) / speed
    }
    let startTime = 0

    let rafId

    const cancel = () => {
        window.cancelAnimationFrame(rafId)
    }

    const promise = new Promise(resolve => {
        const step = timestamp => {
            if (!startTime) {
                startTime = timestamp
            }
            const time = timestamp - startTime
            const percent = totalTime ? ease(Math.min(time / totalTime, 1)) : 1

            window.scrollTo(0, startY + distance * percent)
            rafId = window.requestAnimationFrame(time <= totalTime ? step : resolve)
        }
        rafId = window.requestAnimationFrame(step)
    })

    return {
        cancel,
        promise
    }
}
/**
 * `useWindow`
 *
 * Provides `width` and `height` values.
 *
 * As well as `scrollTo` helper.
 *
 * ```js
 * import { useWindow, back } from '../hooks'
 * const { width, scrollTo } = useWindow()
 * const elementRef = useRef()
 *
 * // Calculate number of columns to render
 * const columnNumber = Math.round(width / 200)
 *
 * const onClick = () => {
 *      // Find element with query selector, and scroll to it over a duration of time
 *      scrollTo('#some-element', { duration: 10, offset: -20 })
 *      // Scroll to element ref, with applied easing, and speed of 0.1px/ms (100px/s)
 *      scrollTo(elementRef.current, { speed: 0.1, ease: back.easeOut })
 * }
 * ```
 */
const useWindow = () => {
    const { width, height, y, direction } = useContext(WindowContext)
    const cancelRef = useRef()
    const scrollTo = async (target, { duration = 0, speed = 0, offset = 0, ease = sine.easeInOut } = {}) => {
        if (cancelRef.current) {
            cancelRef.current()
        }
        const { cancel, promise } = scrollToTarget(target, {
            duration,
            speed,
            offset,
            ease
        })
        cancelRef.current = cancel
        await promise
    }
    return {
        width,
        height,
        y,
        direction,
        scrollTo
    }
}

export default useWindow
