/* eslint-disable import/no-absolute-path, import/extensions, quote-props */

export const defaults = {
    fontFamily: {
        default: 'sofia-pro'
    },
    fontDisplay: 'swap',
    fontSize: 16,
    fontSizes: {
        // [type]: [xs, sm, md, lg, xl, xxl]
        body: [16, 16, 18, 18, 22, 22],
        p: [16, 16, 18, 18, 22, 22],
        ul: [16, 16, 18, 18, 22, 22],
        ol: [16, 16, 18, 18, 22, 22],
        dl: [16, 16, 18, 18, 22, 22],
        table: [14, 14, 14, 18, 18, 18],
        h1: [32, 32, 42, 42, 52, 52],
        h2: [28, 28, 34, 34, 42, 42],
        h3: [24, 24, 28, 28, 32, 32],
        h4: [20, 20, 22, 22, 24, 24],
        h5: [20, 20, 22, 22, 24, 24], // arbitrarily set
        h6: [20, 20, 22, 22, 24, 24], // arbitrarily set
        small: [14, 14, 16, 16, 18, 18],
        caption: [14, 14, 16, 16, 18, 18],
        blockquote: [16, 16, 22, 22, 28, 28]
    },
    fontStyle: {
        normal: 'normal',
        italic: 'italic'
    },
    fontVariant: {
        normal: 'normal',
        smallCaps: 'small-caps'
    },
    lineHeight: 1.6,
    fontWeight: {
        ultraLight: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        // sofia-pro does not have 800 font weight
        black: 900
    }
}

const lineHeights = {
    body: 1.6,
    heading: {
        h1: 1.2,
        h2: 1.2,
        h3: 1.2,
        h4: 1.2,
        h5: 1.2,
        h6: 1.2
    },
    paragraph: 1.6,
    caption: 1.6,
    button: 1.4,
    input: 1.2,
    blockquote: 1.6,
    list: 1.4
}

const rendering = {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility'
}

const fontSettings = {
    html: {
        ...rendering,
        fontFamily: `${defaults.fontFamily.default}, Helvetica, Verdana, Arial, sans-serif`
    },
    body: {
        lineHeight: lineHeights.body,
        letterSpacing: 0
    },
    h1: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.heading.h1
    },
    h2: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.heading.h2
    },
    h3: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.heading.h3
    },
    h4: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.heading.h4
    },
    h5: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.heading.h5
    },
    h6: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.heading.h6
    },
    p: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.paragraph
    },
    ul: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.list
    },
    ol: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.list
    },
    small: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular
    },
    caption: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.caption
    },
    button: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.black,
        lineHeight: lineHeights.button
    },
    input: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.input
    },
    textarea: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.input
    },
    select: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.regular,
        lineHeight: lineHeights.input
    },
    blockquote: {
        fontFamily: `${defaults.fontFamily.default}, sans-serif`,
        fontWeight: defaults.fontWeight.light,
        lineHeight: lineHeights.blockquote,
        fontStyle: defaults.fontStyle.italic
    }
}

export default {
    ...fontSettings,
    fontFamily: `${defaults.fontFamily.default}, sans-serif`, // override ReactiveSearch font-family
    fontSize: undefined, // kill ReactiveSearch font-size
    font: {
        default: defaults.fontFamily.default,
        display: defaults.fontDisplay,
        weight: defaults.fontWeight,
        style: defaults.fontStyle,
        variant: defaults.fontVariant
    },
    fontSizes: defaults.fontSizes,
    fontWeight: defaults.fontWeight,
    rootFontSize: defaults.fontSize
}
