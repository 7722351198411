import { toRem, toRems } from '../../../../../helpers/theme'

const style = ({ theme }) => {
    const height = {
        mobile: 50,
        desktop: 62,
        closeButton: {
            mobile: 36,
            desktop: 44
        },
        filtersButton: {
            mobile: 50,
            desktop: 62
        }
    }
    const width = {
        button: {
            mobile: 50,
            desktop: 56
        },
        closeButton: {
            mobile: 36,
            desktop: 44
        },
        filtersButton: {
            mobile: 50,
            desktop: 62
        }
    }

    return {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: toRem(height.mobile),
        padding: 0,
        [theme.breakpoints.up('lg')]: {
            height: toRem(height.desktop)
        },
        '& .searchInput': {
            width: '100%',

            [theme.breakpoints.up('lg')]: {
                maxWidth: toRem(300)
            }
        },
        '@media print': {
            display: 'none'
        },
        '& .searchCloseBtn': {
            flexShrink: 0,
            width: toRem(width.closeButton.mobile),
            height: toRem(height.closeButton.mobile),
            minHeight: 0,
            marginLeft: toRem(10),
            boxShadow: 'none',
            fontSize: toRem(26),
            [theme.breakpoints.up('lg')]: {
                marginLeft: toRem(20),
                width: toRem(width.closeButton.desktop),
                height: toRem(height.closeButton.desktop),
                fontSize: toRem(32)
            }
        },
        '& .searchFiltersBtn': {
            position: 'relative',
            flexShrink: 0,
            width: toRem(width.filtersButton.mobile),
            height: toRem(height.filtersButton.mobile),
            minHeight: 0,
            marginRight: toRem(10),
            boxShadow: theme.shadows.deep,
            fontSize: toRem(26),
            [theme.breakpoints.up('lg')]: {
                marginRight: toRem(20),
                width: toRem(width.filtersButton.desktop),
                height: toRem(height.filtersButton.desktop),
                fontSize: toRem(32)
            },
            '&_count': {
                position: 'absolute',
                top: toRem(-3),
                right: toRem(-3),
                height: toRem(20),
                minWidth: toRem(20),
                borderRadius: toRem(20),
                padding: toRems([0, 3, 3, 3]),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 1,
                fontSize: toRem(13),
                fontWeight: theme.typography.fontWeight.black,
                // @TODO: colors
                background: theme.colors.accentGreen,
                color: theme.colors.white
            }
        }
    }
}

export default style
