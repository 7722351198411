import { ModalActionTypes } from '../../actions/modal'

const initialState = {
    isOpen: []
}

const modalsReducer = (state = initialState, { type, id }) => {
    switch (type) {
        case ModalActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: state.isOpen.filter(modal => modal !== id)
            }
        }

        case ModalActionTypes.OPEN: {
            return {
                ...state,
                isOpen: [...state.isOpen, id]
            }
        }

        case ModalActionTypes.TOGGLE: {
            if (state.isOpen.includes(id)) {
                return {
                    ...state,
                    isOpen: state.isOpen.filter(modal => modal !== id)
                }
            }
            return {
                ...state,
                isOpen: [...state.isOpen, id]
            }
        }

        default:
            return state
    }
}

export { modalsReducer }
