/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

// SVG - 32 width, 32 height

const NavAvatar = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.99 14.88a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0 2.38a5.88 5.88 0 100-11.76 5.88 5.88 0 000 11.76z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.99 17.89a8.61 8.61 0 00-8.61 8.61H5c0-6.07 4.92-10.99 10.99-10.99s10.99 4.92 10.99 10.99H24.6a8.61 8.61 0 00-8.61-8.61z"
        />
    </>,
    'NavAvatar'
)

export default NavAvatar
