import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SettingsActions } from '../../actions'

/**
 * Settings provider, used for fetching all kinds of app wide data
 *
 * @param {Object} { children }
 * @returns
 */
const SettingsProvider = ({ children }) => {
    const { isLoaded } = useSelector(state => state.settings)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isLoaded) {
            try {
                dispatch(SettingsActions.getSettings())
            } catch (error) {
                dispatch(SettingsActions.setLoaded(false))
                // eslint-disable-next-line no-console
                console.log('[SettingsProvider]: Failed to load settings, ', error)
            }
        }
    }, [isLoaded, dispatch])

    return children
}

export default SettingsProvider
