import { toRem } from '../../helpers/theme'

const style = ({ theme, halfHeightMobile }) => {
    const config = {
        width: {
            desktop: toRem(510),
            mobile: '100%'
        },
        height: {
            default: '100%',
            mobileHalf: '60vh'
        },
        zIndex: {
            main: theme.zIndex.drawer,
            panel: theme.zIndex.over
        }
    }

    return {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: '0',
        zIndex: config.zIndex.main,
        '& .drawerPanel': {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            left: 0,
            right: 'auto',
            top: 'auto',
            bottom: 0,
            zIndex: config.zIndex.panel,
            width: config.width.mobile,
            height: config.height.default,
            overflow: 'hidden',
            background: theme.colors.drawer.background.panel,
            boxShadow: theme.shadows.deep,
            paddingBottom: toRem(theme.global.baseLayout.mobile.navigation.bar.height),
            ...(halfHeightMobile && {
                [theme.breakpoints.down('sm')]: {
                    height: config.height.mobileHalf
                }
            }),
            [theme.breakpoints.up('md')]: {
                width: config.width.desktop,
                left: 'auto',
                right: 0,
                top: 0,
                bottom: 'auto'
            },
            [theme.breakpoints.up('lg')]: {
                paddingBottom: 0
            }
        }
    }
}

export default style

const transition = {
    duration: 0.25,
    ease: 'easeOut'
}

const drawerMainVariant = {
    open: {
        opacity: 1,
        display: 'block',
        transition
    },
    closed: {
        transitionEnd: {
            display: 'none'
        },
        opacity: 0,
        transition
    }
}

const drawerPanelVariant = {
    closedMobile: {
        x: 0,
        y: '100%',
        transition
    },
    closedDesktop: {
        x: '100%',
        y: 0,
        transition
    },
    open: {
        x: 0,
        y: 0,
        transition
    }
}

export { drawerMainVariant, drawerPanelVariant }
