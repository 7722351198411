/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const ArrowSmall = createSvgIcon(
    <path
        d="M16.293 9.293a1 1 0 000 1.414L20.586 15H8a1 1 0 100 2h12.586l-4.293 4.293a1 1 0 001.414 1.414l6-6a1 1 0 000-1.414l-6-6a1 1 0 00-1.414 0z"
        fill="#141313"
    />,
    'ArrowSmall'
)

export default ArrowSmall
