import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useAnalytics } from './useAnalytics'
import useAuthGuard from './useAuthGuard'
import useDrawer from './useDrawer'
import useFetch from './useFetch'
import useNotification from './useNotification'
import { useNotify } from './useNotify'
import useTaxonFlag from './useTaxonFlag'
import useTaxonMutate from './useTaxonMutate'
import useUser from './useUser'

const NOTIFICATION_ID = 'favorites-notification'

const REQUIRED_KEYS = ['id', 'slug', 'author', 'title']

const checkProps = propsObject => {
    REQUIRED_KEYS.forEach(key => {
        if (!Object.keys(propsObject || {}).includes(key)) {
            // eslint-disable-next-line no-console
            console.warn(`[useAddRecipeToFavorites]: field ${key} of value  is required`)
        }
    })
}

// @TODO: @Sale replace taxon mutate with custom mutate logic to more easly handle query invalidation on other pages
const useAddRecipeToFavorites = ({ recipe } = {}) => {
    if (process.env.NODE_ENV !== 'development') {
        checkProps(recipe)
    }
    const favoriteFlagTaxon = useTaxonFlag('flag-saved-by')
    const authGuard = useAuthGuard()
    const { profile: userData = {}, isLoggedIn } = useUser()

    const { eventWithTrackingData } = useAnalytics('coolinarika.addToFavourites', {
        trackingData: {
            elementCategory: 'button',
            elementLabel: 'Add to favourites',
            elementPageLocation: 'body'
        }
    })

    const { notify } = useNotify()
    const { actions: fetch } = useFetch()
    const { sendNotification } = useNotification({
        id: NOTIFICATION_ID
    })
    const dispatch = useDispatch()

    const { taxons, actions } = useTaxonMutate({
        entity: 'recipe',
        entityId: recipe?.id,
        taxonomy: 'protected-coolinarika-flags',
        bag: 'coolinarika-saved-by',
        taxonId: favoriteFlagTaxon?.id,
        enabled: !!recipe?.id && !!favoriteFlagTaxon?.id
    })

    const isFavorited = !!taxons?.length

    const { open: openCollectionsDrawer } = useDrawer('collections-overview', {
        contentId: recipe?.id,
        content: recipe,
        notificationId: NOTIFICATION_ID,
        gtmData: { action: 'Collection', label: 'Add to collection' }
    })

    const handleAddToFavorites = useCallback(
        authGuard(e => {
            e.preventDefault()

            if (!Object.keys(recipe || {})?.length) {
                return
            }

            if (isFavorited) {
                actions.delete({
                    options: {
                        onSuccess: () => {
                            sendNotification({
                                message: `Recept ${recipe?.title} je obrisan iz spremljenih recepata!`,
                                type: 'success'
                            })
                        },
                        onError: () => {
                            sendNotification({
                                message: `Nešto je pošlo po zlu!`,
                                type: 'error'
                            })
                        }
                    }
                })
            } else {
                actions.create({
                    options: {
                        onSuccess: () => {
                            sendNotification({
                                message: `Recept ${recipe?.title} je spremljen!`,
                                type: 'success'
                            })

                            notify({
                                uuid: recipe?.author?.id,
                                type: 'activity',
                                subType: 'saved',
                                contentType: 'recipe',
                                contentId: recipe?.id,
                                url: `/recept/${recipe?.slug}-${recipe?.id}`
                            })

                            if (isLoggedIn) {
                                fetch.create({
                                    data: {
                                        endpoint: '/gamification/people/favorited-content',
                                        payload: {
                                            action: 'increment',
                                            uuid: recipe?.author?.id,
                                            fromUuid: userData?.id,
                                            contentType: ['recipes'],
                                            contentId: recipe?.id,
                                            value: 1
                                        }
                                    }
                                })
                            }
                        },
                        onError: () => {
                            sendNotification({
                                message: `Nešto je pošlo po zlu!`,
                                type: 'error'
                            })
                        }
                    }
                })
                openCollectionsDrawer()
                eventWithTrackingData()
            }
        }),
        [dispatch, authGuard, actions.delete, actions.create, recipe, notify, sendNotification, eventWithTrackingData]
    )

    return { isFavorited, handleAddToFavorites }
}

export default useAddRecipeToFavorites
