/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavSearch = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 16.5a7.5 7.5 0 01-11.843 6.115 1.013 1.013 0 01-.02.021l-3 3a.9.9 0 11-1.273-1.272l2.96-2.961A7.5 7.5 0 1126.5 16.5zm-1.5 0a6 6 0 11-12 0 6 6 0 0112 0z"
    />,
    'NavSearch'
)

export default NavSearch
