import React from 'react'

const initialState = {
    drawersOpen: [],
    isOpen: false,
    drawerId: null,
    drawerType: null,
    contentId: null,
    content: null,
    isForward: true,
    trophy: null,
    stickyDish: null,
    title: null,
    slug: null,
    entity: null,
    entityId: null,
    entityAuthorId: null,
    isEditing: false,
    notificationId: null,
    isActionShots: false,
    isAnimationComplete: false,
    items: [],
    selectionId: null,
    onSubmit: null,
    isSingleSelection: false,
    isResetPasswordEmailSent: false,
    onSuccessDrawer: false,
    closeDrawerOnRouteChange: true,
    gtmData: null,
    onOpen: () => {},
    onClose: () => {}
}

export const DRAWER_ACTIONS = {
    CLOSE_DRAWER: 'HMN/COOLINARIKA/WEB/DRAWERS/CLOSE_DRAWER',
    OPEN_DRAWER: 'HMN/COOLINARIKA/WEB/DRAWERS/OPEN_DRAWER',
    POP_CONTENT: 'HMN/COOLINARIKA/WEB/DRAWERS/POP_CONTENT',
    UPDATE_TITLE: 'HMN/COOLINARIKA/WEB/DRAWERS/UPDATE_TITLE',
    IS_ANIMATION_COMPLETE: 'HMN/COOLINARIKA/WEB/DRAWERS/IS_ANIMATION_COMPLETE',
    UPDATE_ITEMS: 'HMN/COOLINARIKA/WEB/DRAWERS/UPDATE_ITEMS',
    RESET_PASSWORD_EMAIL_SENT: 'HMN/COOLINARIKA/WEB/DRAWERS/RESET_PASSWORD_EMAIL_SENT',
    SET_ON_SUCCESS_DRAWER: 'HMN/COOLINARIKA/WEB/DRAWERS/SET_ON_SUCCESS_DRAWER'
}

function reducer(state, { type, payload: { drawerType, options, onSuccessDrawer, isEmailSent } = {} }) {
    switch (type) {
        case DRAWER_ACTIONS.OPEN_DRAWER: {
            return {
                ...state,
                drawersOpen: state.drawersOpen.includes(drawerType)
                    ? [...state.drawersOpen]
                    : [...state.drawersOpen, drawerType],
                isOpen: true,
                drawerType,
                isForward: true,
                title: options?.title,
                slug: options?.slug,
                content: options?.content,
                contentId: options?.contentId || state.contentId,
                trophy: options?.trophy,
                stickyDish: options?.stickyDish,
                entity: options?.entity,
                entityId: options?.entityId,
                entityAuthorId: options?.entityAuthorId,
                isEditing: options?.isEditing,
                notificationId: options?.notificationId,
                isActionShots: options?.isActionShots,
                items:
                    options?.items && options?.selectionId
                        ? {
                              ...state.items,
                              [options.selectionId]: options.items
                          }
                        : state.items,
                selectionId: options?.selectionId || state.selectionId,
                isSingleSelection: options?.isSingleSelection || state.isSingleSelection,
                onSubmit: options?.onSubmit,
                gtmData: options?.gtmData,
                queryKey: options?.queryKey,
                onOpen: options?.onOpen,
                onClose: options?.onClose,
                closeDrawerOnRouteChange: options?.closeDrawerOnRouteChange
            }
        }
        case DRAWER_ACTIONS.POP_CONTENT: {
            const currentTopDrawer = state.drawersOpen[state.drawersOpen.length - 1]
            const drawerToPop = drawerType || currentTopDrawer
            const filterArray = state.drawersOpen.filter(item => item !== drawerToPop)
            const currentDrawerType = filterArray[filterArray.length - 1]

            if (options?.onClose && typeof options?.onClose === 'function') {
                options.onClose()
            }

            if (!currentDrawerType) {
                return {
                    drawersOpen: [],
                    isOpen: false,
                    drawerType: null,
                    contentId: null,
                    content: null,
                    isForward: true,
                    notificationId: null,
                    items: {},
                    selectionId: options?.selectionId || null,
                    isSingleSelection: options?.isSingleSelection || false,
                    onSubmit: options?.onSubmit || null,
                    gtmData: null
                }
            }
            return {
                ...state,
                isForward: false,
                drawersOpen: filterArray,
                drawerType: currentDrawerType,
                selectionId: options?.selectionId || state.selectionId,
                onSubmit: options?.onSubmit || null,
                isSingleSelection: options?.isSingleSelection || state.isSingleSelection
            }
        }
        case DRAWER_ACTIONS.UPDATE_TITLE: {
            return {
                ...state,
                title: options?.title
            }
        }
        case DRAWER_ACTIONS.CLOSE_DRAWER: {
            if (options?.onClose && typeof options?.onClose === 'function') {
                options.onClose()
            }

            return {
                drawersOpen: [],
                isOpen: false,
                drawerType: null,
                contentId: null,
                content: null,
                isForward: true,
                notificationId: null,
                items: {},
                selectionId: null,
                isSingleSelection: false,
                onSubmit: null,
                gtmData: null
            }
        }
        case DRAWER_ACTIONS.SET_ON_SUCCESS_DRAWER: {
            return {
                ...state,
                onSuccessDrawer
            }
        }
        case DRAWER_ACTIONS.RESET_PASSWORD_EMAIL_SENT: {
            return {
                ...state,
                isResetPasswordEmailSent: isEmailSent
            }
        }
        case DRAWER_ACTIONS.UPDATE_ITEMS: {
            if (!options?.selectionId) {
                return state
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    [options?.selectionId]: options?.items || []
                }
            }
        }

        default:
            return state
    }
}

const DrawerContext = React.createContext(null)
const DrawerDispatchContext = React.createContext(null)

const DrawerProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
        <DrawerContext.Provider value={state}>
            <DrawerDispatchContext.Provider value={dispatch}>{children}</DrawerDispatchContext.Provider>
        </DrawerContext.Provider>
    )
}

export function useDrawerState() {
    return React.useContext(DrawerContext)
}

export function useDrawerDispatch() {
    return React.useContext(DrawerDispatchContext)
}

export { DrawerContext, DrawerDispatchContext, DrawerProvider }
