import { Children, cloneElement, isValidElement, memo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './FilterListView.style'

const FilterListStyled = styled.div(props => ({ ...style(props) }))

const FilterList = ({ children, title }) => (
    <FilterListStyled>
        {!!title && <h2 className="filterList_title">{title}</h2>}
        <ul className="filterList_list" role="listbox">
            {Children.map(children, child => {
                if (!isValidElement(child)) {
                    return null
                }

                return <li className="filterList_item">{cloneElement(child)}</li>
            })}
        </ul>
    </FilterListStyled>
)

FilterList.propTypes = {
    title: PropTypes.string
}

FilterList.defaultProps = {
    title: undefined
}

export default memo(FilterList)
