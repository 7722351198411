const decorationBackgroundSVG = (color, isVertical = false) => {
    // remove color hashtag because of encoding for backgroundImage wave svg
    const plainColor = color ? color.replace('#', '') : '000000'
    if (isVertical) {
        return `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='5' height='11'%3e%3cpath d='M2.916 8.152l-.757.757A2.248 2.248 0 001.556 11H.033a3.744 3.744 0 011.065-3.152l.758-.757a2.25 2.25 0 000-3.182l-.758-.757A3.744 3.744 0 01.033 0h1.523a2.248 2.248 0 00.603 2.091l.757.757a3.75 3.75 0 010 5.304z' fill='%23${plainColor}'/%3e%3c/svg%3e")`
    }
    return `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='11' height='5'%3e%3cpath d='M8.152 1.591l.757.757A2.248 2.248 0 0011 2.951v1.523A3.744 3.744 0 017.848 3.41l-.757-.757a2.25 2.25 0 00-3.182 0l-.757.757A3.744 3.744 0 010 4.474V2.951a2.248 2.248 0 002.091-.603l.757-.757a3.75 3.75 0 015.304 0z' fill='%23${plainColor}'/%3e%3c/svg%3e")`
}

export default decorationBackgroundSVG
