import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './TikTokItem.style'

const IframeStyled = styled.div(props => ({ ...styles(props) }))

/**
 * We use iframe to embed tiktok because tiktok is really
 * bad in loading embeds in the SPA and dynamic DOM.
 *
 * Embed SDK does not how to reload embed after node
 * delete/insert.
 *
 * Iframe makes sure that embed SDK is only initiated
 * once per embed and that DOM is not rewritten.
 *
 * Also this contains simple logic of syncing iframe
 * width/height with its content, inspired by
 * wordpress embed element.
 *
 * https://github.com/WordPress/gutenberg/blob/b77066b38ae7e7a8894b33f328ccfeedfd265c8f/packages/block-library/src/embed/embed-preview.js#L25
 *
 * @param {Object} { htmlContent, fullWidth }
 * @return {*}
 */
const TiktokIframeContainer = ({ htmlContent, fullWidth }) => {
    const [contentRef, setContentRef] = useState(null)
    const [isLoaded, setLoaded] = useState(false)
    const [size, setSize] = useState()

    useEffect(() => {
        if (!isLoaded) {
            return undefined
        }

        const iframeBody = contentRef?.contentWindow?.document?.body

        const onMutation = () => {
            const firstChild = iframeBody?.firstChild

            if (!firstChild) {
                // eslint-disable-next-line no-console
                console.warn('TiktokIframeContainer is missing first child')

                return
            }

            const { top, right, bottom, left } = firstChild.getBoundingClientRect()

            const width = right + left
            const height = top + bottom

            setSize({ width, height })
        }

        iframeBody.style = 'margin: 0;'

        const observer = new MutationObserver(onMutation)
        observer.observe(iframeBody, {
            attributes: true,
            attributeOldValue: false,
            characterData: true,
            characterDataOldValue: false,
            childList: true,
            subtree: true
        })

        onMutation()

        return () => {
            observer.disconnect()
        }
    }, [isLoaded])

    const iframeStyle = useMemo(() => {
        const defaultStyle = {
            border: 0,
            outline: 0,
            margin: 0,
            padding: 0
        }

        if (size) {
            const { width, height } = size

            return {
                ...defaultStyle,
                width: fullWidth ? '100%' : `${width}px`,
                height: `${height}px`,
                backgroundColor: 'transparent',
                margin: !fullWidth ? '0 auto' : '',
                display: 'block'
            }
        }

        return defaultStyle
    }, [fullWidth, size])

    if (!htmlContent) {
        return null
    }

    return (
        <IframeStyled>
            <iframe
                ref={setContentRef}
                frameBorder={0}
                style={iframeStyle}
                title="Tiktok Embed"
                allowtransparency="true"
                srcDoc={htmlContent}
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-same-origin allow-presentation"
                onLoad={() => setLoaded(true)}
            />
        </IframeStyled>
    )
}

TiktokIframeContainer.propTypes = {
    htmlContent: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool
}

TiktokIframeContainer.defaultProps = {
    fullWidth: true
}

const WithMutationObserver = props => {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const loadPolyfill = async () => {
            if (typeof window.IntersectionObserver === 'undefined') {
                await import('mutation-observer')
            }

            setLoaded(true)
        }

        loadPolyfill()
    }, [])

    if (!loaded) {
        return null
    }

    return <TiktokIframeContainer {...props} />
}

export default WithMutationObserver
