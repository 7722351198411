import { global } from '@hmn/coolinarika-web-ui/theme'

const style = ({ theme }) => ({
    margin: 0,
    [theme.breakpoints.up('lg')]: {
        marginLeft: global.baseLayout.desktop.sidebar.width
    }
})

export default style
