import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, size, variant, fontVariant, hasLetterSpacing, isUppercase }) => {
    // for development errors
    const devWarnTxt = theme.colors.error.dev.text
    const devWarnBg = theme.colors.error.dev.background
    // for development errors

    const minHeight = {
        small: toRem(25),
        normal: toRem(30)
    }

    const padding = {
        small: toRems([4, 5]),
        normal: toRems([4, 12, 6, 12])
    }

    const fontSize = {
        small: toRem(13),
        normal: toRem(14),
        larger: toRem(15),
        large: toRem(18),
        xlarge: toRem(20)
    }

    const fontWeight = {
        small: theme.typography.fontWeight.semiBold,
        normal: theme.typography.fontWeight.black
    }

    const lineHeight = 1.2

    return {
        outline: '2px dashed red',
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        minHeight: minHeight[size] || minHeight.normal,
        margin: 0,
        padding: padding[size] || padding.normal,
        outline: 0,
        backgroundColor: theme.colors.heading.label[variant]?.background?.default || devWarnBg,
        color: theme.colors.heading.label[variant]?.text?.default || devWarnTxt,
        fontWeight: fontWeight[size] || fontWeight.normal,
        fontSize: fontSize[size] || fontSize.normal,
        fontVariant: fontVariant || 'normal',
        lineHeight,
        textDecoration: 'none',
        ...(hasLetterSpacing && {
            letterSpacing: toRem(4)
        }),
        ...(isUppercase && {
            textTransform: 'uppercase'
        }),
        '& .headingLabel': {
            '&_prefix': {
                fontWeight: theme.typography.fontWeight.regular
            },
            '&_suffix': {
                fontWeight: theme.typography.fontWeight.regular
            }
        }
    }
}

export default style
