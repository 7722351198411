/* eslint-disable no-console */
import { useMemo, useRef, useState } from 'react'
import { /* useMutation,  */ useQuery, useQueryClient } from 'react-query'

import { getList } from '../queries/requests/entityTaxon'
import useUser from './useUser'

/* const MutationAction = {
    CREATE: 'CREATE',
    DELETE: 'DELETE'
} */

/**
 * `useTaxonList` returns a list of taxons on a specified entity.
 *
 * Provides `data` and `total` values.
 *
 * As well as request information (`status`, `error`, ...).
 *
 * `action` key contains taxon `create` and `delete` methods.
 *
 * @param {string} resource
 * @param {*} [params={}]
 * @param {Object} [options={}]
 * @param {boolean} [options.enabled=true]
 * @param {*[]} [options.initialData=[]]
 * @param {boolean} [options.initialStale=true]
 * @param {boolean} [options.refetchOnWindowFocus=false]
 * @param {boolean} [options.enabledForAnonymous=true]
 * @param {boolean} [options.refetchOnLogin=false]
 * @example
 * const { data } = useTaxonList(
 *      'favorited',
 *      { entity: 'recipes', entityId: '12345' },
 *      {
 *          // if `true`, data will be loaded
 *          // if `false`, cache or `initialData` will be used
 *          enabled: true,
 *          // `array` of entities
 *          initialData: [],
 *          // if `true`, `initialData` will be replaced with fresh
 *          // if `false` `initialData` will be used
 *          initialStale: true,
 *          // if data should or should not be refetched when tabbing
 *          refetchOnWindowFocus: false,
 *          // set to `false` if data requires user token
 *          // set to `true` if data should load for everyone
 *          enabledForAnonymous: true,
 *          // set to `false` if data is equal for everyone
 *          // set to `true` if dada is unique per user
 *          // ignored if `enabledForAnonymous` is set to `false`
 *          refetchOnLogin: true
 *      }
 * )
 */
const useTaxonList = (resource, params = {}, options = {}) => {
    const queryClient = useQueryClient()
    const { id: loggedInUserId, isLoggedIn } = useUser()

    const { entityId } = params

    const {
        enabled = false,
        initialData = [],
        initialStale = true,
        refetchOnWindowFocus = false,
        enabledForAnonymous = true,
        refetchOnLogin = false
    } = options

    const [resourceUri] = useState(() => {
        let resourceBase = resource
        if (resource && resource.indexOf('taxonomies/') === 0) {
            resourceBase = resource.replace('taxonomies/', '')
            console.groupCollapsed(
                '%c[useTaxonList]%c Resource prefix is deprecated!',
                'font-weight: normal; color: #fff; background-color: #900; padding: 1px 2px;',
                'font-weight: normal; font-style: italic;'
            )
            console.log(`Use '${resourceBase}' instead of '${resource}'`)
            console.groupEnd()
        }
        return `taxonomies/${resourceBase}`
    })

    const queryEnabled = enabledForAnonymous || isLoggedIn ? enabled : false

    const queryKey = useMemo(() => {
        if ((enabledForAnonymous && !refetchOnLogin) || !isLoggedIn) {
            return [resourceUri, params]
        }
        return [resourceUri, params, loggedInUserId]
    }, [resourceUri, params, loggedInUserId, enabledForAnonymous, refetchOnLogin])

    const { current: queryInitialData } = useRef({
        data: initialData,
        total: initialData?.length || 0
    })

    const {
        data: queryResult,
        isFetching,
        error,
        status
    } = useQuery(queryKey, () => getList(queryKey), {
        enabled: params && entityId ? queryEnabled : false,
        initialData: queryInitialData,
        initialStale,
        refetchOnWindowFocus,
        refetchOnMount: false
    })

    /* const [queryMutation] = useMutation(
        async ({ taxon, action }) => {
            const { id } = taxon
            switch (action) {
                case MutationAction.CREATE:
                    return entityTaxon.createOne(resourceUri, {
                        entity: params.entity,
                        entityId: params.entityId,
                        taxonId: id
                    })
                case MutationAction.DELETE:
                    return entityTaxon.deleteOne(resourceUri, {
                        entity: params.entity,
                        entityId: params.entityId,
                        taxonId: id
                    })
                default:
                    return Promise.reject(new Error('Action has to be provided'))
            }
        },
        {
            onMutate: ({ taxon, action, cacheAction }) => {
                queryClient.cancelQueries(queryKey)

                const previousValue = queryClient.getQueryData(queryKey)

                switch (action) {
                    case MutationAction.CREATE:
                        queryClient.setQueryData(queryKey, (cache = { data: [], total: 0 }) => ({
                            data: [...cache.data, taxon],
                            total: cache.total + 1
                        }))
                        break
                    case MutationAction.DELETE:
                        queryClient.setQueryData(queryKey, (cache = { data: [], total: 0 }) => ({
                            data: cache.data.filter(item => (item.taxon?.id || item.id) !== taxon.id),
                            total: cache.total ? cache.total - 1 : 0
                        }))
                        break
                    default:
                        console.info('Action has to be provided')
                        break
                }

                if (cacheAction && typeof cacheAction === 'function') {
                    queryClient.setQueryData(queryKey, cache => cacheAction(cache) || cache)
                }

                return previousValue
            },
            onError: (err, variables, previousValue) => {
                queryClient.setQueryData(queryKey, previousValue)
            },
            onSettled: () => {
                queryClient.invalidateQueries(queryKey)
            }
        }
    ) */

    return {
        key: queryKey,
        data: queryResult?.data || queryInitialData.data,
        total: queryResult?.data ? queryResult?.total : queryInitialData.total,
        loading: isFetching,
        status,
        error,
        action: {
            create: async (taxon, { cacheAction } = {}) => {}, // queryMutation({ taxon, action: MutationAction.CREATE, cacheAction }),
            delete: async (taxon, { cacheAction } = {}) => {}, // queryMutation({ taxon, action: MutationAction.DELETE, cacheAction }),
            invalidate: () => {
                queryClient.invalidateQueries(queryKey)
            }
        }
    }
}

export default useTaxonList
