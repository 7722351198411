/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme }) => ({
    display: 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
        display: 'flex'
    }
})

export default style
