/* eslint-disable @typescript-eslint/no-var-requires */
import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { SearchActionTypes } from '../actions'
import { rootReducer } from '../reducers'
import { rootSaga } from '../sagas'

const createMiddlewares = (isDev, isClient) => {
    const createdMiddlewares = []
    const sagaMiddleware = createSagaMiddleware()

    createdMiddlewares.push(sagaMiddleware)

    if (isDev && isClient) {
        // eslint-disable-next-line global-require
        const { createLogger } = require('redux-logger')
        // Add actions you don't want to log here, usually spammy actions
        const blacklistActions = [SearchActionTypes.SAVE]
        createdMiddlewares.push(
            createLogger({
                level: 'info',
                // Filter actions to be logged
                predicate: (getState, action) => !blacklistActions.includes(action.type),
                // Only collapse actions that don't have errors
                collapsed: (getState, action, logEntry) => !logEntry.error
            })
        )
    }

    return createdMiddlewares
}

const bindMiddleware = (middleware, isDev) => {
    if (isDev) {
        // eslint-disable-next-line global-require
        const { composeWithDevTools } = require('redux-devtools-extension/developmentOnly')
        return composeWithDevTools(applyMiddleware(...middleware))
    }

    return applyMiddleware(...middleware)
}

export const initStore = () => {
    const isClient = typeof window !== 'undefined'
    const isDev = process.env.NODE_ENV !== 'production'

    const middlewares = createMiddlewares(isDev, isClient)
    const sagaMiddleware = middlewares[0]

    const reduxStore = createStore(rootReducer, bindMiddleware(middlewares, isDev))

    // This runs sagas on server
    reduxStore.sagaTask = sagaMiddleware.run(rootSaga)

    return reduxStore
}

export const wrapper = createWrapper(initStore /* , { debug: true } */)
