/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavSuperfoodChefAI = createSvgIcon(
    <>
        <path
            fill="#141313"
            fillRule="evenodd"
            d="M23.381 21.342c.942-.829 1.419-1.983 1.419-3.342s-.477-2.513-1.419-3.342c-.954-.84-2.517-1.458-4.881-1.458-2.364 0-3.927.618-4.882 1.458-.94.829-1.418 1.983-1.418 3.342 0 1.292.432 2.396 1.276 3.21.41.396.724.976.724 1.657v1.709l1.79-1.366a2.247 2.247 0 0 1 1.542-.447c.307.024.63.037.968.037 2.364 0 3.927-.617 4.881-1.458ZM26 18c0 3.314-2.368 6-7.5 6-.368 0-.723-.014-1.063-.04a1.048 1.048 0 0 0-.72.205l-2.11 1.61A1 1 0 0 1 13 24.98v-2.113c0-.302-.14-.584-.357-.793C11.533 21.004 11 19.573 11 18c0-3.314 2.368-6 7.5-6s7.5 2.686 7.5 6Z"
            clipRule="evenodd"
        />
    </>,
    'NavSuperfoodChefAI'
)

export default NavSuperfoodChefAI
