/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import { useEffect, useRef } from 'react'
import { _DFPManager, _DFPSlotsProvider } from 'react-dfp'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { SettingsActions } from '../../actions'
import { useScript } from '../../hooks'

// const slotLazyLoadTimeout = 1000

const reloadDfpSlots = () => {
    console.warn('[AdsProvider] reload slots')
    // setTimeout(() => DFPManager.reload(), slotLazyLoadTimeout)
    // DFPManager.destroyGPTSlots() // .then(r => console.log('destroyed slots', r))
}

const loadDfpSlots = () => {
    console.warn('[AdsProvider] load slots')
    // setTimeout(() => DFPManager.load(), slotLazyLoadTimeout)
}

const resetDfpSlots = () => {
    console.warn('[AdsProvider] load & destroy slots')
    // setTimeout(() => DFPManager.load(), slotLazyLoadTimeout)
    // DFPManager.destroyGPTSlots()
}

const useWtg = process.env.NEXT_PUBLIC_GLOBAL_BANNERS_USE_WTG === 'true'

const AdsProvider = ({ children, dfpNetworkId, collapseEmptyDivs, singleRequest, ...rest }) => {
    const dispatch = useDispatch()

    // useScript(
    //     {
    //         src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
    //         options: {
    //             async: true,
    //             readyCondition: () => !!(typeof window !== 'undefined')
    //         }
    //     },
    //     () => {
    //         const googletag = window?.googletag || {}
    //         if (window?.googletag) {
    //             window.googletag.cmd = googletag.cmd || []
    //         }
    //     }
    // )

    const callbackDispatchRef = useRef(false)

    useScript(
        {
            src: 'https://lib.wtg-ads.com/lib.min.js',
            options: {
                async: true,
                type: 'text/plain',
                customData: {
                    category: 'marketing',
                    service: 'wtg-ads',
                    src: 'https://lib.wtg-ads.com/lib.min.js'
                },
                readyCondition: () => !!(typeof window !== 'undefined' && useWtg),
                loadCondition: () => !!(useWtg && typeof window !== 'undefined' && !window?.w2g)
            }
        },
        () => {
            window.googletag = window?.googletag || {}
            window.googletag.cmd = window.googletag.cmd || []

            window.w2g = window?.w2g || {}
            if (window?.w2g) {
                window.w2g.hostname = 'www.coolinarika.com'
            }
        }
    )

    useEffect(() => {
        const handleWtgLoadEvent = () => {
            if (useWtg && !callbackDispatchRef.current) {
                callbackDispatchRef.current = true
                dispatch(SettingsActions.setWtgLoaded(true))
            }
        }

        window.addEventListener('w2gLoaded', handleWtgLoadEvent)

        return () => {
            window.removeEventListener('w2gLoaded', handleWtgLoadEvent)
        }
    }, [useWtg, dispatch])

    return children

    /* return (
        <DFPSlotsProvider
            dfpNetworkId={`${dfpNetworkId}`}
            collapseEmptyDivs={collapseEmptyDivs}
            singleRequest={singleRequest}
            autoLoad={false}
            {...rest}>
            {children}
        </DFPSlotsProvider>
    ) */
}

AdsProvider.propTypes = {
    dfpNetworkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    collapseEmptyDivs: PropTypes.bool,
    singleRequest: PropTypes.bool
}

AdsProvider.defaultProps = {
    dfpNetworkId: 11539325,
    collapseEmptyDivs: false,
    singleRequest: true
}

export { reloadDfpSlots, loadDfpSlots, resetDfpSlots }

export default AdsProvider
