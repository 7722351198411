/* eslint-disable no-console, max-len */
import { useCallback, useEffect, useState } from 'react'

/**
 * Easily dynamically load an external script and know when it's loaded.
 * Useful for interaction with 3rd party libraries (e.g. Google Analytics or various Ad Providers) and you'd
 * prefer to load the script when needed rather than include it in the document head for every page request.
 *
 * In the example blow, we wait until the script has loaded successfully before calling a function declared in the script.
 *
 *      onst status = useScript({ src: '//mas1.adcumulus.com/static/js/app.js', statusCondition: () => {
 *          return window?.Mas && Array.isArray(window?.Mas?.ads)
 *      }})
 *
 *      useEffect(() => {
 *          if (status === 'ready') {
 *              Mas.ads.push({
 *                  handler: () => Mas.register('36qm7gt4jpbuc6m29pdg3','0',params)
 *              })
 *          }
 *      }, [status])
 *
 *  This hook also supports custom condition you can use to further verify whether you're ready to use the script methods or not.
 *  You can also provide load condition if you need to wait for something before loading your script, e.g. you need to load your dependencies first.
 *
 *  But that's not all, you can also provider ref condition if script should be invoked only when target element is rendered and exists in DOM.
 *
 *  Here's a more complex use example:
 *
 *      const { isReady, ref, refCondition } = useScript(
 *          {
 *              src: '',
 *              loadCondition: () => {
 *                  return process?.browser
 *              },
 *              readyCondition: ({ ref }) => {
 *                  return ref?.id && window?.Mas && Array.isArray(window?.Mas?.ads)
 *              }
 *          },
 *          ({ ref }) => {
 *              Mas.ads.push({
 *                  handler: () => Mas.register(ref.id, '0', params)
 *              })
 *          }
 *      )
 *
 *      useEffect(() => {
 *          if (isReady) {
 *              Mas.ads.push({
 *                  handler: () => Mas.register(ref.id, '0', params)
 *              })
 *          }
 *      }, [isReady, ref])
 *
 *
 *      <div id="36qm7gt4jpbuc6m29pdg3" ref={refCondition}></div>
 *
 * However, if you need to reuse this many times, different ad-provider-specific hook & context provider should be created,
 * while only using this hook to initially mount the 3rd party script and to invoke 3rd party methods.
 *
 * @param {*} { src, options = {} }
 * @return {*}
 */
const useScript = ({ src, options = {} }, callback) => {
    const {
        async = true,
        suspense = false,
        defer,
        crossOrigin,
        readyCondition,
        loadCondition,
        reinitialize,
        type,
        customData = {}
    } = options
    // Keep track of script status ('idle, 'loading', 'ready', 'error')
    const [status, setStatus] = useState(src ? 'loading' : 'idle')
    const [refElement, setRefElement] = useState(null)

    const refCondition = useCallback(node => {
        if (node !== null) {
            setRefElement(node)
        }
    }, [])

    const createScript = useCallback(
        (script, resolve, reject) => {
            let scriptElement = script
            // If not existing script is found, create it
            scriptElement = document.createElement('script')

            if (!('src' in customData)) {
                scriptElement.src = src
            }

            if (suspense) {
                scriptElement.onload = () => resolve(script)
                scriptElement.onerror = reject
            } else {
                scriptElement.async = async
            }

            if (defer) {
                scriptElement.defer = defer
            }

            if (crossOrigin) {
                scriptElement.crossOrigin = crossOrigin
            }

            if (type) {
                scriptElement.type = type
            }

            scriptElement.setAttribute('data-status', 'loading')

            Object.entries(customData).forEach(([name, value]) => {
                scriptElement.setAttribute(`data-${name}`, value)
            })

            // Add script to document body
            document.body.appendChild(scriptElement)

            /**
             * Store status in attribute on script.
             *
             * This can be read by other instances of this hook.
             */
            const setAttributeFromEvent = event =>
                scriptElement.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error')

            scriptElement.addEventListener('load', setAttributeFromEvent)
            scriptElement.addEventListener('error', setAttributeFromEvent)
        },
        [src]
    )

    useEffect(() => {
        if (!src) {
            // Allow falsy src value if waiting on another data needed for constructing the script URL passed to this hook
            setStatus('idle')
            return () => {}
        }

        /**
         * Fetch existing script element by src.
         *
         * Also check if it may have been added by another instance of this hook.
         */
        const script =
            document.querySelector(`script[src="${src}"]`) ?? document.querySelector(`script[data-src="${src}"]`)

        /**
         * Script event handler to update status in state.
         *
         * Even if the script already exists we still need to add event handlers
         * to update the state for *this* hook instance.
         */
        const setStateFromEvent = event => {
            setStatus(event.type === 'load' ? 'ready' : 'error')
        }

        if (!script) {
            if (typeof loadCondition !== 'undefined') {
                if (typeof loadCondition !== 'function') {
                    console.error(
                        `[useScript]: Invalid 'loadCondition' '${loadCondition}' provided. Condition must be of 'function' type.`
                    )
                    setStatus('error')
                }

                if (typeof loadCondition === 'function') {
                    let returnValue

                    try {
                        returnValue = loadCondition()
                    } catch (error) {
                        console.error(`[useScript]: 'loadCondition' function threw the following error: ${error}`)
                    }

                    if (typeof returnValue !== 'boolean') {
                        console.error(
                            `[useScript]: Invalid 'loadCondition' return type, returned '${typeof returnValue}'. Condition return type must be boolean.`
                        )
                        setStatus('error')
                    }
                }

                if (loadCondition()) {
                    if (suspense) {
                        // This will throw a Promise, it MUST be used with React.Suspense
                        return new Promise((resolve, reject) => {
                            createScript(script, resolve, reject)
                        })
                    }
                    createScript(script)
                }
            } else {
                if (suspense) {
                    // This will throw a Promise, it MUST be used with React.Suspense
                    return new Promise((resolve, reject) => {
                        createScript(script, resolve, reject)
                    })
                }
                createScript(script)
            }
        } else {
            // Grab existing script status from attribute and update state
            setStatus(script.getAttribute('data-status'))

            // Add event listeners
            script.addEventListener('load', setStateFromEvent)
            script.addEventListener('error', setStateFromEvent)
        }

        if (typeof readyCondition !== 'undefined') {
            if (typeof readyCondition !== 'function') {
                console.error(
                    `[useScript]: Invalid 'readyCondition' '${readyCondition}' provided. Condition must be of 'function' type.`
                )
                setStatus('error')
            }

            if (typeof readyCondition === 'function') {
                let returnValue = false

                try {
                    returnValue = readyCondition({ ref: refElement })
                } catch (error) {
                    console.error(`[useScript]: 'readyCondition' function threw the following error: ${error}`)
                }

                if (typeof returnValue !== 'boolean') {
                    console.error(
                        `[useScript]: Invalid 'readyCondition' return type, returned '${typeof returnValue}'. Condition return type must be boolean.`
                    )
                    setStatus('error')
                }
            }

            if (readyCondition({ ref: refElement, status })) {
                setStatus('ready')
            } else {
                setStatus('error')
            }
        }

        // Remove event listeners on cleanup
        return () => {
            if (script) {
                script.removeEventListener('load', setStateFromEvent)
                script.removeEventListener('error', setStateFromEvent)
                if (reinitialize) {
                    document.body.removeChild(script)
                }
            }
        }
    }, [src, readyCondition, loadCondition]) // Only re-run if script src, load or ready conditions change

    // Toggle callback when refElement exists, if provided
    useEffect(() => {
        if (status === 'ready' && typeof callback === 'function') {
            callback({ refElement })
        }
    }, [status, refElement, callback])

    return {
        isReady: status === 'ready',
        status,
        ref: refElement,
        refCondition
    }
}

export default useScript
