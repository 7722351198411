// We need to centralize the zIndex definitions as they work like global values in the browser.
export default {
    baseLayout: {
        content: 10,
        sidebar: 20,
        topBar: {
            desktop: 20,
            mobile: 552
        }
    },
    under: '-1',
    over: 1,
    scrollbar: {
        shadow: 40
    },
    card: {
        action: 2,
        body: 5 // example for club card
    },
    tooltip: 50,
    header: 100,
    navigation: {
        inPage: 200,
        bar: 551,
        panel: 499
    },
    search: {
        filters: {
            desktop: 19,
            mobile: 551
        },
        results: {
            desktop: 19,
            mobile: 550
        }
    },
    topLine: 400,
    recipeUploader: 450,
    drawer: 500,
    modal: 600,
    cookieNotice: 700,
    emperor: 1001 // rule them all
}
