/* eslint-disable camelcase */

// @TODO: Add to favorites functionality and styling
// @TODO: Edit button functionality and styling

import { memo, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import PropTypes from 'prop-types'

import { useDrawer, useOneElastic, useTaxonList, useUser } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { Card, cardRoundedVariants } from '../../../Card'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HeadingLabel, headingLabelSizes, headingLabelVariants } from '../../../Heading/components/Label'
import { Highlight, highlightSizes } from '../../../Highlight'
import { EditIcon, HeartFillIcon, HeartOutlineIcon, Icon } from '../../../Icon'
import { Image, imageRoundedVariants } from '../../../Image'
import styles from './CardSmall.style'

const RecipeCardSmallStyled = styled.div(props => ({ ...styles(props) }))

dayjs.locale('hr')
dayjs.extend(relativeTime)

const RecipeCardSmall = ({
    item,
    id: propId,
    imageId: propImageId,
    imageWidth,
    imageHeight,
    label,
    userNameWithTimeLabel,
    date,
    title: propTitle,
    titleTagComponent: TitleTag,
    href,
    hrefAs,
    authorId: propAuthorId,
    authorName: propAuthorName,
    className,
    fullHeight,
    actionComponent: Action,
    showFavoritedOnHover,
    noLabel,
    onCardClick,
    ...rest
}) => {
    const {
        id = propId,
        author,
        owned_by_user_profile,
        title: itemTitle,
        image: itemImage
    } = useMemo(() => item || {}, [])
    const { id: userProfileId = propAuthorId } = owned_by_user_profile || {}
    const { id: authorId = userProfileId } = author || {}
    const { id: imageId = propImageId } = itemImage || {}

    const { data: recipeAuthor } = useOneElastic({ resource: 'ljudi', id: authorId, enabled: !!authorId })

    const { id: loggedInUserId } = useUser()

    const { open: openCollectionsOverviewDrawer } = useDrawer('collections-overview')

    const showFavorited = !!loggedInUserId

    const { data: favoritedCategory, action: favoritedCategoryAction } = useTaxonList(
        // 'favorited_categorized',
        'favorited',
        {
            entity: 'recipes',
            entityId: item?.id
        },
        {
            refetchOnWindowFocus: false,
            enabledForAnonymous: false
        }
    )

    // https://day.js.org/docs/en/i18n/loading-into-browser
    const recipeCreatedFromNow =
        userNameWithTimeLabel && item?.created_at ? dayjs(item?.created_at).fromNow() : undefined

    const isFavorited = useMemo(() => favoritedCategory?.length > 0, [favoritedCategory])

    const handleAddToCollection = useCallback(() => {
        if (!isFavorited) {
            favoritedCategoryAction.create({
                id: '/taxonomies/favorites/taxons/uuid-is-favorite'
            })
        }
        openCollectionsOverviewDrawer({ contentId: id })
    }, [id, isFavorited])

    const title = propTitle || itemTitle
    const authorUserName = propAuthorName || recipeAuthor?.display_name
    const cardLabel = `${label || authorUserName}${recipeCreatedFromNow ? ', ' : ''}`

    const recipeOfTheDayListingLabel = date ? `${dayjs(date).format('DD.M.')} by` : undefined

    // @TODO: get edit rights fo user and set `edit`to true if logged in user has edit rights (user or admin with edit
    // rights)
    const edit = authorId && loggedInUserId && authorId === loggedInUserId

    const handleEditClick = () => {
        // placeholder function for profile edit button
        /* eslint-disable-next-line */
        alert('TODO: Edit button click action.')
    }

    const cardActions = useCallback(() => {
        if (edit) {
            return (
                <Button
                    variant={buttonVariants.SECONDARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title="Uredi"
                    onClick={handleEditClick}>
                    <Icon icon={EditIcon} size={24} />
                </Button>
            )
        }
        if (Action) {
            return <Action />
        }
        if (showFavorited) {
            return (
                <Button
                    variant={buttonVariants.PRIMARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title={isFavorited ? 'Uredi' : 'Spremi'}
                    onClick={handleAddToCollection}>
                    <Icon icon={isFavorited ? HeartFillIcon : HeartOutlineIcon} size={32} />
                </Button>
            )
        }
        return null
    }, [
        edit,
        handleEditClick,
        EditIcon,
        Action,
        showFavorited,
        isFavorited,
        handleAddToCollection,
        HeartFillIcon,
        HeartOutlineIcon
    ])

    if (!title && !imageId) {
        return null
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            actionComponent={cardActions}
            disableActionComponent // @TODO: remove or set to false when favorite and edit functionality is done
            rounded={cardRoundedVariants.MEDIUM}
            showFavoritedOnHover={showFavoritedOnHover}
            shadow={false}
            onClick={onCardClick}
            {...rest}>
            <RecipeCardSmallStyled className={className} {...rest}>
                <div className="cardSmall_asset">
                    <Image
                        className="cardPicture"
                        fullHeight={fullHeight}
                        image={imageId}
                        placeholderBgColor={
                            itemImage?.colors?.[itemImage?.colors?.length - 1]?.hex || rest?.placeholderBgColor
                        }
                        alt={title}
                        ratio={1}
                        height={imageHeight}
                        width={imageWidth}
                        rounded={imageRoundedVariants.MEDIUM}
                    />
                </div>
                {(title || label || authorUserName) && (
                    <div className="cardSmall_content cardContent_title">
                        {!noLabel && (label || authorUserName) && (
                            <HeadingLabel
                                prefix={recipeOfTheDayListingLabel}
                                label={cardLabel}
                                suffix={recipeCreatedFromNow}
                                variant={headingLabelVariants.TERTIARY}
                                size={headingLabelSizes.SMALL}
                            />
                        )}

                        {title && (
                            <Highlight size={highlightSizes.XSMALL} TagComponent={TitleTag} hasHover>
                                {title}
                            </Highlight>
                        )}
                    </div>
                )}
            </RecipeCardSmallStyled>
        </Card>
    )
}

RecipeCardSmall.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    authorId: PropTypes.string,
    authorName: PropTypes.string,
    className: PropTypes.string,
    fullHeight: PropTypes.bool,
    actionComponent: PropTypes.elementType,
    showFavoritedOnHover: PropTypes.bool,
    userNameWithTimeLabel: PropTypes.bool,
    noLabel: PropTypes.bool,
    onCardClick: PropTypes.func
}

RecipeCardSmall.defaultProps = {
    item: undefined,
    id: undefined,
    imageId: undefined,
    imageWidth: 390,
    imageHeight: 360,
    label: undefined,
    date: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    href: undefined,
    hrefAs: undefined,
    authorId: undefined,
    authorName: undefined,
    className: undefined,
    fullHeight: false,
    actionComponent: undefined,
    showFavoritedOnHover: false,
    userNameWithTimeLabel: false,
    noLabel: false,
    onCardClick: undefined
}

export default withErrorBoundary(memo(RecipeCardSmall))
