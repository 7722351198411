const constants = {
    CLOSE: 'HMN/COOLINARIKA/WEB/NAVIGATION/CLOSE_NAVIGATION',
    OPEN: 'HMN/COOLINARIKA/WEB/NAVIGATION/OPEN_NAVIGATION',
    TOGGLE: 'HMN/COOLINARIKA/WEB/NAVIGATION/TOGGLE_NAVIGATION',
    SET_DIRECTION: 'HMN/COOLINARIKA/WEB/NAVIGATION/SET_DIRECTION',
    IS_NAVIGATING: 'HMN/COOLINARIKA/WEB/NAVIGATION/IS_NAVIGATING'
}

const actions = {
    open: () => ({ type: constants.OPEN }),
    close: () => ({ type: constants.CLOSE }),
    toggle: () => ({ type: constants.TOGGLE }),
    setIsNavigating: isNavigating => ({ type: constants.IS_NAVIGATING, isNavigating }),
    setDirection: direction => ({ type: constants.SET_DIRECTION, direction })
}

export { constants as NavigationActionTypes, actions as NavigationActions }
