/* eslint-disable no-nested-ternary, max-len */

// @TODO: loading

import { keyframes } from '@emotion/react'

import { decorationBackgroundSVG, toRem, toRems } from '../../helpers/theme'
import { normalizeDimension } from '../../helpers/utils'

// isActionLink wave hover animation
const waveAnimation = () => {
    const wave = keyframes({
        from: {
            backgroundPosition: '0px 100%'
        },
        to: {
            backgroundPosition: '500px 100%'
        }
    })
    return {
        animationName: wave,
        animationDuration: '15s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        animationPlayState: 'paused'
    }
}

const style = ({
    theme,
    size,
    sizeMobile,
    variant,
    simple,
    squared,
    transparent,
    buttonWidth,
    buttonWidthMobile,
    isLoading,
    customFontSize,
    isIconOnly,
    disableIconFill,
    hasShadow,
    disableBorder,
    isActionLink,
    rounded
}) => {
    // for development errors
    const devWarnTxt = theme.colors.error.dev.text
    const devWarnBg = theme.colors.error.dev.background
    // for development errors

    const roundedConfig = {
        small: toRem(4),
        medium: toRem(8),
        large: toRem(15),
        larger: toRem(100),
        rounded: '50%'
    }

    const config = {
        small: {
            minHeight: toRem(28),
            borderWidth: toRem(2),
            padding: isIconOnly ? toRem(2) : isActionLink ? toRems([4, 0]) : toRems([3, 8, 5]),
            fontSize: isIconOnly ? toRem(20) : toRem(customFontSize || 12),
            icon: {
                fontSize: toRem(20),
                hSpacing: isIconOnly ? 0 : toRem(2)
            }
        },
        normal: {
            minHeight: toRem(40),
            borderWidth: toRem(2),
            padding: isIconOnly ? toRem(6) : isActionLink ? toRems([10, 0]) : toRems([9, 16, 10]),
            fontSize: isIconOnly ? toRem(24) : toRem(customFontSize || 14),
            icon: {
                fontSize: toRem(24),
                hSpacing: isIconOnly ? 0 : toRem(6)
            }
        },
        larger: {
            minHeight: toRem(56),
            padding: isIconOnly ? toRem(12) : isActionLink ? toRems([14, 0]) : toRems([13, 32, 15]),
            borderWidth: 0,
            fontSize: isIconOnly ? toRem(32) : toRem(customFontSize || 18),
            icon: {
                fontSize: toRem(32),
                hSpacing: isIconOnly ? 0 : toRem(6)
            }
        },
        large: {
            minHeight: toRem(60),
            borderWidth: toRem(2),
            padding: isIconOnly ? toRem(12) : isActionLink ? toRems([14, 0]) : toRems([13, 32, 15]),
            fontSize: isIconOnly ? toRem(32) : toRem(customFontSize || 18),
            icon: {
                fontSize: toRem(32),
                hSpacing: isIconOnly ? 0 : toRem(6)
            }
        },
        icon: {
            vSpacing: isIconOnly ? 0 : toRems([-4, 0]),
            hSpacingOffset: isIconOnly ? 0 : toRem(-4)
        },
        ...(!rounded && {
            borderRadius: isIconOnly ? '100%' : toRem(100)
        }),
        ...(rounded && {
            borderRadius: roundedConfig[rounded]
        }),
        lineHeight: 1.25,
        reset: {
            dimensions: {
                minWidth: 0,
                minHeight: 0,
                padding: 0
            },
            border: {
                border: 'none',
                borderRadius: 0
            }
        }
    }

    // we manipulate border color and not border width because of size difference and padding calc complications
    const getBorderColor = color => (disableBorder ? 'transparent' : color || devWarnBg)

    return {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        minWidth: normalizeDimension(buttonWidthMobile, true) || normalizeDimension(buttonWidth, true) || 0,
        minHeight: sizeMobile ? config[sizeMobile]?.minHeight : config[size]?.minHeight || config.normal?.minHeight,
        margin: 0,
        padding: sizeMobile ? config[sizeMobile]?.padding : config[size]?.padding || config.normal?.padding,
        borderStyle: 'solid',
        borderWidth: config[size].borderWidth,
        borderColor: getBorderColor(theme.colors.button[variant]?.border?.default),
        ...(!squared && {
            borderRadius: config.borderRadius
        }),
        outline: 0,
        ...(!transparent && {
            backgroundColor: theme.colors.button[variant]?.background?.default || devWarnBg
        }),
        color: theme.colors.button[variant]?.text?.default || devWarnTxt,
        fontFamily: theme.typography.font.default,
        fontWeight: theme.typography.fontWeight.black,
        fontSize: sizeMobile ? config[sizeMobile]?.fontSize : config[size]?.fontSize || config.normal?.fontSize,
        lineHeight: config.lineHeight,
        textDecoration: 'none',
        cursor: 'pointer',
        transitionDuration: theme.transitions.button.default.duration,
        transitionTimingFunction: theme.transitions.button.default.timingFunction,
        transitionDelay: theme.transitions.button.default.delay,
        transitionProperty: 'color, background-color, border-color',
        userSelect: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        ...(simple && {
            verticalAlign: 'baseline',
            ...config?.reset?.dimensions,
            ...config?.reset?.border,
            backgroundColor: 'transparent',
            fontWeight: theme.typography.fontWeight.regular,
            color: theme.colors.link[variant]?.text?.default || devWarnTxt
        }),
        ...(isActionLink && {
            verticalAlign: 'baseline',
            ...config?.reset?.dimensions,
            ...config?.reset?.border,
            paddingBottom: 8,
            fontWeight: theme.typography.fontWeight.black,
            letterSpacing: 4,
            textTransform: 'uppercase',
            color: theme.colors.link[variant]?.text?.default || devWarnTxt,
            backgroundColor: 'transparent',
            backgroundImage: decorationBackgroundSVG(theme.colors.link[variant]?.text?.default),
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'left 100%',
            backgroundSize: 'auto auto',
            ...waveAnimation()
        }),
        ...(hasShadow &&
            !simple &&
            !isActionLink && {
                boxShadow: theme.shadows.deep
            }),
        ...(isLoading && {
            pointerEvents: 'none',
            cursor: 'wait'
        }),
        [theme.breakpoints.up('md')]: {
            minWidth: normalizeDimension(buttonWidth, true) || 0,
            minHeight: config[size]?.minHeight || config.normal?.minHeight,
            padding: config[size]?.padding || config.normal?.padding,
            fontSize: config[size]?.fontSize || config.normal?.fontSize,
            ...(simple && {
                ...config?.reset?.dimensions
            }),
            ...(isActionLink && {
                ...config?.reset?.dimensions,
                paddingBottom: 8
            })
        },
        '&:focus': {
            color: theme.colors.button[variant]?.text?.focus || devWarnTxt,
            ...(!transparent && {
                backgroundColor: theme.colors.button[variant]?.background?.focus || devWarnBg,
                borderColor: getBorderColor(theme.colors.button[variant]?.border?.focus),
                ...(simple && {
                    backgroundColor: 'transparent',
                    color: theme.colors.link[variant]?.text?.focus || devWarnTxt
                }),
                ...(isActionLink && {
                    backgroundColor: 'transparent',
                    backgroundImage: decorationBackgroundSVG(theme.colors.link[variant]?.text?.focus),
                    color: theme.colors.link[variant]?.text?.focus || devWarnTxt
                })
            })
        },
        '&:hover': {
            color: theme.colors.button[variant]?.text?.hover || devWarnTxt,
            ...(!transparent && {
                backgroundColor: theme.colors.button[variant]?.background?.hover || devWarnBg,
                borderColor: getBorderColor(theme.colors.button[variant]?.border?.hover),
                ...(simple && {
                    backgroundColor: 'transparent',
                    color: theme.colors.link[variant]?.text?.hover || devWarnTxt,
                    textDecoration: 'underline'
                }),
                ...(isActionLink && {
                    backgroundColor: 'transparent',
                    backgroundImage: decorationBackgroundSVG(theme.colors.link[variant]?.text?.hover),
                    color: theme.colors.link[variant]?.text?.hover || devWarnTxt,
                    textDecoration: 'none',
                    animationPlayState: 'running'
                })
            })
        },
        '&:active': {
            backgroundColor: theme.colors.button[variant]?.background?.active || devWarnBg,
            borderColor: getBorderColor(theme.colors.button[variant]?.border?.active),
            color: theme.colors.button[variant]?.text?.active || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.active || devWarnTxt
            }),
            ...(isActionLink && {
                backgroundColor: 'transparent',
                backgroundImage: decorationBackgroundSVG(theme.colors.link[variant]?.text?.active),
                color: theme.colors.link[variant]?.text?.active || devWarnTxt
            })
        },
        '&.disabled, [disabled], [disabled="disabled"]': {
            backgroundColor: theme.colors.button[variant]?.background?.disabled || devWarnBg,
            borderColor: getBorderColor(theme.colors.button[variant]?.border?.disabled),
            color: theme.colors.button[variant]?.text?.disabled || devWarnTxt,
            pointerEvents: 'none',
            cursor: 'default',
            ...(!isLoading && {
                opacity: 0.6
            }),
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.disabled || devWarnTxt
            }),
            ...(isActionLink && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.disabled || devWarnTxt,
                animationPlayState: 'paused'
            })
        },
        '& .btn_icon': {
            display: 'inherit',
            fontSize: sizeMobile
                ? config[sizeMobile]?.icon?.fontSize
                : config[size]?.icon?.fontSize || config.normal?.icon?.fontSize,
            margin: config.icon?.vSpacing,
            [theme.breakpoints.up('md')]: {
                fontSize: config[size]?.icon?.fontSize || config.normal?.icon?.fontSize
            },
            '&Left': {
                marginRight: sizeMobile ? config[sizeMobile]?.icon?.hSpacing : config[size]?.icon?.hSpacing,
                marginLeft: config.icon?.hSpacingOffset,
                [theme.breakpoints.up('md')]: {
                    marginRight: config[size]?.icon?.hSpacing
                }
            },
            '&Right': {
                marginRight: config.icon?.hSpacingOffset,
                marginLeft: sizeMobile ? config[sizeMobile]?.icon?.hSpacing : config[size]?.icon?.hSpacing,
                [theme.breakpoints.up('md')]: {
                    marginLeft: config[size]?.icon?.hSpacing
                }
            },
            '& > span': {
                fontSize: 'inherit'
            }
        },
        svg: {
            path: {
                fill: !disableIconFill && 'currentColor'
            }
        },
        '@media print': {
            display: 'none'
        }
    }
}

export default style
