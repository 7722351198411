/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useAnalytics, useInViewAnalytics } from '@hmn/coolinarika-web-core/hooks'

import { Link } from '../Link'
import styles from './Card.style'

const CardStyled = styled.div(props => ({ ...styles(props) }))

const Card = ({
    children,
    title,
    href,
    hrefAs,
    className,
    actionComponent: Action,
    disableActionComponent,
    shadow,
    rounded,
    showActionComponentOnHover,
    fullHeight,
    noMinHeight,
    isEmbedded,
    item,
    onClick,
    onClickEventTracking,
    onViewEventTracking,
    ...rest
}) => {
    const { eventTracking } = useAnalytics('coolinarika.productDetails')
    const { ref } = useInViewAnalytics('coolinarika.productImpression', {
        'trackingData.eec': {
            name: item?.title || title
        }
    })

    const handleClick = () => {
        if (onClickEventTracking) {
            onClickEventTracking(eventTracking, item)
        } else {
            eventTracking({
                'trackingData.eec': {
                    name: item?.title || title
                }
            })
        }
    }

    if (!children) {
        return null
    }

    return (
        <CardStyled
            {...((item || title) && {
                ref
            })}
            fullHeight={fullHeight}
            rounded={rounded}
            className={className}
            onClick={onClick || handleClick}
            shadow={shadow}
            showActionComponentOnHover={showActionComponentOnHover}
            noMinHeight={noMinHeight}
            {...rest}>
            {Action && !disableActionComponent && (
                <div className="cardAction">
                    <Action />
                </div>
            )}
            {href || hrefAs ? (
                isEmbedded ? (
                    <a href={`https://www.coolinarika.com${hrefAs}`} target="_blank" rel="noreferrer">
                        {children}
                    </a>
                ) : (
                    <Link href={href} as={hrefAs} title={title} className="cardInner">
                        {children}
                    </Link>
                )
            ) : (
                <div className="cardInner">{children}</div>
            )}
        </CardStyled>
    )
}

const cardRoundedVariants = Object.freeze({
    SMALL: 'small', // toRem(4)
    MEDIUM: 'medium', // toRem(8)
    LARGE: 'large' // toRem(15)
})

Card.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string,
    actionComponent: PropTypes.elementType,
    disableActionComponent: PropTypes.bool,
    shadow: PropTypes.bool,
    showActionComponentOnHover: PropTypes.bool,
    rounded: PropTypes.oneOf([...Object.values(cardRoundedVariants)]),
    fullHeight: PropTypes.bool,
    noMinHeight: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    onClick: PropTypes.func,
    onClickEventTracking: PropTypes.func,
    onViewEventTracking: PropTypes.func
}

Card.defaultProps = {
    title: undefined,
    href: undefined,
    hrefAs: undefined,
    className: undefined,
    actionComponent: undefined,
    disableActionComponent: false,
    shadow: true,
    showActionComponentOnHover: false,
    rounded: undefined,
    fullHeight: false,
    noMinHeight: false,
    isEmbedded: false,
    item: undefined,
    onClick: undefined,
    onClickEventTracking: undefined,
    onViewEventTracking: undefined
}

export { cardRoundedVariants }

export default Card
