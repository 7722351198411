// https://github.com/garmeeh/next-seo#default-seo-configuration
export const createSeoConfig = ({ webBase, siteName, isHomepage }) => ({
    titleTemplate: isHomepage ? undefined : `%s - ${typeof siteName !== 'string' ? 'Coolinarika' : siteName}`,
    defaultTitle: 'Coolinarika',
    openGraph: {
        type: 'website',
        locale: 'hr_HR',
        url: webBase,
        site_name: siteName
    }
})
