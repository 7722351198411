// @TODO: Better way of handling all these drawer and notification UIDs (this is crap)

import { drawerUID as surveyDrawersUIDs } from '../Survey/uids'

export const drawerUID = {
    LOGIN: 'drawer-auth-login',
    REGISTER: 'drawer-auth-register',
    RESET_PASSWORD: 'drawer-auth-reset-password',
    NEW_PASSWORD: 'drawer-auth-new-password',
    ...surveyDrawersUIDs
}

export const notificationUID = {
    LOGIN: 'PLACEHOLDER_ID_FOR_LOGIN_NOTIFICATION',
    REGISTER: 'PLACEHOLDER_ID_FOR_REGISTER_NOTIFICATION',
    RESET_PASSWORD: 'PLACEHOLDER_ID_FOR_RESET_PASSWORD_NOTIFICATION',
    NEW_PASSWORD: 'PLACEHOLDER_ID_FOR_NEW_PASSWORD_NOTIFICATION'
}
