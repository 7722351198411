import { responsive, toRem } from '../../../../../helpers/theme'

const filtersWidth = {
    laptop: 440,
    desktop: 500
}

const style = ({ theme, isFiltersOpen }) => {
    const background = {
        backgroundColor: theme.colors.background.body.default,
        backgroundImage: 'url(/images/background/body/main.svg)',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'top center'
    }

    const getResultsWidth = value =>
        `calc(100% - ${toRem(value)} - ${theme.global.baseLayout.desktop.sidebar.width} + ${toRem(2)})`

    return {
        '& .searchResults_panel': {
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            zIndex: theme.zIndex.search.results.mobile,
            height: '100%',
            width: '100%',
            paddingTop: toRem(80),
            paddingBottom: toRem(60),
            ...background,
            [theme.breakpoints.down('md')]: {
                transition: 'transform 300ms ease-in',
                ...(isFiltersOpen && {
                    transform: 'translateX(30%)'
                })
            },
            [theme.breakpoints.up('lg')]: {
                left: 'auto',
                right: 0,
                zIndex: theme.zIndex.search.results.desktop,
                width: getResultsWidth(filtersWidth.laptop),
                padding: 0
            },
            [theme.breakpoints.up('xl')]: {
                width: getResultsWidth(filtersWidth.desktop)
            },
            '&Body': {
                overflow: 'auto',
                width: '100%',
                height: '100%',
                paddingTop: toRem(20),
                [theme.breakpoints.up('lg')]: {
                    paddingTop: 0
                }
            }
        },
        '& .searchResults_reactiveList': {
            paddingBottom: 0
        },
        '& .reactiveSearch': {
            '&_resultsWrap': {
                display: 'grid',
                width: '100%',
                maxWidth: '100%',
                ...responsive([
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.xs),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.xs),
                        gridTemplateColumns: 'repeat(1, 1fr)'
                    }, // xs
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.sm),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.sm)
                    }, // sm
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.md),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.md),
                        gridTemplateColumns: 'repeat(2, 1fr)'
                    }, // md
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.lg),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.lg),
                        gridTemplateColumns: 'repeat(1, 1fr)'
                    }, // lg
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.xl),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.xl),
                        gridTemplateColumns: 'repeat(2, 1fr)'
                    }, // xl
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.xxl),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.xxl),
                        gridTemplateColumns: 'repeat(2, 1fr)'
                    }, // xxl
                    {
                        gridColumnGap: toRem(theme.layoutSpacings?.grid?.columnGap?.xxl),
                        gridRowGap: toRem(theme.layoutSpacings?.grid?.rowGap?.xxl),
                        gridTemplateColumns: 'repeat(3, 1fr)'
                    } // xxl
                ]),
                '&:empty': {
                    display: 'none'
                }
            },
            '&_sortOptions': {
                display: 'none'
            }
        }
    }
}

export default style

const transition = {
    duration: 0.25,
    ease: 'easeOut'
}

const searchResultsPanelVariant = {
    closed: {
        opacity: 0,
        transition,
        transitionEnd: {
            display: 'none',
            visibility: 'hidden'
        }
    },
    open: {
        opacity: 1,
        display: 'flex',
        visibility: 'visible',
        transition
    }
}

export { searchResultsPanelVariant }
