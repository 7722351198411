import { convertHexToRGBA as rgba, responsive, toRem } from '../../../../helpers/theme'

const style = ({ theme, variant, overlay, overrideImagePadding = 36.373, fullHeight, noMinHeight }) => {
    const card2x1 = variant === '2x1'
    const card2x2 = variant === '2x2'

    const config = {
        desktop: {
            '1x2': {
                minHeight: '520px'
            },
            '2x2': {
                minHeight: '520px'
            },
            '1x1': {
                minHeight: '250px'
            },
            '2x1': {
                minHeight: '250px'
            }
        }
    }

    return {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        ...(card2x1 && {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        }),
        ...(variant && {
            [theme.breakpoints.up('md')]: {
                minHeight: config.desktop[variant]?.minHeight,
                ...(noMinHeight && {
                    minHeight: 'auto'
                })
            }
        }),
        ...theme.cardHover,
        '& .cardPicture': {
            ...(fullHeight && {
                height: '100%'
            }),
            ...(card2x1 && {
                [theme.breakpoints.up('md')]: {
                    // width: '40%',
                    ...(overrideImagePadding && {
                        paddingBottom: `${overrideImagePadding}%`
                    })
                }
            })
        },
        '& .cardEdit': {
            zIndex: theme.zIndex.over,
            position: 'absolute',
            right: toRem(10),
            top: toRem(10)
        },
        '& .cardContent': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            margin: 0,
            ...(card2x1 && {
                [theme.breakpoints.up('md')]: {
                    position: 'relative',
                    // width: '60%',
                    backgroundColor: theme.colors.card.article.content.background
                }
            }),
            ...theme.cards[variant].spacing,

            // linear background for cards that have lead text
            ...(!card2x1 &&
                overlay && {
                    background: `
                    linear-gradient(
                        180deg,
                        ${rgba(theme.colors.card.overlay.background, 0)} 0,
                        ${rgba(theme.colors.card.overlay.background, 0.3)} 100%
                    )
                `
                })
        },
        '& .cardContent_label': {
            margin: 0,
            padding: `0.385em ${toRem(5)}`,
            fontWeight: theme.cards[variant].label.fontWeight
        },
        '& .cardContent_title': {
            margin: 0
        },
        '& .cardLead': {
            display: theme.cards[variant].lead.display,
            ...(theme?.cards[variant]?.lead.display !== 'none' && {
                ...responsive([
                    { marginTop: toRem(16) },
                    { marginTop: toRem(16) },
                    { marginTop: toRem(18) },
                    { marginTop: toRem(19) },
                    { marginTop: toRem(20) },
                    { marginTop: toRem(22) }
                ]),
                ...(!card2x2 && {
                    ...responsive([
                        { fontSize: toRem(16) },
                        { fontSize: toRem(16) },
                        { fontSize: toRem(16) },
                        { fontSize: toRem(18) },
                        { fontSize: toRem(18) }
                    ])
                }),
                ...(card2x2 && {
                    ...responsive([
                        { fontSize: toRem(16) },
                        { fontSize: toRem(16) },
                        { fontSize: toRem(16) },
                        { fontSize: toRem(18) },
                        { fontSize: toRem(22) }
                    ])
                }),
                lineHeight: 1.6,
                color: theme.colors.card.article.lead.text.white,
                ...(card2x1 && {
                    color: theme.colors.card.article.lead.text.white,
                    [theme.breakpoints.up('md')]: {
                        color: theme.colors.card.article.lead.text.black
                    }
                }),
                blockquote: {
                    margin: 0
                }
            })
        }
    }
}

export default style
