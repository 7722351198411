// Third party imports
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Sidebar.style'

const HeaderSidebarStyled = styled.header(props => ({ ...styles(props) }))

const HeaderSidebar = ({ className, logoComponent: Logo, ...rest }) => {
    if (!Logo) {
        return null
    }

    return (
        <HeaderSidebarStyled className={className} {...rest}>
            <Logo byPodravka />
        </HeaderSidebarStyled>
    )
}

HeaderSidebar.propTypes = {
    className: PropTypes.string,
    logoComponent: PropTypes.elementType
}

HeaderSidebar.defaultProps = {
    className: undefined,
    logoComponent: undefined
}

export default HeaderSidebar
