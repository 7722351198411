/* eslint-disable camelcase */

// @TODO: Cleanup/extend/remove login modal actions when we get the full specs on how login will work
// User modal removed, login drawer in use, leaving comment if revisit needed for login logic

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { NavigationAccountActions } from '@hmn/coolinarika-web-core/actions'
import { useDrawerState } from '@hmn/coolinarika-web-core/context/Drawer'
import { NotificationsContext } from '@hmn/coolinarika-web-core/controllers'
import { useAnalytics, useDrawer, useUser } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../../../../Button'
import { withErrorBoundary } from '../../../../ErrorBoundary'
import { CloseIcon, Icon, NavAvatarIcon, NavHamburgerIcon } from '../../../../Icon'
import { UserAvatar, UserAvatarVariants } from '../../../../User/components'
import styles from './User.style'

const AccountUserStyled = styled.div(props => ({ ...styles(props) }))

const AccountUser = ({ className, onClick, variant, ...rest }) => {
    const dispatch = useDispatch()
    const { drawerType } = useDrawerState()
    const { open: openNotificationsDrawer } = useDrawer('drawer-user-notifications')

    const { profile: user, isLoggedIn } = useUser()

    const { eventWithTrackingData } = useAnalytics('coolinarika.mainItemClick', {
        trackingData: {
            elementCategory: 'accountMenu',
            elementLabel: 'Moj profil',
            elementPageLocation: 'header'
        }
    })

    const notifications = useContext(NotificationsContext)

    const { display_name: userDisplayName, fname: userFirstName, id: userId } = user || {}

    // Account panel
    const { isOpen: isNavigationAccountOpen } = useSelector(state => state.navigationAccount)
    const [isMenuOpen, setIsMenuOpen] = useState(isNavigationAccountOpen)

    useEffect(() => {
        setIsMenuOpen(isNavigationAccountOpen)
    }, [isNavigationAccountOpen])

    useEffect(() => {
        if (!isLoggedIn && isNavigationAccountOpen) {
            dispatch(NavigationAccountActions.toggleAccount())
        }
    }, [isLoggedIn])

    // Notifications drawer open
    const handleNotificationsDrawer = useCallback(() => {
        if (variant !== 'navbar' && notifications.unread?.length && notifications.unread?.length > 0) {
            openNotificationsDrawer()
        }
    }, [dispatch, variant, notifications.unread])

    const { isOpen: isDrawerOpen, open, close } = useDrawer('drawer-auth-login')

    // Account panel/Login modal toggle
    const handleNavAction = useCallback(() => {
        eventWithTrackingData()

        if (!isLoggedIn && !isNavigationAccountOpen) {
            if (isDrawerOpen && drawerType === 'drawer-auth-login') {
                close()
            } else {
                open()
            }
        } else {
            batch(() => {
                dispatch(NavigationAccountActions.toggleAccount())
                close()
            })
        }
    }, [dispatch, isLoggedIn, isNavigationAccountOpen, onClick, isDrawerOpen, drawerType, eventWithTrackingData])

    return (
        <AccountUserStyled
            className={className}
            hasImage={isLoggedIn}
            variant={variant}
            isActive={isLoggedIn}
            {...rest}>
            <div
                className="userNav_inner"
                onKeyDown={handleNavAction}
                onClick={handleNavAction}
                role="button"
                tabIndex={0}>
                <div className="userNav_avatar">
                    <div className="userNav_avatarWrap">
                        {isLoggedIn && userId ? (
                            <UserAvatar
                                onClick={handleNotificationsDrawer}
                                userId={userId}
                                variant={UserAvatarVariants[variant === 'navbar' ? 'NAVIGATIONBAR' : 'NAVIGATION']}
                                notifications={notifications?.unread?.length}
                            />
                        ) : (
                            <Icon icon={NavAvatarIcon} />
                        )}
                    </div>
                </div>
                {variant !== 'navbar' && (
                    <>
                        <div className="userNav_info">
                            <div className="userNav_infoLabel">Moj profil</div>
                            {(userDisplayName || userFirstName) && (
                                <div className="userNav_infoName">{userDisplayName || userFirstName}</div>
                            )}
                        </div>
                        <div className="userNav_action">
                            <Button
                                title={isMenuOpen ? 'Zatvori' : 'Otvori'}
                                size={buttonSizes.NORMAL}
                                variant={buttonVariants.TERTIARY}
                                className="userNav_actionBtn"
                                isIconOnly
                                disableBorder>
                                {isMenuOpen ? (
                                    <Icon icon={CloseIcon} size={26} />
                                ) : (
                                    <Icon icon={NavHamburgerIcon} viewBox="0 0 36 36" size={34} />
                                )}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </AccountUserStyled>
    )
}

const accountUserVariants = Object.freeze({
    DEFAULT: 'default',
    NAVBAR: 'navbar'
})

AccountUser.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf([...Object.values(accountUserVariants)])
}

AccountUser.defaultProps = {
    className: undefined,
    onClick: () => {},
    variant: accountUserVariants.DEFAULT
}

export { accountUserVariants }

export default withErrorBoundary(AccountUser)
