// @TODO: Move everything theme related configs from globals folder

import { breakpoints as globalBreakpoints, createMediaQueries } from '@hmn/coolinarika-web-ui/helpers/theme'

import {
    colors as globalColors,
    global,
    normalize,
    shadows as globalShadows,
    transitions as globalTransitions,
    typography as globalTypography,
    utilities,
    zIndex as globalZIndex
} from '../globals'
import { cardHoverSettings as cardHover, cardStyleConfig as cards } from './cards'
import { layoutDimension, layoutSpacings, layoutTheme } from './layout'

// Define theme overrides here
export const coolinarika = {
    normalize,
    global,
    breakpoints: {
        ...createMediaQueries(globalBreakpoints)
    },
    colors: {
        ...globalColors
    },
    typography: {
        ...globalTypography
    },
    zIndex: {
        ...globalZIndex
    },
    transitions: {
        ...globalTransitions
    },
    shadows: {
        ...globalShadows
    },
    layoutTheme,
    layoutDimension,
    layoutSpacings,
    utilities,
    cards,
    cardHover
}
