import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'fixed',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: theme.zIndex.navigation.bar,
    background: theme.colors.navigation.background.bar,
    boxShadow: theme.shadows.navigation.bar,
    height: toRem(theme.global.baseLayout.mobile.navigation.bar.height),
    '& .navBar_itemSeparator': {
        flexShrink: 0,
        width: 5,
        maxHeight: toRem(28),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.colors.navigation.separator,
        overflow: 'hidden',
        pointerEvents: 'none',
        svg: {
            transform: 'rotate(90deg)',
            width: toRem(40),
            height: 5,
            path: {
                fill: 'currentColor'
            }
        }
    },
    '@media print': {
        display: 'none'
    }
})

export default style
