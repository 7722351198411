import { all, fork } from 'redux-saga/effects'

import { watchForAddToCollection, watchForCreateCollection, watchForRemoveFromCollection } from './collections'
import { watchForGetSettings } from './settings'

function* rootSaga() {
    yield all([
        fork(watchForGetSettings),
        fork(watchForAddToCollection),
        fork(watchForRemoveFromCollection),
        fork(watchForCreateCollection)
    ])
}

export default rootSaga
