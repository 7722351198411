/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const HeartFill = createSvgIcon(
    <path
        d="M22.892 8.891c-2.375-1.62-5.308-.863-6.892.99-1.583-1.853-4.516-2.618-6.891-.99a4.923 4.923 0 00-2.105 3.86c-.126 3.49 2.969 6.288 7.692 10.58l.09.08c.684.621 1.736.621 2.42-.008l.099-.09c4.723-4.283 7.809-7.08 7.692-10.571a4.92 4.92 0 00-2.105-3.85z"
        fill="#fff"
    />,
    'HeartFill'
)

export default HeartFill
