/**
 * This hook covers creating and sending notifications to backend.
 */

import { useMutation } from 'react-query'

import { globalSettings } from '../settings'
import useUser from './useUser'

const { apiBaseUrl } = globalSettings
const isReadOnly = process.env.NEXT_PUBLIC_UI_READ_ONLY === 'true'

const useNotifyFollowers = () => {
    const { id: fromUuid } = useUser()

    const { mutate: notifyFollowers } = useMutation(
        async notificationData => {
            notificationData.uuid = fromUuid

            const { data: newData } = await fetch(`${apiBaseUrl}/notifications/notifyFollowers`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(notificationData)
            })

            return newData
        },
        {
            onSuccess: () => {
                // console.log('success')
            },
            onError: error => {
                // console.log('error', error)
            }
        }
    )

    if (isReadOnly) {
        return { notifyFollowers: () => {} }
    }
    return {
        notifyFollowers
    }
}

const useNotify = () => {
    const { id: fromUuid } = useUser()

    const { mutate: notify } = useMutation(
        async notificationData => {
            if (fromUuid === notificationData.uuid) {
                return null
            }

            notificationData.subType = notificationData.subType || ''
            notificationData.fromUuid = notificationData.fromUuid || fromUuid

            if (!notificationData.url && typeof window !== 'undefined') {
                notificationData.url = window?.location.pathname
            }

            const { data: newData } = await fetch(`${apiBaseUrl}/notifications`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(notificationData)
            })

            return newData
        },
        {
            onSuccess: () => {
                // console.log('success')
            },
            onError: error => {
                // console.log('error', error)
            }
        }
    )
    return {
        notify
    }
}

export { useNotify, useNotifyFollowers }
