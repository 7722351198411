import { Fragment, isValidElement, useCallback } from 'react'
import { useUIDSeed } from 'react-uid'
import parseHtml from 'html-react-parser'

import Blocks2Docbook from '@hmn/blocks2docbook'

import useInnerHtml from './useInnerHtml'

const blocks2Docbook = Blocks2Docbook({
    namespace: 'pio'
})

const getBlocks = (docbook = '') => {
    if (Array.isArray(docbook)) {
        return docbook
    }
    try {
        const parsedBlocks = blocks2Docbook.parse(docbook)

        return parsedBlocks
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[useHtmlContent:getBlocks]', error)
        return []
    }
}

const useHtmlContent = (content = '', elements, className, Wrapper) => {
    const uid = useUIDSeed()
    // @FIXME: Temporary, remove when Docbook parser properly parses quotes
    const contentHtmlProps = useInnerHtml(content)

    const Content = useCallback(() => {
        if (Wrapper) {
            return <Wrapper className={className} {...contentHtmlProps} />
        }

        const blockList = getBlocks(content)

        const itemList = blockList.map((block, index) => {
            const elementFunction = elements[block.name] || elements.default
            return {
                key: uid({ block, index }),
                Element: () => elementFunction(block) || elements.default(block)
            }
        })

        const htmlContent = itemList.map(({ Element, key }) => {
            if (isValidElement(Element)) {
                return <Fragment key={key}>{Element}</Fragment>
            }
            return <Element key={key} />
        })

        return htmlContent
    }, [content])

    return {
        Content
    }
}

export { parseHtml, getBlocks }

export default useHtmlContent
