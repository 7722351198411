// @TODO: Bg color (if needed)
// @TODO: Image styles
// @TODO: Content positioning

import { toRem } from '../../../../helpers'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, height }) => ({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
        height: '100%',
        minHeight: toRem(300)
    },
    '& .parallaxBanner_visual': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height,

        '& > img': {
            width: '100%',
            height
        }
    }
})

export default style
