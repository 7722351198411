import { useMemo } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultSeo as DefaultNextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../../ErrorBoundary'

const DefaultSeo = ({ createSeoConfig, configOptions = {}, ...rest }) => {
    const config = useMemo(() => {
        const { webUrl = 'https://www.coolinarika.com', siteName = 'Coolinarika' } = configOptions

        return createSeoConfig({ webUrl, siteName })
    }, [createSeoConfig, configOptions])

    return <DefaultNextSeo {...config} {...rest} />
}

DefaultSeo.propTypes = {
    createSeoConfig: PropTypes.func.isRequired,
    configOptions: PropTypes.oneOfType([() => null, PropTypes.object])
}

DefaultSeo.defaultProps = {
    configOptions: undefined
}

export default withErrorBoundary(DefaultSeo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // Do something with the error, e.g. log it to Sentry or console, defaults to console
        // eslint-disable-next-line no-console
        console.error('[DefaultSeo]: ', error, componentStack)
    }
})
