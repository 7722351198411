const layoutElements = {
    grid: 1330, // width of the grid on the design
    main: 870, // width of the main content inside grid
    sidebar: 460,
    aside: 280, // width of the sidebar inside grid
    column: 65, // column width
    gutter: 50 // gutter width ( space between columns :) )
}

const calculateContentSize = element => ((layoutElements[element] / layoutElements.grid) * 100).toFixed(2)

export default {
    main: calculateContentSize('main'),
    sidebar: calculateContentSize('sidebar'),
    column: calculateContentSize('column'),
    gutter: calculateContentSize('gutter'),
    aside: calculateContentSize('aside')
}
