import { Component as ReactComponent, forwardRef, isValidElement } from 'react'
import * as Sentry from '@sentry/nextjs'
import PropTypes from 'prop-types'

import DefaultFallback from './DefaultFallback.component'

const changedArray = (a = [], b = []) => a.some((item, index) => !Object.is(item, b[index]))

const initialState = { error: null, info: null }

class ErrorBoundary extends ReactComponent {
    state = initialState

    componentDidUpdate(prevProps) {
        const { error } = this.state
        const { resetKeys, onResetKeysChange } = this.props
        if (error !== null && changedArray(prevProps?.resetKeys, resetKeys)) {
            if (onResetKeysChange) {
                onResetKeysChange(prevProps?.resetKeys, resetKeys)
            }
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(initialState)
        }
    }

    componentDidCatch(error, info) {
        const { onError } = this.props
        // Sentry.captureException(error)
        if (onError) {
            onError(error, info?.componentStack)
        }

        this.setState({ error, info })

        Sentry.withScope(scope => {
            scope.setTag('[coolinarika-web][error-boundary]', true)
            Sentry.captureException({
                error
            })
        })
    }

    resetErrorBoundary = (...args) => {
        const { onReset } = this.props
        if (onReset) {
            onReset(...args)
        }

        this.setState(initialState)
    }

    render() {
        const { error, info } = this.state
        const { fallbackRender, FallbackComponent, fallback, children } = this.props

        if (error !== null) {
            const props = {
                componentStack: info?.componentStack,
                error,
                resetErrorBoundary: this.resetErrorBoundary
            }

            if (isValidElement(fallback)) {
                return fallback
            }

            if (typeof fallbackRender === 'function') {
                return fallbackRender(props)
            }

            if (typeof FallbackComponent === 'function') {
                return (
                    <div
                        style={{
                            outline: '2px dashed red',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <FallbackComponent {...props} />
                    </div>
                )
            }

            throw new Error('ErrorBoundary requires either a fallback, fallbackRender, or FallbackComponent prop')
        }

        return children
    }
}

const withErrorBoundary = (Component, errorBoundaryProps = {}) => {
    const { ref: forwardedRef } = errorBoundaryProps
    const Wrapped = forwardRef((props, ref) => (
        <ErrorBoundary {...errorBoundaryProps}>
            <Component ref={ref || forwardedRef} {...props} />
        </ErrorBoundary>
    ))

    // Format for display in DevTools
    const name = Component.displayName || Component.name || 'Unknown'
    Wrapped.displayName = `withErrorBoundary(${name})`

    return Wrapped
}

ErrorBoundary.propTypes = {
    /**
     * This is a component you want rendered in the event of an error.
     * As props it will be passed the error, componentStack, and resetErrorBoundary (which will reset the error boundary's state when called,
     * useful for a "try again" button when used in combination with the onReset prop).
     * This is required if no fallback or fallbackRender prop is provided.
     */
    FallbackComponent: PropTypes.elementType,
    /**
     * This is a render-prop based API that allows you to inline your error fallback UI into the component that's using the ErrorBoundary.
     * This is useful if you need access to something that's in the scope of the component you're using.
     * It will be called with an object that has error, componentStack, and resetErrorBoundary:
     *
     *  <ErrorBoundary
     *      fallbackRender={({ error, resetErrorBoundary })} => (
     *          <div>
     *              <h1>{error.message}</h1>
     *              <button onClick={() => {
     *                  resetComponentState()
     *                  resetErrorBoundary()
     *              }}>
     *                  Try again
     *              </button>
     *          </div>
     *      )
     *  >
     *      <SomeComponentThatMayThrowError />
     *  </ErrorBoundary>
     *
     */
    fallbackRender: PropTypes.func,
    /**
     * To keep it consistent with the React.Suspense component, we also support a simple fallback prop which you can use for a generic fallback.
     * This will not be passed any props so you can't show the user anything actually useful though, so it's not really recommended.
     */
    fallback: PropTypes.elementType,
    /**
     * This will be called when there's been an error that the ErrorBoundary has handled.
     * It will be called with two arguments: error, componentStack.
     *
     * Use this to send errors to logging service, e.g. Sentry.
     */
    onError: PropTypes.func,
    /**
     * This will be called immediately before the ErrorBoundary resets it's internal state (which will result in rendering the children again).
     * You should use this to ensure that re-rendering the children will not result in a repeat of the same error happening again.
     *
     * onReset will be called with whatever resetErrorBoundary is called with
     */
    onReset: PropTypes.func,
    /**
     * Sometimes an error happens as a result of local state to the component that's rendering the error.
     * If this is the case, then you can pass resetKeys which is an array of values.
     *
     * If the ErrorBoundary is in an error state, then it will check these values each render and if they change from one render to the next,
     * then it will reset automatically (triggering a re-render of the children).
     */
    resetKeys: PropTypes.arrayOf(PropTypes.any),
    /**
     * This is called when the resetKeys are changed (triggering a reset of the ErrorBoundary). It's called with the prevResetKeys and the resetKeys.
     */
    onResetKeysChange: PropTypes.func
}

ErrorBoundary.defaultProps = {
    FallbackComponent: err => <DefaultFallback err={err} />,
    fallback: undefined,
    fallbackRender: undefined,
    // eslint-disable-next-line no-console
    onError: (error, componentStack) => {
        Sentry.withScope(scope => {
            scope.setTag('[coolinarika-web][error-boundary]', true)
            Sentry.captureException({
                error,
                componentStack
            })
        })
    },
    onReset: () => {},
    onResetKeysChange: () => {},
    resetKeys: []
}

export { ErrorBoundary, withErrorBoundary }
