import { FiltersActionTypes } from '../../actions/filters'

const initialState = {
    isOpen: false
}

const filtersReducer = (state = initialState, { type }) => {
    switch (type) {
        case FiltersActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: false
            }
        }

        case FiltersActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }

        case FiltersActionTypes.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        default:
            return state
    }
}

export { filtersReducer }
