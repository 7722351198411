// Colors must not be used directly in components, but they should be (re)assigned here instead

const palette = {
    // Primary colors
    coolLightRed: '#FE000C',
    coolDarkRed: '#ED1C24',
    coolDarkRed100: '#e40e16',
    coolDarkRed200: '#ea000b',
    coolGray: '#BCBDC0',
    // Secondary colors
    pureBlack: '#000000',
    black: '#141313',
    darkGray: '#373535',
    gray800: '#666666',
    gray500: '#9B9B9B',
    gray550: '#BDBDBD',
    gray555: '#BCBCBC',
    gray400: '#D6D6D6',
    gray300: '#E0E0E0',
    gray290: '#E6E6E6',
    gray280: '#EDEDED',
    gray270: '#EEEEEE',
    gray260: '#F1F1F1',
    gray250: '#F5F5F5',
    gray200: '#F8F8F8',
    gray100: '#FBFBFB',
    white: '#FFFFFF',
    fbBlue: '#4267B2',
    fbBlue100: '#224A9B',
    // Accent colors
    accentYellow: '#FCE201',
    accentYellow100: '#FCCD01',
    accentGreen: '#63C584',
    accentGreen100: '#46AD69',
    accentGreen200: '#58B878',
    accentTurquoise: '#63C5BF',
    accentBlue: '#8BC8DB',
    accentBrown: '#A78F79',
    accentBrown200: '#D8A46B',
    // Alert colors
    errorBg: '#FFE8E9',
    errorTx: '#FE000C',
    warningBg: '#FFE7CA',
    warningTx: '#FC8901',
    infoBg: '#E2F8FF',
    infoTx: '#8BC8DB',
    successBg: '#CBFFDC',
    successTx: '#63C584',
    // Development
    devWarning: '#4A412A' // dev error visual notification color (Pantone 448 C - worlds ugliest color)
}

const editorColors = {
    cyanBluishGray: '#abb8c3',
    white: '#ffffff',
    palePink: '#f78da7',
    vividRed: '#cf2e2e',
    luminousVividOrange: '#ff6900',
    luminousVividAmber: '#fcb900',
    lightGreenCyan: '#7bdcb5',
    vividGreenCyan: '#00d084',
    paleCyanBlue: '#8ed1fc',
    vividCyanBlue: '#0693e3',
    vividPurple: '#9b51e0'
}

// Configure global colors here
const colors = {
    text: {
        default: palette.black,
        white: palette.white,
        light: palette.gray500,
        editor: {
            cyanBluishGray: editorColors.cyanBluishGray,
            white: editorColors.white,
            palePink: editorColors.palePink,
            vividRed: editorColors.vividRed,
            luminousVividOrange: editorColors.luminousVividOrange,
            luminousVividAmber: editorColors.luminousVividAmber,
            lightGreenCyan: editorColors.lightGreenCyan,
            vividGreenCyan: editorColors.vividGreenCyan,
            paleCyanBlue: editorColors.paleCyanBlue,
            vividCyanBlue: editorColors.vividCyanBlue,
            vividPurple: editorColors.vividPurple
        }
    },
    background: {
        body: {
            default: palette.gray100
        },
        sidebar: {
            default: palette.white
        },
        card: {
            base: palette.white,
            inner: {
                default: palette.gray100
            }
        }
    },
    shadow: {
        default: palette.pureBlack
    },
    formBlock: {
        background: palette.white
    },
    heading: {
        simple: {
            text: {
                default: palette.black,
                white: palette.white
            }
        },
        highlight: {
            primary: {
                text: {
                    default: palette.white,
                    hover: palette.white
                },
                background: {
                    default: palette.coolLightRed,
                    hover: palette.coolDarkRed
                }
            },
            secondary: {
                text: {
                    default: palette.black,
                    hover: palette.black
                },
                background: {
                    default: palette.accentYellow,
                    hover: palette.accentYellow
                }
            }
        },
        section: {
            text: {
                default: palette.black,
                inverted: palette.white
            }
        },
        label: {
            primary: {
                text: {
                    default: palette.white
                },
                background: {
                    default: palette.coolLightRed
                }
            },
            secondary: {
                text: {
                    default: palette.black
                },
                background: {
                    default: palette.accentYellow
                }
            },
            tertiary: {
                text: {
                    default: palette.black
                },
                background: {
                    default: palette.white
                }
            },
            quaternary: {
                text: {
                    default: palette.black
                },
                background: {
                    default: 'transparent'
                }
            },
            quinary: {
                text: {
                    default: palette.gray500
                },
                background: {
                    default: palette.gray250
                }
            }
        }
    },
    headingSection: {
        single: {
            background: palette.white
        },
        search: {
            background: palette.gray200
        }
    },
    accent: {
        text: {
            primary: palette.accentGreen,
            secondary: palette.accentBlue,
            tertiary: palette.accentYellow,
            quaternary: palette.coolLightRed
        }
    },
    label: {
        text: {
            primary: palette.black,
            secondary: palette.black,
            tertiary: palette.white
        },
        background: {
            primary: palette.white,
            secondary: palette.accentYellow,
            tertiary: palette.coolLightRed
        }
    },
    link: {
        primary: {
            text: {
                default: palette.coolDarkRed,
                hover: palette.coolDarkRed,
                active: palette.coolDarkRed,
                focus: palette.coolDarkRed,
                disabled: palette.coolDarkRed
            }
        },
        secondary: {
            text: {
                default: palette.black,
                hover: palette.black,
                active: palette.black,
                focus: palette.black,
                disabled: palette.black
            }
        },
        tertiary: {
            text: {
                default: palette.gray550,
                hover: palette.black,
                active: palette.black,
                focus: palette.black,
                disabled: palette.gray550
            }
        },
        quaternary: {
            text: {
                default: palette.accentGreen,
                hover: palette.accentGreen,
                active: palette.accentGreen,
                focus: palette.accentGreen,
                disabled: palette.gray250
            }
        },
        quinary: {
            text: {
                default: palette.coolGray,
                hover: palette.coolGray,
                active: palette.coolGray,
                focus: palette.coolGray,
                disabled: palette.gray550
            }
        }
    },
    button: {
        primary: {
            text: {
                default: palette.white,
                hover: palette.white,
                active: palette.white,
                focus: palette.white,
                disabled: palette.white
            },
            background: {
                default: palette.coolLightRed,
                hover: palette.coolDarkRed100,
                active: palette.coolDarkRed100,
                focus: palette.coolDarkRed100,
                disabled: palette.coolLightRed
            },
            border: {
                default: palette.coolLightRed,
                hover: palette.coolDarkRed100,
                active: palette.coolDarkRed100,
                focus: palette.coolDarkRed100,
                disabled: palette.coolLightRed
            }
        },
        secondary: {
            text: {
                default: palette.black,
                hover: palette.black,
                active: palette.black,
                focus: palette.black,
                disabled: palette.black
            },
            background: {
                default: palette.white,
                hover: palette.white,
                active: palette.white,
                focus: palette.white,
                disabled: palette.white
            },
            border: {
                default: palette.gray260,
                hover: palette.gray400,
                active: palette.gray400,
                focus: palette.gray400,
                disabled: palette.gray260
            }
        },
        tertiary: {
            text: {
                default: palette.coolGray,
                hover: palette.gray500,
                active: palette.gray500,
                focus: palette.gray500,
                disabled: palette.coolGray
            },
            background: {
                default: palette.gray250,
                hover: palette.gray280,
                active: palette.gray280,
                focus: palette.gray280,
                disabled: palette.gray250
            },
            border: {
                default: palette.gray250,
                hover: palette.gray280,
                active: palette.gray280,
                focus: palette.gray280,
                disabled: palette.gray250
            }
        },
        quaternary: {
            text: {
                default: palette.white,
                hover: palette.white,
                active: palette.white,
                focus: palette.white,
                disabled: palette.white
            },
            background: {
                default: palette.accentGreen,
                hover: palette.accentGreen100,
                active: palette.accentGreen100,
                focus: palette.accentGreen100,
                disabled: palette.accentGreen
            },
            border: {
                default: palette.accentGreen,
                hover: palette.accentGreen100,
                active: palette.accentGreen100,
                focus: palette.accentGreen100,
                disabled: palette.accentGreen
            }
        },
        quinary: {
            text: {
                default: palette.coolGray,
                hover: palette.coolGray,
                active: palette.coolGray,
                focus: palette.coolGray,
                disabled: palette.coolGray
            },
            background: {
                default: palette.white,
                hover: palette.white,
                active: palette.white,
                focus: palette.white,
                disabled: palette.white
            },
            border: {
                default: palette.gray260,
                hover: palette.gray400,
                active: palette.gray400,
                focus: palette.gray400,
                disabled: palette.gray260
            }
        },
        senary: {
            text: {
                default: palette.white,
                hover: palette.white,
                active: palette.white,
                focus: palette.white,
                disabled: palette.white
            },
            background: {
                default: palette.fbBlue,
                hover: palette.fbBlue100,
                active: palette.fbBlue100,
                focus: palette.fbBlue100,
                disabled: palette.fbBlue
            },
            border: {
                default: palette.fbBlue,
                hover: palette.fbBlue100,
                active: palette.fbBlue100,
                focus: palette.fbBlue100,
                disabled: palette.fbBlue
            }
        },
        septenary: {
            text: {
                default: palette.black,
                hover: palette.black,
                active: palette.black,
                focus: palette.black,
                disabled: palette.black
            },
            background: {
                default: palette.accentYellow,
                hover: palette.accentYellow100,
                active: palette.accentYellow100,
                focus: palette.accentYellow100,
                disabled: palette.accentYellow
            },
            border: {
                default: palette.accentYellow,
                hover: palette.accentYellow100,
                active: palette.accentYellow100,
                focus: palette.accentYellow100,
                disabled: palette.accentYellow
            }
        },
        // @TODO: Try to revisit and if is possible, merge with TERTIARY, diff is default text color
        octonary: {
            text: {
                default: palette.black,
                hover: palette.black,
                active: palette.black,
                focus: palette.black,
                disabled: palette.coolGray
            },
            background: {
                default: palette.gray250,
                hover: palette.gray280,
                active: palette.gray280,
                focus: palette.gray280,
                disabled: palette.gray250
            },
            border: {
                default: palette.gray250,
                hover: palette.gray280,
                active: palette.gray280,
                focus: palette.gray280,
                disabled: palette.gray250
            }
        },
        apple: {
            text: {
                default: palette.white
            },
            background: {
                default: palette.black,
                hover: palette.black,
                active: palette.black,
                focus: palette.black,
                disabled: palette.coolGray
            }
        }
    },
    video: {
        button: {
            play: {
                primary: {
                    color: {
                        default: palette.coolLightRed,
                        hover: palette.white
                    },
                    background: {
                        default: palette.white,
                        hover: palette.coolLightRed
                    }
                },
                secondary: {
                    color: {
                        default: palette.white,
                        hover: palette.coolLightRed
                    },
                    background: {
                        default: palette.coolLightRed,
                        hover: palette.white
                    }
                }
            }
        }
    },
    list: {
        disc: {
            default: palette.coolDarkRed
        },
        decimal: {
            default: palette.coolDarkRed
        }
    },
    modal: {
        background: {
            default: {
                content: palette.white,
                backdrop: palette.black
            }
        }
    },
    search: {
        input: {
            primary: {
                text: {
                    default: palette.black,
                    placeholder: palette.coolGray
                },
                icon: {
                    default: palette.coolGray,
                    hover: palette.black
                },
                background: {
                    default: palette.white
                }
            },
            secondary: {
                text: {
                    default: palette.black,
                    placeholder: palette.coolGray
                },
                icon: {
                    default: palette.coolGray,
                    hover: palette.black
                },
                background: {
                    default: palette.gray250
                }
            }
        },
        ingredients: {
            background: palette.gray200
        }
    },
    form: {
        input: {
            primary: {
                text: {
                    default: palette.black,
                    placeholder: palette.gray500,
                    disabled: palette.gray500
                },
                background: {
                    default: palette.gray250,
                    focus: palette.white
                },
                border: {
                    default: 'transparent',
                    hover: palette.gray300,
                    focus: palette.gray300
                }
            },
            secondary: {
                text: {
                    default: palette.black,
                    placeholder: palette.gray500,
                    disabled: palette.gray500
                },
                background: {
                    default: palette.white,
                    focus: palette.white
                },
                border: {
                    default: palette.gray250,
                    hover: palette.gray250,
                    focus: palette.gray250
                }
            },
            tertiary: {
                text: {
                    default: palette.black,
                    placeholder: palette.gray500,
                    disabled: palette.gray500
                },
                background: {
                    default: palette.white,
                    focus: palette.white
                },
                border: {
                    default: palette.gray300,
                    hover: palette.gray300,
                    focus: palette.gray300
                }
            },
            quaternary: {
                text: {
                    default: palette.black,
                    placeholder: palette.gray500,
                    disabled: palette.gray500
                },
                background: {
                    default: palette.white,
                    focus: palette.white
                },
                border: {
                    default: palette.white,
                    hover: palette.white,
                    focus: palette.white
                }
            }
        },
        checkbox: {
            primary: {
                icon: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.coolLightRed
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.white
                },
                border: {
                    default: palette.coolGray,
                    hover: palette.coolGray,
                    checked: palette.coolGray
                }
            },
            secondary: {
                icon: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.white
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.accentGreen
                },
                border: {
                    default: palette.gray300,
                    hover: palette.accentGreen,
                    checked: palette.accentGreen
                }
            },
            tertiary: {
                icon: {
                    default: palette.coolGray,
                    hover: palette.coolGray,
                    checked: palette.white
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.coolLightRed
                },
                border: {
                    default: palette.gray300,
                    hover: palette.coolGray,
                    checked: palette.coolLightRed
                }
            }
        },
        checkboxCard: {
            primary: {
                text: {
                    default: palette.black,
                    hover: palette.black,
                    checked: palette.white
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.accentGreen
                },
                border: {
                    default: palette.gray300,
                    hover: palette.gray260,
                    checked: palette.gray260
                }
            },
            secondary: {
                text: {
                    default: palette.black,
                    hover: palette.black,
                    checked: palette.white
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.coolLightRed
                },
                border: {
                    default: palette.gray300,
                    hover: palette.coolLightRed,
                    checked: palette.coolLightRed
                }
            }
        },
        switch: {
            primary: {
                slider: {
                    default: palette.coolGray,
                    hover: palette.coolGray,
                    checked: palette.coolLightRed
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.white
                },
                border: {
                    default: palette.gray300,
                    hover: palette.gray400,
                    checked: palette.gray300
                }
            },
            secondary: {
                slider: {
                    default: palette.coolGray,
                    hover: palette.coolGray,
                    checked: palette.accentGreen
                },
                background: {
                    default: palette.white,
                    hover: palette.white,
                    checked: palette.white
                },
                border: {
                    default: palette.gray300,
                    hover: palette.gray400,
                    checked: palette.gray300
                }
            }
        },
        radio: {
            check: {
                default: palette.white,
                checked: palette.coolLightRed
            },
            background: {
                default: palette.white,
                checked: palette.white
            },
            border: {
                default: palette.coolGray,
                checked: palette.coolGray
            }
        },
        richText: {
            button: {
                text: {
                    default: palette.black,
                    hover: palette.black,
                    enabled: palette.black,
                    disabled: palette.gray400
                },
                background: {
                    default: palette.white,
                    hover: palette.gray400,
                    enabled: palette.gray400
                },
                border: {
                    default: palette.gray400,
                    hover: palette.gray400,
                    enabled: palette.gray400
                }
            }
        },
        error: {
            text: palette.errorTx,
            border: palette.errorBg,
            background: palette.errorBg
        }
    },
    article: {
        blockquote: {
            quotes: {
                text: palette.black,
                background: palette.accentYellow
            }
        }
    },
    avatar: {
        text: {
            profileFollowing: {
                background: palette.gray200,
                label: palette.gray500
            },
            recipe: {
                name: palette.black,
                username: palette.black
            },
            photo: {
                name: palette.gray500,
                username: palette.black
            },
            notifications: palette.white
        },
        background: {
            main: palette.white,
            image: palette.white
        }
    },
    profileCard: {
        background: palette.white,
        text: palette.black,
        meta: {
            text: palette.gray500
        }
    },
    userSettingsCard: {
        background: palette.white,
        text: palette.gray500
    },
    recipe: {
        text: {
            info: {
                bullet: palette.coolGray,
                icon: {
                    default: palette.black,
                    cool: palette.coolLightRed
                }
            },
            lead: {
                desktop: palette.white
            },
            navigation: {
                default: palette.coolGray,
                hover: palette.black,
                active: palette.black
            }
        },
        background: {
            head: palette.white,
            toolBox: palette.white,
            step: {
                number: palette.accentYellow
            },
            tags: palette.white,
            navigation: palette.white
        },
        actionShots: {
            text: palette.white,
            background: palette.accentGreen
        }
    },
    pill: {
        primary: {
            text: {
                default: palette.black,
                hover: palette.black,
                active: palette.white
            },
            count: {
                default: palette.gray500,
                hover: palette.gray300,
                active: palette.gray300
            },
            background: {
                default: palette.white,
                hover: palette.white,
                active: palette.accentGreen
            },
            border: {
                default: palette.gray300,
                hover: palette.accentGreen,
                active: palette.accentGreen
            }
        },
        secondary: {
            text: {
                default: palette.black,
                hover: palette.black,
                active: palette.white
            },
            count: {
                default: palette.gray500,
                hover: palette.gray300,
                active: palette.gray300
            },
            background: {
                default: palette.white,
                hover: palette.white,
                active: palette.coolLightRed
            },
            border: {
                default: palette.gray300,
                hover: palette.coolGray,
                active: palette.coolLightRed
            }
        }
    },
    ingredient: {
        ingredientInfo: {
            text: palette.darkGray
        }
    },
    tab: {
        primary: {
            head: {
                background: {
                    default: palette.white
                },
                text: {
                    active: palette.coolDarkRed,
                    inactive: palette.darkGray,
                    hover: palette.coolDarkRed
                },
                indicator: {
                    active: palette.coolDarkRed,
                    inactive: palette.gray300
                }
            }
        }
    },
    notice: {
        success: {
            text: palette.successTx,
            background: palette.successBg
        },
        info: {
            text: palette.infoTx,
            background: palette.infoBg
        },
        warning: {
            text: palette.warningTx,
            background: palette.warningBg
        },
        error: {
            text: palette.errorTx,
            background: palette.errorBg
        }
    },
    navigation: {
        text: {
            default: palette.black,
            hover: palette.black,
            active: palette.coolLightRed,
            subtle: palette.coolGray,
            tertiary: palette.darkGray
        },
        icon: {
            fill: {
                default: palette.black,
                hover: palette.black,
                active: palette.white,
                subtle: palette.gray500
            },
            background: {
                default: palette.gray200,
                hover: palette.gray200,
                active: palette.coolLightRed,
                subtle: palette.gray200
            }
        },
        background: {
            bar: palette.white,
            panel: palette.white
        },
        separator: palette.gray400,
        account: {
            text: {
                default: palette.black,
                username: palette.gray500,
                avatar: palette.white
            },
            background: {
                avatar: palette.coolLightRed
            }
        }
    },
    notification: {
        badge: {
            text: palette.white,
            background: palette.coolLightRed,
            border: palette.white
        },
        item: {
            text: {
                default: palette.black,
                date: palette.gray500
            },
            border: {
                default: palette.gray300
            }
        }
    },
    scrollbar: {
        track: '#EFEFEF',
        thumb: '#BEBEBE'
    },
    logo: {
        primary: {
            cool: palette.coolLightRed,
            default: palette.coolGray,
            text: palette.black
        }
    },
    drawer: {
        background: {
            panel: palette.white
        },
        head: {
            text: palette.gray500
        },
        separator: {
            text: palette.gray500,
            graphic: palette.gray300
        }
    },
    comments: {
        avatar: {
            text: {
                default: palette.black,
                hover: palette.coolDarkRed,
                date: palette.gray500
            },
            background: {
                default: palette.gray250
            },
            border: {
                author: palette.coolDarkRed,
                user: palette.accentGreen
            }
        },
        options: {
            button: {
                open: {
                    text: palette.gray550,
                    border: palette.gray200
                },
                delete: {
                    text: palette.gray500
                }
            },
            background: {
                default: palette.white
            }
        },
        item: {
            border: palette.gray300
        },
        bubble: {
            text: {
                default: palette.white
            },
            background: {
                default: palette.coolLightRed
            }
        },
        count: {
            text: {
                default: palette.black
            },
            background: {
                default: palette.accentYellow
            }
        }
    },
    error: {
        dev: {
            text: palette.white,
            background: palette.devWarning
        }
    },
    card: {
        overlay: {
            background: palette.pureBlack
        },
        article: {
            content: {
                background: palette.white
            },
            lead: {
                text: {
                    white: palette.white,
                    black: palette.black
                }
            }
        },
        coolChef: {
            user: {
                backgroud: palette.gray200
            }
        },
        topChef: {
            separator: {
                background: palette.gray300
            },
            circle: {
                background: palette.accentYellow,
                outline: palette.white
            }
        },
        collage: {
            background: palette.white
        },
        promotion: {
            registration: {
                background: palette.accentGreen
            },
            user_profile: {
                background: palette.accentTurquoise
            },
            taste_profile: {
                background: palette.accentBlue
            },
            newsletter: {
                background: palette.accentBrown200
            }
        },
        notice: {
            background: palette.accentGreen
        }
    },
    trophyCard: {
        background: palette.white,
        color: palette.gray500
    },
    userProfileHead: {
        main: {
            background: palette.white,
            content: {
                color: palette.black
            }
        },
        avatar: {
            background: palette.white,
            title: palette.gray500,
            border: palette.gray250
        },
        tabs: {
            background: palette.white,
            border: palette.gray250,
            hover: palette.errorTx
        },
        trophy: {
            card: {
                border: palette.gray300
            },
            title: {
                background: palette.white
            }
        }
    },
    inspiration: {
        featured: {
            text: palette.white,
            background: '#16332F',
            button: {
                text: {
                    default: palette.black,
                    hover: palette.white
                },
                background: {
                    default: palette.white,
                    hover: palette.coolDarkRed
                }
            }
        }
    },
    gamification: {
        trophy: {
            timeline: {
                progress: palette.gray300
            }
        }
    },
    imageUploader: {
        gallery: {
            image: {
                active: {
                    text: palette.white,
                    border: palette.accentGreen,
                    icon: palette.accentGreen
                }
            }
        }
    },
    dictionary: {
        header: {
            similar: {
                title: {
                    color: palette.pureBlack
                },
                meta: {
                    primary: {
                        color: palette.gray500
                    },
                    secondary: {
                        color: palette.darkGray
                    }
                },
                link: {
                    text: palette.black,
                    underline: palette.gray300
                }
            }
        },
        block: {
            border: {
                color: palette.gray300
            }
        },
        letter: {
            active: {
                text: palette.coolLightRed
            }
        }
    },
    informationCard: {
        background: palette.accentGreen,
        text: palette.white
    },
    auth: {
        separator: {
            text: palette.gray500,
            graphic: palette.gray300
        },
        card: {
            welcome: {
                text: palette.white,
                background: palette.accentGreen
            }
        }
    },
    recipeUploader: {
        window: {
            background: palette.white
        },
        head: {
            background: palette.white
        },
        body: {
            background: palette.white
        },
        separator: {
            text: palette.black,
            graphic: palette.gray300,
            icon: palette.coolGray
        },
        gallery: {
            add: {
                text: palette.black,
                background: palette.white,
                border: palette.gray250
            }
        },
        tags: {
            expander: {
                count: palette.accentGreen
            },
            input: {
                text: palette.white,
                background: palette.accentGreen
            }
        },
        input: {
            decoration: palette.black,
            sequence: palette.accentYellow
        },
        actionBar: {
            button: {
                actions: {
                    disabled: {
                        text: palette.black,
                        background: palette.gray300,
                        border: palette.gray300
                    },
                    enabled: {
                        text: palette.white,
                        background: palette.accentGreen200,
                        border: palette.accentGreen200
                    }
                }
            }
        }
    },
    club: {
        card: {
            background: palette.gray100,
            border: palette.white,
            lead: palette.white
        }
    },
    separator: {
        default: palette.gray300
    },
    stats: {
        text: palette.white,
        background: palette.accentGreen
    },
    share: {
        cta: {
            icon: palette.coolLightRed
        },
        buttons: {
            background: palette.white,
            border: palette.gray300
        }
    },
    table: {
        border: palette.gray300,
        background: palette.gray300
    }
}

// ReactiveSearch overrides because they really got terrible styling mechanics for a library
const reactiveSearchOverrides = {
    textColor: colors.text.default,
    primaryTextColor: colors.text.white,
    primaryColor: '#0B6AFF',
    titleColor: colors.text.default,
    alertColor: '#d9534f',
    borderColor: '#595959'
}

export default {
    ...palette,
    ...colors,
    ...reactiveSearchOverrides
}
