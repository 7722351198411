/* eslint-disable import/no-absolute-path, import/extensions, quote-props, max-len */

import { toRem } from '@hmn/coolinarika-web-ui/helpers/theme'

export default {
    baseLayout: {
        desktop: {
            sidebar: {
                width: `clamp(270px, ${toRem(290)}, ${toRem(290)})`, // clamp to minimum width of 270px
                height: '100vh'
            },
            topBar: {
                spacing: toRem(40)
            }
        },
        mobile: {
            navigation: {
                bar: {
                    height: 60
                }
            }
        }
    },
    // Drawer component
    drawer: {
        hSpacing: {
            desktop: 60,
            mobile: 30
        }
    },
    // Recipe Uploader
    recipeUploader: {
        head: {
            height: {
                desktop: 74,
                mobile: 74
            }
        },
        actionBar: {
            height: 60
        }
    },
    borderRadius: {
        small: toRem(4),
        medium: toRem(8),
        large: toRem(15),
        larger: toRem(100),
        rounded: '50%'
    },
    // RichText Comments
    '& .tox': {
        '& .tox-menu': {
            minWidth: '12em'
        },
        '& .tox-collection--list': {
            '& .tox-collection__item': {
                '&--active': {
                    backgroundColor: 'red',

                    '& .tox-collection__item-label': {
                        color: 'red',

                        '& .tox-autocompleter-highlight': {
                            fontWeight: 400
                        }
                    }
                }
            }
        },
        '& .tox-collection__item-container': {
            minWidth: '10rem',
            '& .user-avatar': {
                width: '3rem',
                height: '3rem',
                borderRadius: '100%'
            },
            '& .tox-collection__item-label': {
                marginLeft: '1rem',
                fontSize: '1rem',

                '& .tox-autocompleter-highlight': {
                    fontWeight: 900
                }
            }
        }
    }
}
