import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, isInSearchPanel, hasPresetHeight }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(hasPresetHeight && {
        height: '50vh'
    }),
    ...(isInSearchPanel &&
        hasPresetHeight && {
            height: `calc(100vh - ${toRem(200)})`
        }),
    [theme.breakpoints.up('lg')]: {
        ...(isInSearchPanel &&
            hasPresetHeight && {
                height: `calc(100vh - ${toRem(260)})`
            })
    },
    '& .searchNoResults': {
        '&_inner': {
            padding: toRems([0, 25]),
            textAlign: 'center'
        },
        '&_asset': {
            maxWidth: toRem(180),
            margin: '0 auto',
            marginBottom: toRem(15),
            [theme.breakpoints.up('lg')]: {
                maxWidth: toRem(265),
                margin: '0 auto',
                marginBottom: toRem(40),
                fontSize: toRem(28)
            }
        },
        '&_title': {
            fontSize: toRem(20),
            lineHeight: 1.2,
            fontWeight: theme.typography.fontWeight.black,
            padding: toRems([0, 20, 0, 20]),
            margin: toRems([0, 0, 10, 0]),
            [theme.breakpoints.up('lg')]: {
                margin: toRems([0, 0, 15, 0]),
                fontSize: toRem(28)
            }
        },
        '&_text': {
            padding: toRems([0, 20, 0, 20]),
            fontSize: toRem(16),
            lineHeight: 1.6,
            [theme.breakpoints.up('lg')]: {
                fontSize: toRem(18)
            },
            '& > *': {
                fontSize: 'inherit',
                lineHeight: 'inherit'
            }
        }
    }
})

export default style
