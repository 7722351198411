// @NOTE: By design we support only these 5 icons, extend if needed

import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { ConditionalWrapper } from '../../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../../ErrorBoundary'
import {
    Icon,
    NavGreenCornerIcon,
    NavHamburgerIcon,
    NavHomeIcon,
    NavInspirationIcon,
    NavRecipesIcon,
    NavSearchIcon
} from '../../../../Icon'
import { Link } from '../../../../Link'
import styles from './Item.style'

const NavBarItemStyled = styled.div(props => ({ ...styles(props) }))

const getIcon = icon =>
    ({
        home: NavHomeIcon,
        inspiration: NavInspirationIcon,
        greenCorner: NavGreenCornerIcon,
        recipes: NavRecipesIcon,
        search: NavSearchIcon,
        hamburger: NavHamburgerIcon
    }[icon])

const NavBarItem = ({
    className,
    href,
    hrefAs,
    icon,
    title,
    onClick,
    action,
    isActive,
    isActiveOffset,
    size,
    ...rest
}) => {
    const router = useRouter()
    const dispatch = useDispatch()

    const handleClick = useCallback(
        (event, ...args) => {
            if (action) {
                dispatch(action)
            } else if (onClick) {
                onClick(event, ...args)
            }

            if (href) {
                router.push(href, hrefAs)
            }
        },
        [dispatch]
    )

    if (!icon || !getIcon(icon)) {
        return null
    }

    return (
        <NavBarItemStyled className={className} isActive={isActive} isActiveOffset={isActiveOffset} {...rest}>
            <ConditionalWrapper condition={!!href} href={href} as={hrefAs} title={title} Wrapper={Link}>
                <span className="barNav_item" onClick={handleClick} role="presentation" title={title}>
                    <span className="barNav_itemIcon">
                        <Icon icon={getIcon(icon)} size={size} viewBox="0 0 36 36" />
                    </span>
                    <span className="barNav_itemIndicator" />
                </span>
            </ConditionalWrapper>
        </NavBarItemStyled>
    )
}

NavBarItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    action: PropTypes.oneOfType([() => null, PropTypes.func]),
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    size: PropTypes.number,
    isActiveOffset: PropTypes.number
}

NavBarItem.defaultProps = {
    className: undefined,
    href: undefined,
    hrefAs: undefined,
    icon: undefined,
    title: undefined,
    onClick: () => {},
    action: undefined,
    isActive: false,
    size: undefined,
    isActiveOffset: undefined
}

export default withErrorBoundary(memo(NavBarItem))
