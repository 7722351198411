import { NotificationActionTypes } from '../../actions/notifications'

const initialState = {
    notifications: []
}

const notificationsReducer = (state = initialState, { type, notification }) => {
    switch (type) {
        case NotificationActionTypes.HIDE: {
            return {
                ...state,
                notifications: state.notifications.filter(stateNotification => stateNotification.id !== notification.id)
            }
        }

        case NotificationActionTypes.SHOW: {
            return {
                ...state,
                notifications: [...state.notifications, notification]
            }
        }

        default:
            return state
    }
}

export { notificationsReducer }
