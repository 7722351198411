const constants = {
    CLOSE_DRAWER: 'HMN/COOLINARIKA/WEB/DRAWERS/CLOSE_DRAWER',
    OPEN_DRAWER: 'HMN/COOLINARIKA/WEB/DRAWERS/OPEN_DRAWER',
    POP_CONTENT: 'HMN/COOLINARIKA/WEB/DRAWERS/POP_CONTENT',
    UPDATE_TITLE: 'HMN/COOLINARIKA/WEB/DRAWERS/UPDATE_TITLE',
    IS_ANIMATION_COMPLETE: 'HMN/COOLINARIKA/WEB/DRAWERS/IS_ANIMATION_COMPLETE',
    UPDATE_ITEMS: 'HMN/COOLINARIKA/WEB/DRAWERS/UPDATE_ITEMS',
    RESET_PASSWORD_EMAIL_SENT: 'HMN/COOLINARIKA/WEB/DRAWERS/RESET_PASSWORD_EMAIL_SENT',
    SET_ON_SUCCESS_DRAWER: 'HMN/COOLINARIKA/WEB/DRAWERS/SET_ON_SUCCESS_DRAWER'
}

const actions = {
    openDrawer: (drawerType, options) => ({
        type: constants.OPEN_DRAWER,
        drawerType,
        options
    }),
    updateTitle: title => ({ type: constants.UPDATE_TITLE, options: { title } }),
    popContent: drawerType => ({ type: constants.POP_CONTENT, drawerType }),
    closeDrawer: () => ({ type: constants.CLOSE_DRAWER }),
    isAnimationComplete: isComplete => ({ type: constants.IS_ANIMATION_COMPLETE, isComplete }),
    updateItems: options => ({ type: constants.UPDATE_ITEMS, options }),
    resetPasswordEmailSent: isEmailSent => ({ type: constants.RESET_PASSWORD_EMAIL_SENT, isEmailSent }),
    setOnSuccessDrawer: onSuccessDrawer => ({ type: constants.SET_ON_SUCCESS_DRAWER, onSuccessDrawer })
}

export { constants as DrawerActionTypes, actions as DrawerActions }
