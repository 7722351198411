// @TODO: stripHtml 2x in codebase and removeHtmlFromString are same functions. Reduce to one!
import xmlUnescape from './xmlUnescape'

const stripHtml = html => html.replace(/<[^>]*>/gim, '')

const excerpt = (value, limitCharacterOutput) => {
    const purifyValue = stripHtml(xmlUnescape(value))
    const charlimit = 190
    const sufiks = '...'
    let newString = ''
    let end = limitCharacterOutput

    // if seconda argument is false, gtfo from here
    // eslint-disable-next-line no-extra-boolean-cast
    if (end === false) {
        return
    }

    // if length of string is less than character limit, just return value back
    if (purifyValue.length <= charlimit || typeof end === 'undefined') {
        // eslint-disable-next-line consistent-return
        return purifyValue
    }

    // if second arg is not boolean or number, return false and console error message
    if (typeof end !== 'boolean' && typeof end !== 'number') {
        // eslint-disable-next-line no-console
        console.error(
            // eslint-disable-next-line max-len
            `value: ${end} should be type of number (limit of characters) or boolean (default limit value to 120 characters)`
        )
        // eslint-disable-next-line consistent-return
        return false
    }

    // use only positive numbers :)
    if (typeof end === 'number') {
        end = Math.abs(end)
    }

    // if is inserted boolean true then return preddefined limit of character limit
    if (typeof end === 'boolean' && end !== false) {
        end = charlimit
    }

    newString = purifyValue.substr(0, end - sufiks.length)
    // eslint-disable-next-line consistent-return
    return `${newString}${sufiks}`
}

export default excerpt
