import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { FilterListView } from '../FilterListView'
import { FilterPill } from '../FilterPill'

const checkIfActive = (filterArray, key) => {
    if (!filterArray?.length) {
        return false
    }

    return filterArray.includes(key)
}

const MultiList = ({ data, componentId, title, onChange, activeFilters }) => {
    const filterValues = useMemo(() => data[componentId] || [], [data, componentId])

    return (
        <FilterListView title={title}>
            {filterValues.map(value => (
                <FilterPill
                    key={`${componentId}-${value}`}
                    id={`${componentId}-${value}`}
                    name={componentId}
                    value={value}
                    label={value}
                    isActive={checkIfActive(activeFilters[componentId], value)}
                    onChange={onChange}
                />
            ))}
        </FilterListView>
    )
}

MultiList.propTypes = {
    data: PropTypes.shape({}).isRequired,
    componentId: PropTypes.string.isRequired,
    title: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    activeFilters: PropTypes.shape({}).isRequired
}

MultiList.defaultProps = {
    title: undefined
}

export default MultiList
