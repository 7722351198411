// @ui imports
import { responsive, toRem, toRems } from '../../helpers/theme'

// Config
const sizeAttributes = {
    xLarge: {
        text: {
            fontSize: {
                small: 32,
                medium: 52,
                large: 72
            },
            lineHeight: {
                small: 1.1,
                medium: 1.1,
                large: 1.1
            }
        },
        spacing: {
            x: {
                small: 5,
                medium: 8,
                large: 8
            },
            y: {
                small: 5,
                medium: 7,
                large: 8
            },
            xLast: {
                small: 5,
                medium: 8,
                large: 8
            }
        }
    },
    large: {
        text: {
            fontSize: {
                small: 24,
                medium: 30,
                large: 36
            },
            lineHeight: {
                small: 1.2,
                medium: 1.2,
                large: 1.2
            }
        },
        spacing: {
            x: {
                small: 5,
                medium: 4,
                large: 4
            },
            y: {
                small: 5,
                medium: 5,
                large: 5
            },
            xLast: {
                small: 4,
                medium: 6,
                large: 6
            }
        }
    },
    normal: {
        text: {
            fontSize: {
                small: 18,
                medium: 24,
                large: 28
            },
            lineHeight: {
                small: 1.2,
                medium: 1.2,
                large: 1.2
            }
        },
        spacing: {
            x: {
                small: 5,
                medium: 4,
                large: 4
            },
            y: {
                small: 5,
                medium: 5,
                large: 5
            },
            xLast: {
                small: 4,
                medium: 6,
                large: 6
            }
        }
    },
    small: {
        text: {
            fontSize: {
                small: 16,
                medium: 18,
                large: 20
            },
            lineHeight: {
                small: 1.2,
                medium: 1.2,
                large: 1.2
            }
        },
        spacing: {
            x: {
                small: 3,
                medium: 3,
                large: 4
            },
            y: {
                small: 3,
                medium: 4,
                large: 4
            },
            xLast: {
                small: 2,
                medium: 4,
                large: 5
            }
        }
    },
    xSmall: {
        text: {
            fontSize: {
                small: 16,
                medium: 16,
                large: 16
            },
            lineHeight: {
                small: 1.2,
                medium: 1.2,
                large: 1.2
            }
        },
        spacing: {
            x: {
                small: 2,
                medium: 2,
                large: 2
            },
            y: {
                small: 3,
                medium: 3,
                large: 3
            },
            xLast: {
                small: 4,
                medium: 4,
                large: 4
            }
        }
    }
}

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, variant, size, hasHover, isReady }) => {
    // for development errors
    const devWarnTxt = theme.colors.error.dev.text
    const devWarnBg = theme.colors.error.dev.background

    // DO NOT TOUCH ANYTHING BELOW THIS POINT (if you do, be vary careful)
    return {
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: theme.zIndex.over,
        width: '100%',
        color: theme.colors.heading.highlight[variant]?.text?.default || devWarnTxt,
        textDecoration: 'none',
        opacity: 0,
        ...(isReady && {
            opacity: 1,
            transitionDuration: theme.transitions.heading.highlight.default.duration,
            transitionTimingFunction: theme.transitions.heading.highlight.default.timingFunction,
            transitionDelay: theme.transitions.heading.highlight.default.delay,
            transitionProperty: 'opacity'
        }),
        ...responsive([
            {
                padding: toRems([sizeAttributes[size].spacing.x.small, sizeAttributes[size].spacing.y.small]),
                fontSize: toRem(sizeAttributes[size].text.fontSize.small),
                lineHeight: sizeAttributes[size].text.lineHeight.small
            }, // xs
            {
                padding: toRems([sizeAttributes[size].spacing.x.small, sizeAttributes[size].spacing.y.small]),
                fontSize: toRem(sizeAttributes[size].text.fontSize.small),
                lineHeight: sizeAttributes[size].text.lineHeight.small
            }, // sm
            {
                padding: toRems([sizeAttributes[size].spacing.x.medium, sizeAttributes[size].spacing.y.medium]),
                fontSize: toRem(sizeAttributes[size].text.fontSize.medium),
                lineHeight: sizeAttributes[size].text.lineHeight.medium
            }, // md
            {
                padding: toRems([sizeAttributes[size].spacing.x.medium, sizeAttributes[size].spacing.y.medium]),
                fontSize: toRem(sizeAttributes[size].text.fontSize.medium),
                lineHeight: sizeAttributes[size].text.lineHeight.medium
            }, // lg
            {
                padding: toRems([sizeAttributes[size].spacing.x.large, sizeAttributes[size].spacing.y.large]),
                fontSize: toRem(sizeAttributes[size].text.fontSize.large),
                lineHeight: sizeAttributes[size].text.lineHeight.large
            }, // xl
            {
                padding: toRems([sizeAttributes[size].spacing.x.large, sizeAttributes[size].spacing.y.large]),
                fontSize: toRem(sizeAttributes[size].text.fontSize.large),
                lineHeight: sizeAttributes[size].text.lineHeight.large
            } // xxl
        ]),
        ...(hasHover && {
            cursor: 'pointer',
            transitionDuration: theme.transitions.heading.highlight.default.duration,
            transitionTimingFunction: theme.transitions.heading.highlight.default.timingFunction,
            transitionDelay: theme.transitions.heading.highlight.default.delay,
            transitionProperty: 'color'
        }),
        '& span': {
            display: 'table', // This is the magic sauce
            whiteSpace: 'nowrap', // This is the magic sauce
            position: 'relative',
            ...responsive([
                {
                    marginTop: toRem(-sizeAttributes[size].spacing.x.small),
                    padding: toRems([0, sizeAttributes[size].spacing.y.small, sizeAttributes[size].spacing.x.small])
                }, // xs
                {
                    marginTop: toRem(-sizeAttributes[size].spacing.x.small),
                    padding: toRems([0, sizeAttributes[size].spacing.y.small, sizeAttributes[size].spacing.x.small])
                }, // sm
                {
                    marginTop: toRem(-sizeAttributes[size].spacing.x.medium),
                    padding: toRems([0, sizeAttributes[size].spacing.y.medium, sizeAttributes[size].spacing.x.medium])
                }, // md
                {
                    marginTop: toRem(-sizeAttributes[size].spacing.x.medium),
                    padding: toRems([0, sizeAttributes[size].spacing.y.medium, sizeAttributes[size].spacing.x.medium])
                }, // lg
                {
                    marginTop: toRem(-sizeAttributes[size].spacing.x.large),
                    padding: toRems([0, sizeAttributes[size].spacing.y.large, sizeAttributes[size].spacing.x.large])
                }, // xl
                {
                    marginTop: toRem(-sizeAttributes[size].spacing.x.large),
                    padding: toRems([0, sizeAttributes[size].spacing.y.large, sizeAttributes[size].spacing.x.large])
                } // xxl
            ]),
            '&:first-of-type': {
                marginTop: 0
            },
            '&:last-of-type': {
                ...responsive([
                    { paddingBottom: toRem(sizeAttributes[size].spacing.xLast.small) }, // xs
                    { paddingBottom: toRem(sizeAttributes[size].spacing.xLast.small) }, // sm
                    { paddingBottom: toRem(sizeAttributes[size].spacing.xLast.medium) }, // md
                    { paddingBottom: toRem(sizeAttributes[size].spacing.xLast.medium) }, // lg
                    { paddingBottom: toRem(sizeAttributes[size].spacing.xLast.large) }, // xl
                    { paddingBottom: toRem(sizeAttributes[size].spacing.xLast.large) } // xxl
                ])
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                backgroundColor: theme.colors.heading.highlight[variant]?.background?.default || devWarnBg,
                ...responsive([
                    { margin: toRems([-sizeAttributes[size].spacing.x.small, -sizeAttributes[size].spacing.y.small]) }, // xs
                    { margin: toRems([-sizeAttributes[size].spacing.x.small, -sizeAttributes[size].spacing.y.small]) }, // sm
                    {
                        margin: toRems([-sizeAttributes[size].spacing.x.medium, -sizeAttributes[size].spacing.y.medium])
                    }, // md
                    {
                        margin: toRems([-sizeAttributes[size].spacing.x.medium, -sizeAttributes[size].spacing.y.medium])
                    }, // lg
                    { margin: toRems([-sizeAttributes[size].spacing.x.large, -sizeAttributes[size].spacing.y.large]) }, // xl
                    { margin: toRems([-sizeAttributes[size].spacing.x.large, -sizeAttributes[size].spacing.y.large]) } // xxl
                ]),
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: -1,
                ...(hasHover && {
                    transitionDuration: theme.transitions.heading.highlight.default.duration,
                    transitionTimingFunction: theme.transitions.heading.highlight.default.timingFunction,
                    transitionDelay: theme.transitions.heading.highlight.default.delay,
                    transitionProperty: 'background-color'
                })
            }
        },
        ...(hasHover && {
            '&:hover': {
                color: theme.colors.heading.highlight[variant]?.text?.hover || devWarnTxt,
                '& span': {
                    '&::before': {
                        background: theme.colors.heading.highlight[variant]?.background?.hover || devWarnBg
                    }
                }
            }
        }),
        '@media print': {
            color: theme.colors.black,
            '& span::before': {
                backgroundColor: 'transparent'
            }
        }
    }
}

export default style
