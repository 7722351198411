import { toRem } from '../../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        nav: {
            spacing: {
                xs: 30,
                lg: 40
            }
        }
    }

    return {
        width: '100%',
        height: '100%',
        '& .accountMenu': {
            '&_container': {
                // this part sucks
                '& .ScrollbarsCustom-Content': {
                    [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }
                }
            },
            '&_nav': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: toRem(config.nav.spacing.xs),
                [theme.breakpoints.up('lg')]: {
                    padding: toRem(config.nav.spacing.lg)
                },
                '&List': {
                    paddingBottom: 0,
                    [theme.breakpoints.up('lg')]: {
                        paddingBottom: toRem(config.nav.spacing.lg) // magic spacing that fixes ScrollBar height issue
                    },
                    '& > li': {
                        marginBottom: toRem(12),
                        [theme.breakpoints.up('xxxl')]: {
                            marginBottom: toRem(15)
                        },
                        '&:last-of-type': {
                            marginBottom: 0
                        }
                    }
                }
            }
        }
    }
}

export default style
