/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const WaveShort = createSvgIcon(
    <path
        d="M.47 3.47a.75.75 0 101.06 1.06L.47 3.47zm37 1.06a.75.75 0 101.06-1.06l-1.06 1.06zm-5.086-2.126l-.501-.557.501.557zm4.129.109l-.53.53.53-.53zm-5.006.68l.502.558-.502-.557zm-9.02-.68l-.53-.53.53.53zm-5.108.866l-.53.53.53-.53zm4.242 0l.53.53-.53-.53zM12.38 2.62l.53.53-.53-.53zm4.242 0l-.53.53.53-.53zM7.38 3.38l-.53.53.53-.53zm4.242 0l-.53-.53.53.53zm-5-.758l-.53.53.53-.53zm-5.09 1.91l1.378-1.38-1.06-1.06L.468 3.47 1.53 4.53zm4.56-1.38l.757.758 1.061-1.06-.757-.758-1.061 1.06zm6.06.758l.758-.757-1.06-1.061-.758.757 1.06 1.061zm3.94-.757l.757.757 1.061-1.06-.757-.758-1.061 1.06zm6.06.757l.867-.866-1.06-1.06-.867.865 1.06 1.061zm3.963-.947l.877.79 1.004-1.116-.878-.79-1.003 1.116zm5.895.79l.877-.79-1.003-1.115-.878.79L32.01 3.75zm3.973-.71L37.47 4.53l1.06-1.06-1.487-1.488-1.06 1.06zm-3.096-.08a2.25 2.25 0 013.096.08l1.06-1.06a3.75 3.75 0 00-5.16-.135l1.004 1.115zm-5.895.79a3.75 3.75 0 005.018 0l-1.004-1.116a2.25 2.25 0 01-3.01 0L26.99 3.751zm-3.973-.71a2.25 2.25 0 013.096-.08l1.003-1.115a3.75 3.75 0 00-5.16.135l1.06 1.06zm-6.17.867a3.75 3.75 0 005.304 0l-1.061-1.06a2.25 2.25 0 01-3.182 0l-1.06 1.06zm-3.939-.757a2.25 2.25 0 013.182 0l1.06-1.061a3.75 3.75 0 00-5.303 0l1.061 1.06zm-6.06.757a3.75 3.75 0 005.303 0l-1.061-1.06a2.25 2.25 0 01-3.182 0l-1.06 1.06zm-3.94-.757a2.25 2.25 0 013.182 0l1.06-1.061a3.75 3.75 0 00-5.303 0l1.061 1.06z"
        fill="#141313"
    />,
    'WaveShort'
)

export default WaveShort
