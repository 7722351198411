import { useEffect, useState } from 'react'

const getOnlineStatus = () => {
    if (typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean') {
        return navigator.onLine
    }
    return true
}

const useOnlineStatus = () => {
    const [onlineStatus, setOnlineStatus] = useState(() => getOnlineStatus())

    useEffect(() => {
        const goOnline = () => setOnlineStatus(true)

        const goOffline = () => setOnlineStatus(false)

        window.addEventListener('online', goOnline)
        window.addEventListener('offline', goOffline)
        return () => {
            window.removeEventListener('online', goOnline)
            window.removeEventListener('offline', goOffline)
        }
    }, [])

    return onlineStatus
}

export default useOnlineStatus
