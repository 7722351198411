import { toRem } from '../theme'
import isNumeric from './isNumeric'

/**
 * Normalize dimensions.
 * @param {string|number} dimension
 * @param {boolean} returnRem
 * @returns {string}
 */

const normalizeDimension = (dimension, returnRem = false) => {
    if (dimension) {
        const permittedStrings = ['auto', '%']
        if (typeof dimension === 'string' && permittedStrings.some(s => dimension?.includes(s))) {
            return dimension
        }
        if (isNumeric(dimension)) {
            const dimensionValue = parseInt(`${dimension}`.replace(/[^0-9]+/g, ''), 10)
            return returnRem ? toRem(dimensionValue) : dimensionValue
        }
    }
    return undefined
}

export default normalizeDimension
