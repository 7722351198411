const constants = {
    GET_SETTINGS: 'HMN/COOLINARIKA/WEB/SETTINGS/GET_SETTINGS',
    SET_IMAGES_VARIATIONS: 'HMN/COOLINARIKA/WEB/SETTINGS/SET_IMAGES_VARIATIONS',
    SET_LOADED: 'HMN/COOLINARIKA/WEB/SETTINGS/SET_SETTINGS_LOADED',
    SET_USING_MODULE_CACHE: 'HMN/COOLINARIKA/WEB/SETTINGS/SET_USING_MODULE_CACHE',
    SET_FLAG_TAXONS: 'HMN/COOLINARIKA/WEB/SETTINGS/SET_FLAG_TAXONS',
    SET_WTG_LOADED: 'HMN/COOLINARIKA/WEB/SETTINGS/SET_WTG_LOADED'
}

const actions = {
    getSettings: () => ({ type: constants.GET_SETTINGS }),
    setImagesVariations: variations => ({ type: constants.SET_IMAGES_VARIATIONS, variations }),
    setLoaded: isLoaded => ({ type: constants.SET_LOADED, isLoaded }),
    setModuleCache: isCache => ({ type: constants.SET_USING_MODULE_CACHE, isCache }),
    setFlagTaxons: taxons => ({ type: constants.SET_FLAG_TAXONS, taxons }),
    setWtgLoaded: wtgLoaded => ({ type: constants.SET_WTG_LOADED, wtgLoaded })
}

export { constants as SettingsActionTypes, actions as SettingsActions }
