/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const Search = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 15c0 5.523-4.477 10-10 10a9.955 9.955 0 01-5.86-1.896l-4.08 4.078a1.5 1.5 0 11-2.12-2.121L9.5 21A9.956 9.956 0 017.5 15c0-5.523 4.477-10 10-10s10 4.477 10 10zm-3 0a7 7 0 11-14 0 7 7 0 0114 0z"
        fill="#BCBDC0"
    />,
    'Search'
)

export default Search
