import { toRem, toRems } from '../../../../../helpers/theme'

const style = ({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    padding: toRems([20, 20, 0, 20]),
    zIndex: theme.zIndex.baseLayout.topBar.mobile,
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
        padding: theme.global.baseLayout.desktop.topBar.spacing,
        paddingBottom: 0,
        paddingTop: toRem(20)
    },
    [theme.breakpoints.up('lg')]: {
        padding: theme.global.baseLayout.desktop.topBar.spacing,
        left: theme.global.baseLayout.desktop.sidebar.width,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: `calc(100% - ${theme.global.baseLayout.desktop.sidebar.width})`,
        zIndex: theme.zIndex.baseLayout.topBar.desktop
    },
    '& > *': {
        pointerEvents: 'auto'
    },
    '& .topBar_search': {
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            maxWidth: toRem(370)
        }
    }
})

export default style
