/* eslint-disable @typescript-eslint/no-empty-function */
// @TODO: loader

import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { useAnalytics } from '@hmn/coolinarika-web-core/hooks'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { withErrorBoundary } from '../ErrorBoundary'
import { Link } from '../Link'
import styles from './Button.style'

const ButtonStyled = styled.button(props => ({ ...styles(props) }))

const Button = ({
    children,
    buttonRef,
    role,
    type,
    title,
    href,
    hrefAs,
    target,
    isExternalHref,
    className,
    customFontSize,
    disabled,
    onBlur,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
    onDragLeave,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    size,
    sizeMobile,
    variant,
    simple,
    width,
    widthMobile,
    loading,
    dataAttrs,
    hasShadow,
    isActionLink,
    iconLeftComponent: IconLeft,
    iconRightComponent: IconRight,
    isIconOnly,
    disableIconFill,
    disableBorder,
    squared,
    transparent,
    rounded,
    noTracking,
    ...rest
}) => {
    const { eventWithTrackingData } = useAnalytics('coolinarika.itemClick', {
        trackingData: {
            elementCategory: href ? 'a' : 'button',
            elementLabel: title,
            elementPageLocation: 'body'
        }
    })

    if (!children && !IconLeft && !IconRight) {
        return null
    }

    const handleOnClick = e => {
        if (!noTracking) {
            eventWithTrackingData()
        }

        onClick(e)
    }

    const getDataAttrs = () => {
        if (!dataAttrs) {
            return null
        }
        return Object.keys(dataAttrs).reduce((attrs, key) => {
            attrs[`data-${key}`] = dataAttrs[key]
            return attrs
        }, {})
    }

    const iconLeft = IconLeft && <span className="btn_icon btn_iconLeft">{IconLeft}</span>
    const iconRight = IconRight && <span className="btn_icon btn_iconRight">{IconRight}</span>

    return (
        <ConditionalWrapper
            condition={!!href && !isExternalHref}
            Wrapper={Link}
            href={href}
            as={hrefAs}
            title={title}
            passHref
            noTag>
            <ButtonStyled
                ref={buttonRef}
                role={role}
                as={href ? 'a' : 'button'}
                href={href}
                target={href ? target : undefined}
                rel={href && target === '_blank' ? 'noopener noreferrer' : undefined}
                type={!href ? type : undefined}
                className={clsx(
                    {
                        disabled
                    },
                    className
                )}
                customFontSize={customFontSize}
                title={title}
                onBlur={onBlur}
                onClick={handleOnClick}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onMouseDown={onMouseDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onDragLeave={onDragLeave}
                onTouchEnd={onTouchEnd}
                onTouchMove={onTouchMove}
                onTouchStart={onTouchStart}
                size={size}
                sizeMobile={sizeMobile}
                variant={variant}
                simple={simple}
                isActionLink={isActionLink}
                buttonWidth={width}
                buttonWidthMobile={widthMobile}
                isLoading={loading}
                {...getDataAttrs()}
                hasShadow={hasShadow}
                isIconOnly={isIconOnly}
                disableIconFill={disableIconFill}
                disableBorder={disableBorder}
                transparent={transparent}
                squared={squared}
                rounded={rounded}
                {...rest}>
                {iconLeft}
                {children}
                {iconRight}
            </ButtonStyled>
        </ConditionalWrapper>
    )
}

const buttonRoundedVariants = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    LARGER: 'larger',
    ROUNDED: 'rounded'
})

const buttonVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    QUATERNARY: 'quaternary',
    QUINARY: 'quinary',
    SENARY: 'senary',
    SEPTENARY: 'septenary',
    OCTONARY: 'octonary',
    APPLE: 'apple'
})

const buttonSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGER: 'larger',
    LARGE: 'large'
})

Button.propTypes = {
    buttonRef: PropTypes.string,
    role: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    isExternalHref: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseUp: PropTypes.func,
    onDragLeave: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchMove: PropTypes.func,
    customFontSize: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    onTouchStart: PropTypes.func,
    size: PropTypes.oneOf([...Object.values(buttonSizes)]),
    sizeMobile: PropTypes.oneOf([...Object.values(buttonSizes)]),
    variant: PropTypes.oneOf([...Object.values(buttonVariants)]),
    simple: PropTypes.bool,
    isActionLink: PropTypes.bool,
    width: PropTypes.string,
    widthMobile: PropTypes.string,
    loading: PropTypes.bool,
    dataAttrs: PropTypes.shape({}),
    hasShadow: PropTypes.bool,
    iconLeftComponent: PropTypes.node,
    iconRightComponent: PropTypes.node,
    isIconOnly: PropTypes.bool,
    disableIconFill: PropTypes.bool,
    disableBorder: PropTypes.bool,
    squared: PropTypes.bool,
    transparent: PropTypes.bool,
    rounded: PropTypes.oneOf([...Object.values(buttonRoundedVariants)]),
    noTracking: PropTypes.bool
}

Button.defaultProps = {
    buttonRef: undefined,
    role: undefined,
    type: 'button',
    className: undefined,
    disabled: false,
    title: undefined,
    href: undefined,
    hrefAs: undefined,
    target: undefined,
    isExternalHref: false,
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    onMouseDown: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onMouseUp: () => {},
    onDragLeave: () => {},
    onTouchEnd: () => {},
    onTouchMove: () => {},
    onTouchStart: () => {},
    size: buttonSizes.NORMAL,
    sizeMobile: undefined,
    customFontSize: undefined,
    variant: buttonVariants.PRIMARY,
    simple: false,
    isActionLink: false,
    width: undefined,
    widthMobile: undefined,
    loading: false,
    dataAttrs: undefined,
    hasShadow: false,
    iconLeftComponent: undefined,
    iconRightComponent: undefined,
    isIconOnly: false,
    disableIconFill: false,
    disableBorder: false,
    squared: false,
    transparent: false,
    rounded: undefined,
    noTracking: false
}

export { buttonVariants, buttonSizes, buttonRoundedVariants }

export default withErrorBoundary(Button)
