import { forwardRef, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { QueryClient, useInfiniteQuery, useQueries, useQuery } from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { globalSettings } from '../settings'
import { useAnalytics } from './useAnalytics'

const { reactQuerySettings, reactQuerySettingsSSR } = globalSettings

const isDev = process.env.NODE_ENV !== 'production'
const apiRoot = process.env.NEXT_PUBLIC_API_ROOT
const apiPort = process.env.NEXT_PUBLIC_API_PORT
const apiPrefix = process.env.NEXT_PUBLIC_API_PREFIX
const apiVersion = process.env.NEXT_PUBLIC_API_VERSION
const baseUrl = `${isDev && apiRoot.includes('localhost') ? 'http' : 'https'}://${apiRoot}${
    isDev && apiPort ? `:${apiPort || 3200}` : ''
}`

const DEFAULT_PAGE_LIMIT = 30
const DEFAULT_HEADERS = { 'Content-Type': 'application/json; charset=utf-8' }

const encodeParamValue = value => {
    if (value !== null && typeof value === 'object') {
        return encodeURIComponent(JSON.stringify(value))
    }

    return encodeURIComponent(value)
}

const getQueryParams = params =>
    Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeParamValue(value)}`)
        .join('&')

const fetchElastic = async ({
    namespace = 'feed',
    index,
    resource,
    id,
    endpoint,
    params,
    method = 'get',
    body,
    headers: headersProp
}) => {
    const indexOrFeed = () => {
        if (index && typeof index === 'string' && index?.length) {
            return `elastic/${index}`
        }
        return `${namespace}`
    }

    // enable for debug
    // console.warn(
    //     '[useElastic] fetchElastic - URL',
    //     `${baseUrl}${indexOrFeed()}${resource ? `/${resource}` : ''}${id ? `/${id}` : ''}${
    //         endpoint ? `/${endpoint}` : ''
    //     }${Object.keys(params || {})?.length ? `?${getQueryParams(params)}` : ''}`
    // )

    // Feed endpoint namespace is used by default, if you need to use specific index, valid index property is expected.

    const ky = (await import('ky-universal')).default

    const fetchUrl = new URL(
        `${apiPrefix}/${apiVersion}/${indexOrFeed()}${resource ? `/${resource}` : ''}${id ? `/${id}` : ''}${
            endpoint ? `/${endpoint}` : ''
        }${Object.keys(params || {})?.length ? `?${getQueryParams(params)}` : ''}`,
        baseUrl
    )

    const headers = headersProp || (method?.toLowerCase() === 'post' ? DEFAULT_HEADERS : undefined)

    const response = await ky(fetchUrl?.href, {
        method,
        body: body && JSON.stringify(body),
        headers,
        highWaterMark: 1000 * 1000 * 20 // ~20 MB because some Elastic payloads might be super big, so we increase the stream threshold here
    })

    // This is a required workaround to avoid calling afterResponse hook in node-fetch which hangs due to response cloning, closely related to water mark threshold
    const data = await response.json()

    return data
}

const getListQueryKey = ({ index = 'combined', namespace = 'feed', resource, id, endpoint, params = {} }) =>
    ['list', index, namespace, resource, endpoint, id, params]
        .filter(value => typeof value !== 'object' || Array.isArray(value) || !!Object.keys(value || {})?.length)
        .filter(Boolean)

const useListElastic = ({
    index,
    namespace,
    resource,
    id,
    endpoint,
    enabled = true,
    params = {},
    infiniteLoadThreshold = 900,
    placeholderData,
    initialData,
    refetchOnMount = false,
    settings,
    gtmData,
    method,
    body
}) => {
    const { isLoaded } = useSelector(state => state.settings)
    const router = useRouter()

    // Id is used when fetching related content from feed endpoints, on client side, related content only
    const queryKey = useMemo(
        () => getListQueryKey({ index, namespace, resource, id, endpoint, params }),
        [index, namespace, resource, id, endpoint, params]
    )

    const [ref, inView] = useInView({
        triggerOnce: false,
        rootMargin: `${infiniteLoadThreshold}px 0px`,
        trackVisibility: true,
        delay: 200
    })

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
        isFetched,
        isLoading
    } = useInfiniteQuery(
        queryKey,
        ({ pageParam }) =>
            fetchElastic({
                id,
                index,
                namespace,
                resource,
                endpoint,
                params: { ...params, page: pageParam || 1 },
                method,
                body
            }),
        {
            ...reactQuerySettings,
            ...settings,
            refetchOnMount,
            enabled: !!isLoaded && !!enabled,
            placeholderData,
            initialData,
            getNextPageParam: (lastPage, allPages) => {
                const { limit = DEFAULT_PAGE_LIMIT } = params
                const morePagesExist = lastPage?.length >= limit || lastPage?.data?.length >= limit

                if (!morePagesExist) {
                    return undefined
                }

                return allPages?.length + 1
            }
        }
    )

    const { eventTracking } = useAnalytics('coolinarika.VirtualPageview')

    useEffect(() => {
        if (hasNextPage && inView) {
            fetchNextPage()
            const homepage = '16CbwOufSIGnG357b0TeO7R2zZAyHScEBHxA.l.ktxD.l7'
            const otherRoutes = '1wCVJOsf7dOnZT7XJ3A3U2YlLZo1w_c0VUz6czWaUh3.p7'
            window?.pp_gemius_hit?.(router?.asPath === '/' ? homepage : otherRoutes)

            if (gtmData && data?.pages?.length) {
                const webUrl = new URL(gtmData?.url)
                eventTracking({
                    virtualPageIndex: data?.pages?.length + 1,
                    virtualPageUrl: `${webUrl?.pathname}/page-${data?.pages?.length + 1}`,
                    virtualPageTitle: `${gtmData?.title} - ${data?.pages?.length + 1}`
                })
            }
        }
    }, [hasNextPage, inView])

    return {
        queryKey,
        ref,
        inView,
        status,
        error,
        isFetching,
        pages: data?.pages || [],
        total: data?.total || data?.length || data?.pages?.length || 0,
        data: data || {},
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
        isFetched,
        isLoading
    }
}

const getListElastic = async ({
    index,
    namespace,
    resource,
    id,
    endpoint,
    enabled = true,
    params = {},
    method,
    body
}) => {
    const queryKey = getListQueryKey({ index, namespace, resource, id, endpoint, params })

    const queryClient = new QueryClient(reactQuerySettingsSSR)

    await queryClient.prefetchInfiniteQuery(
        queryKey,
        ({ pageParam }) =>
            fetchElastic({
                id,
                index,
                namespace,
                resource,
                endpoint,
                params: { ...params, page: pageParam || 1 },
                method,
                body
            }),
        {
            enabled: !!enabled,
            ...reactQuerySettingsSSR
        }
    )

    // This fixes Next.js upstream serialization bug, hello darkness my old friend
    const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)))

    const { data } = dehydratedState?.queries?.[0]?.state || {}

    return {
        queryKey,
        data: data || {},
        dehydratedState
    }
}

// @NOTE: This dude can only be used for single queries, not infinite list queries
const useParallelElastic = (queries = [], { refetchOnMount = false } = {}) => {
    const { isLoaded } = useSelector(state => state.settings)

    const parallelResponses = useQueries(
        queries.map(
            ({
                index,
                namespace,
                resource,
                id,
                endpoint,
                enabled = true,
                params = {},
                method,
                body,
                headers,
                queryKey = getListQueryKey({ index, namespace, resource, id, endpoint, params })
            }) => ({
                queryKey,
                queryFn: () =>
                    fetchElastic({
                        id,
                        index,
                        namespace,
                        resource,
                        endpoint,
                        params,
                        method,
                        body,
                        headers
                    }),
                ...reactQuerySettings,
                enabled: !!isLoaded && !!enabled,
                refetchOnMount
            })
        )
    )

    const data = useMemo(() => {
        if (parallelResponses && Array.isArray(parallelResponses) && parallelResponses?.length) {
            return parallelResponses.map(response => ({
                status: response?.status,
                error: response?.error,
                isFetching: response?.isFetching,
                pages: response?.data?.pages || [],
                total: response?.data?.total || response?.data?.length || response?.data?.pages?.length || 0,
                data: response?.data || {},
                isFetchingNextPage: response?.isFetchingNextPage,
                fetchNextPage: response?.fetchNextPage,
                hasNextPage: response?.hasNextPage,
                refetch: response?.refetch
            }))
        }

        return []
    }, [parallelResponses, queries])

    return data
}

const getParallelElastic = async (queries = []) => {
    const queryClient = new QueryClient(reactQuerySettingsSSR)

    await Promise.allSettled(
        queries.map(
            ({
                index,
                namespace,
                resource,
                id,
                endpoint,
                enabled = true,
                params = {},
                isInfiniteQuery = true,
                method,
                body,
                queryKey = getListQueryKey({ index, namespace, resource, id, endpoint, params })
            }) =>
                isInfiniteQuery
                    ? queryClient.prefetchInfiniteQuery(
                          queryKey,
                          ({ pageParam }) =>
                              fetchElastic({
                                  id,
                                  index,
                                  namespace,
                                  resource,
                                  endpoint,
                                  params: { ...params, page: pageParam || 1 },
                                  method,
                                  body
                              }),
                          {
                              enabled: !!enabled,
                              ...reactQuerySettingsSSR
                          }
                      )
                    : queryClient.prefetchQuery(
                          queryKey,
                          ({ pageParam }) =>
                              fetchElastic({
                                  id,
                                  index,
                                  namespace,
                                  resource,
                                  endpoint,
                                  params: { ...params, page: pageParam || 1 },
                                  method,
                                  body
                              }),
                          {
                              enabled: !!enabled,
                              ...reactQuerySettingsSSR
                          }
                      )
        )
    )

    // This fixes Next.js upstream serialization bug, hello darkness my old friend
    const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)))

    return dehydratedState
}

const getOneQueryKey = ({ index = 'combined', namespace = 'feed', resource, id, endpoint, params = {} }) =>
    ['one', index, namespace, resource, endpoint, id, params]
        .filter(value => typeof value !== 'object' || Array.isArray(value) || !!Object.keys(value || {})?.length)
        .filter(Boolean)

const useOneElastic = ({
    index,
    namespace,
    resource,
    id,
    endpoint,
    enabled = true,
    params = {},
    settings,
    refetchOnMount = false,
    keepPreviousData = true,
    initialData = {},
    method,
    body
}) => {
    const { isLoaded } = useSelector(state => state.settings)

    const queryKey = useMemo(
        () => getOneQueryKey({ index, namespace, resource, id, endpoint, params }),
        [index, namespace, resource, id, endpoint, params]
    )

    const { status, data, error, isFetching, refetch } = useQuery(
        queryKey,
        () => id !== null && fetchElastic({ index, namespace, resource, id, endpoint, params, method, body }),
        {
            ...reactQuerySettings,
            ...settings,
            refetchOnMount,
            keepPreviousData,
            enabled: !!isLoaded && !!enabled
        }
    )

    return {
        queryKey,
        status,
        error,
        isFetching,
        pages: data?.pages || [],
        total: data?.total || data?.length || data?.pages?.[0]?.length || 0,
        data: data || initialData,
        refetch
    }
}

const getOneElastic = async ({
    index,
    namespace,
    resource,
    id,
    endpoint,
    enabled = true,
    params = {},
    initialData = {},
    method,
    body
}) => {
    const queryKey = getOneQueryKey({ index, namespace, resource, id, endpoint, params })

    const queryClient = new QueryClient(reactQuerySettingsSSR)

    await queryClient.prefetchQuery(
        queryKey,
        () => fetchElastic({ index, namespace, resource, id, endpoint, params, method, body }),
        {
            enabled: !!enabled
        }
    )

    // This fixes Next.js upstream serialization bug, hello darkness my old friend
    const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)))

    const { data } = dehydratedState?.queries?.[0]?.state || {}

    return {
        queryKey,
        data: data || initialData,
        dehydratedState
    }
}

const InfiniteLoadTrigger = forwardRef((props, ref) => (
    <div
        style={{
            height: 30,
            visibility: 'hidden'
        }}
        ref={ref}
    />
))

const InfiniteLoadLoader = () => (
    <div
        style={{
            margin: '40px auto 0'
        }}>
        <div
            style={{
                margin: 'auto',
                position: 'relative',
                width: 80,
                height: 80,
                borderRadius: '50%',
                background: '#FFF',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)'
            }}>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 48,
                    height: 48,
                    background: 'url(/images/loaders/spinner.gif)',
                    backgroundSize: 'contain'
                }}
            />
        </div>
    </div>
)

export {
    fetchElastic,
    useOneElastic,
    useListElastic,
    getOneElastic,
    getListElastic,
    useParallelElastic,
    getParallelElastic,
    InfiniteLoadTrigger,
    InfiniteLoadLoader,
    getOneQueryKey,
    getListQueryKey
}
