import { createContext } from 'react'
import PropTypes from 'prop-types'

const AnalyticsContext = createContext()

const AnalyticsContextProvider = ({ children, instance }) => (
    <AnalyticsContext.Provider value={{ analytics: instance }}>{children}</AnalyticsContext.Provider>
)

AnalyticsContextProvider.propTypes = {
    instance: PropTypes.oneOfType([() => null, PropTypes.object])
}

AnalyticsContextProvider.defaultProps = {
    instance: undefined
}

export { AnalyticsContextProvider }

export default AnalyticsContext
