/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavBlog = createSvgIcon(
    <>
        <path d="M20.233 17.233a.567.567 0 00-.567-.567H13.83a.567.567 0 000 1.133h5.836a.567.567 0 00.567-.566zM13.83 18.933a.567.567 0 100 1.133h3.545a.567.567 0 100-1.133H13.83z" />
        <path d="M15.162 24.372h-1.896a1.135 1.135 0 01-1.133-1.133v-9.973c0-.624.509-1.133 1.134-1.133h6.966c.625 0 1.133.509 1.133 1.134v3.484a.567.567 0 001.133 0v-3.485A2.269 2.269 0 0020.233 11h-6.966A2.269 2.269 0 0011 13.267v9.972a2.269 2.269 0 002.267 2.266h1.895a.567.567 0 100-1.133z" />
        <path d="M23.503 19.204a1.702 1.702 0 00-2.404 0l-3.11 3.103a.567.567 0 00-.142.237l-.678 2.23a.567.567 0 00.694.71l2.287-.633a.567.567 0 00.249-.145l3.104-3.098a1.702 1.702 0 000-2.404zm-3.8 4.595l-1.15.319.337-1.109 2.099-2.094.801.801-2.087 2.083zm2.999-2.993l-.11.11-.801-.802.11-.109a.567.567 0 01.801.801zM19.666 14.4H13.83a.567.567 0 000 1.133h5.836a.567.567 0 100-1.133z" />
    </>,
    'NavBlog'
)

export default NavBlog
