import { forwardRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styled from '@emotion/styled'

import { Icon, SearchIcon } from '../../../../Icon'
import { styles } from './Input.style'

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <Icon className="searchIcon" icon={SearchIcon} />
    </components.DropdownIndicator>
)

const selector = ({ search }) => search.isOpen

const InputStyled = styled.div(props => ({ ...styles(props) }))

const Input = forwardRef(({ className, value, ...restProps }, ref) => {
    const isSearchOpen = useSelector(selector)

    useEffect(() => {
        if (isSearchOpen) {
            ref.current.focus()
        }
    }, [ref, isSearchOpen])

    return (
        <InputStyled className={className} isSearchOpen={isSearchOpen}>
            <AsyncCreatableSelect
                ref={ref}
                className="searchSelectContainer"
                classNamePrefix="searchSelect"
                {...restProps}
            />
        </InputStyled>
    )
})

Input.propTypes = {
    ...AsyncCreatableSelect.propTypes
}

Input.defaultProps = {
    ...AsyncCreatableSelect.defaultProps,
    components: {
        DropdownIndicator
    }
}

export default Input
