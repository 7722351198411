// @NOTE: if u update this method be sure to update xmlEscape also
const xmlUnescape = value => {
    if (typeof value !== 'string') {
        throw new Error(`value of type string expected, got ${typeof value}`)
    }

    const xmlUnescapeMap = {
        '&gt;': '>',
        '&lt;': '<',
        '&apos;': "'",
        '&quot;': '"',
        '&amp;': '&'
    }

    return value.replace(/(&gt;)|(&lt;)|(&quot;)|(&amp;)|(&apos;)/g, match => xmlUnescapeMap[match])
}

export default xmlUnescape
