import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/coolinarika-web-ui/components/ErrorBoundary'

import TiktokIframeContainer from './TiktokIframeContainer.component'

const embedCache = {}

const TikTokItemController = ({ url, fullWidth }) => {
    const [post, setPost] = useState()

    useEffect(() => {
        const loadEmbed = async () => {
            if (!url) {
                return
            }

            try {
                if (!embedCache[url]) {
                    const response = await fetch(`https://www.tiktok.com/oembed?url=${url}`)
                    const data = await response.json()

                    embedCache[url] = data?.html
                }

                setPost(embedCache[url])
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }

        loadEmbed()
    }, [])

    if (url && post) {
        return <TiktokIframeContainer htmlContent={`${post}`} fullWidth={fullWidth} />
    }

    return null
}

TikTokItemController.propTypes = {
    url: PropTypes.string,
    fullWidth: PropTypes.bool
}

TikTokItemController.defaultProps = {
    url: undefined,
    fullWidth: true
}

export default withErrorBoundary(memo(TikTokItemController), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[TikTokItemController]: ', error, componentStack)
    }
})
