import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

/**
 * Exactly like useState hook only state is persisted in
 * query params of URL
 *
 * @example
 *
 * const [{ search }, setQuery] = useQueryState({ search: '' })
 *
 * // use setter callback if you
 * // wish to merge current query
 * // params with new ones
 * setQuery(current => ({
 *  ...current,
 *  search: 'Something coo'
 * }))
 *
 * @example
 *
 * // all hooks share single URL query so
 * // setting like this will reset all
 * // other query params
 * setQuery({ search: 'Something coo' })
 *
 * @example
 *
 * // when setting initial value through argument,
 * // those values will be returned by hook but not
 * // in URL params, use them to define default
 * // url params, for example default sort
 * const [{ sort }, setQuery] = useQueryState({ sort: `{ 'title.keyword': { order: 'asc' } }` })
 *
 * @param {Object|Function|null|undefined} initialValue
 * @return {[Object, Function]}
 */
const useQueryState = initialValue => {
    const router = useRouter()
    const { query, pathname, replace } = router

    /* console.log('useQueryState', {
        initialValue,
        query,
        pathname
    }) */

    const routerRef = useRef(query)

    useEffect(() => {
        routerRef.current = {
            query,
            pathname
        }
    }, [query, pathname])

    const setState = useCallback(
        newStateOrSetter => {
            const newState =
                typeof newStateOrSetter === 'function' ? newStateOrSetter(routerRef.current.query) : newStateOrSetter

            replace(
                {
                    pathname,
                    query: newState
                },
                undefined,
                {
                    shallow: true
                }
            )
        },
        [replace]
    )

    const state = useMemo(
        () => ({
            ...(typeof initialValue === 'function' ? initialValue() : initialValue),
            ...query
        }),
        [query]
    )

    return [state, setState]
}

export default useQueryState
