// @NOTE: time is timestamp
const datesAreOnSameDay = (first, second) => {
    const firstDate = new Date(first)
    const secondDate = new Date(second)
    return (
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
    )
}

export default datesAreOnSameDay
