import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    ...theme.cardHover,
    '& .cardSmall': {
        '&_asset': {
            display: 'block',
            flexShrink: 0,
            width: toRem(115),
            marginRight: toRem(-15)
        },
        '&_content': {
            display: 'block',
            width: `calc(100% - ${toRem(100)})`
        }
    }
})

export default style
