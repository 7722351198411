import { toRem } from '../../helpers/theme'

const Style = ({ theme }) => ({
    transform: 'translateZ(0)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.colors.coolLightRed,
    height: toRem(3),
    zIndex: theme.zIndex.topLine,
    [theme.breakpoints.up('md')]: {
        height: toRem(5)
    },
    '@media print': { display: 'none' }
})

export default Style
