import { useQuery } from 'react-query'
import ky from 'ky-universal'
import { useRouter } from 'next/router'

import { globalSettings } from '../settings'

const { reactQuerySettings } = globalSettings

const fetchSession = async () => {
    const res = await ky('/api/auth/session')
    const session = await res.json()
    if (Object.keys(session).length) {
        return session
    }
    return null
}

const useSession = ({ required, redirectTo = '/api/auth/signin?error=SessionExpired', queryConfig = {} } = {}) => {
    const router = useRouter()
    const query = useQuery(['one', 'users', 'session'], fetchSession, {
        ...reactQuerySettings,
        ...queryConfig,
        onSettled(data, error) {
            if (queryConfig.onSettled) {
                queryConfig.onSettled(data, error)
            }
            if (data || !required) {
                return
            }
            router.push(redirectTo)
        }
    })
    return [query.data, query.status === 'loading', query]
}

export { useSession, fetchSession }
