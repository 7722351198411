import PropTypes from 'prop-types'

const isDev = process.env.NODE_ENV !== 'production'

const DefaultFallback = ({ err, ...rest }) => {
    if (!err) {
        return null
    }

    if (isDev) {
        return <pre style={{ fontSize: 10 }}>{JSON.stringify(err, null, 2).slice(0, 100)}</pre>
    }

    return null
}

DefaultFallback.propTypes = {
    err: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
}

DefaultFallback.defaultProps = {
    err: undefined
}

export default DefaultFallback
