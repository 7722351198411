/* eslint-disable camelcase */

import { useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { useOneElastic } from '@hmn/coolinarika-web-core/hooks'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HeadingLabel, headingLabelSizes, headingLabelVariants } from '../../../Heading/components/Label'
import { HtmlContent } from '../../../HtmlContent'
import { Image } from '../../../Image'
import { Link } from '../../../Link'
import styles from './Avatar.style'

const AvatarStyled = styled.div(props => ({ ...styles(props) }))

const avatarVariants = Object.freeze({
    PROFILEFOLLOWING: 'profileFollowing',
    RECIPE: 'recipe',
    RECIPEOFDAY: 'recipeOfDay',
    NAVIGATION: 'navigation',
    NAVIGATIONBAR: 'navigationBar',
    PHOTO: 'photo',
    CLUB: 'club',
    COOLCHEF: 'coolChef',
    NOTIFICATION: 'notification'
})

const Avatar = ({
    className,
    userId,
    href,
    hrefAs,
    variant,
    inline,
    isFollowing,
    isFollowingText,
    label,
    date,
    imageLabel,
    notifications,
    onClick,
    username,
    ...rest
}) => {
    const { data: user } = useOneElastic({ resource: 'ljudi', id: userId, enabled: !!userId })
    const { display_name, fname, lname, image } = user || {}
    const articleCreateDate = useMemo(() => `${dayjs(date).format('D.M.YYYY.')}`, [date])
    return (
        !!user && (
            <AvatarStyled
                variant={variant}
                inline={inline}
                className={className}
                imageLabel={imageLabel}
                onClick={onClick}
                hasNotifications={notifications}
                {...rest}>
                <ConditionalWrapper
                    condition={!!href && ['recipe', 'photo', 'coolChef'].includes(variant)}
                    Wrapper={Link}
                    href={href}
                    as={hrefAs}>
                    <div className="avatarInner">
                        <div className="userAssets">
                            {!!notifications && ['navigation', 'navigationBar', 'notification'].includes(variant) && (
                                <div className="avatarNotifications">
                                    {['navigation', 'navigationBar'].includes(variant) && <span>{notifications}</span>}
                                </div>
                            )}
                            <div className="userAssets_imageWrap">
                                {image?.id && (
                                    <Image
                                        image={image?.id}
                                        height={400}
                                        width={400}
                                        placeholderBgColor={image?.colors?.[image?.colors?.length - 1]?.hex}
                                        title={display_name}
                                        alt={display_name}
                                        ratio={1}
                                    />
                                )}
                            </div>
                        </div>
                        {!['navigation', 'navigationBar', 'coolChef', 'notification'].includes(variant) &&
                            (username || display_name || fname || lname) && (
                                <div className="userInfo">
                                    <div className="userInfo_block">
                                        {['profileFollowing'].includes(variant) && label && (
                                            <div className="userInfo_label">
                                                <HtmlContent content={label} wrapperComponent="span" />
                                            </div>
                                        )}
                                        <div className="userInfo_username">{username || display_name || fname}</div>
                                        {['profile', 'photo', 'profileFollowing', 'club'].includes(variant) &&
                                            (fname || lname) && (
                                                <div className="userInfo_name">
                                                    {fname} {lname}
                                                </div>
                                            )}
                                        {date && <div className="userInfo_date">{articleCreateDate}</div>}
                                    </div>
                                    {['profileFollowing'].includes(variant) && isFollowing && isFollowingText && (
                                        <div className="userInfo_block">
                                            <div className="userInfo_following">
                                                <HeadingLabel
                                                    variant={headingLabelVariants.QUINARY}
                                                    size={headingLabelSizes.SMALL}>
                                                    <HtmlContent content={isFollowingText} wrapperComponent="span" />
                                                </HeadingLabel>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                    </div>
                </ConditionalWrapper>
            </AvatarStyled>
        )
    )
}

Avatar.propTypes = {
    className: PropTypes.string,
    userId: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(avatarVariants)]),
    inline: PropTypes.bool,
    isFollowingText: PropTypes.string,
    isFollowing: PropTypes.bool,
    label: PropTypes.string,
    date: PropTypes.string,
    imageLabel: PropTypes.string,
    notifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onClick: PropTypes.func,
    username: PropTypes.string
}

Avatar.defaultProps = {
    className: undefined,
    userId: undefined,
    href: undefined,
    hrefAs: undefined,
    variant: avatarVariants.RECIPE,
    inline: false,
    isFollowingText: undefined,
    isFollowing: false,
    label: undefined,
    date: undefined,
    imageLabel: undefined,
    notifications: undefined,
    onClick: undefined,
    username: undefined
}

export { avatarVariants }

export default withErrorBoundary(Avatar)
