/* eslint-disable import/no-absolute-path, import/extensions, quote-props, max-len */

const defaultTransition = {
    duration: '300ms',
    timingFunction: 'ease-in-out',
    delay: '0ms'
}

export default {
    default: defaultTransition,
    button: {
        default: defaultTransition
    },
    tab: {
        primary: {
            head: {
                text: defaultTransition,
                indicator: defaultTransition
            },
            body: {
                item: defaultTransition
            }
        }
    },
    icon: {
        default: defaultTransition
    },
    swiper: {
        default: {
            nav: defaultTransition
        }
    },
    modal: {
        default: defaultTransition
    },
    heading: {
        highlight: {
            default: defaultTransition
        }
    },
    pill: {
        default: defaultTransition
    },
    input: {
        text: {
            default: defaultTransition
        },
        checkbox: {
            default: defaultTransition
        },
        checkboxCard: {
            default: defaultTransition
        },
        radio: {
            default: defaultTransition
        },
        switch: {
            default: defaultTransition
        }
    },
    navigation: {
        text: {
            default: defaultTransition
        },
        icon: {
            default: defaultTransition
        }
    },
    notification: {
        item: {
            default: defaultTransition
        }
    }
}
