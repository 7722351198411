import { responsive, toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, isActive, colorVariant, isThin, isPrimaryNavigationItem }) => {
    const config = {
        text: {
            color: theme.colors.navigation.text[colorVariant]
        },
        icon: {
            color: theme.colors.navigation.icon.fill[colorVariant],
            background: theme.colors.navigation.icon.background[colorVariant]
        }
    }

    return {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        a: {
            width: '100%',
            height: '100%',
            textDecoration: 'none !important'
        },
        '& .navItem': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontWeight: isThin ? theme.typography.fontWeight.light : theme.typography.fontWeight.black,
            lineHeight: 1.3,
            fontSize: toRem(18),
            color: config.text.color,
            transitionDuration: theme.transitions.navigation.text.default.duration,
            transitionTimingFunction: theme.transitions.navigation.text.default.timingFunction,
            transitionDelay: theme.transitions.navigation.text.default.delay,
            transitionProperty: 'color',
            cursor: 'pointer',
            ...(isActive && {
                color: theme.colors.navigation.text.active
            }),
            ...responsive(
                isPrimaryNavigationItem
                    ? [
                          { fontSize: toRem(16) }, // xs
                          { fontSize: toRem(16) }, // sm
                          { fontSize: toRem(15) }, // md
                          { fontSize: toRem(15) }, // lg
                          { fontSize: toRem(16) }, // xl
                          { fontSize: toRem(19) }, // xxl
                          { fontSize: toRem(15) } // xxxl
                      ]
                    : [
                          { fontSize: toRem(16) }, // xs
                          { fontSize: toRem(16) }, // sm
                          { fontSize: toRem(15) }, // md
                          { fontSize: toRem(15) }, // lg
                          { fontSize: toRem(15) }, // xl
                          { fontSize: toRem(18) }, // xxl
                          { fontSize: toRem(15) } // xxxl
                      ]
            ),
            '&:hover': {
                color: theme.colors.navigation.text.default,
                '& .navItem_icon': {
                    backgroundColor: theme.colors.navigation.icon.background.active,
                    color: theme.colors.navigation.icon.fill.active
                }
            }
        },
        '& .navItemButton': {
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            width: 'auto',
            height: 'auto',
            paddingTop: toRem(6),
            paddingBottom: toRem(6),
            ...responsive([
                { fontSize: toRem(18) }, // xs
                { fontSize: toRem(18) }, // sm
                { fontSize: toRem(15) }, // md
                { fontSize: toRem(15) }, // lg
                { fontSize: toRem(15) }, // xl
                { fontSize: toRem(18) }, // xxl
                { fontSize: toRem(15) } // xxxl
            ]),
            '& .btn_icon': {
                margin: toRems([-10, 0, -10, -10]),
                fontSize: toRem(36),
                [theme.breakpoints.down('sm')]: {
                    marginRight: toRem(8)
                }
            }
        },
        '& .navItem_icon': {
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: toRem(12),
            borderRadius: '100%',
            width: toRem(40),
            height: toRem(40),
            fontSize: toRem(40),
            backgroundColor: config.icon.background,
            color: config.icon.color,
            transitionDuration: theme.transitions.navigation.icon.default.duration,
            transitionTimingFunction: theme.transitions.navigation.icon.default.timingFunction,
            transitionDelay: theme.transitions.navigation.icon.default.delay,
            transitionProperty: 'color, background-color',
            overflow: 'hidden',
            ...(isActive && {
                backgroundColor: theme.colors.navigation.icon.background.active,
                color: theme.colors.navigation.icon.fill.active
            }),
            [theme.breakpoints.up('md')]: {
                marginRight: toRem(10),
                width: toRem(36),
                height: toRem(36),
                fontSize: toRem(36)
            },
            svg: {
                path: {
                    fill: 'currentColor'
                }
            }
        },
        '& .navItem_label': {
            small: {
                fontSize: toRem(10),
                fontWeight: theme.typography.fontWeight.light
            }
        },
        '& .navItem_notifications': {
            display: 'inline-flex',
            flexDirection: 'columns',
            flexShrink: 0,
            verticalAlign: 'middle',
            alignItems: 'center',
            justifyContent: 'center',
            height: toRem(20),
            minWidth: toRem(20),
            borderRadius: toRem(20),
            margin: toRems([-5, 0, -4, 5]),
            padding: toRems([2, 4, 5, 4]),
            background: theme.colors.notification.badge.background,
            color: theme.colors.notification.badge.text,
            fontSize: toRem(13),
            lineHeight: 1,
            fontWeight: theme.typography.fontWeight.black,
            textAlign: 'center'
        },
        '& .navItem_children': {
            overflow: 'hidden',
            ul: {
                paddingTop: toRem(10),
                paddingLeft: toRem(52),
                [theme.breakpoints.up('md')]: {
                    paddingLeft: toRem(46)
                },
                li: {
                    marginBottom: toRem(10),
                    '&:last-of-type': {
                        marginBottom: 0
                    }
                }
            }
        }
    }
}

export default style
