import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const selector = state => state.settings.taxons.flags

/**
 * Pull flag taxon from global settings
 *
 * Support matching by slug or id
 *
 * @param {Object} slugOrId slug or id of taxon
 * @return {Object} Taxon object
 */
const useTaxonFlag = slugOrId => {
    const flagTaxons = useSelector(selector, shallowEqual)

    const taxon = useMemo(
        () => flagTaxons.find(item => item.slug === slugOrId || item.id === slugOrId),
        [slugOrId, flagTaxons]
    )

    return taxon
}

export default useTaxonFlag
