import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    '& .mobileNavigationPanel': {
        position: 'fixed',
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: theme.zIndex.navigation.panel,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        background: theme.colors.navigation.background.panel,
        paddingBottom: toRem(theme.global.baseLayout.mobile.navigation.bar.height)
    },
    '& .mobileNavigationPanel_inner': {
        width: '100%',
        height: '100%',
        overflowY: 'auto'
    },
    '& .mobileNavigationPanel_close': {
        position: 'absolute',
        zIndex: theme.zIndex.over,
        right: toRem(30),
        top: toRem(30)
    }
})

export default style

const motionVariants = () => {
    const transition = {
        duration: 0.25,
        ease: 'easeOut'
    }
    return {
        open: {
            x: 0,
            y: 0,
            transition
        },
        closed: {
            x: 0,
            y: '100%',
            transition
        }
    }
}

export { motionVariants }
