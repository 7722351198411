import { useState } from 'react'
import { useQueries, useQuery, useQueryClient } from 'react-query'
import * as Sentry from '@sentry/nextjs'

import dataProvider from '@hmn/data-provider'
import * as PodravkaClient from '@hmn/podravkaio'

import { globalSettings } from '../settings'

const { reactQuerySettings, apiBaseUrl } = globalSettings

const dataProviderBaseUrl = process.env.NEXT_PUBLIC_PODRAVKAIO_ROOT

const useOne = (resource, params = {}, options = {}) => {
    const queryClient = useQueryClient()
    const { initialData } = options
    const [data, setData] = useState(initialData || null)
    const [error, setError] = useState(null)
    const [enabled, setEnabled] = useState(true)
    const [status, setStatus] = useState(null)

    if (!PodravkaClient.ApiClient.instance.basePath || PodravkaClient.ApiClient.instance.basePath.includes('docs')) {
        // Initiate dataProvider for server side based requests, on refresh or if there is not initalized apiClient Path
        dataProvider.init({
            baseUrl: `${apiBaseUrl}/podravkaio`,
            language: 'hr'
        })
    }

    const enableQuery = !!(
        params &&
        params.id &&
        dataProviderBaseUrl &&
        !PodravkaClient.ApiClient.basePath?.includes('/_health-check') &&
        !PodravkaClient.ApiClient.basePath?.includes('/docs')
    )

    const { isFetching } = useQuery(
        [resource, params],
        async (queryResource, queryParams) => {
            if (!enabled) {
                return
            }

            // Check if dataProvider is initialized
            if (!dataProvider) {
                throw new Error('DataProvider is not initialized')
            }
            try {
                const result = await dataProvider.getOne(resource, params)
                setData(result.data)
                setStatus(result.status)
                // return result
            } catch (err) {
                if (err.response) {
                    const errStatus = err.response.status
                    if (errStatus === 404) {
                        Sentry.captureException(
                            `Resource ${params?.id} not found. Clean ES extended attributes for nonexisting resource.`
                        )
                        // Set error state if it's a 404 error
                        setError(err)
                        setEnabled(false)
                    } else if (errStatus >= 500 && errStatus < 600) {
                        Sentry.captureException(`Server Error: ${errStatus}`)
                        // Disable the query to stop pinging the service
                        // queryClient.getQueryCache().invalidateQueries([resource, params])
                        setEnabled(false)
                        // Set error state for server errors
                        setError(err)
                    } else {
                        // Rethrow other errors to trigger the onError callback
                        throw err
                    }
                } else {
                    // Rethrow other errors to trigger the onError callback
                    throw err
                }
            }
        },
        {
            initialData: initialData ? { data: initialData } : undefined,
            ...reactQuerySettings,
            enabled: enableQuery && enabled
        }
    )

    return {
        data,
        loading: isFetching,
        status,
        error,
        retry: () => {
            if (!enabled) {
                return
            }
            queryClient.invalidateQueries(resource)
        }
    }
}

const getOne = (resource, params = {}) => {
    const { data: { data } = {} } = dataProvider.getOne(resource, params)
    // test
    return {
        data
    }
}

const useOneParallel = (resource, params = {}) => {
    const { ids } = params

    const response = useQueries(
        ids.map(id => ({
            queryKey: [resource, id],
            queryFn: () => dataProvider.getOne(resource, { id })
        }))
    )

    return response
}

export { getOne, useOneParallel }

export default useOne
