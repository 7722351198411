/**
 * Convert HEX colors to RGBA colors
 * @param colorString
 * @param alpha
 */
const convertHexToRGBA = (colorString, alpha = 1) => {
    // Remove hashtag from color string
    const color = colorString.substr(1)

    const re = new RegExp(`.{1,${color.length / 3}}`, 'g')
    let colors = color.match(re)

    if (colors && colors[0].length === 1) {
        colors = colors.map(n => n + n)
    }

    return colors ? `rgba(${colors.map(n => parseInt(n, 16)).join(', ')}, ${alpha})` : ''
}

export default convertHexToRGBA
