import { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { HtmlContent } from '../../../HtmlContent'
import styles from './Label.style'

const HeadingLabelStyled = styled.div(props => ({ ...styles(props) }))

const HeadingLabel = ({
    children,
    TagComponent: Tag,
    hasLetterSpacing,
    isUppercase,
    size,
    variant,
    fontVariant,
    label,
    prefix,
    suffix,
    className,
    ...rest
}) => {
    const labelText = useMemo(() => {
        const prefixText = prefix ? `<span class="headingLabel_prefix">${prefix}</span> ` : ''
        const suffixText = suffix ? ` <span class="headingLabel_suffix">${suffix}</span>` : ''
        return `${prefixText}${label}${suffixText}`
    }, [prefix, label, suffix])

    if (!children && !label) {
        return null
    }

    return (
        <HeadingLabelStyled
            as={Tag}
            size={size}
            variant={variant}
            className={className}
            fontVariant={fontVariant}
            hasLetterSpacing={hasLetterSpacing}
            isUppercase={isUppercase}
            {...rest}>
            {children} {label && <HtmlContent content={labelText} className="headingLabel" wrapperComponent="span" />}
        </HeadingLabelStyled>
    )
}

const headingLabelVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    QUATERNARY: 'quaternary',
    QUINARY: 'quinary'
})

const headingLabelSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGER: 'larger',
    LARGE: 'large',
    XLARGE: 'xlarge'
})

const headingLabelFontVariants = Object.freeze({
    NORMAL: 'normal',
    SMALL_CAPS: 'uppercase' // temp hack
})

HeadingLabel.propTypes = {
    className: PropTypes.string,
    TagComponent: PropTypes.elementType,
    hasLetterSpacing: PropTypes.bool,
    isUppercase: PropTypes.bool,
    size: PropTypes.oneOf([...Object.values(headingLabelSizes)]),
    variant: PropTypes.oneOf([...Object.values(headingLabelVariants)]),
    fontVariant: PropTypes.oneOf([...Object.values(headingLabelFontVariants)]),
    label: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string
}

HeadingLabel.defaultProps = {
    className: undefined,
    TagComponent: 'div',
    hasLetterSpacing: false,
    isUppercase: false,
    size: headingLabelSizes.NORMAL,
    variant: headingLabelVariants.PRIMARY,
    fontVariant: headingLabelFontVariants.NORMAL,
    label: undefined,
    prefix: undefined,
    suffix: undefined
}

export { headingLabelVariants, headingLabelSizes, headingLabelFontVariants }

export default HeadingLabel
