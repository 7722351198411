import { global, zIndex } from '@hmn/coolinarika-web-ui/theme'

const style = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    height: global.baseLayout.desktop.sidebar.height,
    width: global.baseLayout.desktop.sidebar.width,
    zIndex: zIndex.baseLayout.sidebar,
    background: theme.colors.background.sidebar.default
})

export default style
