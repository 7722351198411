import { useCallback } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { DRAWER_ACTIONS, useDrawerDispatch } from '@hmn/coolinarika-web-core/context/Drawer'

import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { HeadingLabel, headingLabelFontVariants, headingLabelVariants } from '../../../Heading/components/Label'
import { ArrowSmallIcon, CloseIcon, Icon } from '../../../Icon'
import styles from './Head.style'

const HeadStyled = styled.div(props => ({ ...styles(props) }))

const Head = ({ title, hasBackButton, onClose, className, drawerType, ...rest }) => {
    const dispatch = useDrawerDispatch()

    const handleBackButton = useCallback(() => {
        dispatch({
            type: DRAWER_ACTIONS.POP_CONTENT,
            payload: {
                drawerType
            }
        })
    }, [dispatch, drawerType])

    return (
        <HeadStyled className={className} {...rest}>
            <div className="headInner">
                {hasBackButton && (
                    <Button
                        title="Natrag"
                        className="headBack_btn"
                        variant={buttonVariants.SECONDARY}
                        size={buttonSizes.LARGE}
                        isIconOnly
                        simple
                        onClick={() => handleBackButton()}>
                        <span className="showForSr">Nazad</span>
                        <Icon icon={ArrowSmallIcon} rotate={180} />
                    </Button>
                )}
                {title && (
                    <HeadingLabel
                        isUppercase
                        variant={headingLabelVariants.SECONDARY}
                        fontVariant={headingLabelFontVariants.SMALL_CAPS}
                        hasLetterSpacing
                        label={title}
                    />
                )}
                <Button
                    className="headClose_btn"
                    variant={buttonVariants.TERTIARY}
                    size={buttonSizes.NORMAL}
                    sizeMobile={buttonSizes.SMALL}
                    title="Zatvori"
                    onClick={onClose}
                    isIconOnly>
                    <span className="showForSr">Zatvori</span>
                    <Icon icon={CloseIcon} size={32} sizeMobile={28} />
                </Button>
            </div>
        </HeadStyled>
    )
}

Head.propTypes = {
    title: PropTypes.string,
    hasBackButton: PropTypes.bool,
    className: PropTypes.string,
    drawerType: PropTypes.string,
    onClose: PropTypes.func
}

Head.defaultProps = {
    title: undefined,
    hasBackButton: false,
    className: undefined,
    drawerType: undefined,
    onClose: undefined
}

export default Head
