const constants = {
    CLOSE: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/CLOSE_RECIPE_UPLOADER',
    OPEN: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/OPEN_RECIPE_UPLOADER',
    TOGGLE: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/TOGGLE_RECIPE_UPLOADER',
    ANIMATION_COMPLETE: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/ANIMATION_COMPLETE',
    SET_RECIPE_ID: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/SET_RECIPE_ID',
    SET_DRAFT_ID: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/SET_DRAFT_ID',
    SET_IS_NEW_RECIPE: 'HMN/COOLINARIKA/WEB/RECIPE_UPLOADER/SET_IS_NEW_RECIPE'
}

const actions = {
    open: () => ({ type: constants.OPEN }),
    close: () => ({ type: constants.CLOSE }),
    toggle: () => ({ type: constants.TOGGLE }),
    animationComplete: () => ({ type: constants.ANIMATION_COMPLETE }),
    setRecipeId: recipeId => ({ type: constants.SET_RECIPE_ID, recipeId }),
    setDraftId: draftId => ({ type: constants.SET_DRAFT_ID, draftId }),
    setIsNewRecipe: isNewRecipe => ({ type: constants.SET_IS_NEW_RECIPE, isNewRecipe })
}

export { constants as RecipeUploaderActionTypes, actions as RecipeUploaderActions }
