/* eslint-disable no-console */
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { linearGrowth, retry } from 'redux-saga-retry'

import dataProvider from '@hmn/data-provider'
import * as PodravkaClient from '@hmn/podravkaio'

import { SettingsActions, SettingsActionTypes } from '../../actions/settings'
import { getListElastic } from '../../hooks'
import { globalSettings } from '../../settings'

const { constants, apiBaseUrl } = globalSettings
const { IMAGES_VARIATIONS_RESOURCE } = constants

// Cache on module level
export const moduleCache = {
    imageVariations: [],
    flagTaxons: []
}
/**
 * Fetch image variation settings from Podravka.io
 *
 * @return {*}
 */
const getImageVariationSettings = async () => {
    let imageVariations = moduleCache?.imageVariations

    if (!moduleCache?.imageVariations?.length) {
        // Just in case the provider in _app.js somehow didn't fire or init.
        if (
            !PodravkaClient.ApiClient.instance.basePath ||
            PodravkaClient.ApiClient.instance.basePath.includes('docs')
        ) {
            dataProvider.init({
                baseUrl: `${apiBaseUrl}/podravkaio`,
                language: 'hr'
            })
        }

        const { data: variationList } = await dataProvider.getList(IMAGES_VARIATIONS_RESOURCE)

        // eslint-disable-next-line camelcase
        const onlyClientOwnedVariations = variationList.filter(({ client_owned }) => !!client_owned)

        const allVariationResponses = await Promise.allSettled([
            ...onlyClientOwnedVariations.map(({ id }) => dataProvider.getOne(IMAGES_VARIATIONS_RESOURCE, { id }))
        ])

        imageVariations = allVariationResponses.map(response =>
            response?.status === 'fulfilled' ? response.value?.data : response.reason
        )

        moduleCache.imageVariations = JSON.parse(JSON.stringify(imageVariations))
    }

    return imageVariations
}

const getFlagTaxons = async () => {
    let flagTaxons = moduleCache?.flagTaxons

    if (!moduleCache.flagTaxons?.length) {
        const { data } = await getListElastic({ resource: 'settings', endpoint: 'taxonomies/flags' })
        moduleCache.flagTaxons = JSON.parse(JSON.stringify(data?.pages?.[0] || []))
        flagTaxons = data?.pages?.[0] || []
    }

    return flagTaxons
}

/**
 * Batch fetch all the required settings.
 * You will have to manually add your settings to parallel fetchers.
 */
const getAllOtherInitialSettings = async () => {
    const settings = {}

    // Add your settings fetcher here
    const parallelSettingsFetchers = [getImageVariationSettings(), getFlagTaxons()]

    // Resolve your settings fetcher here, resolving order is retained from the above list
    const [imageVariationsResponse, flagTaxonsResponse] = await Promise.allSettled(parallelSettingsFetchers)

    // Because you have to manually create reducers for all your settings, you also have to manually dispatch the results
    if (imageVariationsResponse?.status === 'fulfilled') {
        const { value: imageVariations } = imageVariationsResponse
        settings.imageVariations = JSON.parse(JSON.stringify(imageVariations || []))
    }

    if (flagTaxonsResponse?.status === 'fulfilled') {
        const { value: flagTaxons } = flagTaxonsResponse
        settings.flagTaxons = JSON.parse(JSON.stringify(flagTaxons || []))
    }

    return settings
}

const getSettings = function* () {
    const { isLoaded } = yield select(state => state.settings)
    if (!isLoaded && moduleCache?.imageVariations?.length && moduleCache?.flagTaxons?.length) {
        yield all([
            put(SettingsActions.setImagesVariations(moduleCache?.imageVariations)),
            put(SettingsActions.setFlagTaxons(moduleCache?.flagTaxons)),
            put(SettingsActions.setLoaded(true))
        ])

        yield put(SettingsActions.setModuleCache(true))
    } else {
        try {
            yield put(SettingsActions.setModuleCache(false))

            // Get other settings
            const { imageVariations, flagTaxons } = yield call(getAllOtherInitialSettings)

            yield all([
                put(SettingsActions.setImagesVariations(imageVariations)),
                put(SettingsActions.setFlagTaxons(flagTaxons))
            ])

            yield put(SettingsActions.setLoaded(true))
        } catch (error) {
            yield put(SettingsActions.setLoaded(false))
            // eslint-disable-next-line no-console
            console.error('[Settings Saga - getSettings]:', error)
        }
    }
}

export const watchForGetSettings = function* () {
    yield takeEvery(
        SettingsActionTypes.GET_SETTINGS,
        retry(getSettings, {
            backoff: linearGrowth,
            retries: 4
        })
    )
}
