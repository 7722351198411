/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavImages = createSvgIcon(
    <>
        <path d="M23.686 11H12.314A1.316 1.316 0 0011 12.314v11.372A1.316 1.316 0 0012.314 25h11.372A1.316 1.316 0 0025 23.686V12.314A1.316 1.316 0 0023.686 11zm.237 12.686a.238.238 0 01-.237.237h-10.31l7.316-7.315 3.231 3.23v3.848zm0-5.37l-2.85-2.85a.538.538 0 00-.761 0l-8.232 8.232V12.314a.238.238 0 01.234-.237h11.372a.238.238 0 01.237.237v6.002z" />
        <path d="M15.039 13.154a1.885 1.885 0 100 3.769 1.885 1.885 0 000-3.77zm0 2.692a.808.808 0 110-1.615.808.808 0 010 1.615z" />
    </>,
    'NavImages'
)

export default NavImages
