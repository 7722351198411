// @NOTE: Props are added because of autocomplete help
// @TODO: Cleanup props if it turn out we do not need most of them (there is always '...rest')

import { memo } from 'react'
import ScrollBar from 'react-scrollbars-custom'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../ErrorBoundary'
import styles from './ScrollBar.style'

const ScrollbarStyled = styled.div(props => ({ ...styles(props) }))

const Scrollbar = ({
    children,
    native,
    mobileNative,
    momentum,
    disableTracksMousewheelScrolling,
    disableTrackXMousewheelScrolling,
    disableTrackYMousewheelScrolling,
    minimalThumbSize,
    maximalThumbSize,
    minimalThumbXSize,
    maximalThumbXSize,
    minimalThumbYSize,
    maximalThumbYSize,
    noScroll,
    noScrollX,
    noScrollY,
    permanentTracks,
    permanentTrackX,
    permanentTrackY,
    removeTracksWhenNotUsed,
    removeTrackYWhenNotUsed,
    removeTrackXWhenNotUsed,
    translateContentSizesToHolder,
    translateContentSizeYToHolder,
    translateContentSizeXToHolder,
    trackClickBehavior,
    scrollTop,
    scrollLeft,
    scrollDetectionThreshold,
    hasTopShadow,
    hasBottomShadow,
    isFullWidth,
    autoHide,
    className,
    ...rest
}) => {
    if (!children) {
        return null
    }
    return (
        <ScrollbarStyled
            topShadow={hasTopShadow}
            bottomShadow={hasBottomShadow}
            fullWidth={isFullWidth}
            autoHide={autoHide}
            className={className}>
            <ScrollBar
                native={native}
                mobileNative={mobileNative}
                momentum={momentum}
                disableTracksMousewheelScrolling={disableTracksMousewheelScrolling}
                disableTrackXMousewheelScrolling={disableTrackXMousewheelScrolling}
                disableTrackYMousewheelScrolling={disableTrackYMousewheelScrolling}
                minimalThumbSize={minimalThumbSize}
                maximalThumbSize={maximalThumbSize}
                minimalThumbXSize={minimalThumbXSize}
                maximalThumbXSize={maximalThumbXSize}
                minimalThumbYSize={minimalThumbYSize}
                maximalThumbYSize={maximalThumbYSize}
                noScroll={noScroll}
                noScrollX={noScrollX}
                noScrollY={noScrollY}
                permanentTracks={permanentTracks}
                permanentTrackX={permanentTrackX}
                permanentTrackY={permanentTrackY}
                removeTracksWhenNotUsed={removeTracksWhenNotUsed}
                removeTrackYWhenNotUsed={removeTrackYWhenNotUsed}
                removeTrackXWhenNotUsed={removeTrackXWhenNotUsed}
                translateContentSizesToHolder={translateContentSizesToHolder}
                translateContentSizeYToHolder={translateContentSizeYToHolder}
                translateContentSizeXToHolder={translateContentSizeXToHolder}
                trackClickBehavior={trackClickBehavior}
                scrollTop={scrollTop}
                scrollLeft={scrollLeft}
                scrollDetectionThreshold={scrollDetectionThreshold}
                noDefaultStyles
                {...rest}
                // @NOTE: there is an issue with applying {...rest}, it generates "theme=[object Object]" attribute. We need to reset it.
                theme={undefined}>
                {children}
            </ScrollBar>
        </ScrollbarStyled>
    )
}

Scrollbar.propTypes = {
    native: PropTypes.bool,
    mobileNative: PropTypes.bool,
    momentum: PropTypes.bool,
    disableTracksMousewheelScrolling: PropTypes.bool,
    disableTrackXMousewheelScrolling: PropTypes.bool,
    disableTrackYMousewheelScrolling: PropTypes.bool,
    minimalThumbSize: PropTypes.number,
    maximalThumbSize: PropTypes.number,
    minimalThumbXSize: PropTypes.number,
    maximalThumbXSize: PropTypes.number,
    minimalThumbYSize: PropTypes.number,
    maximalThumbYSize: PropTypes.number,
    noScroll: PropTypes.bool,
    noScrollX: PropTypes.bool,
    noScrollY: PropTypes.bool,
    permanentTracks: PropTypes.bool,
    permanentTrackX: PropTypes.bool,
    permanentTrackY: PropTypes.bool,
    removeTracksWhenNotUsed: PropTypes.bool,
    removeTrackYWhenNotUsed: PropTypes.bool,
    removeTrackXWhenNotUsed: PropTypes.bool,
    translateContentSizesToHolder: PropTypes.bool,
    translateContentSizeYToHolder: PropTypes.bool,
    translateContentSizeXToHolder: PropTypes.bool,
    trackClickBehavior: PropTypes.string,
    scrollTop: PropTypes.number,
    scrollLeft: PropTypes.number,
    scrollDetectionThreshold: PropTypes.number,
    hasTopShadow: PropTypes.bool,
    hasBottomShadow: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    autoHide: PropTypes.bool,
    className: PropTypes.string
}

Scrollbar.defaultProps = {
    native: undefined,
    mobileNative: undefined,
    momentum: true,
    disableTracksMousewheelScrolling: undefined,
    disableTrackXMousewheelScrolling: undefined,
    disableTrackYMousewheelScrolling: undefined,
    minimalThumbSize: 30,
    maximalThumbSize: undefined,
    minimalThumbXSize: undefined,
    maximalThumbXSize: undefined,
    minimalThumbYSize: undefined,
    maximalThumbYSize: 8000,
    noScroll: undefined,
    noScrollX: undefined,
    noScrollY: undefined,
    permanentTracks: undefined,
    permanentTrackX: undefined,
    permanentTrackY: undefined,
    removeTracksWhenNotUsed: undefined,
    removeTrackYWhenNotUsed: undefined,
    removeTrackXWhenNotUsed: undefined,
    translateContentSizesToHolder: undefined,
    translateContentSizeYToHolder: undefined,
    translateContentSizeXToHolder: undefined,
    trackClickBehavior: 'jump',
    scrollTop: undefined,
    scrollLeft: undefined,
    scrollDetectionThreshold: 100,
    hasTopShadow: false,
    hasBottomShadow: false,
    isFullWidth: false,
    autoHide: false,
    className: undefined
}

export default withErrorBoundary(memo(Scrollbar))
