/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const Filter = createSvgIcon(
    <path
        d="M24.25 20.875h-1.997A2.62 2.62 0 0019.75 19a2.62 2.62 0 00-2.503 1.875H7.75a.75.75 0 000 1.5h9.497a2.62 2.62 0 002.503 1.875 2.62 2.62 0 002.503-1.875h1.997a.75.75 0 100-1.5zm-4.5 1.875c-.62 0-1.125-.505-1.125-1.125S19.13 20.5 19.75 20.5s1.125.505 1.125 1.125-.505 1.125-1.125 1.125zM24.25 8.875h-1.997A2.62 2.62 0 0019.75 7a2.62 2.62 0 00-2.503 1.875H7.75a.75.75 0 000 1.5h9.497a2.62 2.62 0 002.503 1.875 2.62 2.62 0 002.503-1.875h1.997a.75.75 0 100-1.5zm-4.5 1.875c-.62 0-1.125-.505-1.125-1.125S19.13 8.5 19.75 8.5s1.125.505 1.125 1.125-.505 1.125-1.125 1.125zM24.25 14.875h-9.497A2.62 2.62 0 0012.25 13a2.62 2.62 0 00-2.503 1.875H7.75a.75.75 0 000 1.5h1.997a2.62 2.62 0 002.503 1.875 2.62 2.62 0 002.503-1.875h9.497a.75.75 0 100-1.5zm-12 1.875c-.62 0-1.125-.505-1.125-1.125S11.63 14.5 12.25 14.5s1.125.505 1.125 1.125-.505 1.125-1.125 1.125z"
        fill="#141313"
    />,
    'Filter'
)

export default Filter
