import { toRem } from '../../helpers/theme'

const style = ({ theme, rounded, shadow, showActionComponentOnHover, fullHeight, noMinHeight }) => {
    const roundedConfig = {
        small: toRem(4),
        medium: toRem(8),
        large: toRem(15)
    }

    return {
        position: 'relative',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        boxShadow: shadow && theme.shadows.subtle,
        ...(rounded && {
            borderRadius: roundedConfig[rounded],
            transform: 'translate3d(0,0,0)' // Fixes rounded corner overflow on safari
        }),
        ...(showActionComponentOnHover && {
            '@media (hover: hover)': {
                '.cardAction': {
                    opacity: 0,
                    transition: 'opacity 300ms ease-in',
                    willChange: 'opacity'
                },
                '&:hover': {
                    '.cardAction': {
                        opacity: 1
                    }
                }
            }
        }),
        ...(fullHeight && {
            height: '100%'
        }),
        '& .cardInner': {
            width: '100%',
            ...(fullHeight && {
                height: '100%'
            }),
            display: 'flex',
            flexDirection: 'column',
            color: theme.colors.text.default,
            textDecoration: 'none'
        },
        '& .cardAction': {
            position: 'absolute',
            zIndex: theme.zIndex.card.action,
            right: toRem(10),
            top: toRem(10)
        }
    }
}

export default style
