/* eslint-disable quote-props, no-dupe-keys, max-len */
import merge from 'deepmerge'

import { convertHexToRGBA as rgba, createFontVariations, toRem, toRems } from '../../helpers/theme'

const style = theme => {
    const { fontSizes, rootFontSize } = theme.typography
    const globals = {
        html: {
            fontSize: '100%',
            [theme.breakpoints.up('md')]: {
                // fluid resize magic, with the magic number of 0.834vw
                fontSize: 'clamp(80%, 0.834vw, 100%)'
            }
        },
        body: {
            color: theme.colors.text.default,
            backgroundColor: theme.colors.background.body.default,
            backgroundImage: 'url(/images/background/body/main.svg)',
            backgroundRepeat: 'repeat',
            backgroundPosition: 'top center'
        },
        a: {
            color: theme.colors.link.primary.text.default,
            textDecoration: 'underline',
            '&:active, &:hover': {
                textDecoration: 'none'
            }
        },
        'ol, ul': {
            margin: 0,
            padding: 0,
            listStyleType: 'none'
        },
        table: {
            width: '100%',
            fontSize: toRem(18),
            padding: toRems([0, 30]),
            marginBottom: toRem(50),
            borderTop: `1px solid ${theme.colors.table.border}`,
            borderBottom: `1px solid ${theme.colors.table.border}`,
            borderCollapse: 'collapse',
            [theme.breakpoints.down('sm')]: {
                overflowX: 'scroll'
            },
            tr: {
                borderBottom: `1px solid ${theme.colors.table.border}`,
                verticalAlign: 'top',
                // '&:first-of-type': {
                //     borderBottom: `3px solid ${theme.colors.table.border}`,
                //     fontWeight: theme.typography.fontWeight.bold
                // },
                '&:last-of-type': {
                    borderBottom: 'none'
                },
                '&:nth-of-type(odd)': {
                    background: `${rgba(theme.colors.table.background, 0.4)}`
                }
            }
        },
        th: {
            padding: toRem(10)
        },
        td: {
            padding: toRem(10),
            minWidth: toRem(250),
            [theme.breakpoints.up('md')]: {
                minWidth: toRem(150)
            }
        },
        // this makes react-query devtools not
        // go full height and interfere with UI elements
        '.ReactQueryDevtools > button': {
            height: '50px !important'
        },

        // ========================================
        // Chatbot styles
        // libs/web/ui/src/components/ChatBot/ChatBot.component.js
        // ========================================
        '& .ib-button-messaging.RIGHT_BOTTOM, .ib-eye-catcher__content-container.right': {
            [theme.breakpoints.down('md')]: {
                // Per request: position chatbox 100px above navigation bar
                bottom: `calc(${toRem(theme.global.baseLayout.mobile.navigation.bar.height)} + 100px) !important`
            }
        },
        '.ib-widget-wrapper.RIGHT_BOTTOM': {
            right: '100px !important',
            [theme.breakpoints.up('lg')]: {
                right: '20px !important'
            }
        }
        // ========================================
    }
    return merge.all([
        theme.normalize,
        theme.utilities,
        theme.typography,
        createFontVariations({ fontSizes, rootFontSize }),
        theme.global,
        globals
    ])
}

export default style
