import { toRem, toRems } from '../../../../../helpers/theme'

const style = ({ theme }) => ({
    marginLeft: 0,
    marginBottom: toRem(15),

    [theme.breakpoints.up('lg')]: {
        marginBottom: toRem(30)
    },

    '& .filterList_title': {
        width: 'fit-content', // this may not work in every browser - TODO: check
        marginBottom: toRem(15),
        padding: toRems([4, 7]),
        fontSize: toRem(13),
        fontWeight: theme.typography.fontWeight.bold,
        lineHeight: 1.3,
        letterSpacing: '3px',
        textTransform: 'uppercase',
        background: theme.colors.accentYellow,
        color: theme.colors.black,

        [theme.breakpoints.up('lg')]: {
            marginBottom: toRem(20)
        }
    },

    '& .filterList_list': {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        maxHeight: 'unset',
        paddingBottom: 0
    },

    '& .filterList_item': {
        margin: toRems([0, 10, 10, 0])
    }
})

export default style
