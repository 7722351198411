import { convertHexToRGBA as rgba, toRem } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        nav: {
            spacing: {
                xs: 30,
                lg: 40
            }
        },
        list: {
            spacing: {
                xs: 20,
                md: 20,
                xxl: 30
            }
        }
    }

    return {
        width: '100%',
        height: '100%',
        '& .navigationMenu': {
            '&_container': {
                // this part sucks
                '& .ScrollbarsCustom-Content': {
                    [theme.breakpoints.up('lg')]: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }
                }
            },
            '&_nav': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: toRem(config.nav.spacing.xs),
                [theme.breakpoints.up('lg')]: {
                    padding: toRem(config.nav.spacing.lg),
                    paddingBottom: 0 // bottom spacing is set by the last nav list
                },
                '&List': {
                    paddingTop: toRem(config.list.spacing.xs),
                    paddingBottom: toRem(config.list.spacing.xs),
                    borderTop: '1px solid',
                    borderColor: rgba(theme.colors.navigation.separator, 0.4),
                    [theme.breakpoints.up('md')]: {
                        paddingTop: toRem(config.list.spacing.md),
                        paddingBottom: toRem(config.list.spacing.md)
                    },
                    [theme.breakpoints.up('xxxl')]: {
                        paddingTop: toRem(config.list.spacing.xxxl),
                        paddingBottom: toRem(config.list.spacing.xxxl)
                    },
                    '&:first-of-type': {
                        border: 'none',
                        paddingTop: 0
                    },
                    '&:last-of-type': {
                        paddingBottom: 0,
                        [theme.breakpoints.up('lg')]: {
                            paddingBottom: toRem(config.nav.spacing.lg) // magic spacing that fixes ScrollBar height issue
                        }
                    },
                    '&.isBottom': {
                        marginTop: 'auto'
                    },
                    '& > li': {
                        marginBottom: toRem(12),
                        [theme.breakpoints.up('xxxl')]: {
                            marginBottom: toRem(15)
                        },
                        '&:last-of-type': {
                            marginBottom: 0
                        }
                    }
                }
            }
        }
    }
}

export default style
