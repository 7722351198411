import { useCallback } from 'react'

import useDrawer from './useDrawer'
import useUser from './useUser'

/**
 * Returns a method creator which opens
 * login drawer if user is not logged in.
 *
 * const authGuard = useAuthGuard()
 *
 * const onFavoriteClick = authGuard(() => {
 *  // TODO some logic
 *  // logic will not executre until
 *  // user is logged in
 * })
 *
 * <button onClick={onFavoriteClick}>Favorite</button>
 *
 * @return {*}
 */
const useAuthGuard = () => {
    const { isLoggedIn } = useUser()

    const { open: openLoginDrawer } = useDrawer('drawer-auth-login')

    const actionGuard = useCallback(
        callback =>
            (...args) => {
                if (!isLoggedIn) {
                    openLoginDrawer()
                    return
                }

                if (typeof callback !== 'function') {
                    // eslint-disable-next-line no-console
                    console.warn('[useAuthGuard] callback is not a function')

                    return
                }

                callback(...args)
            },
        [isLoggedIn]
    )

    return actionGuard
}

export default useAuthGuard
