/* eslint-disable max-len */
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

// import dynamic from 'next/dynamic'
import { NavigationActions } from '@hmn/coolinarika-web-core/actions'
import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import { lg, useBreakpoints, useDrawer, useOneElastic, useUser } from '@hmn/coolinarika-web-core/hooks'

import { HeaderSidebar } from '@hmn/coolinarika-web-ui/components/Header/components'
import { Logo } from '@hmn/coolinarika-web-ui/components/Logo'
import { NavigationDesktop, NavigationMobile } from '@hmn/coolinarika-web-ui/components/Navigation'

import style from './Sidebar.style'

const selectNavigationData = responseData => {
    const blogSubcategories = (responseData?.blogSubcategories || [])?.map(category => ({
        title: category?.title,
        href: `/blog/${category?.slug}`
    }))

    const inspirationSubcategories = (responseData?.inspirationSubcategories || [])
        // We have custom inspiration menu item 'zeleni-kutak' that is isLanding type without visibility on Inspiration list
        .filter(item => item?.slug !== 'zeleni-kutak')
        .map(item => ({
            title: item?.title,
            href: `/inspiracija/${item?.slug}-${item?.id}`
        }))

    return {
        blogSubcategories,
        inspirationSubcategories
    }
}

const selectUserNavigationData = responseData => {
    const collections = (responseData?.collections || [])?.map(category => ({
        title: category?.title,
        href: `/moj-profil/spremljeno/${category?.id}`
    }))

    return {
        collections
    }
}

const SidebarStyled = styled.div(props => ({ ...style(props) }))

const recipeUploaderOpenSelector = state => state.recipeUploader.isOpen

const Sidebar = () => {
    const [isDesktop] = useBreakpoints(lg)
    const dispatch = useDispatch()
    const isRecipeUploaderOpen = useSelector(recipeUploaderOpenSelector)
    const { isLoggedIn, profile: userProfile } = useUser()
    const { open: openShoppingDrawer } = useDrawer('drawer-shopping-list', {
        gtmData: { action: 'Shopping', label: 'Shopping View' }
    })

    const handleDrawerOpen = useCallback(() => {
        openShoppingDrawer()
        if (!isDesktop) {
            dispatch(NavigationActions.close())
        }
    }, [dispatch, isDesktop])

    // Get navigation data
    const { data: navigationData } = useOneElastic({
        resource: 'navigation',
        settings: {
            select: selectNavigationData,
            staleTime: Infinity,
            caheTime: Infinity
        }
    })

    // Get user navigation data
    const { data: userNavigationData } = useOneElastic({
        resource: 'navigation/user',
        id: userProfile?.id,
        enabled: !!userProfile?.id,
        settings: {
            select: selectUserNavigationData
        },
        initialData: []
    })

    const navData = useMemo(
        () => ({
            primary: [
                {
                    title: 'Naslovnica',
                    href: '/',
                    icon: 'home',
                    active: ['/']
                },
                {
                    // does not work in dev mode (localhost)
                    title: 'Coolie',
                    href: '/coolie',
                    icon: 'superfoodChefAI',
                    active: ['/coolie']
                },
                {
                    title: 'Zeleni kutak',
                    href: '/inspiracija/zeleni-kutak',
                    icon: 'greenCorner',
                    active: ['/inspiracija/zeleni-kutak', '^/inspiracija/zeleni-kutak(-[a-f0-9-]+)?$']
                },
                {
                    title: 'Inspiracija',
                    href: '/inspiracija',
                    icon: 'inspiration',
                    active: ['/inspiracija'],
                    ...(navigationData?.inspirationSubcategories?.length && {
                        items: [{ title: 'Ideje', href: '/inspiracija' }, ...navigationData?.inspirationSubcategories]
                    })
                },

                {
                    title: 'Recepti',
                    href: '/recepti/novi',
                    icon: 'recipes',
                    active: ['/recepti/', '/recept'],
                    items: [
                        {
                            title: 'Novi',
                            href: '/recepti/novi'
                        },
                        {
                            title: 'Popularni',
                            href: '/recepti/popularni'
                        },
                        {
                            title: 'Video recepti',
                            href: '/recepti/video'
                        },
                        {
                            title: 'By Coolinarika',
                            href: '/recepti/by-coolinarika'
                        },
                        {
                            title: 'Recept dana',
                            href: '/recepti/recept-dana'
                        }
                    ]
                },
                {
                    title: 'Jela',
                    href: '/jela',
                    icon: 'dishes',
                    active: ['jela']
                },
                {
                    title: 'Namirnice',
                    href: '/namirnice',
                    icon: 'ingredients',
                    active: ['namirnice', 'namirnica']
                },
                {
                    title: 'Slike',
                    href: '/slike/nove',
                    icon: 'images',
                    active: ['/slike/', 'slika'],
                    items: [
                        {
                            title: 'Nove',
                            href: '/slike/nove'
                        },
                        {
                            title: 'Popularne',
                            href: '/slike/popularne'
                        }
                    ]
                },
                {
                    title: 'Blog',
                    href: '/blog',
                    icon: 'blog',
                    items: [
                        {
                            title: 'Novo',
                            href: '/blog'
                        },
                        ...(navigationData?.blogSubcategories || [])
                    ]
                },
                {
                    title: 'Ljudi',
                    href: '/ljudi',
                    icon: 'people',
                    active: ['ljudi'],
                    items: [
                        {
                            title: 'Svi',
                            href: '/ljudi'
                        },
                        {
                            title: 'Cool Chefovi',
                            href: '/ljudi/cool-chefovi'
                        }
                    ]
                }
            ],
            secondary: [
                isLoggedIn && {
                    title: 'Popis za kupnju',
                    icon: 'shoppingList',
                    onClick: () => handleDrawerOpen()
                },
                isLoggedIn && {
                    title: 'Spremljeno',
                    href: '/moj-profil/spremljeno',
                    icon: 'saved',
                    expanded: true,
                    items: [
                        { title: 'Svi spremljeni recepti', href: '/moj-profil/spremljeno' },
                        ...(userNavigationData?.collections || [])
                    ]
                }
            ].filter(Boolean),
            tertiary: [
                !isLoggedIn && {
                    title: 'Registracija',
                    href: '/registracija'
                },
                {
                    title: 'Newsletter',
                    href: '/newsletter'
                },
                {
                    title: 'Rječnik',
                    href: '/rjecnik'
                },
                {
                    title: 'Kontakt',
                    href: '/info/kontakt'
                },
                {
                    title: 'Impressum',
                    href: '/info/impressum'
                },
                {
                    title: 'Kako koristiti Coolinariku',
                    href: '/info/kako-koristiti-coolinariku'
                },
                {
                    title: 'Pravila zaštite privatnosti',
                    href: '/info/zastita-privatnosti'
                },
                {
                    title: 'Uvjeti korištenja',
                    href: '/info/uvjeti-koristenja'
                },
                {
                    title: 'Pravila o korištenju kolačića',
                    href: '/info/pravila-o-koristenju-kolacica'
                },
                {
                    title: 'Postavke kolačića',
                    href: '#',
                    className: 'kc-show-preferences'
                }
            ].filter(Boolean),
            account: [
                {
                    title: 'Moji recepti',
                    href: '/moj-profil/recepti',
                    icon: 'recipes'
                },
                {
                    title: 'Moje slike',
                    href: '/moj-profil/slike',
                    icon: 'images'
                },
                {
                    title: 'Trofeji',
                    href: '/moj-profil/trofeji',
                    icon: 'trophies'
                },
                {
                    title: 'Tagovi',
                    href: '/moj-profil/tagovi',
                    icon: 'tags'
                },
                {
                    title: 'Pratim',
                    href: '/moj-profil/pratim',
                    icon: 'usersFollowing'
                },
                {
                    title: 'Prate me',
                    href: '/moj-profil/prate-me',
                    icon: 'usersFollowers'
                },
                {
                    title: 'Postavke',
                    href: '/moj-profil/postavke',
                    icon: 'settings'
                }
            ].filter(Boolean)
        }),
        [handleDrawerOpen, navigationData, userNavigationData, isLoggedIn, userProfile]
    )

    return (
        <>
            <Media lessThan="lg">
                {!isRecipeUploaderOpen && (
                    <NavigationMobile
                        primaryData={navData?.primary}
                        secondaryData={navData?.secondary}
                        tertiaryData={navData?.tertiary}
                        accountData={navData?.account}
                    />
                )}
            </Media>
            <Media greaterThan="md">
                <SidebarStyled>
                    <HeaderSidebar logoComponent={Logo} />
                    <NavigationDesktop
                        primaryData={navData?.primary}
                        secondaryData={navData?.secondary}
                        tertiaryData={navData?.tertiary}
                        accountData={navData?.account}
                    />
                </SidebarStyled>
            </Media>
        </>
    )
}

export default Sidebar
