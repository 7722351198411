import { useCallback, useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { FiltersActions } from '@hmn/coolinarika-web-core/actions'
import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import { lg, useBreakpoints, usePluralize } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../../../../Button'
import { ArrowSmallIcon, Icon } from '../../../../Icon'
import { ScrollBar } from '../../../../ScrollBar'
import styles, { searchFiltersPanelVariant } from './FiltersContainer.style'

const selector = ({ search, filters }) => ({
    isSearchOpen: search.isOpen,
    isFiltersOpen: filters.isOpen
})

const SearchFiltersStyled = styled.div(props => ({ ...styles(props) }))

const SearchFilters = ({ className, children, numberOfResults, ...rest }) => {
    const dispatch = useDispatch()
    const filters = useRef()
    const { isSearchOpen, isFiltersOpen } = useSelector(selector, shallowEqual)
    const [isDesktopView] = useBreakpoints(lg)

    const results = usePluralize('%{c} recept |||| %{c} recepta |||| %{c} recepata', numberOfResults || 0)

    const handleToggleFilters = useCallback(() => {
        dispatch(FiltersActions.toggle())
    }, [dispatch])

    const filtersAreVisible = useMemo(
        () => (isSearchOpen && isDesktopView) || (isFiltersOpen && !isDesktopView),
        [isSearchOpen, isFiltersOpen, isDesktopView]
    )

    return (
        <SearchFiltersStyled className={className} ref={filters} {...rest}>
            <motion.div
                className="searchFilters_panel"
                initial="closed"
                animate={filtersAreVisible ? 'open' : 'closed'}
                variants={searchFiltersPanelVariant}>
                <ScrollBar hasTopShadow hasBottomShadow>
                    <div className="searchFilters_panelBody">{children}</div>
                </ScrollBar>
                {!!numberOfResults && (
                    <Media lessThan="lg">
                        <div className="searchFilters_panelFooter">
                            <Button
                                className="searchFilters_panelBtn"
                                title={results}
                                variant={buttonVariants.QUATERNARY}
                                width="100%"
                                size={buttonSizes.NORMAL}
                                iconRightComponent={<Icon icon={ArrowSmallIcon} />}
                                onClick={handleToggleFilters}>
                                {results}
                            </Button>
                        </div>
                    </Media>
                )}
            </motion.div>
        </SearchFiltersStyled>
    )
}

SearchFilters.propTypes = {
    className: PropTypes.string,
    numberOfResults: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SearchFilters.defaultProps = {
    className: undefined,
    numberOfResults: undefined
}

export default SearchFilters
