/* eslint-disable no-console */
import { useEffect, useState } from 'react'

/**
 * Similar to useState but first arg is key to the value in local storage.
 *
 *   const [name, setName] = useLocalStorage('name', 'Bob')
 *
 *
 * @param {*} key
 * @param {*} initialValue
 */
export const useLocalStorage = (key, initialValue) => {
    const stored = typeof window !== 'undefined' && localStorage.getItem(key)
    const initial = stored ? JSON.parse(stored) : initialValue
    const [value, setValue] = useState(initial)

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}

export default useLocalStorage
