import { useCallback, useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { AnalyticsContext } from '../controllers/AnalyticsContext'
import useUser from './useUser'

const isBrowser = typeof window !== 'undefined'
const isReadOnly = process.env.NEXT_PUBLIC_UI_READ_ONLY === 'true'

const detectWebView = () => {
    if (!isBrowser) {
        return false
    }

    const { standalone } = window?.navigator
    const userAgent = window?.navigator?.userAgent?.toLowerCase()
    const safari = /safari/.test(userAgent)
    const ios = /iphone|ipod|ipad/.test(userAgent)

    if (ios && !standalone && !safari) {
        // Maybe iOS WebView
        return true
    }

    if (!ios && userAgent?.includes('wv')) {
        // Maybe Android WebView
        return true
    }

    return false
}

/**
 * @typedef TrackingCallbacks useAnalytics return type
 * @property {(payload: object) => void} pageView
 * @property {(payload: object) => void} eventWithTrackingData
 * @property {(payload?: object, eventName?: string) => void} eventTracking
 */

/**
 * Obviusly a hook for handling analyitics :D
 * it takes event name and event payload and returns pageView and eventTracking methods
 *
 * @param {string} eventName
 * @param {object} [payload={}]
 * @param {boolean} [devOnly=false]
 * @return {TrackingCallbacks} {pageView, eventWithTrackingData, eventTracking}
 */
const useAnalytics = (eventName, payload = {}, devOnly = false) => {
    const { analytics } = useContext(AnalyticsContext)
    const { profile: user } = useUser()

    const pageView = useCallback(
        dynamicPagePayload => {
            if (isBrowser && analytics) {
                /* analytics.page({
                    coolinarikaUserAgent: window?.navigator?.userAgent,
                    coolinarikaWebView: detectWebView(),
                    ...dynamicPagePayload
                })
                console.log('[GTM] pageView', {
                    type: 'page',
                    coolinarikaUserAgent: window?.navigator?.userAgent,
                    coolinarikaWebView: detectWebView(),
                    ...dynamicPagePayload
                }) */
                window.dataLayer.push({
                    type: 'page',
                    event: 'pageview',
                    coolinarikaUserAgent: window?.navigator?.userAgent,
                    coolinarikaWebView: detectWebView(),
                    ...dynamicPagePayload
                })

                /* console.log('[GTM] pageView dataLayer', window.dataLayer) */
            }
        },
        [analytics]
    )

    const eventTracking = useCallback(
        (dynamicPayload, dynamicEventName = eventName) => {
            const isDynamicPlayloadFunc = dynamicPayload && {}.toString.call(dynamicPayload) === '[object Function]'

            if (isBrowser && analytics) {
                requestAnimationFrame(() => {
                    // Event data before dynamic data is added
                    const staticEventData = {
                        type: 'track',
                        event: dynamicEventName,
                        coolinarikaUserAgent: window?.navigator?.userAgent,
                        coolinarikaWebView: detectWebView(),
                        ...payload
                    }

                    const eventData = {
                        ...staticEventData,
                        ...(isDynamicPlayloadFunc ? dynamicPayload(staticEventData) : dynamicPayload)
                    }

                    /* analytics.track(eventName, {
                        coolinarikaUserAgent: window?.navigator?.userAgent,
                        coolinarikaWebView: detectWebView(),
                        ...payload,
                        ...dynamicPayload
                    })
                    console.log('[GTM] eventTracking', {
                        eventName,
                        type: 'track',
                        coolinarikaUserAgent: window?.navigator?.userAgent,
                        coolinarikaWebView: detectWebView(),
                        ...payload,
                        ...dynamicPayload
                    }) */
                    window.dataLayer.push(eventData)

                    /* console.log('[GTM] eventTracking dataLayer', window.dataLayer) */
                })
            }
        },
        [analytics, eventName, payload]
    )

    const eventWithTrackingData = useCallback(
        (dynamicTrackingData, dynamicEventName = eventName) => {
            const dynamicPayload = data => ({
                ...data,
                trackingData: {
                    ...(user &&
                        user?.id && {
                            accountID: user?.id
                        }),
                    ...data.trackingData,
                    ...dynamicTrackingData
                }
            })

            eventTracking(dynamicPayload, dynamicEventName)
        },
        [eventTracking, eventName, user]
    )

    if (isReadOnly && devOnly) {
        return {
            pageView: () => {},
            eventTracking: () => {},
            eventWithTrackingData: () => {}
        }
    }

    return {
        pageView,
        eventTracking,
        eventWithTrackingData
    }
}

/**
 * This is extended useAnalytics hook which uses intersection observer to trigger event
 *
 * @param {string} [eventName='coolinarika.GAView']
 * @param {object} [payload={}]
 * @param {object} [observerOptions={}]
 * @param {boolean} [devOnly=false]
 * @return {object} {ref}
 */
const useInViewAnalytics = (eventName = 'coolinarika.GAView', payload = {}, observerOptions = {}, devOnly = false) => {
    const { ref, inView } = useInView(observerOptions)
    const { eventTracking } = useAnalytics(eventName, payload, devOnly)

    useEffect(() => {
        if (inView) {
            eventTracking()
        }
    }, [inView])

    return { ref }
}

/**
 * Hook which wraps the useAnalytics hook and predefines some of the data that is shared among all forms.
 *
 * @param {object} [payload={}]
 * @param {boolean} [devOnly=false]
 * @returns {Object} { eventWithTrackingData }
 */
const useFormAnalytics = (payload = {}, devOnly = false) => {
    const { eventWithTrackingData } = useAnalytics(
        'coolinarika.formSubmit',
        {
            ...payload,
            trackingData: {
                elementCategory: 'form',
                elementPageLocation: 'body',
                ...payload.trackingData
            }
        },
        devOnly
    )

    return {
        eventWithTrackingData
    }
}

/**
 * Hook that can be used for tracking analytics data of the search component (e.g. can be used
 * for blogs, people,... search).
 *
 * @param {string} label
 * @param {boolean} isSearching
 * @param {Object} searchTerm
 * @param {Object[]} searchPages
 * @param {boolean} devOnly
 */
const useSearchAnalytics = (label, isSearching, searchTerm, searchPages, devOnly = false) => {
    const { eventWithTrackingData } = useAnalytics('coolinarika.search', {
        trackingData: {
            elementCategory: 'search',
            elementLabel: label,
            elementPageLocation: 'body'
        },
        devOnly
    })

    useEffect(() => {
        if (searchTerm && !isSearching) {
            eventWithTrackingData({
                search: {
                    query: searchTerm,
                    status: searchPages?.[0]?.length ? 'got results' : 'no results'
                }
            })
        }
    }, [isSearching, searchTerm, searchPages])
}

export { useAnalytics, useInViewAnalytics, useFormAnalytics, useSearchAnalytics }
