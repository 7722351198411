import { toRem } from '../../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, isActive, isActiveOffset }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    a: {
        width: '100%',
        height: '100%',
        textDecoration: 'none !important'
    },
    '& .barNav': {
        '&_item': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minHeight: toRem(theme.global.baseLayout.mobile.navigation.bar.height),
            cursor: 'pointer',
            overflow: 'hidden',
            color: theme.colors.navigation.icon.fill.default,
            ...(isActive && {
                color: theme.colors.navigation.text.active
            }),
            '&Icon': {
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: toRem(40),
                height: toRem(40),
                fontSize: toRem(46),
                transitionDuration: theme.transitions.navigation.icon.default.duration,
                transitionTimingFunction: theme.transitions.navigation.icon.default.timingFunction,
                transitionDelay: theme.transitions.navigation.icon.default.delay,
                transitionProperty: 'color',
                overflow: 'hidden',
                svg: {
                    path: {
                        fill: 'currentColor'
                    }
                }
            },
            '&Indicator': {
                display: 'block',
                position: 'absolute',
                left: '50%',
                bottom: toRem(12),
                marginLeft: toRem(isActiveOffset || -2),
                width: 4,
                height: 4,
                borderRadius: '100%',
                transitionDuration: theme.transitions.navigation.icon.default.duration,
                transitionTimingFunction: theme.transitions.navigation.icon.default.timingFunction,
                transitionDelay: theme.transitions.navigation.icon.default.delay,
                transitionProperty: 'transform, background-color',
                pointerEvents: 'none',
                transform: `translateY(${toRem(30)})`,
                ...(isActive && {
                    backgroundColor: theme.colors.navigation.text.active,
                    transform: 'translateY(0)'
                })
            }
        }
    }
})

export default style
