// @TODO: Only proof of concept for now, rewrite later
const constants = {
    OPEN_NOTIFICATION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/OPEN_NOTIFICATION',
    CLOSE_NOTIFICATION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/CLOSE_NOTIFICATION',
    CREATE_COLLECTION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/CREATE_COLLECTION',
    UPDATE_COLLECTION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/UPDATE_COLLECTION',
    DELETE_COLLECTION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/DELETE_COLLECTION',
    ADD_TO_COLLECTION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/ADD_TO_COLLECTION',
    REMOVE_FROM_COLLECTION: 'HMN/COOLINARIKA/WEB/COLLECTIONS/REMOVE_FROM_COLLECTION'
}

const actions = {
    openNotification: notification => ({ type: constants.OPEN_NOTIFICATION, notification }),
    closeNotification: notification => ({ type: constants.CLOSE_NOTIFICATION, notification }),
    create: collection => ({ type: constants.CREATE_COLLECTION, collection }),
    update: collection => ({ type: constants.UPDATE_COLLECTION, collection }),
    delete: collection => ({ type: constants.DELETE_COLLECTION, collection }),
    add: collection => ({ type: constants.ADD_TO_COLLECTION, collection }),
    remove: collection => ({ type: constants.REMOVE_FROM_COLLECTION, collection })
}

export { constants as CollectionActionTypes, actions as CollectionActions }
