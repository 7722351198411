/* eslint-disable no-console, camelcase */
import { createContext, useCallback, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { useUser } from '../../hooks'
import { globalSettings } from '../../settings'

const { apiBaseUrl } = globalSettings

const NotificationsContext = createContext({})

async function fetchUserNotifications(baseUrl, userId) {
    if (!baseUrl || !userId) {
        return null
    }

    const response = await fetch(`${baseUrl}/notifications/${userId}`)
    const data = await response.json()

    return data?.response
}

const NotificationsContextProvider = ({ children }) => {
    const { id: userId } = useUser()

    const queryKey = ['notifications', userId]

    const queryClient = useQueryClient()

    const { data: notifications } = useQuery(queryKey, () => fetchUserNotifications(apiBaseUrl, userId))

    const viewNotification = useCallback(
        async notification => {
            if (!notification?.id && !notification.ids) {
                return
            }

            const notificationIds = notification.ids?.length ? notification.ids : [notification.id]

            await fetch(`${apiBaseUrl}/notifications/viewed`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(notificationIds)
            }).then(response => response.json())

            // We don't really gain anything if we check that the viewed request succeeded.
            // We have three choices to handle a failing request:
            // a) Wait until we're sure it failed - the user will see nothing happen when they click (bad)
            // b) Update optimistically and rollback if we find out it failed - the user will see the rollback relatively quickly
            // c) Update optimistically and never check if it failed - the user will see the rollback on refresh
            // Option c) is the cheapest and pretty much just as bad as the other two
            queryClient.setQueryData(queryKey, previousNotifications => {
                previousNotifications
                    .filter(n => notificationIds.includes(n.id))
                    .forEach(n => {
                        n.viewed = true
                    })
                return previousNotifications
            })
        },
        [queryClient, queryKey]
    )

    const onMessage = message => {
        const newNotification = JSON.parse(message?.data)
        queryClient.setQueryData(queryKey, previousNotifications => [newNotification, ...previousNotifications])
    }

    // SSE
    useEffect(() => {
        if (!userId) {
            return undefined
        }

        const eventSource = new EventSource(`${apiBaseUrl}/notifications/events/${userId}`)
        eventSource.onmessage = onMessage

        return () => {
            eventSource.close()
        }
    }, [userId])

    const value = useMemo(
        () => ({
            all: notifications,
            unread: notifications?.filter(n => !n.viewed),
            viewNotification
        }),
        [notifications, viewNotification]
    )

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}

export { NotificationsContextProvider }

export default NotificationsContext
