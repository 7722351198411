import { TasteProfileActionTypes } from '../../actions/tasteProfile'

const initialState = {
    ingredients: [],
    ingredientsIgnore: [],
    meals: []
}

const tasteProfileReducer = (state = initialState, { type, ingredient }) => {
    switch (type) {
        case TasteProfileActionTypes.ADD: {
            if (state.ingredients.some(item => item === ingredient)) {
                return {
                    ...state,
                    ingredients: state.ingredients.filter(item => item !== ingredient)
                }
            }
            return {
                ...state,
                ingredients: [...state.ingredients, ingredient]
            }
        }

        case TasteProfileActionTypes.ADD_ARRAY: {
            return {
                ...state,
                ingredients: [...ingredient]
            }
        }

        case TasteProfileActionTypes.ADD_IGNORE: {
            if (state.ingredientsIgnore.some(item => item === ingredient)) {
                return {
                    ...state,
                    ingredientsIgnore: state.ingredientsIgnore.filter(item => item !== ingredient)
                }
            }
            return {
                ...state,
                ingredientsIgnore: [...state.ingredientsIgnore, ingredient]
            }
        }
        case TasteProfileActionTypes.ADD_IGNORE_ARRAY: {
            return {
                ...state,
                ingredientsIgnore: [...ingredient]
            }
        }

        case TasteProfileActionTypes.ADD_MEALS: {
            if (state.meals.some(item => item === ingredient)) {
                return {
                    ...state,
                    meals: state.meals.filter(item => item !== ingredient)
                }
            }
            return {
                ...state,
                meals: [...state.meals, ingredient]
            }
        }

        case TasteProfileActionTypes.ADD_MEALS_ARRAY: {
            return {
                ...state,
                meals: [...ingredient]
            }
        }

        case TasteProfileActionTypes.CLEAR: {
            return {
                ingredients: [],
                ingredientsIgnore: []
            }
        }

        default:
            return state
    }
}

export { tasteProfileReducer }
