import { useMutation } from 'react-query'

import { globalSettings } from '../settings'

const { apiBaseUrl } = globalSettings

const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
}

const sendRequest = async (endpoint, payload) => {
    const responseData = await fetch(`${apiBaseUrl}${endpoint}`, {
        method: 'POST',
        headers: DEFAULT_HEADERS,
        body: JSON.stringify(payload)
    }).then(response => response.json())

    return responseData
}

const useFetch = () => {
    const {
        mutate,
        isLoading,
        data: responseData,
        status,
        error,
        ...restMutate
    } = useMutation(async data => {
        const { endpoint, payload } = data || {}

        return sendRequest(endpoint, payload)
    })

    return {
        data: responseData,
        isLoading,
        status,
        error,
        restMutate,
        actions: {
            create: ({ data, options }) => {
                if (isLoading) {
                    return
                }

                mutate(data, options)
            }
        }
    }
}

export default useFetch
