/* eslint-disable no-useless-escape */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import { slugify } from '@hmn/coolinarika-web-core/helpers'

import { RecipeCard, RecipeCardSmall, recipeCardVariants } from '../../../Recipe/components'
import styles from './ResultItem.style'

const getSlug = item => {
    if (!item?.slug && !item?.title) {
        return `/recept/${item?.id}`
    }

    if (item?.slug) {
        return `/recept/${item?.slug}-${item?.id}`
    }

    return `/recept/${slugify(item?.title)}-${item?.id}`
}

const ResultItemStyled = styled.div(props => ({ ...styles(props) }))

const ResultItem = ({ data: item, isFullSize, className, onClick, ...props }) => {
    if (isFullSize) {
        return (
            <ResultItemStyled className={className} {...props}>
                <RecipeCard
                    onCardClick={onClick}
                    className="resultItem_card"
                    item={item}
                    href={getSlug(item)}
                    titleTagComponent="h3"
                    variant={recipeCardVariants['1x1']}
                    fullHeight
                />
            </ResultItemStyled>
        )
    }

    return (
        <ResultItemStyled className={className} {...props}>
            <Media greaterThan="md">
                <RecipeCard
                    onCardClick={onClick}
                    className="resultItem_card"
                    item={item}
                    href={getSlug(item)}
                    titleTagComponent="h3"
                    variant={recipeCardVariants['1x1']}
                    fullHeight
                />
            </Media>
            <Media lessThan="lg">
                <RecipeCardSmall
                    onCardClick={onClick}
                    className="resultItem_card"
                    item={item}
                    href={getSlug(item)}
                    titleTagComponent="h3"
                />
            </Media>
        </ResultItemStyled>
    )
}

ResultItem.propTypes = {
    className: PropTypes.string,
    isFullSize: PropTypes.bool,
    data: PropTypes.oneOfType([() => null, PropTypes.object]),
    onClick: PropTypes.func
}

ResultItem.defaultProps = {
    className: undefined,
    isFullSize: false,
    data: undefined,
    onClick: undefined
}

export default ResultItem
