// @ui imports
import { toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({
    rounded,
    isImageLoading,
    withPlaceholder,
    transitionDuration,
    placeholderBgColor,
    isDocbook,
    isImageError
}) => {
    const roundedConfig = {
        small: toRem(4),
        medium: toRem(8),
        large: toRem(15),
        rounded: '50%'
    }

    return {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 0,
        padding: 0,
        ...(rounded && {
            borderRadius: roundedConfig[rounded]
        }),
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            pointerEvents: 'none',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            opacity: 0.3,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            ...(isImageLoading && { backgroundColor: placeholderBgColor }),
            ...(isImageError && {
                backgroundImage: 'url(/images/placeholder/card-placeholder-image.png)',
                backgroundSize: 'contain'
            })
        },
        img: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            ...(withPlaceholder && {
                filter: isImageLoading ? 'blur(15px)' : 'blur(0px)',
                opacity: isImageLoading ? 0.5 : 1,
                transform: isImageLoading ? 'scale(1.05)' : 'scale(1)',
                transition: `opacity ${transitionDuration}ms ease-in, transform ${transitionDuration}ms ease-in, filter ${
                    transitionDuration * 2
                }ms ease-in`
            })
        }
    }
}

export default style
