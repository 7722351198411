import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Content.style'

const ContentStyled = styled.div(props => ({ ...styles(props) }))

const Content = ({ children, className, ...rest }) => (
    <ContentStyled className={className} role="dialog" {...rest}>
        {children}
    </ContentStyled>
)

Content.propTypes = {
    className: PropTypes.string
}

Content.defaultProps = {
    className: undefined
}

export default Content
