const style = () => ({
    minWidth: 0,
    '& .resultItem_card': {
        '& .cardInner': {
            // for some reason there is a 250px min height set on cards, so...this...fml
            '& > div': {
                minHeight: 0
            }
        }
    }
})

export default style
