/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const Edit = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.124 10.032a.728.728 0 000-1.058L21.302 7.22a.796.796 0 00-1.098 0l-1.526 1.47 2.92 2.812 1.526-1.47zm-2.304 2.22L17.9 9.438l-7.786 7.5v2.811h2.92l7.786-7.499zM25.686 22H7v3h18.686v-3z"
        fill="#141313"
    />,
    'Edit'
)

export default Edit
