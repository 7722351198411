/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavLogOut = createSvgIcon(
    <>
        <path d="M23.05 14.2h-1.3v-1.95a3.25 3.25 0 10-6.5 0 .65.65 0 001.3 0 1.95 1.95 0 113.9 0v1.95h-6.5A1.95 1.95 0 0012 16.15v7.02a1.95 1.95 0 001.95 1.95h9.1A1.95 1.95 0 0025 23.17v-7.02a1.95 1.95 0 00-1.95-1.95zm.65 8.97a.65.65 0 01-.65.65h-9.1a.65.65 0 01-.65-.65v-7.02a.65.65 0 01.65-.65h9.1a.65.65 0 01.65.65v7.02z" />
        <path d="M18.5 17.71a1.95 1.95 0 100 3.901 1.95 1.95 0 000-3.901zm0 2.6a.65.65 0 110-1.299.65.65 0 010 1.299z" />
    </>,
    'NavLogOut'
)

export default NavLogOut
