import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { FilterListView } from '../FilterListView'
import { FilterPill } from '../FilterPill'

const ToggleButtonList = ({ data, title, componentIds, labels, onChange, activeFilters }) => {
    const dataFilters = useMemo(
        () =>
            componentIds
                .filter(componentId => !!data[componentId])
                .map(componentId => ({
                    componentId,
                    value: true,
                    label: labels[componentId]
                })),
        [data, componentIds, labels]
    )

    return (
        <FilterListView title={title}>
            {dataFilters?.map(({ componentId, value, label }) => (
                <FilterPill
                    key={componentId}
                    id={componentId}
                    name={componentId}
                    value={value}
                    label={label}
                    isActive={!!activeFilters[componentId]}
                    onChange={onChange}
                />
            ))}
        </FilterListView>
    )
}

ToggleButtonList.propTypes = {
    data: PropTypes.shape({}).isRequired,
    componentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    labels: PropTypes.shape({}).isRequired,
    title: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    activeFilters: PropTypes.shape({}).isRequired
}

ToggleButtonList.defaultProps = {
    title: undefined
}

export default ToggleButtonList
