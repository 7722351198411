/* eslint-disable no-console */
import { SettingsActionTypes } from '../../actions/settings'

const initialState = {
    isLoaded: false,
    isUsingModuleCache: false,
    imagesConfig: {
        variations: []
    },
    taxons: {
        flags: []
    },
    isWtgLoaded: false
}

const settingsReducer = (
    state = initialState,
    { type, variations, config, isLoaded, isCache: isUsingModuleCache, taxons, wtgLoaded }
) => {
    switch (type) {
        case SettingsActionTypes.SET_IMAGES_VARIATIONS: {
            return {
                ...state,
                imagesConfig: {
                    variations: (Array.isArray(variations) && variations.length && variations) || []
                }
            }
        }

        case SettingsActionTypes.SET_USING_MODULE_CACHE: {
            return {
                ...state,
                isUsingModuleCache
            }
        }

        case SettingsActionTypes.SET_LOADED: {
            return {
                ...state,
                isLoaded
            }
        }

        case SettingsActionTypes.SET_FLAG_TAXONS: {
            return {
                ...state,
                taxons: {
                    ...state.taxons,
                    flags: Array.isArray(taxons) ? taxons : []
                }
            }
        }

        case SettingsActionTypes.SET_WTG_LOADED: {
            return {
                ...state,
                isWtgLoaded: wtgLoaded
            }
        }

        default:
            return state
    }
}

export { settingsReducer }
