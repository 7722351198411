// @TODO: Waaaaay better Icon handling (dynamic import??)
// @TODO: Active item detection (main navigation , account navigation)

import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useRouter } from 'next/router'
import PropTypes from 'prop-types' // Trim - from end of text

import { Button, buttonVariants } from '../../../Button'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HtmlContent } from '../../../HtmlContent'
import {
    Icon,
    NavBlogIcon,
    NavDishesIcon,
    NavGreenCornerIcon,
    NavHamburgerIcon,
    NavHomeIcon,
    NavImageAddIcon,
    NavImagesIcon,
    NavIngredientsIcon,
    NavInspirationIcon,
    NavLogOutIcon,
    NavMenusIcon,
    NavMoreIcon,
    NavNotificationsIcon,
    NavPeopleIcon,
    NavRecipeAddIcon,
    NavRecipesIcon,
    NavSavedIcon,
    NavSearchIcon,
    NavSettingsIcon,
    NavShoppingListIcon,
    NavSuperfoodChefAIIcon,
    NavTagsIcon,
    NavTrophiesIcon,
    NavUserIcon,
    NavUsersFollowersIcon,
    NavUsersFollowingIcon
} from '../../../Icon'
import { Link } from '../../../Link'
import { SubItem } from '../SubItem'
import styles from './Item.style'
import { transition, variants } from './Item.variants'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

const itemExpansionDelay = 500

const getIcon = icon =>
    ({
        home: NavHomeIcon,
        inspiration: NavInspirationIcon,
        recipes: NavRecipesIcon,
        dishes: NavDishesIcon,
        ingredients: NavIngredientsIcon,
        blog: NavBlogIcon,
        people: NavPeopleIcon,
        more: NavMoreIcon,
        shoppingList: NavShoppingListIcon,
        menus: NavMenusIcon,
        saved: NavSavedIcon,
        user: NavUserIcon,
        search: NavSearchIcon,
        hamburger: NavHamburgerIcon,
        images: NavImagesIcon,
        trophies: NavTrophiesIcon,
        tags: NavTagsIcon,
        usersFollowing: NavUsersFollowingIcon,
        usersFollowers: NavUsersFollowersIcon,
        settings: NavSettingsIcon,
        logOut: NavLogOutIcon,
        recipeAdd: NavRecipeAddIcon,
        imageAdd: NavImageAddIcon,
        notifications: NavNotificationsIcon,
        superfoodChefAI: NavSuperfoodChefAIIcon,
        greenCorner: NavGreenCornerIcon
    }[icon])

const Item = ({
    className,
    variant,
    href,
    hrefAs,
    icon,
    expanded,
    title,
    items,
    onClick,
    action,
    isSubtle,
    isThin,
    colorVariant,
    notifications,
    activeTriggers,
    isPrimaryNavigationItem,
    onClickEventTracking,
    ...rest
}) => {
    const { push, pathname } = useRouter()
    const dispatch = useDispatch()
    const uid = useUIDSeed()
    const { isNavigating } = useSelector(state => state.navigation)
    const expansionTimeoutRef = useRef()

    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => setIsExpanded(expanded), [expanded])

    const handleItemExpand = condition => {
        if (expansionTimeoutRef.current) {
            clearTimeout(expansionTimeoutRef.current)
        }
        expansionTimeoutRef.current = setTimeout(
            () => requestAnimationFrame(() => setIsExpanded(condition)),
            itemExpansionDelay
        )
    }

    const handleClick = useCallback(
        (event, ...args) => {
            if (onClickEventTracking) {
                onClickEventTracking({
                    isPrimary: true,
                    label: title
                })
            }

            if (action) {
                dispatch(action)
            } else if (onClick) {
                onClick(event, ...args)
            }

            if (items?.length && !isNavigating) {
                handleItemExpand(!isExpanded)
            }

            if (href && !pathname?.includes(href) && !isExpanded) {
                push(href, hrefAs)
            }
        },
        [dispatch, isExpanded, href, isNavigating, onClickEventTracking, pathname, push]
    )

    const handleSubItemClick = (e, subItem) => {
        if (onClickEventTracking) {
            onClickEventTracking({
                isPrimary: false,
                label: subItem.title
            })
        }

        if (subItem.onClick) {
            subItem.onClick(e)
        }
    }

    useEffect(() => {
        const checkIfChildShouldActivateParent = child => {
            if (typeof activeTriggers !== 'undefined' && Array.isArray(activeTriggers) && activeTriggers.length) {
                const activators = activeTriggers.some(trigger => {
                    if (trigger.startsWith('^')) {
                        const regex = new RegExp(trigger)
                        return child?.href && regex.test(child.href) && regex.test(pathname)
                    }
                    return child?.href && child.href === trigger && pathname === trigger
                })
                return activators && !pathname.includes('moj-profil') && !pathname.includes('/inspiracija/zeleni-kutak')
            }
            return false
        }

        // If not expanded by default, change expanded state on route change
        if (!expanded && !isNavigating) {
            // Check if item has some custom activation rules which should be taken into account first
            const childActivatesParent = items?.some(item => checkIfChildShouldActivateParent(item))

            if (childActivatesParent) {
                handleItemExpand(true)
            } else {
                handleItemExpand(pathname === href)
            }
        }

        return () => {
            if (expansionTimeoutRef.current) {
                clearTimeout(expansionTimeoutRef.current)
            }
        }
    }, [pathname, href, title, items, isNavigating])

    if (!title) {
        return null
    }

    const getCurrentNavItem = () => {
        if (!expanded && isExpanded && items?.length) {
            return true
        }

        if (href === '/inspiracija/zeleni-kutak' && pathname === '/inspiracija/[slug]') {
            return true
        }

        const parentActive = pathname === href

        const childActive = items?.some(item => item?.href === pathname)

        return parentActive || childActive
    }

    const isCustomIconStyle = () => {
        if (href && pathname) {
            if (href === '/inspiracija/zeleni-kutak' && pathname === '/inspiracija/[slug]') {
                return true
            }
        }
        return false
    }

    return (
        <ItemStyled
            variant={variant}
            className={`${className || ''} ${isCustomIconStyle() ? 'isGreen' : ''}`}
            isActive={getCurrentNavItem()}
            isSubtle={isSubtle}
            isThin={isThin}
            colorVariant={colorVariant}
            isPrimaryNavigationItem={isPrimaryNavigationItem}
            {...rest}>
            {variant === 'button' ? (
                <Button
                    title={title}
                    onClick={handleClick}
                    href={href}
                    hrefAs={hrefAs}
                    variant={buttonVariants.SECONDARY}
                    iconLeftComponent={icon && getIcon(icon) && <Icon icon={getIcon(icon)} viewBox="0 0 36 36" />}
                    className="navItemButton"
                    noTracking>
                    <HtmlContent content={title} wrapperComponent="span" />
                </Button>
            ) : (
                <ConditionalWrapper
                    condition={!!href && !items?.length}
                    href={href}
                    as={hrefAs}
                    title={title}
                    Wrapper={Link}
                    noTracking>
                    <span className="navItem" onClick={handleClick} role="presentation">
                        {icon && getIcon(icon) && (
                            <span className="navItem_icon">
                                <Icon icon={getIcon(icon)} viewBox="0 0 36 36" />
                            </span>
                        )}
                        <span className="navItem_label">
                            <HtmlContent content={title} wrapperComponent="span" />
                            {!!notifications && <span className="navItem_notifications">{notifications}</span>}
                        </span>
                    </span>
                </ConditionalWrapper>
            )}
            {!!items?.length && (
                <motion.div
                    initial="collapsed"
                    animate={isExpanded ? 'open' : 'collapsed'}
                    transition={transition}
                    variants={variants}
                    className="navItem_children">
                    <ul>
                        {items.map(child => (
                            <li key={uid(child)}>
                                <SubItem
                                    href={child.href}
                                    hrefAs={child.hrefAs}
                                    title={child.title}
                                    onClick={e => handleSubItemClick(e, child)}
                                />
                            </li>
                        ))}
                    </ul>
                </motion.div>
            )}
        </ItemStyled>
    )
}

const itemVariants = Object.freeze({
    DEFAULT: 'default',
    BUTTON: 'button'
})

const colorVariants = Object.freeze({
    DEFAULT: 'default',
    SUBTLE: 'subtle',
    TERTIARY: 'tertiary'
})

Item.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(itemVariants)]),
    colorVariant: PropTypes.oneOf([...Object.values(colorVariants)]),
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.oneOfType([() => null, PropTypes.object]),
    action: PropTypes.oneOfType([() => null, PropTypes.func]),
    onClick: PropTypes.func,
    isSubtle: PropTypes.bool,
    isThin: PropTypes.bool,
    isPrimaryNavigationItem: PropTypes.bool,
    onClickEventTracking: PropTypes.func,
    expanded: PropTypes.bool,
    notifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    activeTriggers: PropTypes.arrayOf(PropTypes.string)
}

Item.defaultProps = {
    className: undefined,
    variant: itemVariants.DEFAULT,
    colorVariant: colorVariants.DEFAULT,
    href: undefined,
    hrefAs: undefined,
    icon: undefined,
    title: undefined,
    items: undefined,
    onClick: () => {},
    action: undefined,
    isSubtle: false,
    isThin: false,
    expanded: false,
    isPrimaryNavigationItem: false,
    onClickEventTracking: undefined,
    notifications: undefined,
    activeTriggers: undefined
}

export { itemVariants, colorVariants }

export default withErrorBoundary(Item)
