// import { zIndex } from '@hmn/coolinarika-web-ui/theme'

const style = ({ theme }) => ({
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
    /**
     * @PERFORMANCE: Commented out because backgroundAttachment: 'fixed' here caused a lot of performance & re-rendering issues.
     * This entire style has been moved to GlobalStyles.style.js instead.
     */
    /* '& > main': {
        backgroundColor: theme.colors.background.body.default,
        backgroundImage: 'url(/images/background/body/main.png)',
        backgroundRepeat: 'repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'top center'
    } */
    // commented out because of an issue with drawer component inside page, do not know what issues will it cause
    // zIndex: zIndex.baseLayout.content
})

export default style
