// Third party imports
import PropTypes from 'prop-types'

const ConditionalWrapper = ({ condition, Wrapper, children, ...rest }) =>
    condition ? <Wrapper {...rest}>{children}</Wrapper> : <>{children}</>

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    Wrapper: PropTypes.elementType.isRequired
}

export default ConditionalWrapper
