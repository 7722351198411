const constants = {
    SHOW: 'HMN/COOLINARIKA/WEB/NOTIFICATIONS/SHOW_NOTIFICATION',
    HIDE: 'HMN/COOLINARIKA/WEB/NOTIFICATIONS/HIDE_NOTIFICATION'
}

const actions = {
    show: notification => ({ type: constants.SHOW, notification }),
    hide: notification => ({ type: constants.HIDE, notification })
}

export { constants as NotificationActionTypes, actions as NotificationActions }
