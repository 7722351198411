import { decorationBackgroundSVG, responsive, toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, largeLineHeight }) => ({
    overflow: 'hidden',
    position: 'relative',
    '.headingSectionMain': {
        '&_logoContainer': {
            ...responsive([
                { marginBottom: toRem(20) },
                { marginBottom: toRem(25) },
                { marginBottom: toRem(30) },
                { marginBottom: toRem(35) }
            ]),
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            }
        },
        '&_header': {
            display: 'flex',
            alignItems: 'center'
        },
        '&_label': {
            display: 'flex',
            marginBottom: toRem(10)
        },
        '&Header_title': {
            display: 'inline-flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'relative',
            width: 'auto',
            ...(largeLineHeight && {
                lineHeight: 1.2
            }),
            '& .userInfo_username': {
                fontSize: 'inherit'
            }
        },
        '&_body': {
            display: 'flex',
            marginTop: toRem(15),
            [theme.breakpoints.up('md')]: {
                maxWidth: toRem(750),
                marginTop: toRem(30)
            }
        },
        '&_wave': {
            '&.primary': {
                display: 'none',
                [theme.breakpoints.up('lg')]: {
                    position: 'relative',
                    display: 'flex',
                    flex: 1,
                    top: toRem(5),
                    minWidth: toRem(100),
                    marginLeft: toRem(20),
                    width: '100%',
                    height: toRem(5),
                    backgroundColor: 'transparent',
                    backgroundImage: decorationBackgroundSVG(theme.colors.separator.default),
                    backgroundRepeat: 'repeat-x',
                    backgroundPosition: '0 center',
                    backgroundSize: 'contain'
                }
            },
            '&.secondary': {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'inline-flex',
                    width: toRem(62),
                    padding: toRems([15, 15, 0, 0]),
                    svg: {
                        width: toRem(37),
                        height: toRem(5),
                        path: {
                            fill: 'currentColor'
                        }
                    }
                }
            }
        }
    }
})

export default style
