/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { globalSettings } from '../settings'

const { constants } = globalSettings
const { IMAGES_DIMENSION_TOLERANCE, IMAGES_RATIO_TOLERANCE } = constants

/**
 * Calculates image ratio from provided initial values or derives the ratio from config variations.
 *
 * @param {*} imageRatio
 * @param {*} variations
 * @param {*} initialWidth
 * @param {*} initialHeight
 * @return {*}
 */
const getImageRatio = (imageRatio, variations, initialWidth, initialHeight) => {
    const initialRatioFallback = parseInt(initialWidth, 10) / parseInt(initialHeight, 10) || 1

    if (imageRatio && typeof imageRatio !== 'string' && !Number.isNaN(imageRatio)) {
        // If provided initial ratio is number, we can use it right away
        return imageRatio
    }

    // Else Try and derive the image ratio from string, if provided, e.g. "4-3-35mm" or "4:3" or "1.3" should become 1.3333333
    if (imageRatio && typeof imageRatio === 'string' && !imageRatio?.toLowerCase().includes('original')) {
        return (
            variations.find(
                ({ aspect_ratio_name, aspect_ratio_slug }) =>
                    (aspect_ratio_name && aspect_ratio_name.toLowerCase().includes(imageRatio?.toLowerCase())) ||
                    (aspect_ratio_slug && aspect_ratio_slug.includes(imageRatio?.toLowerCase()))
            )?.aspect_ratio_value || initialRatioFallback
        )
    }

    if (imageRatio?.toLowerCase().includes('original')) {
        // If we're using original aspect ratio, we have to return false and derive it from image instance later
        return false
    }

    // Else return fallback
    return initialRatioFallback
}

/**
 * Derives optimal image instance ID
 *
 * @param {*} instances
 * @param {*} width
 * @param {*} height
 * @return {*}
 */
const getImageInstance = (instances, width, height) => {
    let optimalInstance
    let optimalInstanceId

    const instanceWidth = parseInt(`${width}`.replace(/[^0-9]+/g, ''), 10)

    if (!Number.isNaN(parseFloat(instanceWidth))) {
        optimalInstance = instances?.find(
            variationInstance => Math.abs(variationInstance.max_width - instanceWidth) <= IMAGES_DIMENSION_TOLERANCE
        )
    } else if (!Number.isNaN(parseFloat(height))) {
        optimalInstance = instances?.find(
            variationInstance => Math.abs(variationInstance.max_height - height) <= IMAGES_DIMENSION_TOLERANCE
        )
    }

    if (optimalInstance && Object.keys(optimalInstance).length) {
        // eslint-disable-next-line no-extra-semi
        ;({ id: optimalInstanceId } = optimalInstance)
    }

    return {
        optimalInstanceId
    }
}

/**
 * Get image url.
 *
 * @param {string|number} imageId
 * @param {('4:3','1:1','21:9','25:9','2:3','original')} ratio
 * @param {number} width
 * @param {number} height
 * @param {('jpg'|'webp')} type
 * @return {string|null}
 *
 * ```js
 * import { useImageUrl } from '@hmn/coolinarika-web-core/hooks'
 * const imageUrl = useImageUrl(1, 'original', 1000, undefined, 'jpg')
 * ```
 */
const useImageURL = (imageId, ratio = 'original', width = undefined, height = undefined, type = undefined) => {
    const { imagesConfig, isLoaded } = useSelector(state => state.settings)

    return useMemo(() => {
        // isLoading check is removed becouse is not avaliable in ssr
        // Aditional check for missing image variants is added
        if (imageId) {
            const { variations } = imagesConfig
            const imageRatio = getImageRatio(ratio, variations, width, height)

            // When we have ratio, frist try to find variation using the exact ratio
            let imageVariation = variations.find(configVariation => configVariation.aspect_ratio_value === imageRatio)

            // If no exact variation is found,  try and find image variation within configured ratio tolerance
            if (!imageVariation) {
                imageVariation = variations.find(
                    ({ aspect_ratio_value }) =>
                        aspect_ratio_value && Math.abs(aspect_ratio_value - imageRatio) <= IMAGES_RATIO_TOLERANCE
                )
            }

            // If we still don't have our variation, e.g. if no variation matches aspect ratio value, use first available fill variation or just use first variation
            if (!imageVariation) {
                imageVariation = variations.find(({ method }) => method === 'fill')?.[0] || variations[0]
            }

            // This replaces removed isLoaded check at the top
            if (!imageVariation) {
                return null
            }

            const { instances = [], formats, url_template } = imageVariation

            let optimalInstance
            const { optimalInstanceId } = getImageInstance(instances, width, height)
            if (optimalInstanceId) {
                optimalInstance = instances.find(instance => instance.id === optimalInstanceId)
            } else {
                optimalInstance = instances[instances.length - 1]
            }

            const { max_height, max_width, id: instanceId } = optimalInstance

            const replaceAll = (urlTemplate, replaceMap) => {
                const re = new RegExp(Object.keys(replaceMap).join('|'), 'gi')

                return urlTemplate.replace(re, matched => replaceMap[matched.toLowerCase()])
            }

            const instanceFormat = formats?.find(
                ({ label, type: instanceType, extension }) =>
                    type &&
                    typeof type === 'string' &&
                    type?.length &&
                    (instanceType.includes(type) || type.includes(extension) || type.includes(label.toLowerCase()))
            )?.extension

            const urlReplacements = {
                'https://podravkaiovariations.blob.core.windows.net': 'https://podravkaiovariations.azureedge.net',
                '{image_id}': imageId,
                '{instance_max_width}': max_width,
                '{instance_max_height}': max_height,
                '{instance_id}': instanceId,
                '{format_extension}': instanceFormat || formats?.[0]?.extension || 'jpeg'
            }

            return replaceAll(url_template, urlReplacements)
        }
        return null
    }, [imageId, ratio, width, height, type, imagesConfig, isLoaded])
}

const useCreateImageURL = () => {
    const { imagesConfig, isLoaded } = useSelector(state => state.settings)

    return useCallback(
        (imageId, ratio, width, height, type) => {
            if (imageId && isLoaded) {
                const { variations } = imagesConfig

                const imageRatio = getImageRatio(ratio, variations, width, height)

                // When we have ratio, frist try to find variation using the exact ratio
                let imageVariation = variations.find(
                    configVariation => configVariation.aspect_ratio_value === imageRatio
                )

                // If no exact variation is found,  try and find image variation within configured ratio tolerance
                if (!imageVariation) {
                    imageVariation = variations.find(
                        ({ aspect_ratio_value }) =>
                            aspect_ratio_value && Math.abs(aspect_ratio_value - imageRatio) <= IMAGES_RATIO_TOLERANCE
                    )
                }

                // If we still don't have our variation, e.g. if no variation matches aspect ratio value, use first available fill variation or just use first variation
                if (!imageVariation) {
                    imageVariation = variations.find(({ method }) => method === 'fill')?.[0] || variations[0]
                }

                const { instances = [], formats, url_template } = imageVariation

                let optimalInstance
                const { optimalInstanceId } = getImageInstance(instances, width, height)
                if (optimalInstanceId) {
                    optimalInstance = instances.find(instance => instance.id === optimalInstanceId)
                } else {
                    optimalInstance = instances[instances.length - 1]
                }

                const { max_height, max_width, id: instanceId } = optimalInstance

                const replaceAll = (urlTemplate, replaceMap) => {
                    const re = new RegExp(Object.keys(replaceMap).join('|'), 'gi')

                    return urlTemplate.replace(re, matched => replaceMap[matched.toLowerCase()])
                }

                const instanceFormat = formats?.find(
                    ({ label, type: instanceType, extension }) =>
                        type &&
                        typeof type === 'string' &&
                        type?.length &&
                        (instanceType.includes(type) || type.includes(extension) || type.includes(label.toLowerCase()))
                )?.extension

                const urlReplacements = {
                    'https://podravkaiovariations.blob.core.windows.net': 'https://podravkaiovariations.azureedge.net',
                    '{image_id}': imageId,
                    '{instance_max_width}': max_width,
                    '{instance_max_height}': max_height,
                    '{instance_id}': instanceId,
                    '{format_extension}': instanceFormat || formats?.[0]?.extension || 'jpeg'
                }

                return replaceAll(url_template, urlReplacements)
            }

            return null
        },
        [imagesConfig, isLoaded]
    )
}

export { useCreateImageURL }

export default useImageURL
