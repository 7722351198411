import { forwardRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { InfiniteLoadTrigger, lg, useBreakpoints } from '@hmn/coolinarika-web-core/hooks'

import { Column, Row } from '../../../../Layout'
import { SearchNoResults } from '../../NoResults'
import { SearchResultItem } from '../../ResultItem'
import { SearchResultTitle } from '../../ResultsTitle'
import styles, { searchResultsPanelVariant } from './SearchResults.style'

const selector = ({ search, filters }) => ({
    isSearchOpen: search.isOpen,
    isFiltersOpen: filters.isOpen
})

const SearchResultsStyled = styled.div(props => ({ ...styles(props) }))

const SearchResults = forwardRef(
    (
        {
            className,
            children,
            searchResultsTitle,
            showResultsPanel,
            showNoResults,
            scrollTargetId,
            data,
            onClick,
            ...rest
        },
        ref
    ) => {
        const uuid = useUIDSeed()
        const { isSearchOpen, isFiltersOpen } = useSelector(selector, shallowEqual)
        const [isDesktopView] = useBreakpoints(lg)

        return (
            <SearchResultsStyled
                className={className}
                isFiltersOpen={isDesktopView || (isFiltersOpen && !isDesktopView)}
                {...rest}>
                <motion.div
                    className="searchResults_panel"
                    initial="closed"
                    animate={isSearchOpen && showResultsPanel ? 'open' : 'closed'}
                    variants={searchResultsPanelVariant}>
                    <div className="searchResults_panelBody" id={scrollTargetId}>
                        <Row variant="search.panel.body">
                            <Column variant="search.panel.title" className="sale" disabled={!isDesktopView}>
                                <SearchResultTitle title={searchResultsTitle} titleDecoration headingSize="NORMAL" />
                            </Column>
                            <Column variant="search.panel.results">
                                {!showNoResults ? (
                                    <div className="reactiveSearch_resultsWrap">
                                        {data?.map((result, index) => (
                                            // eslint-disable-next-line react/jsx-indent
                                            <SearchResultItem
                                                onClick={onClick}
                                                key={uuid(`${result?.id}-${index}`)}
                                                data={result}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <SearchNoResults
                                        isInSearchPanel
                                        title="Ups, nismo pronašli niti jedan recept :/"
                                        text="Ovo se rijetko događa. Žao nam je! Probaj potražiti nešto drugo."
                                    />
                                )}
                            </Column>
                        </Row>
                        <InfiniteLoadTrigger ref={ref} />
                    </div>
                </motion.div>
            </SearchResultsStyled>
        )
    }
)

SearchResults.propTypes = {
    className: PropTypes.string,
    searchResultsTitle: PropTypes.string,
    showNoResults: PropTypes.bool,
    scrollTargetId: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape),
    onClick: PropTypes.func,
    showResultsPanel: PropTypes.bool
}

SearchResults.defaultProps = {
    className: undefined,
    searchResultsTitle: undefined,
    showNoResults: false,
    scrollTargetId: undefined,
    data: undefined,
    onClick: undefined,
    showResultsPanel: false
}

export default SearchResults
