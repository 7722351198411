import { HYDRATE } from 'next-redux-wrapper'
import { combineReducers } from 'redux'

import { collectionsReducer } from './collections'
import { drawersReducer } from './drawer'
import { filtersReducer } from './filters'
import { modalsReducer } from './modal'
import { navigationReducer } from './navigation'
import { navigationAccountReducer } from './navigationAccount'
import { notificationsReducer } from './notifications'
import { recipeUploaderReducer } from './recipeUploader'
import { searchReducer } from './search'
import { settingsReducer } from './settings'
import { tasteProfileReducer } from './tasteProfile'

const combinedReducer = combineReducers({
    navigation: navigationReducer,
    navigationAccount: navigationAccountReducer,
    search: searchReducer,
    filters: filtersReducer,
    modals: modalsReducer,
    collections: collectionsReducer,
    drawers: drawersReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    recipeUploader: recipeUploaderReducer,
    tasteProfile: tasteProfileReducer
})

/**
 * Each time when pages that have getStaticProps or getServerSideProps are opened by user the HYDRATE action will be dispatched.
 * This may happen during initial page load and during regular page navigation.
 *
 * The payload of this action will contain the state at the moment of static generation or server side rendering,
 * so our reducer must merge it with existing client state properly.
 */
const rootReducer = (state = {}, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...action.payload, // use previous state
            ...state // apply delta from hydration,
        }

        // If we already retrieved settings on server side, reuse them for client side as well, preserve settings
        // This also preserves previous client side state when calling page with getServerSideProps
        if (action.payload.settings && action.payload.settings.isLoaded) {
            action.payload.settings.isLoaded = false

            nextState.settings = {
                ...action.payload.settings
            }
        }

        return nextState
    }
    return combinedReducer(state, action)
}

export { rootReducer }

export * from './navigation'
export * from './navigationAccount'
export * from './search'
export * from './filters'
export * from './modal'
export * from './collections'
export * from './drawer'
export * from './settings'
export * from './notifications'
export * from './recipeUploader'
