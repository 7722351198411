/* eslint-disable camelcase */
// @TODO: Edit button functionality
// @TODO: Check if edit is available for the logged in user (user or admin)

import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import cardVariationHandler from '@hmn/coolinarika-web-core/helpers/card'
import excerpt from '@hmn/coolinarika-web-core/helpers/excerpt'
import { md, useBreakpoints, useUser } from '@hmn/coolinarika-web-core/hooks'

import { ParallaxBanner } from '../../../Banner/components/Parallax'
import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { Card, cardRoundedVariants } from '../../../Card'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { DefaultFallback, withErrorBoundary } from '../../../ErrorBoundary'
import {
    HeadingLabel,
    headingLabelSizes,
    headingLabelVariants,
    HeadingSection,
    headingSectionSizes
} from '../../../Heading/components'
import { Highlight, highlightSizes } from '../../../Highlight'
import { EditIcon, Icon } from '../../../Icon'
import { Image } from '../../../Image'
import styles from './Card.style'

const ArticleCardStyled = styled.div(props => ({ ...styles(props) }))

const articleLabelVariants = Object.freeze({
    PRIMARY: 'TERTIARY', // white
    SECONDARY: 'SECONDARY' // yellow
})

const feedItemLabels = Object.freeze({
    DEFAULT: 'Članak',
    GIVEAWAY: 'Nagradni natječaj',
    NATIVE: 'Izdvojeno'
})

const articleCardVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '2x1': '2x1',
    '2x2': '2x2'
})

const ArticleCard = ({
    item,
    imageId: propImageId,
    imageWidth,
    imageHeight,
    itemId: propId,
    label,
    title: propTitle,
    titleTagComponent: TitleTag,
    lead: propLead,
    href,
    hrefAs,
    authorId: propAuthorId,
    className,
    variant,
    labelVariant,
    overrideImagePadding,
    fullHeight,
    isParallaxed,
    isFeedArticle,
    currentCategoryListing,
    noMinHeight,
    ...rest
}) => {
    const [isMobileUp] = useBreakpoints(md)

    const { id: loggedInUserId } = useUser()

    const {
        _id = propId,
        author,
        owned_by_user_profile,
        title: itemTitle,
        lead = propLead,
        image: itemImage,
        taxons,
        extended_attributes
    } = useMemo(() => item || {}, [item])
    const { id: userProfileId = propAuthorId } = owned_by_user_profile || {}
    const { id: authorId = userProfileId } = author || {}
    const { id: imageId = propImageId } = itemImage || {}

    const title = propTitle || itemTitle

    const isGiveaway = taxons?.['coolinarika-flags']?.some(({ slug }) => slug === 'flag-giveaway')
    const isNagradniNatjecaj = taxons?.['coolinarika-article-category']?.some(
        ({ slug }) => slug === 'nagradni-natjecaji'
    )
    const isSecondaryLabel = isGiveaway || extended_attributes?.native || extended_attributes?.promoted

    const getLabel = () => {
        if (isFeedArticle) {
            if (isGiveaway) {
                return feedItemLabels.GIVEAWAY
            }

            if (extended_attributes?.native || extended_attributes?.promoted) {
                return feedItemLabels.NATIVE
            }

            return feedItemLabels.DEFAULT
        }

        if (!currentCategoryListing) {
            return taxons?.['coolinarika-article-category']?.[0]?.title
        }

        if (currentCategoryListing && currentCategoryListing?.toLowerCase() === 'korisnici') {
            return author?.display_name
        }

        return null
    }

    const getLabelVariant = () => {
        if (isFeedArticle && isSecondaryLabel) {
            return headingLabelVariants.SECONDARY
        }

        return headingLabelVariants[labelVariant]
    }

    const edit = authorId && loggedInUserId && authorId === loggedInUserId

    const handleEditClick = useCallback(() => {
        // placeholder function for profile edit button
        /* eslint-disable-next-line */
        alert('TODO: Edit button click action.')
    }, [])

    const cardActions = useCallback(
        () =>
            edit ? (
                <Button
                    variant={buttonVariants.SECONDARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title="Uredi"
                    onClick={handleEditClick}>
                    <Icon icon={EditIcon} size={24} />
                </Button>
            ) : null,
        [edit, handleEditClick]
    )

    const articleLabelSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': headingLabelSizes.SMALL,
                '1x2': headingLabelSizes.SMALL,
                '2x1': headingLabelSizes.SMALL,
                '2x2': headingLabelSizes[isMobileUp ? 'LARGER' : 'SMALL']
            }),
        [isMobileUp]
    )

    const articleHighlightSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': highlightSizes[isMobileUp ? 'SMALL' : 'NORMAL'],
                '1x2': highlightSizes[isMobileUp ? 'NORMAL' : 'LARGE'],
                '2x1': highlightSizes[isMobileUp ? 'NORMAL' : 'SMALL'],
                '2x2': highlightSizes[isMobileUp ? 'LARGE' : 'NORMAL']
            }),
        [isMobileUp]
    )

    const HighLightContent = useCallback(() => {
        let template = null

        if (variant !== articleCardVariants['2x1']) {
            template = (
                <Highlight
                    size={articleHighlightSizeVariants[variant]}
                    TagComponent={TitleTag}
                    className="cardContent_title">
                    {title}
                </Highlight>
            )
        } else if (variant === articleCardVariants['2x1']) {
            template = (
                <>
                    <Media lessThan="md">
                        <Highlight
                            size={articleHighlightSizeVariants[variant]}
                            TagComponent={TitleTag}
                            className="cardContent_title">
                            {title}
                        </Highlight>
                    </Media>
                    <Media greaterThan="sm">
                        <HeadingSection
                            size={headingSectionSizes.SMALLER}
                            className="cardContent_title cardPicture_title">
                            {title}
                        </HeadingSection>
                    </Media>
                </>
            )
        } else {
            template = (
                <HeadingSection size={headingSectionSizes.SMALLER} className="cardContent_title cardPicture_title">
                    {title}
                </HeadingSection>
            )
        }

        return template
    }, [title, isMobileUp])

    if (!title) {
        return null
    }

    return (
        <Card
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            actionComponent={cardActions}
            rounded={cardRoundedVariants.MEDIUM}
            {...rest}>
            <ArticleCardStyled
                variant={variant}
                articleCardVariants={articleCardVariants}
                overlay={!!lead}
                overrideImagePadding={overrideImagePadding}
                noMinHeight={noMinHeight}>
                <ConditionalWrapper condition={isParallaxed} Wrapper={ParallaxBanner} height={imageHeight * 1.7}>
                    <Image
                        classNameProgressive="cardPicture"
                        fullHeight={fullHeight} // @TODO: Fix, ovdje treba 1x2 varijacija slike ako je card 1x2 dimenzija
                        image={imageId}
                        placeholderBgColor={
                            itemImage?.colors?.[itemImage?.colors?.length - 1]?.hex || rest?.placeholderBgColor
                        }
                        alt={title}
                        ratio={cardVariationHandler(variant)}
                        height={imageHeight}
                        width={imageWidth}
                    />
                </ConditionalWrapper>
                {(title || label || getLabel()) && (
                    <div className="cardContent">
                        <HeadingLabel
                            className="cardContent_label"
                            label={isNagradniNatjecaj ? 'Nagradni natječaj' : label || getLabel()}
                            variant={getLabelVariant()}
                            size={articleLabelSizeVariants[variant]}
                            isUppercase={headingLabelVariants.SECONDARY === getLabelVariant()}
                        />
                        {title && <HighLightContent />}
                        {lead &&
                            [
                                articleCardVariants['2x1'],
                                articleCardVariants['2x2'],
                                articleCardVariants['1x2']
                            ].includes(variant) && (
                                <Media greaterThan="sm">
                                    <div className="cardLead">{excerpt(lead, true)}</div>
                                </Media>
                            )}
                    </div>
                )}
            </ArticleCardStyled>
        </Card>
    )
}

ArticleCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    imageId: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemId: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    authorId: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleCardVariants)]),
    labelVariant: PropTypes.oneOf([...Object.values(articleLabelVariants)]),
    fullHeight: PropTypes.bool,
    overrideImagePadding: PropTypes.number,
    isParallaxed: PropTypes.bool,
    isFeedArticle: PropTypes.bool,
    currentCategoryListing: PropTypes.string,
    noMinHeight: PropTypes.bool
}

ArticleCard.defaultProps = {
    item: undefined,
    imageId: undefined,
    imageWidth: 390,
    imageHeight: 360,
    itemId: undefined,
    label: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    lead: undefined,
    href: undefined,
    hrefAs: undefined,
    authorId: undefined,
    className: undefined,
    variant: articleCardVariants['1x1'],
    labelVariant: articleLabelVariants.SECONDARY,
    fullHeight: false,
    overrideImagePadding: undefined,
    isParallaxed: false,
    isFeedArticle: false,
    currentCategoryListing: undefined,
    noMinHeight: false
}

export { articleCardVariants, articleLabelVariants }

export default withErrorBoundary(ArticleCard, {
    FallbackComponent: err => <DefaultFallback err={err} />
})
