/**
 * @TODO: This HOC is only used due to Reflebox' ThemeProvider incompatibility with
 * Coolinarika's core ThemeProvider and various Emotion major version differences.
 *
 * We should keep track of this pull request and update when it's released:
 *  * https://github.com/rebassjs/rebass/pull/1051/files
 */
import { withTheme } from '@emotion/react'
import PropTypes from 'prop-types'
import { Flex } from 'theme-ui'

const Row = ({ children, theme, disabled, ...rest }) => {
    if (disabled) {
        return null
    }

    return (
        <Flex theme={theme.layoutTheme} {...rest}>
            {children}
        </Flex>
    )
}

Row.propTypes = {
    disabled: PropTypes.bool
}

Row.defaultProps = {
    disabled: false
}

export default withTheme(Row)
