// @TODO: Setup predefined fontSizes within theme and allow for easier selection, e.g. "small", "large", etc.

import { isColor, toRem } from '../../../../helpers'

const style = ({ theme, color, size }) => ({
    display: 'inline-flex',
    verticalAlign: 'middle',
    width: '1em',
    height: '1em',
    userSelect: 'none',
    flexShrink: 0,
    fontSize: size !== 'inherit' ? toRem(size) : size,
    path: {
        fill: isColor(color) ? color : theme.colors[color]
    }
})

export default style
