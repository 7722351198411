/* eslint-disable camelcase */
// @TODO: This component will be a work in progress for a long time
// @TODO: Edit button functionality
// @TODO: Check if edit is available for the logged in user (user or admin)
// @TODO: Rethink how to pass down recipe of the day date + username inside HeadingLabel. It's naming challenge :).
//  Rewrite if necessary when real data is available with new/better name for the prop

import { memo, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import cardVariationHandler from '@hmn/coolinarika-web-core/helpers/card'
import excerpt from '@hmn/coolinarika-web-core/helpers/excerpt'
import { md, useAddRecipeToFavorites, useBreakpoints, useUser } from '@hmn/coolinarika-web-core/hooks'

import 'dayjs/locale/hr'
import { ParallaxBanner } from '../../../Banner/components/Parallax'
import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { Card, cardRoundedVariants } from '../../../Card'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { HeadingLabel, headingLabelSizes, headingLabelVariants } from '../../../Heading/components'
import { Highlight, highlightSizes } from '../../../Highlight'
import { ArrowSmallIcon, EditIcon, HeartFillIcon, HeartOutlineIcon, Icon } from '../../../Icon'
import { Image } from '../../../Image'
import styles from './Card.style'

const RecipeCardStyled = styled.div(props => ({ ...styles(props) }))

dayjs.locale('hr')
dayjs.extend(relativeTime)

const recipeCardVariants = Object.freeze({
    '1x1': '1x1',
    '2x2': '2x2',
    SEEALL: 'SEEALL'
})

const RecipeCard = ({
    item,
    id: propId,
    imageId: propImageId,
    imageWidth,
    imageHeight,
    label,
    userNameWithTimeLabel,
    usernameOnlyLabel,
    date,
    title: propTitle,
    titleTagComponent: TitleTag,
    lead: propLead,
    href,
    hrefAs,
    authorId: propAuthorId,
    authorName: propAuthorName,
    className,
    variant,
    fullHeight,
    rounded,
    actionComponent: Action,
    showFavoritedOnHover,
    seeAllTitle,
    seeAllCount,
    noLabel,
    isParallaxed,
    onCardClick,
    noMinHeight,
    isEmbedded,
    isCoolfoodRecipe,
    onClickEventTracking,
    onViewEventTracking,
    ...rest
}) => {
    const { isFavorited, handleAddToFavorites } = useAddRecipeToFavorites({ recipe: item })
    const [isMobileUp] = useBreakpoints(md)

    const {
        author,
        owned_by_user_profile_id,
        title: itemTitle,
        lead = propLead,
        image: itemImage
    } = useMemo(() => item || {}, [])
    const { id: userProfileId = propAuthorId } = owned_by_user_profile_id || {}
    const { id: authorId = userProfileId } = author || {}
    const { id: imageId = propImageId } = itemImage || {}
    const { id: loggedInUserId } = useUser()
    const showFavorited = !!loggedInUserId

    // https://day.js.org/docs/en/i18n/loading-into-browser
    const recipeCreatedFromNow =
        userNameWithTimeLabel && item?.created_at ? dayjs(item?.created_at).fromNow() : undefined

    const title = propTitle || itemTitle
    const authorUserName = propAuthorName || item?.author?.display_name
    const cardLabel = `${label || authorUserName}${recipeCreatedFromNow ? ', ' : ''}`

    const recipeOfTheDayListingLabel = date ? `${dayjs(date).format('DD.MM.')} by` : undefined

    // @TODO: get edit rights fo user and set `edit`to true if logged in user has edit rights (user or admin with edit
    // rights)
    const edit = !!(authorId && loggedInUserId && authorId === loggedInUserId)

    const handleEditClick = () => {
        // placeholder function for profile edit button
        /* eslint-disable-next-line */
        alert('TODO: Edit button click action.')
    }

    const recipeLabelSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': headingLabelSizes.SMALL,
                '2x2': headingLabelSizes[isMobileUp ? 'LARGER' : 'SMALL']
            }),
        [isMobileUp]
    )

    const recipeHighlightSizeVariants = useMemo(
        () =>
            Object.freeze({
                '1x1': highlightSizes[isMobileUp ? 'SMALL' : 'NORMAL'],
                '2x2': highlightSizes[isMobileUp ? 'LARGE' : 'NORMAL']
            }),
        [isMobileUp]
    )

    const cardActions = useCallback(() => {
        if (edit) {
            return (
                <Button
                    variant={buttonVariants.SECONDARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title="Uredi"
                    onClick={handleEditClick}>
                    <Icon icon={EditIcon} size={24} />
                </Button>
            )
        }
        if (Action) {
            return <Action />
        }
        if (showFavorited) {
            return (
                <Button
                    variant={buttonVariants.PRIMARY}
                    size={buttonSizes.SMALL}
                    isIconOnly
                    title={isFavorited ? 'Uredi' : 'Spremi'}
                    onClick={handleAddToFavorites}>
                    <Icon icon={isFavorited ? HeartFillIcon : HeartOutlineIcon} size={32} />
                </Button>
            )
        }
        return null
    }, [
        item,
        edit,
        handleEditClick,
        EditIcon,
        Action,
        showFavorited,
        isFavorited,
        handleAddToFavorites,
        HeartFillIcon,
        HeartOutlineIcon
    ])

    const DefaultContent = useCallback(
        () => (
            <>
                {!!(title || label || authorUserName) && (
                    <div className="cardContent">
                        {!noLabel && !!(label || authorUserName) && !usernameOnlyLabel && (
                            <HeadingLabel
                                className="cardContent_label"
                                prefix={recipeOfTheDayListingLabel}
                                label={cardLabel}
                                suffix={recipeCreatedFromNow}
                                variant={headingLabelVariants.TERTIARY}
                                size={recipeLabelSizeVariants[variant]}
                            />
                        )}

                        {usernameOnlyLabel && (
                            <HeadingLabel
                                className="cardContent_label"
                                label={authorUserName}
                                variant={headingLabelVariants.TERTIARY}
                                size={recipeLabelSizeVariants[variant]}
                            />
                        )}

                        {!!title && (
                            <Highlight
                                size={recipeHighlightSizeVariants[variant]}
                                TagComponent={TitleTag}
                                hasHover
                                className="cardContent_title">
                                {title}
                            </Highlight>
                        )}
                        {!!lead && variant === recipeCardVariants['2x2'] && (
                            <Media greaterThan="sm">
                                <div className="cardLead">{excerpt(lead, true)}</div>
                            </Media>
                        )}
                    </div>
                )}
            </>
        ),
        [
            title,
            label,
            noLabel,
            authorUserName,
            recipeOfTheDayListingLabel,
            cardLabel,
            recipeCreatedFromNow,
            headingLabelVariants,
            recipeLabelSizeVariants,
            lead
        ]
    )

    const SeeAllContent = useCallback(
        () => (
            <div className="cardContent_seeAll">
                {seeAllCount && (
                    <div className="cardContent_circle">
                        <div className="cardContent_circleInner">{seeAllCount}</div>
                    </div>
                )}
                {seeAllTitle && (
                    <p className="cardContent_seeAllTitle">
                        {seeAllTitle} <Icon icon={ArrowSmallIcon} size={24} />
                    </p>
                )}
            </div>
        ),
        [seeAllCount, seeAllTitle, ArrowSmallIcon]
    )

    if (!title) {
        return null
    }

    return (
        <Card
            onClick={onCardClick}
            title={title}
            href={href}
            hrefAs={hrefAs}
            className={className}
            fullHeight={fullHeight}
            actionComponent={cardActions}
            rounded={cardRoundedVariants.MEDIUM}
            showFavoritedOnHover={showFavoritedOnHover}
            noMinHeight={noMinHeight}
            isEmbedded={isEmbedded}
            item={item}
            onClickEventTracking={onClickEventTracking}
            onViewEventTracking={onViewEventTracking}
            {...rest}>
            <RecipeCardStyled variant={variant} overlay={!!lead} noMinHeight={noMinHeight}>
                <ConditionalWrapper condition={isParallaxed} Wrapper={ParallaxBanner} height={imageHeight * 1.5}>
                    <Image
                        className="cardPicture"
                        fullHeight={fullHeight}
                        image={imageId}
                        placeholderBgColor={
                            itemImage?.colors?.[itemImage?.colors?.length - 1]?.hex || rest?.placeholderBgColor
                        }
                        alt={title}
                        ratio={cardVariationHandler(variant)}
                        height={imageHeight}
                        width={imageWidth}
                        rounded={rounded}
                    />
                    {isCoolfoodRecipe && (
                        <div className="cardPicture_stampCoolFood">
                            <Image
                                width={130}
                                height={130}
                                image="/images/coolfood/slatka-lica-slastica/cool-food-white-stamp.svg"
                            />
                        </div>
                    )}
                </ConditionalWrapper>

                {variant !== recipeCardVariants.SEEALL ? <DefaultContent /> : <SeeAllContent />}
            </RecipeCardStyled>
        </Card>
    )
}

RecipeCard.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    authorId: PropTypes.string,
    usernameOnlyLabel: PropTypes.bool,
    authorName: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(recipeCardVariants)]),
    fullHeight: PropTypes.bool,
    rounded: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    actionComponent: PropTypes.elementType,
    showFavoritedOnHover: PropTypes.bool,
    seeAllTitle: PropTypes.string,
    seeAllCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userNameWithTimeLabel: PropTypes.bool,
    noLabel: PropTypes.bool,
    isParallaxed: PropTypes.bool,
    onCardClick: PropTypes.func,
    noMinHeight: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    isCoolfoodRecipe: PropTypes.bool,
    onClickEventTracking: PropTypes.func,
    onViewEventTracking: PropTypes.func
}

RecipeCard.defaultProps = {
    item: undefined,
    id: undefined,
    imageId: undefined,
    imageWidth: 390,
    imageHeight: 360,
    label: undefined,
    date: undefined,
    title: undefined,
    titleTagComponent: 'h3',
    usernameOnlyLabel: false,
    lead: undefined,
    href: undefined,
    hrefAs: undefined,
    authorId: undefined,
    authorName: undefined,
    className: undefined,
    variant: recipeCardVariants['1x1'],
    fullHeight: false,
    rounded: undefined,
    actionComponent: undefined,
    showFavoritedOnHover: false,
    seeAllTitle: undefined,
    seeAllCount: undefined,
    userNameWithTimeLabel: false,
    noLabel: false,
    isParallaxed: false,
    onCardClick: undefined,
    noMinHeight: false,
    isEmbedded: false,
    isCoolfoodRecipe: false,
    onClickEventTracking: undefined,
    onViewEventTracking: undefined
}

export { recipeCardVariants }

export default withErrorBoundary(memo(RecipeCard))
