// @TODO: Better way of handling drawer UIDs (this is crap)

export const drawerContentIds = {
    EDIT_TAG: 'edit-tag',
    ADD_TAG: 'add-tag',
    SURVEY_TASTE_PROFILE: 'taste-profile-survey',
    COLLECTIONS_OVERVIEW: 'collections-overview',
    COLLECTIONS_EDIT: 'collections-edit',
    SURVEY_PROFILE_INFORMATION: 'drawer-survey-profile-information',
    LOGIN: 'drawer-auth-login',
    REGISTER: 'drawer-auth-register',
    RESET_PASSWORD: 'drawer-auth-reset-password',
    NEW_PASSWORD: 'drawer-auth-new-password',
    SETTINGS_AVATAR: 'drawer-edit-settings-avatar',
    SETTINGS_BIOGRAPHY: 'drawer-edit-settings-biography',
    SETTINGS_NEWSLETTER: 'drawer-edit-settings-newsletter',
    SETTINGS_BASIC_INFO: 'drawer-edit-settings-basic-info',
    SETTINGS_OTHER: 'drawer-edit-settings-other',
    SETTINGS_GAMIFY: 'drawer-edit-settings-gamify',
    SETTINGS_PASSWORD: 'drawer-edit-settings-password',
    SETTINGS_PRIVACY: 'drawer-edit-settings-privacy',
    SETTINGS_CONSENT: 'drawer-edit-settings-consent',
    TROPHY_DETAILS: 'trophy-details',
    DISH_DRAWER_SINGLE: 'dish-drawer-single',
    DISH_DRAWER_ALL: 'dish-drawer-all',
    COMMENTS: 'drawer-comments',
    CONTACT: 'drawer-contact',
    IMAGE_UPLOADER_GALLERY: 'drawer-image-uploader-gallery',
    SHOPPING_LIST: 'drawer-shopping-list',
    IMAGE_UPLOADER_EDITOR: 'drawer-image-uploader-editor',
    USER_NOTIFICATIONS: 'drawer-user-notifications',
    UPLOADER_TAGS: 'drawer-recipe-uploader-tags',
    UPLOADER_TAGS_SUCCESS: 'drawer-recipe-uploader-success',
    PHOTO_GALLERY: 'drawer-photo-gallery',
    COOL_FOOD_ENTRY: 'drawer-cool-food-entry'
}
