import { createMedia } from '@artsy/fresnel'

const ExampleAppMedia = createMedia({
    breakpoints: {
        xs: 0,
        sm: 360,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1600,
        bannerSmall: 1360,
        bannerLarge: 1650
    }
})

// Make styles for injection into the header of the page
const mediaStyles = ExampleAppMedia.createMediaStyle()

const { Media, MediaContextProvider } = ExampleAppMedia

export default mediaStyles

export { Media, MediaContextProvider }
