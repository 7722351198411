import { memo } from 'react'
// eslint-disable-next-line
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { drawerContentIds } from './contentId.constants'

const {
    EDIT_TAG,
    ADD_TAG,
    SURVEY_TASTE_PROFILE,
    COLLECTIONS_OVERVIEW,
    COLLECTIONS_EDIT,
    SURVEY_PROFILE_INFORMATION,
    LOGIN,
    REGISTER,
    RESET_PASSWORD,
    NEW_PASSWORD,
    SETTINGS_BASIC_INFO,
    SETTINGS_OTHER,
    SETTINGS_GAMIFY,
    SETTINGS_AVATAR,
    SETTINGS_BIOGRAPHY,
    SETTINGS_NEWSLETTER,
    SETTINGS_PASSWORD,
    SETTINGS_PRIVACY,
    SETTINGS_CONSENT,
    TROPHY_DETAILS,
    DISH_DRAWER_SINGLE,
    DISH_DRAWER_ALL,
    COMMENTS,
    CONTACT,
    IMAGE_UPLOADER_GALLERY,
    SHOPPING_LIST,
    IMAGE_UPLOADER_EDITOR,
    USER_NOTIFICATIONS,
    UPLOADER_TAGS,
    UPLOADER_TAGS_SUCCESS,
    PHOTO_GALLERY,
    COOL_FOOD_ENTRY
} = drawerContentIds

const drawersList = {
    [ADD_TAG]: dynamic(import('@hmn/coolinarika-web-ui/components/Tag/components/Drawers/TagsAdd.component'), {
        ssr: false
    }),
    [EDIT_TAG]: dynamic(import('@hmn/coolinarika-web-ui/components/Tag/components/Drawers/TagEdit.component'), {
        ssr: false
    }),
    [IMAGE_UPLOADER_GALLERY]: dynamic(import('@hmn/coolinarika-web-ui/components/ImageUploader/Gallery.component'), {
        ssr: false
    }),
    [LOGIN]: dynamic(import('@hmn/coolinarika-web-ui/components/Auth/Login.component'), { ssr: false }),
    [SURVEY_TASTE_PROFILE]: dynamic(import('@hmn/coolinarika-web-ui/components/Survey/TasteProfile.component'), {
        ssr: false
    }),
    [COLLECTIONS_OVERVIEW]: dynamic(
        import('@hmn/coolinarika-web-ui/components/Collections/CollectionsDrawer.component'),
        { ssr: false }
    ),
    [COLLECTIONS_EDIT]: dynamic(import('@hmn/coolinarika-web-ui/components/Collections/CollectionsEdit.component'), {
        ssr: false
    }),
    [SURVEY_PROFILE_INFORMATION]: dynamic(
        import('@hmn/coolinarika-web-ui/components/Survey/ProfileInformation.component'),
        { ssr: false }
    ),
    [SETTINGS_BASIC_INFO]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Basic/Basic.component'),
        { ssr: false }
    ),
    [REGISTER]: dynamic(import('@hmn/coolinarika-web-ui/components/Auth/Register.component'), { ssr: false }),
    [RESET_PASSWORD]: dynamic(import('@hmn/coolinarika-web-ui/components/Auth/ResetPassword.component'), {
        ssr: false
    }),
    [NEW_PASSWORD]: dynamic(import('@hmn/coolinarika-web-ui/components/Auth/NewPassword.component'), { ssr: false }),
    [SETTINGS_OTHER]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Other/Other.component'),
        { ssr: false }
    ),
    [SETTINGS_GAMIFY]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Gamify/Gamify.component'),
        { ssr: false }
    ),
    [SETTINGS_AVATAR]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Avatar/Avatar.component'),
        { ssr: false }
    ),
    [SETTINGS_BIOGRAPHY]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Biography/Biography.component'),
        { ssr: false }
    ),
    [SETTINGS_NEWSLETTER]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Newsletter/Newsletter.component'),
        { ssr: false }
    ),
    [SETTINGS_PASSWORD]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Password/Password.component'),
        { ssr: false }
    ),
    [SETTINGS_PRIVACY]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Privacy/Privacy.component'),
        { ssr: false }
    ),
    [SETTINGS_CONSENT]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/Settings/Consent/Consent.component'),
        { ssr: false }
    ),
    [TROPHY_DETAILS]: dynamic(
        import('@hmn/coolinarika-web-ui/components/Gamification/components/Trophy/Details.component'),
        { ssr: false }
    ),
    [DISH_DRAWER_ALL]: dynamic(import('@hmn/coolinarika-web-ui/components/Dish/Drawer/DishDrawerAll.component'), {
        ssr: false
    }),
    [DISH_DRAWER_SINGLE]: dynamic(import('@hmn/coolinarika-web-ui/components/Dish/Drawer/DishDrawerSingle.component'), {
        ssr: false
    }),
    [COMMENTS]: dynamic(import('@hmn/coolinarika-web-ui/components/Comments/Comments.component'), { ssr: false }),
    [CONTACT]: dynamic(import('@hmn/coolinarika-web-ui/components/Contact/Contact.component'), { ssr: false }),
    [SHOPPING_LIST]: dynamic(import('@hmn/coolinarika-web-ui/components/ShoppingList/ShoppingList.component'), {
        ssr: false
    }),
    [IMAGE_UPLOADER_EDITOR]: dynamic(import('@hmn/coolinarika-web-ui/components/ImageUploader/Editor.component'), {
        ssr: false
    }),
    [USER_NOTIFICATIONS]: dynamic(import('@hmn/coolinarika-web-ui/components/Notifications/Notifications.component'), {
        ssr: false
    }),
    [UPLOADER_TAGS]: dynamic(
        import('@hmn/coolinarika-web-ui/components/RecipeUploader/components/Drawers/Tags.component'),
        { ssr: false }
    ),
    [UPLOADER_TAGS_SUCCESS]: dynamic(
        import('@hmn/coolinarika-web-ui/components/RecipeUploader/components/Drawers/Success.component'),
        { ssr: false }
    ),
    [PHOTO_GALLERY]: dynamic(import('@hmn/coolinarika-web-ui/components/Photo/components/Drawers/Gallery.component'), {
        ssr: false
    }),
    [COOL_FOOD_ENTRY]: dynamic(
        import('@hmn/coolinarika-web-ui/components/User/components/CoolFoodEntry/CoolFoodEntry.component'),
        { ssr: false }
    )
}

const DrawerContent = ({ drawerType }) => {
    if (!drawerType) {
        return null
    }

    const DrawerTypeComponent = drawersList[drawerType]

    return <DrawerTypeComponent />
}

DrawerContent.propTypes = {
    drawerType: PropTypes.string
}

DrawerContent.defaultProps = {
    drawerType: undefined
}

export default memo(DrawerContent)
