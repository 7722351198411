/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = () => ({
    position: 'relative',

    '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        background: `url(/svg/tiktok-background.svg) no-repeat center / cover`,
        width: '100%',
        height: '100%',
        zIndex: -1
    }
})

export default style
