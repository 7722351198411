import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Icon.style'

const IconStyled = styled.span(props => ({ ...styles(props) }))

const Icon = ({ icon: IconComponent, svg: SvgComponent, color, size, rotate, sizeMobile, ...rest }) => {
    if (SvgComponent) {
        // @TODO: Sanitize props if we're using SVGR component, but we should drop SVGR completely
        delete rest.theme
        delete rest.titleAccess
    }

    return (
        <IconStyled
            rotate={rotate}
            color={color}
            size={size}
            sizeMobile={sizeMobile}
            svg={typeof SvgComponent !== 'undefined'}>
            {SvgComponent ? <SvgComponent width={size} height={size} {...rest} /> : <IconComponent {...rest} />}
        </IconStyled>
    )
}

Icon.propTypes = {
    icon: PropTypes.elementType,
    svg: PropTypes.elementType,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sizeMobile: PropTypes.number
}

Icon.defaultProps = {
    icon: undefined,
    svg: undefined,
    color: 'text.default',
    size: 'inherit',
    sizeMobile: undefined,
    rotate: undefined
}

export default Icon
