import { keyframes } from '@emotion/react'

import { toRem } from '../../../../helpers/theme'

const normalizeDimension = dimension =>
    typeof dimension === 'string' && dimension?.includes('auto')
        ? dimension
        : parseInt(`${dimension}`.replace(/[^0-9]+/g, ''), 10)

const shimmer = keyframes({
    '0%': {
        backgroundPosition: 'top right'
    },
    '100%': {
        backgroundPosition: 'top left'
    }
})

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ imageWidth, imageHeight, rounded, ratio, fullHeight, isDocbook }) => {
    const width = normalizeDimension(imageWidth)

    const height = normalizeDimension(imageHeight)

    const getRatio = () => {
        if (ratio) {
            return `${(1 / ratio) * 100}%`
        }

        if (!Number.isNaN(imageWidth) || !Number.isNaN(imageHeight)) {
            if (typeof imageHeight === 'string' && imageHeight?.includes('auto')) {
                return imageHeight
            }

            return `${(height / width) * 100}%`
        }

        return '100%'
    }

    return {
        position: 'relative',
        width: '100%',
        ...(fullHeight && {
            height: '100%'
        }),
        ...(isDocbook && {
            marginBottom: toRem(16)
        }),
        paddingBottom: getRatio(),
        borderRadius: rounded ? toRem(4) : 0,
        '& .shimmerFallback': {
            background: `
                linear-gradient(
                    to right,
                    rgb(246, 247, 248) 0%,
                    rgb(237, 238, 241) 20%,
                    rgb(246, 247, 248) 40%,
                    rgb(246, 247, 248) 100%
            )`,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            position: 'absolute',
            backgroundSize: '2250px 2250px !important',
            backgroundRepeat: 'no-repeat !important',
            backgroundPosition: '0 0 !important',
            animation: `${shimmer} 1.5s ease-in-out infinite`
        }
    }
}

export default style
