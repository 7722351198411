import { DrawerActionTypes } from '../../actions/drawer'

const initialState = {
    drawersOpen: [],
    isOpen: false,
    drawerId: null,
    drawerType: null,
    contentId: null,
    content: null,
    isForward: true,
    trophy: null,
    stickyDish: null,
    title: null,
    slug: null,
    entity: null,
    entityId: null,
    entityAuthorId: null,
    isEditing: false,
    notificationId: null,
    isActionShots: false,
    isAnimationComplete: false,
    items: [],
    selectionId: null,
    onSubmit: null,
    isSingleSelection: false,
    isResetPasswordEmailSent: false,
    onSuccessDrawer: false,
    gtmData: null
}

const drawersReducer = (
    state = initialState,
    { type, drawerType, isAnimationComplete, options, isEmailSent, onSuccessDrawer }
) => {
    switch (type) {
        case DrawerActionTypes.OPEN_DRAWER: {
            return {
                ...state,
                drawersOpen: state.drawersOpen.includes(drawerType)
                    ? [...state.drawersOpen]
                    : [...state.drawersOpen, drawerType],
                isOpen: true,
                drawerType,
                isForward: true,
                title: options?.title,
                slug: options?.slug,
                content: options?.content,
                contentId: options?.contentId || state.contentId,
                trophy: options?.trophy,
                stickyDish: options?.stickyDish,
                entity: options?.entity,
                entityId: options?.entityId,
                entityAuthorId: options?.entityAuthorId,
                isEditing: options?.isEditing,
                notificationId: options?.notificationId,
                isActionShots: options?.isActionShots,
                items:
                    options?.items && options?.selectionId
                        ? {
                              ...state.items,
                              [options.selectionId]: options.items
                          }
                        : state.items,
                selectionId: options?.selectionId || state.selectionId,
                isSingleSelection: options?.isSingleSelection || state.isSingleSelection,
                onSubmit: options?.onSubmit,
                gtmData: options?.gtmData
            }
        }

        case DrawerActionTypes.POP_CONTENT: {
            const currentTopDrawer = state.drawersOpen[state.drawersOpen.length - 1]
            const drawerToPop = drawerType || currentTopDrawer
            const filterArray = state.drawersOpen.filter(item => item !== drawerToPop)
            const currentDrawerType = filterArray[filterArray.length - 1]
            if (!currentDrawerType) {
                return {
                    drawersOpen: [],
                    isOpen: false,
                    drawerType: null,
                    contentId: null,
                    content: null,
                    isForward: true,
                    notificationId: null,
                    items: {},
                    selectionId: options?.selectionId || null,
                    isSingleSelection: options?.isSingleSelection || false,
                    onSubmit: options?.onSubmit || null,
                    gtmData: null
                }
            }
            return {
                ...state,
                isForward: false,
                drawersOpen: filterArray,
                drawerType: currentDrawerType,
                selectionId: options?.selectionId || state.selectionId,
                onSubmit: options?.onSubmit || null,
                isSingleSelection: options?.isSingleSelection || state.isSingleSelection
            }
        }
        case DrawerActionTypes.UPDATE_TITLE: {
            return {
                ...state,
                title: options.title
            }
        }

        case DrawerActionTypes.UPDATE_ITEMS: {
            if (!options?.selectionId) {
                return state
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    [options?.selectionId]: options?.items || []
                }
            }
        }

        case DrawerActionTypes.CLOSE_DRAWER: {
            return {
                drawersOpen: [],
                isOpen: false,
                drawerType: null,
                contentId: null,
                content: null,
                isForward: true,
                notificationId: null,
                items: {},
                selectionId: null,
                isSingleSelection: false,
                onSubmit: null,
                gtmData: null
            }
        }

        case DrawerActionTypes.IS_ANIMATION_COMPLETE: {
            return {
                ...state,
                isAnimationComplete
            }
        }

        case DrawerActionTypes.RESET_PASSWORD_EMAIL_SENT: {
            return {
                ...state,
                isResetPasswordEmailSent: isEmailSent
            }
        }

        case DrawerActionTypes.SET_ON_SUCCESS_DRAWER: {
            return {
                ...state,
                onSuccessDrawer
            }
        }

        default:
            return state
    }
}

export { drawersReducer }
