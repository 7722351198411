/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavShoppingList = createSvgIcon(
    <>
        <path d="M16.093 12.982c-.316 1.074-.32 2.623-.32 4.564a.5.5 0 01-1 0v-.073c0-1.85 0-3.545.36-4.773.188-.637.485-1.2.973-1.6.493-.406 1.126-.6 1.894-.6s1.4.194 1.894.6c.488.4.785.963.972 1.6.361 1.228.361 2.923.361 4.773v.073a.5.5 0 11-1 0c0-1.94-.005-3.49-.32-4.564-.154-.522-.368-.88-.648-1.11-.274-.225-.663-.372-1.259-.372-.596 0-.986.147-1.26.372-.279.23-.493.588-.647 1.11z" />
        <path d="M13.205 15.914a.45.45 0 00-.442.533l1.108 5.911a1.45 1.45 0 001.425 1.183h5.408a1.45 1.45 0 001.425-1.183l1.108-5.911a.45.45 0 00-.442-.533h-9.59zm-1.524.735a1.55 1.55 0 011.524-1.835h9.59c.97 0 1.702.881 1.523 1.835l-1.108 5.912a2.55 2.55 0 01-2.506 2.08h-5.408a2.55 2.55 0 01-2.506-2.08l-1.108-5.912z" />
    </>,
    'NavShoppingList'
)

export default NavShoppingList
