// Web variables
const webRoot = process.env.NEXT_PUBLIC_WEB_ROOT
const webPort = process.env.NEXT_PUBLIC_WEB_PORT

// API variables
const apiRoot = process.env.NEXT_PUBLIC_API_ROOT
const apiPort = process.env.NEXT_PUBLIC_API_PORT
const apiPrefix = process.env.NEXT_PUBLIC_API_PREFIX
const apiVersion = process.env.NEXT_PUBLIC_API_VERSION
const isDev = process.env.NODE_ENV !== 'production'

const apiBaseUrl = `${isDev && apiRoot.includes('localhost') ? 'http' : 'https'}://${apiRoot}${
    isDev && apiPort ? `:${apiPort || 3200}` : ''
}/${apiPrefix}/${apiVersion}`

const webBaseUrl = `${isDev ? 'http' : 'https'}://${webRoot}${isDev && webPort ? `:${webPort}` : ''}`

const reactQuerySettings = {
    staleTime: 1000 * 60, // 1 min, set to Infinity to never go stale
    // staleTime: 0,
    cacheTime: 0, //  set to Infinity to disable garbage collection, this is only in-memory cache
    // cacheTime: 0,
    refetchOnWindowFocus: process.env.NODE_ENV === 'production' && typeof window !== 'undefined',
    retry: 3,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // exponential backoff
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error', 'isFetching', 'isFetchingNextPage']
}

const reactQuerySettingsSSR = {
    defaultOptions: {
        queries: {
            staleTime: 0, // set to Infinity to never go stale
            cacheTime: 1000 * 60, // 1 min, set to Infinity to disable garbage collection, this is only in-memory cache
            retry: 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000) // exponential backoff
        }
    }
}

const constants = {
    IMAGES_VARIATIONS_RESOURCE: 'images/config/variations',
    IMAGES_VARIATIONS_DEFAULT_VARIATION: 'original-aspect',
    IMAGES_RATIO_TOLERANCE: 0.3,
    IMAGES_DIMENSION_TOLERANCE: 640,
    IMAGES_TRANSITION_DURATION_MS: 150,
    IMAGE_LQIP_FILTER_NAME: 'LQIP',
    NOTIFICATIONS_DEFAULT_DURATION: 4000,
    NOTIFICATIONS_DEFAULT_DELAY: 200
}

export default {
    reactQuerySettings,
    reactQuerySettingsSSR,
    constants,
    apiBaseUrl,
    webBaseUrl
}
