import { responsive, toRem } from '../../../helpers/theme'

export const dishCardVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '2x1': '2x1',
    '2x2': '2x2',
    SEEALL: 'SEEALL'
})

const style = ({ theme, variant }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    ...theme.cardHover,
    ...(variant === dishCardVariants.SEEALL && {
        cursor: theme.cards[variant].card.cursor,
        userSelect: 'none',
        '&::before': {
            zIndex: theme.zIndex.over,
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'block',
            background: theme.cards[variant].card.background
        }
    }),
    '& .cardContent': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 0,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        ...theme.cards[variant].spacing
    },
    '& .cardContent_label': {
        margin: 0,
        padding: `0.385em ${toRem(5)}`,
        fontWeight: theme.cards[variant].label.fontWeight
    },
    '& .cardContent_title': {
        margin: 0
    },
    '& .cardLead': {
        ...responsive([
            { marginTop: toRem(16) },
            { marginTop: toRem(16) },
            { marginTop: toRem(18) },
            { marginTop: toRem(19) },
            { marginTop: toRem(20) },
            { marginTop: toRem(22) }
        ]),
        ...responsive([
            { fontSize: toRem(16) },
            { fontSize: toRem(16) },
            { fontSize: toRem(16) },
            { fontSize: toRem(18) },
            { fontSize: toRem(18) }
        ]),
        lineHeight: 1.6,
        color: theme.colors.white,
        blockquote: {
            margin: 0
        }
    },
    '& .cardContent_seeAll': {
        width: `calc(100% - ${toRem(10)})`,
        zIndex: theme.zIndex.over,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    '& .cardContent_seeAllTitle': {
        marginTop: toRem(20),
        fontSize: toRem(16),
        lineHeight: 1.2,
        color: theme.colors.white,
        fontWeight: theme.typography.fontWeight.black,
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(25)
        },
        svg: {
            path: {
                fill: theme.colors.white
            }
        }
    },
    '& .cardContent_circle': {
        position: 'relative',
        width: toRem(60),
        height: toRem(60),
        borderRadius: '50%',
        lineHeight: 0,
        backgroundColor: theme.colors.accentYellow,
        '&::before': {
            content: '""',
            display: 'block',
            paddingBottom: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: toRem(70),
            height: toRem(70)
        }
    },
    '& .cardContent_circleInner': {
        position: 'absolute',
        bottom: '50%',
        width: '100%',
        fontSize: toRem(20),
        fontWeight: theme.typography.fontWeight.black,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(24)
        }
    }
})

export default style
