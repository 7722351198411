const variants = {
    open: {
        opacity: 1,
        height: 'auto'
    },
    collapsed: {
        opacity: 0,
        height: 0
    }
}

const transition = {
    duration: 0.25,
    ease: [0.04, 0.62, 0.23, 0.98]
}

export { variants, transition }
