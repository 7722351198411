import { toRem } from '@hmn/coolinarika-web-ui/helpers/theme'

/* eslint-disable quote-props, max-len */
export default {
    // Display
    // Show for Screen Reader
    '.showForSr': {
        position: 'absolute !important',
        width: '1px',
        height: '1px',
        padding: 0,
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        border: 0
    },
    // Text
    '.u-textLeft': {
        textAlign: 'left'
    },
    '.u-textCenter': {
        textAlign: 'center'
    },
    '.u-textRight': {
        textAlign: 'right'
    },
    '.u-textJustify': {
        textAlign: 'justify'
    },
    '.u-textUppercase': {
        textTransform: 'uppercase'
    },
    '.u-textUppercaseFirst': {
        '&::first-letter': {
            textTransform: 'uppercase'
        }
    },
    '.u-textLowercase': {
        textTransform: 'lowercase'
    },
    '.u-textCapitalize': {
        textTransform: 'capitalize'
    },
    '.u-textTruncate': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordWrap: 'normal'
    },
    '.u-textBreak': {
        wordBreak: 'break-word',
        wordWrap: 'break-word'
    },
    '.u-textNowrap': {
        whiteSpace: 'nowrap !important'
    },
    // Box sizing
    '.no-padding': {
        padding: 0
    },
    // 100% height form - used in combination with DrawerBody component to apply ScrollBar functionality
    '.u-formFullHeight': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    '.u-paragraphMargin': {
        p: {
            marginBottom: toRem(24)
        }
    }
}
