import { useEffect } from 'react'
import { destroyCookie, parseCookies } from 'nookies'

/**
 * Login as different user through his token
 * that is resolved from admin token through
 * GET /api/podravkaio/auth/login-as-user
 * endpoint.
 *
 * After user logout or if no user is logged in it also
 * tries to restore previous user from accessToken store
 * inside cookie.
 *
 * @param {Object} { onLogin, session, isLoggedIn, sessionLoading, enabled = true }
 */
const useLoginAsUser = ({ onLogin, session, isLoggedIn, sessionLoading, enabled = true }) => {
    useEffect(() => {
        if (!enabled) {
            return
        }

        const loginAsUser = async () => {
            try {
                const userToken = parseCookies().coolinarikaLoginAsUserToken

                if (!userToken) {
                    return
                }

                await onLogin({ accessToken: userToken })

                destroyCookie(null, 'coolinarikaLoginAsUserToken')
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }

        loginAsUser()
    }, [enabled])

    useEffect(() => {
        if (session || sessionLoading || isLoggedIn) {
            return
        }

        const loginAsUser = async () => {
            try {
                const { coolinarikaLoginAsUserPreviousToken: userToken, coolinarikaLoginAsUserActive } = parseCookies()

                // if userAccessToken exists that means
                // that current user was using "Login as User"
                // feature, so here we restore the previous
                // user
                if (!coolinarikaLoginAsUserActive && userToken) {
                    destroyCookie(null, 'coolinarikaLoginAsUserPreviousToken')

                    await onLogin({ accessToken: userToken, redirect: false })
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }

        loginAsUser()
    }, [session, sessionLoading, isLoggedIn])
}

export default useLoginAsUser
