// @TODO: Colors!!!

import { toRem, toRems } from '../../../../../helpers/theme'

const filtersWidth = {
    laptop: 440,
    desktop: 500
}

const style = ({ theme }) => ({
    '& .searchFilters_panel': {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        zIndex: theme.zIndex.search.filters.mobile,
        height: '100%',
        width: '100%',
        paddingTop: toRem(80),
        paddingBottom: 0,
        borderLeft: `1px solid ${theme.colors.gray250}`,
        borderRight: `1px solid ${theme.colors.gray250}`,
        backgroundColor: theme.colors.white,
        willChange: 'transform',
        boxShadow: theme.shadows.deep,

        [theme.breakpoints.up('lg')]: {
            left: theme.global.baseLayout.desktop.sidebar.width,
            right: 'auto',
            zIndex: theme.zIndex.search.filters.desktop,
            paddingTop: toRem(110),
            paddingBottom: toRem(0),
            maxWidth: toRem(filtersWidth.laptop),
            backgroundImage: 'unset',
            boxShadow: 'none'
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: toRem(filtersWidth.desktop)
        },
        '& .filtersHead': {
            display: 'flex',
            margin: toRems([0, 0, 40, 0])
        },
        '& .filtersHead_results': {
            width: '100%'
        },
        '& .filtersResults_button': {
            small: {
                fontSize: toRem(10),
                position: 'absolute',
                right: toRem(10),
                fontWeight: theme.typography.fontWeight.bold
            }
        },

        '&Body': {
            width: '100%',
            height: '100%',
            padding: toRems([20, 20, 0, 20]),
            [theme.breakpoints.up('md')]: {
                padding: toRems([20, 40, 0, 40])
            }
        },

        '&Footer': {
            padding: toRems([0, 25, 20, 25])
        },

        '&Btn': {
            '& .btn_icon': {
                position: 'absolute',
                right: toRem(15)
            }
        },

        '& div[class*="Container-toggleButtons"]': {
            margin: toRems([10, 0, 15]),
            [theme.breakpoints.up('md')]: {
                margin: toRems([10, 0, 30])
            }
        }
    }
})

export default style

const transition = {
    duration: 0.25,
    ease: 'easeOut'
}

const searchFiltersPanelVariant = {
    closed: {
        x: '-100%',
        y: 0,
        transition,
        transitionEnd: {
            display: 'none',
            visibility: 'hidden'
        }
    },
    open: {
        x: 0,
        y: 0,
        display: 'flex',
        visibility: 'visible',
        transition
    }
}

export { searchFiltersPanelVariant }
