/* eslint-disable no-console */
/* eslint-disable max-len */
import { useEffect } from 'react'

const useKvikiConsent = enabled => {
    useEffect(() => {
        if (enabled) {
            ;(async () => {
                const KvikiConsent = (await import('kviki-consent'))?.default
                KvikiConsent.init(
                    {
                        consentLogEndpoint: '/api/cookieconsent/save',
                        privacyPolicyLink: '/info/zastita-privatnosti',
                        termsOfUseLink: '/info/uvjeti-koristenja',
                        cookiePolicyLink: '/info/pravila-o-koristenju-kolacica'
                    },
                    {
                        isTcfCompliant: true,
                        tcfComplianceConfig: {
                            gvlBaseUrl: '/api/cookieconsent',
                            gvlDefaultFileName: 'vendor-list',
                            gvlLanguageFileName: 'vendor-list?language=[LANG]',
                            disclosedVendorIds: [
                                1, 4, 10, 11, 12, 13, 15, 16, 21, 23, 24, 25, 27, 28, 32, 34, 36, 37, 39, 40, 42, 45,
                                50, 52, 57, 59, 60, 63, 68, 69, 70, 71, 72, 73, 76, 77, 78, 81, 82, 84, 85, 87, 91, 93,
                                94, 95, 97, 98, 108, 109, 110, 119, 124, 126, 128, 129, 130, 132, 136, 137, 138, 139,
                                140, 156, 161, 163, 168, 192, 195, 202, 209, 212, 213, 226, 228, 241, 243, 246, 253,
                                264, 273, 275, 278, 281, 284, 294, 302, 304, 308, 312, 315, 317, 328, 345, 373, 381,
                                384, 385, 388, 394, 397, 402, 409, 415, 416, 423, 431, 447, 450, 452, 466, 468, 512,
                                559, 565, 587, 602, 631, 657, 676, 686, 703, 734, 755, 758, 759, 762, 767, 787, 793,
                                807, 827, 832, 853, 929, 1050
                            ],
                            explicitAcString:
                                '2~70.89.93.108.122.149.196.236.259.311.313.323.358.415.449.486.494.495.540.574.609.827.864.981.1029.1048.1051.1095.1097.1126.1205.1211.1276.1301.1365.1415.1423.1449.1514.1570.1577.1598.1651.1716.1735.1753.1765.1870.1878.1889.1958.2072.2253.2299.2357.2373.2415.2506.2526.2568.2571.2575.2624.2677~dv.'
                        },
                        categories: {
                            analytics: {
                                autoClear: {
                                    reloadPage: true
                                },
                                services: {
                                    google: {
                                        label: 'Google Analytics',
                                        cookies: [
                                            {
                                                name: /^(_ga|_gid)/
                                            }
                                        ]
                                    }
                                }
                            },
                            marketing: {
                                autoClear: {
                                    reloadPage: true
                                },
                                services: {
                                    'wtg-ads': {
                                        label: 'Waytogrow'
                                    },
                                    gemius: {
                                        label: 'Gemius'
                                    },
                                    google: {
                                        label: 'Google Ads'
                                    }
                                }
                            }
                        },
                        language: {
                            autoDetect: false,
                            translations: {
                                hr: {
                                    consentModal: {
                                        description:
                                            'Koristimo vlastite kolačiće i kolačiće trećih strana kako bismo vam mogli prikazati web stranicu te razumjeti kako je koristite, s ciljem poboljšanja korisničkog iskustva i razvoja naših proizvoda. Klikom na „Prihvaćam sve“ učitat će se svi kolačići. Klikom na „Prihvaćam samo nužne“ učitat će se samo oni kolačići koji su neophodni za ispravno funkcioniranje web stranice (ti kolačići ne mogu se isključiti). Ako želite odabrati vrstu kolačića, kliknite na Postavke kolačića. <br>Mi i naši partneri ({{count}}) pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su jedinstveni ID-ovi u kolačićima za obradu osobnih podataka. Možete ponovno pregledati svoje odabire pristanka ili povući pristanak u bilo kojem trenutku klikom na poveznicu na svoje postavke kolačića u našim Pravilima o korištenju kolačića.',
                                        showVendorsBtn: 'Popis partnera (dobavljača)',
                                        vendorTitle: 'Mi i naši partneri obavljamo sljedeće na temelju vaših postavki'
                                    },
                                    preferencesModal: {
                                        illustrationsTitle: 'Primjeri',
                                        purposeVendorCountLabel: '{{count}} partnera može koristiti ovu svrhu',
                                        viewIllustrationsLabel: 'Pogledajte primjere',
                                        viewVendorsLabel: 'Popis IAB dobavljača',
                                        sections: [
                                            {
                                                title: 'Nužni <span class="pm__badge">Uvijek omogućeni</span>',
                                                description:
                                                    'Ovi su kolačići potrebni za funkcioniranje web stranice i ne mogu se isključiti. Obično se postavljaju samo kao odgovor na vaše radnje koje predstavljaju zahtjev za usluge, kao što su postavljanje vaših postavki privatnosti, prijava ili ispunjavanje obrazaca. Možete postaviti svoj preglednik da blokira ili upozorava na ove kolačiće, ali neki dijelovi web stranice tada neće raditi.',
                                                linkedCategory: 'necessary',
                                                cookieTable: {
                                                    headers: {
                                                        name: 'Naziv',
                                                        domain: 'Domena/Pružatelj',
                                                        duration: 'Trajanje',
                                                        description: 'Opis'
                                                    },
                                                    body: [
                                                        {
                                                            name: 'cc_cookie',
                                                            domain: window.location.hostname,
                                                            duration: '182 dana',
                                                            description:
                                                                'Pohranjuje informacije o prihvaćenim kolačićima, kada su prihvaćeni i druge informacije koje se koriste u logici prihvaćanja kolačića'
                                                        },
                                                        {
                                                            name: 'audit',
                                                            domain: 'rubiconproject',
                                                            duration: '1 godina',
                                                            description:
                                                                'Utvrđuju je li posjetitelj prihvatio kolačiće te na taj način osigurava da se obavijest o prihvaćanju kolačića ne pojavljuje posjetitelju ponovno prilikom sljedećeg dolaska na internetsku stranicu.'
                                                        },
                                                        {
                                                            name: 'checkForPermission',
                                                            domain: 'bidr.io',
                                                            duration: '1 dan',
                                                            description:
                                                                'Utvrđuju je li posjetitelj prihvatio kolačiće te na taj način osigurava da se obavijest o prihvaćanju kolačića ne pojavljuje posjetitelju ponovno prilikom sljedećeg dolaska na internetsku stranicu.'
                                                        },
                                                        {
                                                            name: 'cookietest',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Utvrđuju je li posjetitelj prihvatio kolačiće te na taj način osigurava da se obavijest o prihvaćanju kolačića ne pojavljuje posjetitelju ponovno prilikom sljedećeg dolaska na internetsku stranicu.'
                                                        },
                                                        {
                                                            name: 'gdpr status',
                                                            domain: 'media.net',
                                                            duration: '6 mjeseci',
                                                            description:
                                                                'Utvrđuju je li posjetitelj prihvatio kolačiće te na taj način osigurava da se obavijest o prihvaćanju kolačića ne pojavljuje posjetitelju ponovno prilikom sljedećeg dolaska na internetsku stranicu.'
                                                        },
                                                        {
                                                            name: 'cu',
                                                            domain: 'ipredictive.com',
                                                            duration: '1 godina',
                                                            description:
                                                                'Utvrđuje je li posjetitelj prihvatio marketinšku kategoriju u obavijesti o kolačićima. Ovi kolačići su nužni za usklađenost s odredbama Opće uredbe o zaštiti podataka/GDPR.'
                                                        },
                                                        {
                                                            name: 'test',
                                                            domain: 'm6r.eu',
                                                            duration: '1 dan',
                                                            description:
                                                                'Utvrđuje je li posjetitelj prihvatio marketinšku kategoriju u obavijesti o kolačićima. Ovi kolačići su nužni za usklađenost s odredbama Opće uredbe o zaštiti podataka/GDPR.'
                                                        },
                                                        {
                                                            name: 'AWSALB',
                                                            domain: 'script.dotmetrics.net',
                                                            duration: '1 dan',
                                                            description:
                                                                'Kolačić koji bilježi koji poslužiteljski klaster vam pruža uslugu.'
                                                        },
                                                        {
                                                            name: 'AWSALBCORS',
                                                            domain: 'script.dotmetrics.net',
                                                            duration: '6 dana',
                                                            description:
                                                                'Kolačić koji bilježi koji poslužiteljski klaster vam pruža uslugu.'
                                                        },
                                                        {
                                                            name: 'DNT',
                                                            domain: 'mrpdata.net',
                                                            duration: '10 godina',
                                                            description:
                                                                'Identificira da je posjetitelj povukao svoju privolu za kolačiće, pratitelje ili druge alate za targetiranje javnosti.'
                                                        },
                                                        {
                                                            name: 'JSESSIONID',
                                                            domain: 'coo.acadv.net',
                                                            duration: '1 dan',
                                                            description: 'Čuva sigurnost vaše sesije.'
                                                        },
                                                        {
                                                            name: 'SERVERID',
                                                            domain: window.location.hostname,
                                                            duration: '1 dan',
                                                            description:
                                                                'Koristi se za dodjeljivanje posjetitelja konkretnom serveru. Ova je funkcija nužna za funkcionalnost stranice.'
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                title: 'Analitički',
                                                description:
                                                    'Ovi kolačići omogućuju nam brojanje posjeta i izvora prometa kako bismo mogli mjeriti i poboljšati performanse naše web stranice. Oni nam pomažu saznati koje su stranice najpopularnije i najmanje popularne te vidjeti kako se posjetitelji kreću po web mjestu. Svi podaci koje ovi kolačići prikupljaju skupni su i stoga anonimni. Ako ne dopustite ove kolačiće, nećemo znati kada ste posjetili naše web stranice, te nećemo moći pratiti njihovu učinkovitost.',
                                                linkedCategory: 'analytics',
                                                cookieTable: {
                                                    headers: {
                                                        name: 'Naziv',
                                                        domain: 'Domena/Pružatelj',
                                                        duration: 'Trajanje',
                                                        description: 'Opis'
                                                    },
                                                    body: [
                                                        {
                                                            name: '_dc_gtm_UA-#',
                                                            domain: window.location.hostname,
                                                            duration: '1 dan',
                                                            description:
                                                                'Koristi Google Tag Manager za kontrolu učitavanja Google Analyticsovog script taga.'
                                                        },
                                                        {
                                                            name: '_ga',
                                                            domain: window.location.hostname,
                                                            duration: '2 godine',
                                                            description:
                                                                'Bilježi jedinstveni ID koji se koristi za generiranje statističkih podataka o tome na koji način posjetitelj koristi internetsku stranicu.'
                                                        },
                                                        {
                                                            name: '_ga_#',
                                                            domain: window.location.hostname,
                                                            duration: '2 godine',
                                                            description:
                                                                'Koristi Google Analytics za prikupljanje podataka o broju posjeta korisnika internetskoj stranici kao i o datumima prvog i zadnjeg posjeta.'
                                                        },
                                                        {
                                                            name: '_gat',
                                                            domain: window.location.hostname,
                                                            duration: '1 dan',
                                                            description:
                                                                'Koristi Google Analytics za regulaciju stope zahtjeva.'
                                                        },
                                                        {
                                                            name: '_gid',
                                                            domain: window.location.hostname,
                                                            duration: '1 dan',
                                                            description:
                                                                'Bilježi jedinstveni ID koji se koristi za generiranje statističkih podataka o tome na koji način posjetitelj koristi internetsku stranicu.'
                                                        },
                                                        {
                                                            name: '_hjAbsoluteSessionInProgress',
                                                            domain: window.location.hostname,
                                                            duration: '1 dan',
                                                            description:
                                                                'Koristi se za brojanje koliko puta je internetska stranica posjećena od strane različitih posjetitelja. To se radi na način da se posjetitelju dodijeli ID tako da posjetitelj ne bude registriran dvaput.'
                                                        },
                                                        {
                                                            name: '_hjid',
                                                            domain: window.location.hostname,
                                                            duration: '1 godina',
                                                            description:
                                                                'Postavlja jedinstveni ID sesije što omogućuje internetskoj stranici dobivanje podataka o ponašanju posjetitelja za statističke svrhe.'
                                                        },
                                                        {
                                                            name: '_hjIncludeInPageviewSample',
                                                            domain: window.location.hostname,
                                                            duration: '1 dan',
                                                            description:
                                                                'Određuje treba li kretanje/navigacija korisnika internetskom stranicom biti registrirana na određeno statističko mjesto. '
                                                        },
                                                        {
                                                            name: '_hjTLDTest',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Detektira SEO rangiranje za trenutnu stranicu. Ova je usluga dio statističkih i analitičkih usluga treće strane.'
                                                        },
                                                        {
                                                            name: 'pageviewCount',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Koristi se za identificiranje učestalosti posjeta te koliko je posjetitelj dugo na stranici. Kolačić se također koristi za određivanje koliko i koje podstranice posjetitelj posjećuje na stranicu. Taj podatak može koristiti stranica u svrhu optimalizacije domene i njezinih podstranica.'
                                                        },
                                                        {
                                                            name: 'collect',
                                                            domain: 'google-analytics',
                                                            duration: 'Sesija',
                                                            description:
                                                                'Koristi se za slanje podataka o uređaju posjetitelja i ponašanju posjetitelja Google Analyticsu. Prati posjetitelja preko uređaja i marketinških kanala. '
                                                        },
                                                        {
                                                            name: 'd',
                                                            domain: 'quantserve.com',
                                                            duration: '3 mjeseca',
                                                            description:
                                                                'Prikuplja anonimne podatke o posjetima korisnika internetskoj stranici, kao što su broj posjeta, prosječno provedeno vrijeme na stranici te koje stranice su bile učitane, u svrhu generiranja izvješća za optimalizaciju sadržaja stranice. '
                                                        },
                                                        {
                                                            name: 'visitor-id',
                                                            domain: 'media.net',
                                                            duration: '1 godina',
                                                            description:
                                                                'Ovaj se kolačić koristi za prikupljane podataka o posjetitelju. Podatak se pohranjuje za internu analitiku kod operatera stranice. Internu analitiku koriste internetske stranice za optimalizaciju njihovih domena.'
                                                        },
                                                        {
                                                            name: '_#/redot.gif',
                                                            domain: 'gemius.pl',
                                                            duration: 'Sesija',
                                                            description: ''
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                title: 'Razvoj proizvoda i marketing',
                                                description:
                                                    'Ove kolačiće mogu postaviti naši oglašivački partneri putem naše web stranice. Te ih tvrtke mogu koristiti za izradu profila vaših interesa i prikazivanje relevantnih oglasa na drugim web stranicama. Ne pohranjuju osobne podatke izravno, već se temelje na jedinstvenoj identifikaciji vašeg preglednika i uređaja putem kojeg pristupate Internetu. Ako ne dopustite ove kolačiće, vaše korisničko iskustvo sadržavat će manje ciljanog oglašavanja.',
                                                linkedCategory: 'marketing',
                                                cookieTable: {
                                                    headers: {
                                                        name: 'Naziv',
                                                        domain: 'Domena/Pružatelj',
                                                        duration: 'Trajanje',
                                                        description: 'Opis'
                                                    },
                                                    body: [
                                                        {
                                                            name: 'next-auth',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Omogućava praćenje prehrambenih preferencija korisnika/posjetitelja.'
                                                        },
                                                        {
                                                            name: 'NID',
                                                            domain: 'google-analytics',
                                                            duration: '6 mjeseci',
                                                            description:
                                                                'Skuplja podatke o preferencijama korisnika. Bilježi jedinstvenu identifikaciju koja identificira uređaj korisnika koji se vraća. Identifikacija se koristi za ciljano oglašavanje. Praćenje statistika posjećenosti.'
                                                        },
                                                        {
                                                            name: '__gads',
                                                            domain: 'google-ads',
                                                            duration: '1 godina',
                                                            description:
                                                                'Oglašavanje. Koristi se za bilježenje koji oglasi su bili prikazani korisniku. Definira koji je oglas korisnik odabrao („kliknuo“). Prikaz AdSense oglasa.'
                                                        },
                                                        {
                                                            name: 'ads/ga-audiences',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Koristi Google AdWords za ponovno angažiranje posjetitelja za koje je vjerojatno da će postati kupci na temelju ponašanja posjetitelja preko različitih stranica online. Prikaz AdSense oglasa.'
                                                        },
                                                        {
                                                            name: 'goog_pem_mod',
                                                            domain: window.location.hostname,
                                                            duration: 'trajno',
                                                            description:
                                                                'Isporuka oglasa i remarketing. Prikaz AdSense oglasa. Koristi se za slanje podataka o uređaju i ponašanju posjetitelja Google Anylyticsu. Prati posjetitelja preko uređaja i marketinških kanala. '
                                                        },
                                                        {
                                                            name: '_gcl_au',
                                                            domain: window.location.hostname,
                                                            duration: '3 mjeseca',
                                                            description:
                                                                'Koristi Google AdSense za testiranje efikasnosti oglašavanja preko stranica korištenjem svojih usluga. Prikaz AdSense oglasa.'
                                                        },
                                                        {
                                                            name: 'pagead/1p-user-list/#',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Prati interakcije, interese i navigaciju korisnika preko različitih stranica. Prikaz AdSense oglasa. Prati je li korisnik pokazao interes za specifičan proizvod ili događanje na različitim stranicama i detektira kako korisnik navigira između stranica. To se koristi za mjerenje oglašavanja i olakšavanja plaćanja naknada između stranica. '
                                                        },
                                                        {
                                                            name: '__gfp_64b',
                                                            domain: 'Gemius.pl',
                                                            duration: '999 dana',
                                                            description:
                                                                'Praćenje statistika posjećenosti. Prati koji sadržaj korisnici konzumiraju. Prikuplja podatke o posjetu korisnika stranici, kao što je kojim stranicama je pristupljeno. Zabilježeni podaci koriste se za kategorizaciju korisnikova interesa i demografski profil u smislu ponovne prodaje za ciljano oglašavanje. '
                                                        },
                                                        {
                                                            name: 'Gdyn',
                                                            domain: window.location.hostname,
                                                            duration: '62 mjeseca',
                                                            description:
                                                                'Praćenje statistika posjećenosti. Koristi se u kontekstu tzv. pop-up oglasnog sadržaja na stranici. Kolačić utvrđuje koji se oglasi posjetitelju trebaju prikazati, te prati da oglasi ne budu isporučeni/prikazani više nego je potrebno. '
                                                        },
                                                        {
                                                            name: 'gstorage',
                                                            domain: window.location.hostname,
                                                            duration: 'trajno',
                                                            description:
                                                                'Kolačić identifikacija korisnika. Praćenje statistika posjećenosti. Postavlja jedinstvenu identifikaciju posjetitelja koja omogućuje oglašivačima trećim stranama da ciljaju posjetitelja s relevantnim oglasom. Usluga sparivanja pruža se od strane oglašivačkih čvorišta treće strane, što olakšava oglašivačima nuđenje u stvarnom vremenu.'
                                                        },
                                                        {
                                                            name: 'Gtest',
                                                            domain: window.location.hostname,
                                                            duration: '62 mjeseca',
                                                            description:
                                                                'Praćenje statistika posjećenosti. Ovaj kolačić je podešen da prikuplja podatke o ponašanju i navigaciji korisnika koji se koriste za optimalizaciju stranice. Kolačić također omogućava Google Adsu i Google Analyticsu da prikupe podatke o posjetiteljima za marketinške svrhe. '
                                                        },
                                                        {
                                                            name: '_fbp',
                                                            domain: 'facebook.com',
                                                            duration: '3 mjeseca',
                                                            description:
                                                                'Bazni kolačić za oglašavanje. Koristi Facebook za isporuku serije oglašivačkih proizvoda kao što je nuđenje od oglašivača trećih strana u stvarnom vremenu.'
                                                        },
                                                        {
                                                            name: 'fr',
                                                            domain: window.location.hostname,
                                                            duration: '',
                                                            description:
                                                                'Omogućava isporuku remarketing oglasa. Koristi Facebook za isporuku serije oglašivačkih proizvoda kao što je nuđenje od oglašivača trećih strana u stvarnom vremenu.'
                                                        },
                                                        {
                                                            name: 'tr',
                                                            domain: window.location.hostname,
                                                            duration: 'Sesija',
                                                            description:
                                                                'Omogućava isporuku oglasa. Koristi Facebook za isporuku serije oglašivačkih proizvoda kao što je nuđenje od oglašivača trećih strana u stvarnom vremenu.'
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    },
                                    vendorsModal: {
                                        title: 'IAB popis dobavljača',
                                        allowAllConsentBtn: 'Dopusti sve',
                                        denyAllConsentBtn: 'Zabrani sve',
                                        allowSelectionBtn: 'Spremi postavke',
                                        viewPrivacyPolicyLabel: 'Pogledajte Pravila privatnosti',
                                        viewLegitimateInterestClaimLabel: 'Pogledajte tvrdnje za legitimni interes',
                                        viewDeviceStorageDisclosureLabel: 'Pogledajte Izjavu o pohrani uređaja',
                                        cookieLifespanLabel: 'Životni vijek kolačića',
                                        cookieLifespanMonthsLabel: 'Mjeseca',
                                        usesNonCookieAccessLabel:
                                            'Ovaj dobavljač koristi druge metode pohrane ili pristupa informacijama osim kolačića',
                                        dataDeclarationLabel: 'Izjava o podacima',
                                        dataRetentionLabel: 'Zadržavanje podataka',
                                        standardRetentionLabel: 'Standardno zadržavanje',
                                        dataRetentionDaysLabel: 'Dana',
                                        consentPurposesLabel: 'Svrhe pristanka',
                                        legitimateInterestPurposesLabel: 'Svrhe legitimnog interesa',
                                        specialPurposesLabel: 'Posebne namjene',
                                        featuresLabel: 'Značajke',
                                        specialFeaturesLabel: 'Posebne značajke'
                                    }
                                }
                            }
                        }
                    },
                    {
                        services: {
                            youtube: {
                                embedUrl: '{data-id}',
                                languages: {
                                    hr: {
                                        notice: 'Ovaj sadržaj se poslužuje iz treće strane. Prikazivanjem ovog sadržaja prihvaćate pravila stranice youtube.com',
                                        loadAllBtn: 'Dozvoli'
                                    }
                                }
                            }
                        }
                    }
                )
            })()
        }

        return () => {}
    }, [])
}

export default useKvikiConsent
