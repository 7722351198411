/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const HeartOutline = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.485c1.956-1.62 4.95-2.127 7.455-.418l.002.001a5.92 5.92 0 012.54 4.64v.002c.07 2.084-.83 3.887-2.253 5.65-1.396 1.732-3.396 3.545-5.714 5.647l-.147.133-.002.002a2.796 2.796 0 01-3.766.014v-.002l-.088-.078-.004-.003-.104-.095c-2.296-2.086-4.279-3.888-5.666-5.608-1.423-1.764-2.324-3.568-2.249-5.653a5.923 5.923 0 012.54-4.649c2.507-1.72 5.501-1.203 7.456.417zm6.327 1.234c-1.89-1.289-4.263-.712-5.566.813l-.76.89-.76-.89c-1.306-1.528-3.679-2.109-5.567-.814l12.653.001zm0 0a3.92 3.92 0 011.67 3.06c.047 1.406-.537 2.747-1.81 4.326-1.287 1.596-3.17 3.308-5.554 5.47l-.1.09-.005.004a.796.796 0 01-1.07.004l-.003-.003-.087-.078h-.002c-2.382-2.166-4.268-3.88-5.556-5.477-1.274-1.58-1.858-2.92-1.807-4.326a3.925 3.925 0 011.671-3.07"
        fill="#fff"
    />,
    'HeartOutline'
)

export default HeartOutline
