// @TODO: Behavior on window resize
// @FIXME: When card before is 1x2 with forced fullHeight, parallax is getting wrong
// parent div height on mount therefor animation is broken

import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import PropTypes from 'prop-types'

import { useWindow } from '@hmn/coolinarika-web-core/hooks'

import styles from './Parallax.style'

const ParallaxBannerStyled = styled.div(props => ({ ...styles(props) }))

const ParallaxBanner = ({ height, threshold, children }) => {
    const [offsetTop, setOffsetTop] = useState(0)
    const [offsetBottom, setOffsetBottom] = useState(0)
    const [translateOffset, setTranslateOffset] = useState(0)

    const { scrollY } = useViewportScroll()
    const { height: windowHeight } = useWindow()

    const y = useTransform(scrollY, [offsetTop, offsetBottom], [0, -translateOffset], {
        clamp: true
    })
    const parallax = useRef()

    const handleOffset = () => {
        const { top, bottom, height: parentHeight } = parallax?.current.getBoundingClientRect()

        const offsetTopRel = Math.round(top - windowHeight)
        const offsetBottomRel = Math.round(bottom)
        const offsetThreshold = Math.round(parentHeight * threshold)

        setOffsetTop(offsetTopRel + offsetThreshold)
        setOffsetBottom(offsetBottomRel - offsetThreshold)
        setTranslateOffset(height - parentHeight)
    }

    useEffect(() => {
        if (parallax) {
            handleOffset()
            window.addEventListener('resize', handleOffset)
        }

        return () => {
            window.removeEventListener('resize', handleOffset)
        }
    }, [])

    return (
        <ParallaxBannerStyled ref={parallax} height={height}>
            <motion.div className="parallaxBanner_visual" style={{ y }}>
                {children}
            </motion.div>
        </ParallaxBannerStyled>
    )
}

ParallaxBanner.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    threshold: PropTypes.number
}

ParallaxBanner.defaultProps = {
    threshold: 0.3
}

export default ParallaxBanner
