import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useAnalytics } from '@hmn/coolinarika-web-core/hooks'

import { withErrorBoundary } from '../../../ErrorBoundary'
import { ScrollBar } from '../../../ScrollBar'
import { colorVariants, Item, itemVariants } from '../Item'
import styles from './Menu.style'

const MenuStyled = styled.div(props => ({ ...styles(props) }))

const Menu = ({ className, primaryData, secondaryData, tertiaryData, ...rest }) => {
    const uid = useUIDSeed()

    const { eventWithTrackingData: itemEventTracking } = useAnalytics('coolinarika.mainItemClick', {
        trackingData: {
            elementCategory: 'menuItem',
            elementPageLocation: 'header'
        }
    })
    const { eventWithTrackingData: subItemEventTracking } = useAnalytics('coolinarika.subItemClick', {
        trackingData: {
            elementCategory: 'menuSubItem',
            elementPageLocation: 'header'
        }
    })

    const handleTracking = ({ isPrimary, label }) => {
        if (isPrimary) {
            itemEventTracking({
                elementLabel: label
            })
        } else {
            subItemEventTracking({
                elementLabel: label
            })
        }
    }

    return (
        <MenuStyled className={className} {...rest}>
            <ScrollBar hasTopShadow hasBottomShadow className="navigationMenu_container">
                <nav className="navigationMenu_nav">
                    <ul className="navigationMenu_navList">
                        {primaryData.map((item, index) => (
                            <li key={uid(`${item?.title}-${index}`)}>
                                <Item
                                    href={item?.href}
                                    hrefAs={item?.hrefAs}
                                    icon={item?.icon}
                                    title={item?.title}
                                    items={item?.items}
                                    onClick={item?.onClick}
                                    action={item?.action}
                                    variant={itemVariants[item?.variant?.toUpperCase()]}
                                    colorVariant={item?.colorVariant}
                                    expanded={item?.expanded}
                                    activeTriggers={item?.active}
                                    isPrimaryNavigationItem
                                    onClickEventTracking={handleTracking}
                                />
                            </li>
                        ))}
                    </ul>

                    {secondaryData?.length > 0 && (
                        <ul className="navigationMenu_navList">
                            {secondaryData?.map((item, index) => (
                                <li key={uid(`${item?.title}-${index}`)}>
                                    <Item
                                        href={item?.href}
                                        hrefAs={item?.hrefAs}
                                        icon={item?.icon}
                                        title={item?.title}
                                        items={item?.items}
                                        onClick={item?.onClick}
                                        action={item?.action}
                                        variant={itemVariants[item?.variant?.toUpperCase()]}
                                        colorVariant={item?.colorVariant}
                                        expanded={item?.expanded}
                                        activeTriggers={item?.active}
                                        onClickEventTracking={handleTracking}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}

                    <ul className="navigationMenu_navList isBottom">
                        {tertiaryData.map((item, index) => (
                            <li key={uid(`${item?.title}-${index}`)}>
                                <Item
                                    className={item?.className}
                                    href={item?.href}
                                    hrefAs={item?.hrefAs}
                                    icon={item?.icon}
                                    title={item?.title}
                                    items={item?.items}
                                    onClick={item?.onClick}
                                    action={item?.action}
                                    variant={itemVariants[item?.variant?.toUpperCase()]}
                                    isThin
                                    colorVariant={item?.subtle || colorVariants.TERTIARY}
                                    expanded={item?.expanded}
                                    activeTriggers={item?.active}
                                    onClickEventTracking={handleTracking}
                                />
                            </li>
                        ))}
                    </ul>
                </nav>
            </ScrollBar>
        </MenuStyled>
    )
}

Menu.propTypes = {
    className: PropTypes.string,
    primaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    secondaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    tertiaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

Menu.defaultProps = {
    className: undefined,
    primaryData: undefined,
    secondaryData: undefined,
    tertiaryData: undefined
}

export default withErrorBoundary(Menu)
