/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavTrophies = createSvgIcon(
    <>
        <path d="M18 9c-3.86 0-7 3.14-7 7a7 7 0 003.23 5.896v4.945a.539.539 0 00.838.448L18 25.334l2.932 1.955a.539.539 0 00.837-.448v-4.945A7 7 0 0025 16c0-3.86-3.14-7-7-7zm2.692 16.835L18.3 24.239a.539.539 0 00-.598 0l-2.393 1.596V22.46A6.96 6.96 0 0018 23a6.96 6.96 0 002.692-.539v3.374zM18 21.923A5.93 5.93 0 0112.077 16 5.93 5.93 0 0118 10.077 5.93 5.93 0 0123.923 16 5.93 5.93 0 0118 21.923z" />
        <path d="M22.282 14.74a.539.539 0 00-.437-.369l-2.326-.33-1.038-2.053a.539.539 0 00-.962 0l-1.038 2.053-2.326.33a.539.539 0 00-.295.924l1.675 1.594-.395 2.249a.538.538 0 00.776.572L18 18.64l2.084 1.07a.538.538 0 00.776-.572l-.395-2.249 1.675-1.594a.539.539 0 00.142-.555zm-2.769 1.568a.538.538 0 00-.159.483l.258 1.466-1.366-.701a.539.539 0 00-.492 0l-1.366.701.258-1.466a.538.538 0 00-.16-.483l-1.078-1.027 1.503-.213a.539.539 0 00.405-.29L18 13.424l.684 1.354c.08.157.23.265.405.29l1.503.213-1.079 1.027z" />
    </>,
    'NavTrophies'
)

export default NavTrophies
