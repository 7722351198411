import { createSvgIcon } from '../utils'

const NavGreenCorner = createSvgIcon(
    <>
        <defs>
            <style>
                {`.cls-1 {
                        fill: white !important;
                        stroke: #63c584  !important;
                        stroke-miterlimit: 10;
                    }
                    .isGreen .cls-1 {
                        fill: #63c584 !important;
                        stroke: white  !important;
                        stroke-miterlimit: 10;
                    }
                    .isGreen  .navItem_icon {
                    background-color: #63c584 !important;
                    } 

                    `}
            </style>
        </defs>
        <path
            className="cls-1"
            d="M19.58,21.78c-.93,1.24-4.07,1.64-6.03-.22-4.08-3.86-.87-10.24-.87-10.24,2.72,2.13,11.41,4.45,6.9,10.46ZM20.22,20.8c5.09-.83,3.74-5.11,3.76-8.42,0,0-3.41-.61-5.06,2.59M18.87,25.61c1.07-2.06-.26-5.42-2.48-8.1M22.49,14.98c-1.41,1.03-1.81,2.54-1.65,3.26"
        />
    </>,
    'NavGreenCorner'
)

export default NavGreenCorner
