import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../ErrorBoundary'

const variants = {
    enter: isForward => ({
        x: isForward ? '100%' : '-100%',
        opacity: 0
    }),
    center: {
        x: 0,
        opacity: 1
    },
    exit: isForward => ({
        x: isForward ? '-100%' : '100%',
        opacity: 0
    })
}

const ContentSlider = ({ key, isForward, children }) => (
    <motion.div
        key={key}
        custom={isForward}
        variants={variants}
        transition={{
            x: { type: 'easeOut', duration: 0.25 }
        }}
        style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%'
        }}
        initial="enter"
        animate="center"
        exit="exit">
        {children}
    </motion.div>
)

ContentSlider.propTypes = {
    key: PropTypes.string,
    isForward: PropTypes.bool
}

ContentSlider.defaultProps = {
    key: undefined,
    isForward: true
}

export default withErrorBoundary(ContentSlider)
