import breakpoints from './breakpoints'

/**
 * Helper to render a definition given a value and a property name.
 * @param value
 * @param property
 * @returns {*}
 */
const definition = (value, property) => (typeof value === 'object' ? value : { [property]: value })

/**
 * Helper to write down a media query definition.
 * @param value
 * @param feature
 * @param unit
 * @returns {string}
 */
const media = (value, feature = 'min-width', unit = 'em') =>
    `@media screen and (${feature}: ${value}${value > 0 ? unit : ''})`

/**
 * Helper to use arrays as values to specify mobile-first responsive styles.
 * @param propertyOrValues
 * @param maybeValues
 * @returns {*}
 */
const responsiveProperties = (propertyOrValues, maybeValues) => {
    // Convert pixel breakpoints to 'em's
    const breaks = Object.values(breakpoints.values).map(value => value / 16)
    const values = typeof propertyOrValues === 'string' ? maybeValues : propertyOrValues

    const initial = values[0]

    return values
        .map(
            (value, index) =>
                value !== null && {
                    [media(breaks[index])]: definition(value, propertyOrValues)
                }
        )
        .reduce(
            (accumulator, value) => ({
                ...accumulator,
                ...value
            }),
            initial !== null ? definition(initial, propertyOrValues) : {}
        )
}

export default responsiveProperties
