import { NavigationActionTypes } from '../../actions/navigation'

const initialState = {
    isOpen: false,
    direction: 'PUSH',
    isNavigating: false
}

const navigationReducer = (state = initialState, { type, direction, isNavigating }) => {
    switch (type) {
        case NavigationActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: false
            }
        }

        case NavigationActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }

        case NavigationActionTypes.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        case NavigationActionTypes.SET_DIRECTION: {
            return {
                ...state,
                direction
            }
        }

        case NavigationActionTypes.IS_NAVIGATING: {
            return {
                ...state,
                isNavigating
            }
        }

        default:
            return state
    }
}

export { navigationReducer }
