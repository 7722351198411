/* eslint-disable no-useless-escape */
// @TODO: Active item detection

import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { slugify } from '@hmn/coolinarika-web-core/helpers'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { Link } from '../../../Link'
import styles from './SubItem.style'

const SubItemStyled = styled.div(props => ({ ...styles(props) }))

const SubItem = ({ className, href, hrefAs, title, onClick, ...rest }) => {
    const { pathname, asPath } = useRouter()
    const [active, setActive] = useState(false)

    useEffect(() => {
        // Special case for blog, since it's root route
        if (!hrefAs) {
            setActive(pathname === href)
        } else {
            const slugifiedTitle = slugify(title?.toLowerCase())
            setActive(asPath?.includes(slugifiedTitle))
        }
    }, [href, hrefAs, pathname, asPath])

    if (!title) {
        return null
    }

    // onClick
    const handleClick = (event, ...args) => {
        if (onClick) {
            onClick(event, ...args)
        }
    }

    return (
        <SubItemStyled className={className} active={active} {...rest}>
            <ConditionalWrapper condition={!!href} href={href} as={hrefAs} title={title} Wrapper={Link}>
                <span className="subNavItem" onClick={handleClick} role="presentation">
                    {title}
                </span>
            </ConditionalWrapper>
        </SubItemStyled>
    )
}

SubItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func
}

SubItem.defaultProps = {
    className: undefined,
    href: undefined,
    hrefAs: undefined,
    title: undefined,
    onClick: () => {}
}

export default withErrorBoundary(SubItem)
