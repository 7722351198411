import styled from '@emotion/styled'

// @ui imports
import { responsive, toRem, toRems } from '../../helpers/theme'
import { layoutDimension } from '../../theme/themes/layout'

const blockVerticalMargin = 40

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme }) => ({
    [theme.breakpoints.up('xl')]: {
        paddingRight: `${layoutDimension.column}%`
    },
    position: 'relative',
    width: '100%',
    '&::after': {
        content: '""',
        display: 'block',
        clear: 'both'
    },
    '& h1, h2, h3, h4, h5, h6': {},
    h1: {
        ...responsive([
            { marginBottom: toRem(16) },
            { marginBottom: toRem(16) },
            { marginBottom: toRem(20) },
            { marginBottom: toRem(20) },
            { marginBottom: toRem(25) },
            { marginBottom: toRem(25) }
        ])
    },
    '& > * + h1': {
        marginTop: toRem(40)
    },
    h2: {
        ...responsive([
            { marginBottom: toRem(16) },
            { marginBottom: toRem(16) },
            { marginBottom: toRem(20) },
            { marginBottom: toRem(20) },
            { marginBottom: toRem(25) },
            { marginBottom: toRem(25) }
        ])
    },
    '& > * + h2': {
        marginTop: toRem(40)
    },
    h3: {
        ...responsive([
            { marginBottom: toRem(8) },
            { marginBottom: toRem(8) },
            { marginBottom: toRem(10) },
            { marginBottom: toRem(10) },
            { marginBottom: toRem(12) },
            { marginBottom: toRem(12) }
        ])
    },
    '& > * + h3': {
        marginTop: toRem(40)
    },
    h4: {
        ...responsive([
            { marginBottom: toRem(8) },
            { marginBottom: toRem(8) },
            { marginBottom: toRem(10) },
            { marginBottom: toRem(10) },
            { marginBottom: toRem(12) },
            { marginBottom: toRem(12) }
        ])
    },
    '& > * + h4': {
        marginTop: toRem(32)
    },
    '& h5, h6': {
        ...responsive([
            { marginBottom: toRem(4) },
            { marginBottom: toRem(4) },
            { marginBottom: toRem(5) },
            { marginBottom: toRem(6) },
            { marginBottom: toRem(7) },
            { marginBottom: toRem(7) }
        ])
    },
    '& > * + h5, > * + h6': {
        marginTop: toRem(24)
    },
    img: {
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: theme.global.borderRadius.medium
    },
    p: {
        marginBottom: toRem(blockVerticalMargin)
    },
    ul: {
        margin: toRems([blockVerticalMargin, 0]),
        li: {
            margin: toRems([0, 0, 8, 0]),
            padding: toRems([0, 0, 0, 14]),
            '&::before': {
                content: '"●"',
                display: 'block',
                position: 'relative',
                maxWidth: 0,
                maxHeight: 0,
                left: toRem(-14),
                fontSize: toRem(14),
                color: theme.colors.list.disc.default
            },
            '&:last-of-type': {
                marginBottom: 0
            },
            '& ul': {
                marginTop: toRem(blockVerticalMargin / 2),
                '& li::before': {
                    content: '"○"'
                }
            }
        }
    },
    ol: {
        margin: toRems([blockVerticalMargin, 0]),
        counterReset: 'ol-list-counter',
        li: {
            counterIncrement: 'ol-list-counter',
            margin: toRems([0, 0, 8, 0]),
            padding: toRems([0, 0, 0, 26]),
            '&::before': {
                content: 'counter(ol-list-counter) "."',
                display: 'block',
                position: 'relative',
                maxWidth: 0,
                maxHeight: 0,
                left: toRem(-26),
                color: theme.colors.list.decimal.default
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        }
    },
    // Similar as blockquote (temporary)
    'p div': {
        position: 'relative',
        margin: toRems([24, 0, 30, -20]),
        paddingLeft: toRem(49),
        fontSize: toRem(22),
        lineHeight: 1.5,
        fontWeight: theme.typography.fontWeight.light,
        fontStyle: theme.typography.font.style.italic,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 450,
            width: '100%',
            float: 'right',
            margin: toRems([60, 0, 46, 45]),
            paddingLeft: toRem(52),
            fontSize: toRem(28)
        },
        '&::before': {
            content: '""',
            zIndex: theme.zIndex.under,
            position: 'absolute',
            left: 0,
            top: -10,
            display: 'block',
            width: toRem(57),
            height: toRem(60),
            padding: toRems([6, 12, 0, 12]),
            // eslint-disable-next-line max-len
            background: `${theme.colors.article.blockquote.quotes.background} url(/svg/icons/open-quote.svg) no-repeat center 15px / 30px 25px`,
            [theme.breakpoints.up('md')]: {
                width: toRem(63),
                height: toRem(74),
                top: -5
            }
        }
    },
    blockquote: {
        position: 'relative',
        margin: toRems([24, 0, 30, -20]),
        paddingLeft: toRem(49),
        fontSize: toRem(22),
        lineHeight: 1.5,
        fontWeight: theme.typography.fontWeight.light,
        fontStyle: theme.typography.font.style.italic,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 450,
            width: '100%',
            float: 'right',
            margin: toRems([24, 0, 30, 20]),
            paddingLeft: toRem(52),
            fontSize: toRem(28)
        },
        [theme.breakpoints.up('xl')]: {
            marginRight: `-${layoutDimension.column / 2}%`
        },
        [theme.breakpoints.up('xxl')]: {
            marginRight: `-${layoutDimension.column}%`
        },
        '&::before': {
            content: '""',
            zIndex: theme.zIndex.under,
            position: 'absolute',
            left: 0,
            top: -10,
            display: 'block',
            width: toRem(57),
            height: toRem(60),
            padding: toRems([6, 12, 0, 12]),
            // eslint-disable-next-line max-len
            background: `${theme.colors.article.blockquote.quotes.background} url(/svg/icons/open-quote.svg) no-repeat center 15px / 30px 25px`,
            [theme.breakpoints.up('md')]: {
                width: toRem(63),
                height: toRem(74),
                top: -5
            }
        }
    },
    iframe: {
        width: '100%',
        height: '56.25vw',
        overflowX: 'hidden',
        overflowY: 'auto'
    },

    /* Custom classes from CMS editor */
    '& .small_notice': {
        fontSize: toRem(12),
        color: '#666' // @todo: add color to global styles or palette
    }
})

export const EmbededVideo = styled.div(({ theme, children, ...attrs }) => ({
    overflow: 'hidden',
    paddingBottom: 'calc(100% * 9/16)',
    position: 'relative',
    height: 0,
    iframe: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }
}))

export const EmbedIFrame = styled.div(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginBottom: toRem(50)
}))

export const Table = styled.div(({ ...attrs }) => ({
    overflowX: 'scroll'
}))

export const Row = styled.div(({ theme, children, ...attrs }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        gridColumnGap: toRem(25)
    },
    marginBottom: toRem(50)
}))

export const Column = styled.div(({ theme, children, ...attrs }) => {
    const { width } = attrs
    return {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width
        }
    }
})

export const Card = styled.div(({ theme, children }) => ({ marginBottom: toRem(50) }))

export default style
