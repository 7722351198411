/**
 * Round input `value` to number of decimal `places`
 * @param value
 * @param places
 * @returns {number}
 */
export const round = (value, places) => Math.round(value * 10 ** places) / 10 ** places

/**
 * Round up miliseconds input to the nearest integer, since we're using hundreds anyway.
 * @param milliseconds
 * @returns {string}
 */
export const formatMs = milliseconds => `${Math.round(milliseconds)}ms`
