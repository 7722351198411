import { toRem, toRems } from '../../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, hasImage, variant, isActive }) => {
    const config = {
        default: {
            height: 'auto',
            padding: toRems([14, 30, 30]),
            background: theme.colors.background.sidebar.default,
            textAlign: 'left',
            justifyContent: 'flex-start',
            cursor: 'default',
            avatar: {
                width: toRem(46),
                height: toRem(46),
                margin: toRems([0, 10, 0, 0]),
                padding: 0,
                fontSize: toRem(32)
            }
        },
        navbar: {
            height: '100%',
            padding: 0,
            background: 'none',
            textAlign: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            avatar: {
                width: toRem(38),
                height: toRem(38),
                margin: 0,
                padding: toRem(1),
                fontSize: toRem(22)
            }
        }
    }

    return {
        position: 'relative',
        width: '100%',
        height: config[variant].height,
        zIndex: theme.zIndex.over + 1,
        padding: config[variant].padding,
        background: config[variant].background,
        textAlign: config[variant].textAlign,
        cursor: config[variant].cursor,
        a: {
            textDecoration: 'none',
            color: theme.colors.navigation.account.text.default
        },
        '& .userNav': {
            '&_inner': {
                padding: toRem(10),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: config[variant].height,
                justifyContent: config[variant].justifyContent,
                cursor: 'pointer'
            },
            '&_avatar': {
                flexShrink: 0,
                position: 'relative',
                width: config[variant].avatar.width,
                height: config[variant].avatar.height,
                margin: config[variant].avatar.margin,
                padding: config[variant].avatar.padding,
                borderRadius: '100%',
                background: hasImage ? 'transparent' : theme.colors.navigation.account.background.avatar,
                [theme.breakpoints.up('xxxl')]: {
                    width: toRem(60),
                    height: toRem(60)
                },
                '&::after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    zIndex: theme.zIndex.over,
                    top: toRem(-2),
                    left: toRem(-2),
                    width: `calc(100% + ${toRem(4)})`,
                    height: `calc(100% + ${toRem(4)})`,
                    borderStyle: 'solid',
                    borderWidth: toRem(2),
                    borderColor: theme.colors.navigation.account.background.avatar,
                    borderRadius: '100%',
                    pointerEvents: 'none',
                    transitionDuration: theme.transitions.navigation.icon.default.duration,
                    transitionTimingFunction: theme.transitions.navigation.icon.default.timingFunction,
                    transitionDelay: theme.transitions.navigation.icon.default.delay,
                    transitionProperty: 'transform, opacity',
                    transform: 'scale(0.8)',
                    opacity: 0,
                    ...(isActive && {
                        transform: 'scale(1)',
                        opacity: 1
                    })
                },
                '&Wrap': {
                    position: 'relative',
                    zIndex: theme.zIndex.over + 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    borderRadius: '100%',
                    fontSize: config[variant].avatar.fontSize,
                    color: hasImage
                        ? theme.colors.navigation.account.text.default
                        : theme.colors.navigation.account.text.avatar,
                    svg: {
                        path: {
                            fill: 'currentColor'
                        }
                    }
                }
            },
            '&_info': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                lineHeight: 1.4,
                '&Label': {
                    fontSize: toRem(18),
                    fontWeight: theme.typography.fontWeight.black
                },
                '&Name': {
                    fontSize: toRem(14),
                    color: theme.colors.navigation.account.text.username
                }
            },
            '&_action': {
                flexShrink: 0,
                marginLeft: 'auto',
                paddingLeft: toRem(10),
                '&Btn': {
                    width: toRem(40),
                    height: toRem(40)
                }
            }
        }
    }
}

export default style
