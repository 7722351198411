const cardVariationHandler = variation => {
    switch (variation) {
        case '2x2':
        case '1x1':
            return 'Ratio 4:3'
        case '2x1':
            return 'Ratio 16:9'
        case '1x2':
            return 'Ratio 2:3'
        default:
            return '4:3'
    }
}

export default cardVariationHandler
