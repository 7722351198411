/* eslint-disable no-console */
// @TODO: Think about moving BaseNavigation (Navigation component) directly inside here

import { useEffect, useRef, useState } from 'react'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { NavigationAccountActions, NavigationActions, SearchActions } from '@hmn/coolinarika-web-core/actions'
import { DRAWER_ACTIONS, useDrawerDispatch, useDrawerState } from '@hmn/coolinarika-web-core/context/Drawer'
import { _loadDfpSlots, _reloadDfpSlots, _resetDfpSlots } from '@hmn/coolinarika-web-core/controllers/AdsProvider'
import { lg, useAnalytics, useBreakpoints, useDrawer, useSession } from '@hmn/coolinarika-web-core/hooks'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Drawer, SearchV2 } from '@hmn/coolinarika-web-ui/components'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { drawerUID } from '@hmn/coolinarika-web-ui/components/Auth/uids'

import { fbqTrack } from '@web/utils/fbPixel'

import style from './BaseLayout.style'
import Content from './Content.component'
import Sidebar from './Sidebar.component'

const { NEW_PASSWORD: NEW_PASSWORD_DRAWER_UID } = drawerUID
const { REGISTER: REGISTER_DRAWER_UID } = drawerUID

const RecipeUploader = dynamic(() => import('@hmn/coolinarika-web-ui/components/RecipeUploader'), { ssr: false })
// const Search = dynamic(() => import('@hmn/coolinarika-web-ui/components/Search/components'), { ssr: true })
// const Drawer = dynamic(() => import('@hmn/coolinarika-web-ui/components/Drawer'), { ssr: false })

const LayoutStyled = styled.main(props => ({ ...style(props) }))

const homepage = '16CbwOufSIGnG357b0TeO7R2zZAyHScEBHxA.l.ktxD.l7'
const otherRoutes = '1wCVJOsf7dOnZT7XJ3A3U2YlLZo1w_c0VUz6czWaUh3.p7'

const useWtg = process.env.NEXT_PUBLIC_GLOBAL_BANNERS_USE_WTG === 'true'
const virtualPageViewInterval = process.env.NEXT_PUBLIC_WEB_RECIPE_PAGEVIEW_INTERVAL

// eslint-disable-next-line react/prop-types
const BaseLayout = ({ children, layout, history }) => {
    const router = useRouter()
    const { query, pathname, asPath } = router
    const dispatch = useDispatch()
    const { pageView } = useAnalytics()
    const { isOpen: isNavigationOpen } = useSelector(state => state.navigation, shallowEqual)
    const { isOpen: isSearchOpen } = useSelector(state => state.search, shallowEqual)
    const { isOpen: isNavigationAccountOpen } = useSelector(state => state.navigationAccount, shallowEqual)
    const { isOpen: isDrawerOpen, closeDrawerOnRouteChange } = useDrawerState()
    const { isOpen: isRecipeUploaderOpen, isAnimationComplete: isRecipeUploaderAnimationComplete } = useSelector(
        state => state.recipeUploader,
        shallowEqual
    )

    const [session] = useSession()

    const recipePageViewTimeoutRef = useRef(null)

    const [isDesktop] = useBreakpoints(lg)
    const [_isLoading, setIsLoading] = useState(false)

    const previousRoute = useRef(router?.asPath) // Using ref as we need this to behave like static

    const { open: openNewPasswordDrawer } = useDrawer(NEW_PASSWORD_DRAWER_UID)
    const { open: openRegisterDrawer } = useDrawer(REGISTER_DRAWER_UID)

    const firePageViewTrackingEvents = () => {
        try {
            window?.pp_gemius_hit(router?.asPath === '/' ? homepage : otherRoutes)
            window?.dm?.AjaxEvent('pageview')
            fbqTrack.init('255665094904221')
            fbqTrack.pageView()
            pageView()
        } catch (error) {
            // Ignore errors thrown by metrics
        }
    }

    useEffect(() => {
        if (layout !== 'embed') {
            firePageViewTrackingEvents()
        }
        // loadDfpSlots()
    }, [])

    useEffect(() => {
        // Password reset
        if (query && query?.['zaboravljena-lozinka']) {
            setTimeout(() => {
                openNewPasswordDrawer()
            }, 1500)
        }

        // Facebook registration
        if (
            (query && query?.code && !!query?.code?.length) ||
            (session?.shouldCreatePioAccount && session?.facebookToken)
        ) {
            setTimeout(() => {
                openRegisterDrawer()
            }, 1500)
        }
    }, [query, dispatch, session])

    useEffect(() => {
        if (pathname.includes('recept') || asPath.includes('recept') || pathname.includes('inspiracija/[slug]')) {
            recipePageViewTimeoutRef.current = setInterval(
                firePageViewTrackingEvents,
                1000 * 60 * parseInt(virtualPageViewInterval || '5', 10)
            )
        }

        return () => {
            if (recipePageViewTimeoutRef.current !== null) {
                clearInterval(recipePageViewTimeoutRef.current)
            }
        }
    }, [pathname, asPath])

    useEffect(() => {
        const isBodyLocked = isRecipeUploaderOpen && isRecipeUploaderAnimationComplete
        document.body.style.overflow = isBodyLocked ? 'hidden' : ''
    }, [isRecipeUploaderOpen, isRecipeUploaderAnimationComplete])

    const drawerDispatch = useDrawerDispatch()

    useEffect(() => {
        const handleRouteChangeComplete = e => {
            if (isNavigationOpen) {
                dispatch(NavigationActions.close())
            }

            // if (isSearchOpen && isReadOnly) {
            //     batch(() => {
            //         dispatch(SearchActions.close())
            //         dispatch(SearchActions.manuallyClosed(false))
            //     })
            // }

            if (isNavigationAccountOpen && !isDesktop) {
                dispatch(NavigationAccountActions.closeAccount())
            }

            if (
                closeDrawerOnRouteChange &&
                isDrawerOpen &&
                !query?.code &&
                !query?.code?.length &&
                !query?.['zaboravljena-lozinka']
            ) {
                drawerDispatch({
                    type: DRAWER_ACTIONS.CLOSE_DRAWER,
                    payload: {
                        drawerType: null
                    }
                })
            }

            dispatch(NavigationActions.setIsNavigating(false))

            setIsLoading(false)
            firePageViewTrackingEvents()

            /* if (typeof window !== 'undefined' && window?.googletag) {
                const { googletag } = window

                googletag.cmd.push(() => googletag.destroySlots())
            } */
        }

        const handleRouteChangeStart = route => {
            if (typeof window !== 'undefined' && window?.googletag && !useWtg) {
                const { googletag } = window

                googletag.cmd.push(() => {
                    // console.warn('[AdProvider]: Destroying all slots before navigation...')
                    googletag.destroySlots()
                })
            }

            setIsLoading(true)

            dispatch(NavigationActions.setIsNavigating(true))

            if (previousRoute.current.split('/').filter(Boolean).length >= route.split('/').filter(Boolean).length) {
                dispatch(NavigationActions.setDirection('POP'))
            } else {
                dispatch(NavigationActions.setDirection('PUSH'))
            }

            previousRoute.current = route

            if (process.browser) {
                window.sessionStorage.setItem('ClickingIndicator', 1)
            }
            // reloadDfpSlots()
        }

        router.events.on('routeChangeComplete', handleRouteChangeComplete)
        router.events.on('routeChangeStart', handleRouteChangeStart)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
            router.events.off('routeChangeStart', handleRouteChangeStart)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isNavigationOpen, isSearchOpen, isNavigationAccountOpen, isDrawerOpen, isDesktop, batch])

    useEffect(() => {
        const { previous, current } = history
        const [currentPath] = current.split('?')
        const [previousPath, previousParams] = previous?.split('?') || []

        if (currentPath !== previousPath && previousParams) {
            router.replace(currentPath, currentPath, { shallow: true })
            dispatch(SearchActions.close())
        }
    }, [history])

    if (layout === 'embed') {
        return children
    }

    return (
        <LayoutStyled>
            <Sidebar />
            <Content>{children}</Content>
            <SearchV2 />
            {/* {isLoading && <Loader />} */}
            <RecipeUploader />

            <Drawer />

            {/* {isDrawerOpen && isRecipeUploaderAnimationComplete && <Drawer />} */}
        </LayoutStyled>
    )
}

BaseLayout.propTypes = {
    children: PropTypes.node.isRequired,
    layout: PropTypes.string,
    history: PropTypes.shape({
        previous: PropTypes.string,
        current: PropTypes.string
    }).isRequired
}

BaseLayout.defaultProps = {
    layout: 'default'
}

export default BaseLayout
