/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { useAnalytics } from '@hmn/coolinarika-web-core/hooks'

// @NOTE: Sometimes Link component does not need 'a' tag in it because the component will handle the tag (see Button)

const HmnLink = ({
    children,
    href,
    as,
    passHref,
    prefetch,
    scroll,
    noTag,
    title,
    isExternal,
    className,
    noTracking,
    trackingLabel,
    ...rest
}) => {
    const { query } = useRouter()

    const globalSearchParams = useMemo(() => {
        if (!query?.pretrazivanje) {
            return {}
        }

        return {
            pretrazivanje: query.pretrazivanje
        }
    }, [query])

    const { eventWithTrackingData } = useAnalytics('coolinarika.itemClick', {
        trackingData: {
            elementCategory: 'link',
            elementLabel: trackingLabel || as?.pathname || as || href,
            elementPageLocation: 'body'
        }
    })

    const handleClick = () => {
        if (!noTracking) {
            eventWithTrackingData()
        }
    }

    if (!children) {
        return null
    }

    return (
        <span onClick={handleClick}>
            {isExternal ? (
                <a href={href} title={title} className={className} rel="noopener noreferrer" target="_blank" {...rest}>
                    {children}
                </a>
            ) : (
                <Link
                    legacyBehavior
                    href={{
                        pathname: href?.pathname || href,
                        query: {
                            ...(href?.query || {}),
                            ...globalSearchParams
                        }
                    }}
                    as={{
                        pathname: as?.pathname || as || href,
                        query: {
                            ...(as?.query || {}),
                            ...globalSearchParams
                        }
                    }}
                    passHref={noTag ? passHref : false}
                    prefetch={prefetch}
                    scroll={scroll}>
                    {!noTag ? (
                        <a className={className} title={title} {...rest}>
                            {children}
                        </a>
                    ) : (
                        children
                    )}
                </Link>
            )}
        </span>
    )
}

HmnLink.propTypes = {
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    passHref: PropTypes.bool,
    prefetch: PropTypes.bool,
    scroll: PropTypes.bool,
    title: PropTypes.string,
    noTag: PropTypes.bool,
    isExternal: PropTypes.bool,
    trackingLabel: PropTypes.string,
    noTracking: PropTypes.bool,
    className: PropTypes.string
}

HmnLink.defaultProps = {
    href: undefined,
    as: undefined,
    passHref: false,
    prefetch: false,
    scroll: true,
    title: undefined,
    noTag: false,
    isExternal: false,
    trackingLabel: undefined,
    noTracking: false,
    className: undefined
}

export default HmnLink
