import { forwardRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './SvgIcon.style'

const SvgIconStyled = styled.svg(props => ({ ...styles(props) }))

const SvgIcon = forwardRef(
    ({ children, iconComponent: IconComponent, className, size, color, titleAccess, viewBox, ...other }, ref) => (
        <SvgIconStyled
            as={IconComponent}
            className={className}
            focusable="false"
            viewBox={viewBox}
            size={size}
            color={color}
            aria-hidden={titleAccess ? undefined : true}
            role={titleAccess ? 'img' : undefined}
            ref={ref}
            {...other}>
            {children}
            {titleAccess ? <title>{titleAccess}</title> : null}
        </SvgIconStyled>
    )
)

SvgIcon.propTypes = {
    className: PropTypes.string,
    iconComponent: PropTypes.elementType,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Provides a human-readable title for the element that contains it
     * https://www.w3.org/TR/SVG-access/#Equivalent
     */
    titleAccess: PropTypes.string,
    /**
     * Allows you to redefine what the coordinates without units mean inside an SVG element.
     * For example, if the SVG element is 500 (width) by 200 (height),
     * and you pass viewBox="0 0 50 20",
     * this means that the coordinates inside the SVG will go from the top left corner (0,0)
     * to bottom right (50,20) and each unit will be worth 10px
     */
    viewBox: PropTypes.string
}

SvgIcon.defaultProps = {
    className: undefined,
    color: 'text.default',
    size: 'inherit',
    iconComponent: 'svg',
    titleAccess: undefined,
    viewBox: '0 0 32 32'
}

export default SvgIcon
