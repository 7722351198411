// @TODO: heading size with variants

import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../../../ErrorBoundary'
import {
    HeadingLabel,
    headingLabelSizes,
    headingLabelVariants,
    HeadingSection,
    headingSectionSizes
} from '../../../Heading/components'
import { HtmlContent } from '../../../HtmlContent'
import { WaveShortIcon } from '../../../Icon'
import { Logo } from '../../../Logo'
import styles from './Main.style'

const HeadingSectionMainStyled = styled.section(props => ({ ...styles(props) }))

const HeadingSectionMain = ({
    title,
    titleTagComponent: TagComponent,
    titleDecoration,
    lead,
    label,
    className,
    hideLogo,
    beforeTitleComponent: BeforeTitleComponent,
    headingSize,
    hasShadow,
    largeLineHeight,
    ...rest
}) => {
    if (!title) {
        return null
    }

    return (
        <HeadingSectionMainStyled
            className={className}
            hasShadow={!!hasShadow}
            largeLineHeight={largeLineHeight}
            {...rest}>
            {!hideLogo && (
                <div className="headingSectionMain_logoContainer">
                    <Logo byPodravka decoration className="headingSectionMain_logo" href="/" />
                </div>
            )}

            {label && (
                <div className="headingSectionMain_label">
                    <HeadingLabel variant={headingLabelVariants.SECONDARY} size={headingLabelSizes.NORMAL} isUppercase>
                        <HtmlContent content={label} wrapperComponent="span" />
                    </HeadingLabel>
                </div>
            )}

            <header className="headingSectionMain_header">
                <HeadingSection
                    size={headingSectionSizes[headingSize]}
                    TagComponent={TagComponent}
                    className="headingSectionMainHeader_title">
                    {BeforeTitleComponent && <BeforeTitleComponent />}
                    <HtmlContent content={title} wrapperComponent="span" />
                </HeadingSection>

                {titleDecoration && (
                    <span aria-hidden role="presentation" className="headingSectionMain_wave primary" />
                )}
            </header>

            {lead && (
                <div className="headingSectionMain_body">
                    <span aria-hidden role="presentation" className="headingSectionMain_wave secondary">
                        <WaveShortIcon viewBox="0 0 37 5" width="37" height="5" />
                    </span>
                    <HtmlContent content={lead} className="headingSectionMain_lead" wrapperComponent="div" />
                </div>
            )}
        </HeadingSectionMainStyled>
    )
}

HeadingSectionMain.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    titleDecoration: PropTypes.bool,
    lead: PropTypes.string,
    label: PropTypes.string,
    beforeTitleComponent: PropTypes.elementType,
    hideLogo: PropTypes.bool,
    headingSize: PropTypes.string,
    hasShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    largeLineHeight: PropTypes.bool
}

HeadingSectionMain.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    titleDecoration: false,
    lead: undefined,
    label: undefined,
    beforeTitleComponent: undefined,
    hideLogo: false,
    headingSize: undefined,
    hasShadow: undefined,
    largeLineHeight: false
}

export default withErrorBoundary(HeadingSectionMain)
