import { decorationBackgroundSVG, toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, decoration, rounded }) => {
    const logoSize = {
        small: 20,
        default: 34,
        xxl: 40
    }
    const bySize = {
        // by Podravka text is 35% of logoSize
        small: logoSize.small * 0.35,
        default: logoSize.default * 0.35,
        xxl: logoSize.xxl * 0.35
    }

    return {
        display: 'inline-flex',
        flexDirection: 'column',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        cursor: 'default',
        ...(decoration && {
            display: 'flex',
            flexDirection: 'row'
        }),
        ...(rounded && {
            maxWidth: toRem(150),
            borderRadius: toRem(100),
            backgroundColor: theme.colors.white,
            boxShadow: theme.shadows.deep
        }),
        a: {
            outline: 'none !important',
            textDecoration: 'none !important'
        },
        '& .coolLogo': {
            ...(rounded && {
                width: '100%'
            }),
            margin: 0,
            padding: 0,
            '&_txt': {
                display: 'block',
                fontStyle: theme.typography.font.style.normal,
                fontWeight: theme.typography.fontWeight.black,
                color: theme.colors.logo.primary.default,
                lineHeight: 1.3,
                ...(!rounded && {
                    fontSize: toRem(logoSize.default),
                    [theme.breakpoints.up('xxl')]: {
                        fontSize: toRem(logoSize.xxl)
                    },
                    [theme.breakpoints.up('xxxl')]: {
                        fontSize: toRem(logoSize.default)
                    }
                }),
                ...(rounded && {
                    // yeah....this sucks
                    padding: toRems([9, 20, 13]),
                    [theme.breakpoints.up('md')]: {
                        padding: toRems([10, 20, 12])
                    },
                    lineHeight: 1,
                    fontSize: toRem(logoSize.small)
                }),
                '& > span': {
                    color: theme.colors.logo.primary.cool
                },
                '& > p': {
                    position: 'absolute ',
                    display: 'inline',
                    marginTop: toRem(logoSize.small / 1.3),
                    fontSize: toRem(logoSize.small / 2)
                }
            },
            '&_wave': {
                position: 'relative',
                display: 'flex',
                flex: 1,
                top: toRem(5),
                marginLeft: toRem(20),
                width: '100%',
                height: toRem(5),
                backgroundColor: 'transparent',
                backgroundImage: decorationBackgroundSVG(theme.colors.separator.default),
                backgroundRepeat: 'repeat-x',
                backgroundPosition: '0 center',
                backgroundSize: 'contain'
            },
            '&_by': {
                display: 'block',
                marginTop: toRem(-2),
                paddingRight: toRem(2),
                lineHeight: 1,
                textAlign: 'right',
                whiteSpace: 'nowrap',
                color: theme.colors.logo.primary.text,
                fontWeight: theme.typography.fontWeight.black,
                fontSize: toRem(bySize.default),
                [theme.breakpoints.up('xxl')]: {
                    fontSize: toRem(bySize.xxl)
                },
                [theme.breakpoints.up('xxxl')]: {
                    fontSize: toRem(bySize.default)
                }
            }
        }
    }
}

export default style
