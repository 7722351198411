import { createContext, useEffect, useRef, useState } from 'react'

import throttle from '../../helpers/throttle'

const WindowContext = createContext({
    width: 0,
    height: 0
})

const getSize = () => {
    if (!process.browser || typeof window === 'undefined') {
        return {
            width: 0,
            height: 0
        }
    }
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
}

const WindowContextProvider = ({ children }) => {
    const [size, setSize] = useState(getSize)

    // `useRef` instead of `useState` to persist scroll state without re-render
    const isScrollingDown = useRef(false)
    const scrollY = useRef(0)

    // Trigger re-render by settings these
    const [isScrollingDownValue, setIsScrollingDownValue] = useState(0)
    const [scrollYValue, setScrollYValue] = useState(0)

    useEffect(() => {
        if (!process.browser) {
            return () => {}
        }

        const onResize = () => {
            setSize(getSize())
        }

        const onScroll = () => {
            const y = window.scrollY
            // if scroll has changed
            if (y !== scrollY.current) {
                isScrollingDown.current = y > scrollY.current
                scrollY.current = y

                // trigger re-render
                setIsScrollingDownValue(isScrollingDown.current)
                setScrollYValue(scrollY.current)
            }
        }

        const onResizeThrottled = throttle(onResize, 250)
        window.addEventListener('resize', onResizeThrottled)
        // Trigger initial resize event
        // onResizeThrottled()

        const onScrollThrottled = throttle(onScroll, 250)
        window.addEventListener('scroll', onScrollThrottled)

        return () => {
            window.removeEventListener('resize', onResizeThrottled)
            window.removeEventListener('scroll', onScrollThrottled)
        }
    }, [])

    return (
        <WindowContext.Provider
            value={{
                width: size.width,
                height: size.height,
                y: scrollYValue,
                direction: isScrollingDownValue ? 'down' : 'up'
            }}>
            {children}
        </WindowContext.Provider>
    )
}

export { WindowContextProvider }

export default WindowContext
