import { toRem, toRems } from '@hmn/coolinarika-web-ui/helpers/theme'

const getColumnGridProps = (gridColumnSpan = 12) => ({
    gridColumn: `span ${gridColumnSpan}`
})

const form = {
    row: {
        columns: {
            flexDirection: 'column'
        }
    },
    column: {
        width: '100%',
        mb: toRems([25, 25, 25, 28, 28, 28, 28, 28], true),
        small: {
            width: '100%',
            mb: toRem(15)
        },
        large: {
            width: '100%',
            mb: toRem(45)
        },
        auth: {
            mb: toRems([15, 15, 15, 20, 20, 20, 20, 20], true)
        }
    },
    survey: {
        row: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            mx: toRems([-5, -5, -5, -7, -7, -7, -7, -7], true),
            '& > div': {
                // Taste profile survey ingredient pills, hardcode
                // margins because of 'party' bug
                WebkitTapHighlightColor: 'transparent',
                marginLeft: '2px',
                marginRight: '2px'
            }
        },
        rowLeft: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            mx: toRems([-5, -5, -5, -7, -7, -7, -7, -7], true)
        },
        column: {
            width: 'auto',
            mx: toRems([5, 5, 5, 7, 7, 7, 7, 7], true),
            mb: toRems([10, 10, 10, 14, 14, 14, 14, 14], true)
        }
    },
    recipe: {
        grid: {
            row: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridColumnGap: toRems([10, 10, 10, 20, 20, 20, 20, 20], true),
                gridRowGap: toRems([25, 25, 25, 40, 40, 40, 40, 40], true),
                alignItems: 'flex-start',
                mb: toRems([25, 25, 25, 40, 40, 40, 40, 40], true),
                width: '100%',
                maxWidth: '100%'
            },
            column: {
                12: {
                    ...getColumnGridProps(12)
                },
                11: {
                    ...getColumnGridProps(11)
                },
                10: {
                    ...getColumnGridProps(10)
                },
                9: {
                    ...getColumnGridProps(9)
                },
                8: {
                    ...getColumnGridProps(8)
                },
                7: {
                    ...getColumnGridProps(7)
                },
                6: {
                    ...getColumnGridProps(6)
                },
                5: {
                    ...getColumnGridProps(5)
                },
                4: {
                    ...getColumnGridProps(4)
                },
                3: {
                    ...getColumnGridProps(3)
                },
                2: {
                    ...getColumnGridProps(2)
                },
                1: {
                    ...getColumnGridProps(1)
                }
            }
        },
        tags: {
            row: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                mb: toRem(-10)
            },
            column: {
                width: 'auto',
                margin: toRems([0, 10, 10, 0])
            }
        }
    }
}

export default {
    form
}
