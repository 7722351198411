// @TODO: Close button when we get Button component variants fully done

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { NavigationActions } from '@hmn/coolinarika-web-core/actions'

import { Button, buttonSizes, buttonVariants } from '../../../Button'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { CloseIcon, Icon } from '../../../Icon'
import styles, { motionVariants } from './Panel.style'

const PanelStyled = styled.div(props => ({ ...styles(props) }))

const Panel = ({ className, children, ...rest }) => {
    const dispatch = useDispatch()

    const handleNavigationToggle = useCallback(() => dispatch(NavigationActions.toggle()), [dispatch])

    const { isOpen: isNavigationOpen } = useSelector(state => state.navigation)
    const [isMenuOpen, setIsMenuOpen] = useState(isNavigationOpen)

    useEffect(() => {
        setIsMenuOpen(isNavigationOpen)
    }, [isNavigationOpen])

    return (
        <PanelStyled className={className} {...rest}>
            <motion.div
                className="mobileNavigationPanel"
                initial="closed"
                animate={isMenuOpen ? 'open' : 'closed'}
                variants={motionVariants()}>
                <div className="mobileNavigationPanel_inner">{children}</div>
                <Button
                    className="mobileNavigationPanel_close"
                    variant={buttonVariants.TERTIARY}
                    size={buttonSizes.NORMAL}
                    sizeMobile={buttonSizes.SMALL}
                    title="Zatvori"
                    onClick={handleNavigationToggle}
                    isIconOnly>
                    <span className="showForSr">Zatvori</span>
                    <Icon icon={CloseIcon} size={32} />
                </Button>
            </motion.div>
        </PanelStyled>
    )
}

Panel.propTypes = {
    className: PropTypes.string
}

Panel.defaultProps = {
    className: undefined
}

export default withErrorBoundary(Panel)
