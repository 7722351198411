// @NOTE: Some menu items are hardcoded in because we need complex functionality for them and they are default menu items no matter what

import { Fragment, memo, useCallback, useContext, useEffect } from 'react'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { NavigationAccountActions, RecipeUploaderActions } from '@hmn/coolinarika-web-core/actions'
import { NotificationsContext } from '@hmn/coolinarika-web-core/controllers'
import { useAnalytics, useDrawer, useUser } from '@hmn/coolinarika-web-core/hooks'

import { withErrorBoundary } from '../../../../ErrorBoundary'
import { ScrollBar } from '../../../../ScrollBar'
import { colorVariants, Item, itemVariants } from '../../Item'
import styles from './Menu.style'

const selector = ({ recipeUploader }) => ({
    isRecipeUploaderOpen: recipeUploader.isOpen
})

const AccountMenuStyled = styled.div(props => ({ ...styles(props) }))

const AccountMenu = ({ className, accountData, ...rest }) => {
    const dispatch = useDispatch()
    const { isRecipeUploaderOpen } = useSelector(selector, shallowEqual)
    const uid = useUIDSeed()
    const { eventTracking } = useAnalytics('coolinarika.itemView')

    const { logOut } = useUser()

    const notifications = useContext(NotificationsContext)

    const { eventWithTrackingData } = useAnalytics('coolinarika.mainItemClick', {
        trackingData: {
            elementCategory: 'menuItem',
            elementPageLocation: 'header'
        }
    })

    const handleTracking = ({ label }) => {
        eventWithTrackingData({
            elementLabel: label
        })
    }

    // Recipe Uploader open
    const handleRecipeUploaderToggle = useCallback(() => {
        eventWithTrackingData({
            elementLabel: 'Dodaj recept'
        })

        batch(() => {
            dispatch(RecipeUploaderActions.setDraftId(null))
            dispatch(RecipeUploaderActions.setRecipeId(null))
            dispatch(RecipeUploaderActions.setIsNewRecipe(true))
            dispatch(NavigationAccountActions.closeAccount())
        })

        dispatch(RecipeUploaderActions.toggle())
    }, [dispatch, eventWithTrackingData])

    const { open: openImageDrawer } = useDrawer('drawer-image-uploader-gallery', {
        gtmData: { action: 'Photo', label: 'Add Photo' }
    })

    const { open: openNotificationsDrawer } = useDrawer('drawer-user-notifications', {
        gtmData: {
            action: 'Notification',
            label: 'Show Notifications'
        }
    })

    useEffect(() => {
        if (isRecipeUploaderOpen) {
            eventTracking({
                eventCategory: 'Drawer - View',
                eventAction: 'Recipe',
                eventLabel: 'Dodaj recept'
            })
        }
    }, [isRecipeUploaderOpen])

    if (!accountData?.length) {
        return null
    }

    return (
        <AccountMenuStyled className={className} {...rest}>
            <ScrollBar hasTopShadow hasBottomShadow className="accountMenu_container">
                <nav className="accountMenu_nav">
                    <ul className="accountMenu_navList">
                        {/* Recipe Uploader Button */}
                        <li>
                            <Item
                                title="Dodaj recept"
                                variant={itemVariants.BUTTON}
                                icon="recipeAdd"
                                onClick={handleRecipeUploaderToggle}
                            />
                        </li>

                        {/* Image Uploader Button */}
                        <li>
                            <Item
                                title="Dodaj sliku"
                                variant={itemVariants.BUTTON}
                                icon="imageAdd"
                                onClick={openImageDrawer}
                            />
                        </li>

                        {/* Account navigation items */}
                        {accountData.map((item, index) => {
                            const notificationsPosition = accountData.length - 2
                            return (
                                <Fragment key={uid(`${item?.title}-${index}`)}>
                                    <li>
                                        <Item
                                            href={item?.href}
                                            hrefAs={item?.hrefAs}
                                            icon={item?.icon}
                                            title={item?.title}
                                            items={item?.items}
                                            onClick={item?.onClick}
                                            action={item?.action}
                                            variant={itemVariants[item?.variant?.toUpperCase()]}
                                            colorVariant={item?.colorVariant}
                                            expanded={item?.expanded}
                                            notifications={item?.notifications}
                                            onClickEventTracking={handleTracking}
                                        />
                                    </li>
                                    {index === notificationsPosition && (
                                        <li>
                                            <Item
                                                title="Notifikacije"
                                                icon="notifications"
                                                notifications={notifications?.unread?.length}
                                                onClick={openNotificationsDrawer}
                                                onClickEventTracking={handleTracking}
                                            />
                                        </li>
                                    )}
                                </Fragment>
                            )
                        })}

                        {/* Log Out */}
                        <li>
                            <Item
                                title="Odjavi me"
                                icon="logOut"
                                colorVariant={colorVariants.SUBTLE}
                                onClick={logOut}
                            />
                        </li>
                    </ul>
                </nav>
            </ScrollBar>
        </AccountMenuStyled>
    )
}

AccountMenu.propTypes = {
    className: PropTypes.string,
    accountData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

AccountMenu.defaultProps = {
    className: undefined,
    accountData: undefined
}

export default withErrorBoundary(memo(AccountMenu))
