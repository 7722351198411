import dataProvider from '@hmn/data-provider'

const getResourceUri = resource => (resource.indexOf('taxonomies/') === 0 ? resource : `taxonomies/${resource}`)

/**
 * Get a list of taxons from entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.entityId
 * @return {Object}
 * { data, total }
 */
export const getList = async (resource, params = {}) => {
    const { entity, entityId } = params
    const resourceUri = getResourceUri(resource)

    const result = await dataProvider.getListTaxon(resourceUri, {
        entity,
        entityId
    })

    const { data, total } = result

    return {
        data,
        total: total || data?.length || 0
    }
}

/**
 * Attach a taxon on entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.entityId
 * @param {string|number} params.taxonId
 * @return {Object}
 * { data }
 */
export const createOne = async (resource, params = {}) => {
    const { entity, entityId, taxonId } = params
    const resourceUri = getResourceUri(resource)

    const result = await dataProvider.createTaxon(resourceUri, {
        entity,
        entityId,
        data: { taxon: taxonId }
    })

    const { data } = result

    return {
        data
    }
}

/**
 * Remove a taxon from entity
 *
 * @param {string} resource
 * @param {Object} params
 * @param {string} params.entity
 * @param {string|number} params.entityId
 * @param {string|number} params.taxonId
 * @return {*}
 */
export const deleteOne = async (resource, params = {}) => {
    const { entity, entityId, taxonId } = params
    const resourceUri = getResourceUri(resource)

    await dataProvider.deleteTaxon(resourceUri, {
        entity,
        entityId,
        id: taxonId
    })

    return 'ok'
}
