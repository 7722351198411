/* eslint-disable camelcase */
/**
 * @TODO: Revisit and revalidate performance, both of this component and PIO API:
 *  * https://www.responsivebreakpoints.com/
 *  * https://web.dev/serve-responsive-images/
 *  * https://web.dev/serve-images-with-correct-dimensions/
 *  * https://web.dev/image-cdns/
 *  * https://web.dev/choose-the-right-image-format/
 *  * https://web.dev/compress-images/
 */

import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { globalSettings } from '@hmn/coolinarika-web-core/settings'

import { isBrowser } from '../../helpers/utils'
// Internal Image components
import { ProgressiveImage, ProgressiveImageStyled } from './components'
import styles from './Image.style'

const { constants } = globalSettings
const { IMAGES_RATIO_TOLERANCE, IMAGE_LQIP_FILTER_NAME, IMAGES_DIMENSION_TOLERANCE, IMAGES_TRANSITION_DURATION_MS } =
    constants

const isProd = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_APP_ENV === 'production'

// eslint-disable-next-line max-len
const shimmerFallbackBackground = `data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' width%3D'200' height%3D'150' viewBox%3D'0 0 200 150'%2F%3E`

/**
 *Replace url template from API source with actualy instance variables.
 *
 * @param {*} urlTemplate
 * @param {*} replaceMap
 * @return {*}
 */
const replaceAll = (urlTemplate = '', replaceMap) => {
    const re = new RegExp(Object.keys(replaceMap).join('|'), 'gi')

    return urlTemplate.replace(re, matched => replaceMap[matched.toLowerCase()])
}

/**
 * Get either source of an image or image id to use as a key in the image cache
 *
 * @param {*} image
 */
const getImageCacheKey = image => (image && image?.id) || image

/**
 * Cache the images we've already seen before, so we don't bother with lazy-loading & fading in on subsequent mounts or re-renders
 *
 * @param {*} image
 * @return {*}
 */
const imageCache = Object.create({})
const inImageCache = image => {
    const cacheKey = getImageCacheKey(image)
    return imageCache[cacheKey] || false
}

const activateCacheForImage = image => {
    const cacheKey = getImageCacheKey(image)

    if (cacheKey) {
        imageCache[cacheKey] = true
    }
}

/**
 * Test whether provided URL is base64
 *
 * @param {*} urlString
 * @return {*}
 */
const isBase64 = (urlString = '') => {
    if (urlString?.length && urlString?.split(',')?.length) {
        const [, base64Encoding] = urlString.split(',')
        const regexp = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
        return regexp.test(base64Encoding) ? urlString : undefined
    }

    return undefined
}

/**
 * Test whether provided image string is valid URL, if it's valid then return URL else return undefined
 *
 * @param {*} urlString
 * @return {*}
 */
const isValidImageUrl = (urlString = '') => {
    // eslint-disable-next-line max-len
    const regexp =
        // eslint-disable-next-line max-len
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    return regexp.test(urlString) ? urlString : undefined
}

/**
 * Test whether provided image string is local asset
 *
 * @param {*} urlString
 * @return {*}
 */
const isLocalImageUrl = (urlString = '') => {
    const regexp = /^(?!(?:https?|ftp):\/\/)?\/[^"]+?\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/
    return regexp.test(urlString) ? urlString : undefined
}

/**
 * Calculates image ratio from provided initial values or derives the ratio from config variations.
 *
 * @param {*} imageRatio
 * @param {*} variations
 * @param {*} initialWidth
 * @param {*} initialHeight
 * @return {*}
 */
const getImageRatio = (imageRatio, variations, initialWidth, initialHeight) => {
    const initialRatioFallback = parseInt(initialWidth, 10) / parseInt(initialHeight, 10) || 1

    if (imageRatio && typeof imageRatio !== 'string' && !Number.isNaN(imageRatio)) {
        // If provided initial ratio is number, we can use it right away
        return imageRatio
    }

    // Else Try and derive the image ratio from string, if provided, e.g. "4-3-35mm" or "4:3" or "1.3" should become 1.3333333
    if (imageRatio && typeof imageRatio === 'string' && !imageRatio?.toLowerCase().includes('original')) {
        return (
            variations.find(
                ({ aspect_ratio_name, aspect_ratio_slug }) =>
                    (aspect_ratio_name && aspect_ratio_name.toLowerCase().includes(imageRatio?.toLowerCase())) ||
                    (aspect_ratio_slug && aspect_ratio_slug.includes(imageRatio?.toLowerCase()))
            )?.aspect_ratio_value || initialRatioFallback
        )
    }

    if (imageRatio?.toLowerCase().includes('original')) {
        // If we're using original aspect ratio, we have to return false and derive it from image instance later
        return false
    }

    // Else return fallback
    return initialRatioFallback
}

/**
 * Derives variation instances only from config, no image response object is required.
 * Only image ID is required.
 *
 * @param {*} imageRatio
 * @param {*} variations
 * @return {*}
 */
const getImageVariationFromConfig = (imageRatio, variations) => {
    // When we have ratio, frist try to find variation using the exact ratio
    let variation = variations.find(configVariation => configVariation.aspect_ratio_value === imageRatio)

    // If no exact variation is found,  try and find image variation within configured ratio tolerance
    if (!variation) {
        variation = variations.find(
            ({ aspect_ratio_value }) =>
                aspect_ratio_value && Math.abs(aspect_ratio_value - imageRatio) <= IMAGES_RATIO_TOLERANCE
        )
    }

    // If we still don't have our variation, e.g. if no variation matches aspect ratio value, use first available fill variation or just use first variation
    if (!variation) {
        variation = variations.find(({ method }) => method === 'fill')?.[0] || variations[0]
    }

    return variation
}

/**
 * Derives variation instances from both image config and image response object,
 * used to get real image ratio. Image response object is required.
 *
 * @param {*} configVariations
 * @param {*} imageVariations
 * @return {*}
 */
const getImageVariationFromConfigAndImage = (configVariations, imageVariations) => {
    const originalAspectVariationFromConfig = configVariations.find(
        configVar =>
            !configVar?.aspect_ratio_name &&
            !configVar?.aspect_ratio_value &&
            configVar?.name.toLowerCase().includes('original')
    )

    const originalAspectVariationFromImage = imageVariations.find(imageVar =>
        imageVar?.url_template?.toLowerCase().includes(originalAspectVariationFromConfig?.id)
    )

    // Merge config values with real dimension values derived from image instance
    const instances = [
        ...new Set(
            originalAspectVariationFromImage?.url_vars?.instance
                .map(imageInstance => {
                    // Check if config instance exists that matches image instance
                    const validInstance = originalAspectVariationFromConfig.instances.find(instance =>
                        imageInstance.filename.includes(instance.id)
                    )

                    if (validInstance && Object.keys(validInstance || {})?.length) {
                        const originalWidth = imageInstance?.width
                        const originalHeight = imageInstance?.height

                        return JSON.stringify({
                            ...validInstance, // These are needed to construct valid URL reference later
                            original_max_width: originalWidth, // These two are used purely for UI stuff
                            original_max_height: originalHeight
                        })
                    }

                    return false
                })
                .filter(Boolean)
        )
    ].map(item => JSON.parse(item))

    return {
        ...originalAspectVariationFromConfig,
        instances
    }
}

/**
 * Derives image instances from either config or image response objects, based on variation instances provided
 *
 * @param {*} instances
 * @param {*} width
 * @param {*} height
 * @param {boolean} maxWidth
 * @param {boolean} maxHeight
 * @return {*}
 */
const getImageInstance = (instances, width, height, maxWidth, maxHeight) => {
    let optimalInstance
    let optimalInstanceId
    let instanceRatio
    let instanceMaxWidth
    let instanceMaxHeight

    const { id: lqipInstanceId } =
        instances.find(variationInstance =>
            variationInstance?.filters?.toLowerCase()?.includes(IMAGE_LQIP_FILTER_NAME?.toLowerCase())
        ) || {}

    const instanceWidth = parseInt(`${width}`.replace(/[^0-9]+/g, ''), 10)
    const instanceSizes = `(max-width: ${instanceWidth}px) 100vw, ${instanceWidth}px`

    if (!Number.isNaN(parseFloat(instanceWidth))) {
        optimalInstance = instances.find(
            variationInstance => Math.abs(variationInstance.max_width - instanceWidth) <= IMAGES_DIMENSION_TOLERANCE
        )
    } else if (!Number.isNaN(parseFloat(height))) {
        optimalInstance = instances.find(
            variationInstance => Math.abs(variationInstance.max_height - height) <= IMAGES_DIMENSION_TOLERANCE
        )
    }

    if (optimalInstance && Object.keys(optimalInstance).length) {
        // eslint-disable-next-line no-extra-semi
        ;({ id: optimalInstanceId } = optimalInstance)

        // If we're using the "original" as image ratio, we need to calculate it from instance dimensions
        const { original_max_width, original_max_height } = optimalInstance
        if (original_max_width && original_max_height) {
            instanceRatio = original_max_width / original_max_height

            if (maxWidth) {
                instanceMaxWidth = original_max_width
            }

            if (maxHeight) {
                instanceMaxHeight = original_max_height
            }
        }
    }

    return {
        lqipInstanceId,
        instanceWidth,
        instanceSizes,
        instanceRatio,
        optimalInstanceId,
        instanceMaxWidth,
        instanceMaxHeight
    }
}

const generateImageURLFromTemplate = (urlTemplate, imageId, width, height, instanceId, instanceType) => {
    const replacements = {
        ...(!isProd
            ? {}
            : { 'https://podravkaiovariations.blob.core.windows.net': 'https://podravkaiovariations.azureedge.net' }),
        '{image_id}': imageId,
        '{instance_max_width}': width,
        '{instance_max_height}': height,
        '{instance_id}': instanceId,
        '{format_extension}': instanceType
    }

    return replaceAll(urlTemplate, replacements)
}

/**
 * Generates image srcset for WebP, JPEG, provide placeholder values and source value for onLoad image trigger
 *
 * @param {*} instances
 * @param {*} sizes
 * @param {*} initialType
 * @param {*} imageId
 * @param {*} urlTemplate
 * @param {*} lqipInstanceId
 * @param {*} optimalInstanceId
 * @param {*} availableFormats
 * @return {*}
 */
const getImageData = (
    instances,
    sizes,
    initialType,
    imageId,
    urlTemplate,
    lqipInstanceId,
    optimalInstanceId,
    availableFormats
) => {
    const webpSrcSet = []
    const jpegSrcSet = []
    let lqip
    let src

    instances.forEach(instance => {
        const { max_height, max_width, id: instanceId } = instance
        // While iterating over instances, if current instance has LQIP filter, use it as LQIP src, else use it as srcset
        if (instanceId === lqipInstanceId) {
            lqip = generateImageURLFromTemplate(
                urlTemplate,
                imageId,
                max_width,
                max_height,
                instanceId,
                initialType || 'webp'
            )
        }

        if (instanceId === optimalInstanceId) {
            src = generateImageURLFromTemplate(
                urlTemplate,
                imageId,
                max_width,
                max_height,
                instanceId,
                initialType || 'webp'
            )
        }

        webpSrcSet.push(
            `${generateImageURLFromTemplate(
                urlTemplate,
                imageId,
                max_width,
                max_height,
                instanceId,
                'webp'
            )} ${max_width}w`
        )

        jpegSrcSet.push(
            `${generateImageURLFromTemplate(
                urlTemplate,
                imageId,
                max_width,
                max_height,
                instanceId,
                'jpeg'
            )} ${max_width}w`
        )
    })

    if (sizes?.length && (webpSrcSet?.length || jpegSrcSet?.length)) {
        return {
            lqip,
            src,
            srcSet: {
                sizes,
                webp: webpSrcSet.join(','),
                jpeg: jpegSrcSet.join(',')
            }
        }
    }

    return {}
}

const ImageStyled = styled.picture(props => ({ ...styles(props) }))

const Image = ({
    image,
    placeholder: initialPlaceholder,
    placeholderBgColor,
    type: initialType,
    width: initialWidth,
    height: initialHeight,
    method: initialMethod,
    alt,
    title,
    className,
    classNameProgressive,
    rounded,
    lazyLoad,
    ratio: initialRatio,
    fullHeight,
    setMaxWidth,
    setMaxHeight,
    isPrint,
    ...other
}) => {
    const { imagesConfig, isLoaded } = useSelector(state => state.settings, shallowEqual)
    let { current: seenBefore } = useRef(isBrowser && inImageCache(image))

    const { source, placeholder, ratio, width, height, srcSetData, max_width, max_height } = useMemo(() => {
        const imageHeight = initialHeight
        let imageRatio
        let imageWidth = initialWidth
        let imageData

        if (image && isLoaded) {
            // Get image id, but for original-aspect image we actually have to use image object because we're fetching dimensions from image response instead of using image config
            const imageId = typeof image === 'object' && Object.keys(image)?.length ? image?.id : image

            const { variations = [] } = imagesConfig
            let variation

            if (variations.length) {
                // First, let's handle image ratio so we can find variation that we need
                imageRatio = getImageRatio(initialRatio, variations, initialWidth, initialHeight)

                if (imageRatio) {
                    // If we have ratio, we can try and find variation from config
                    variation = getImageVariationFromConfig(imageRatio, variations)

                    // If we have no useful ratio, find the variation without ratio, e.g. variation with original aspect and derive ratio from its dimensions
                } else if (typeof image === 'object' && Object.keys(image)?.length) {
                    // But we have to merge config variation with image variation to get exact image dimensions
                    const { variations: imageVariations } = image

                    if (imageVariations?.length) {
                        variation = getImageVariationFromConfigAndImage(variations, imageVariations)
                    }
                }
            }

            if (typeof variation === 'object' && Object.keys(variation).length) {
                const { instances = [], url_template, formats } = variation

                if (instances.length) {
                    // Now that we have proper variation instances, lets get essentials require to construct image data
                    const {
                        instanceWidth,
                        instanceSizes,
                        instanceRatio,
                        lqipInstanceId,
                        optimalInstanceId,
                        instanceMaxWidth,
                        instanceMaxHeight
                    } = getImageInstance(instances, initialWidth, initialHeight, setMaxWidth, setMaxHeight)

                    imageWidth = instanceWidth
                    imageRatio = instanceRatio

                    const desiredFormat =
                        initialType ||
                        (!!formats?.length && formats?.find(({ extension }) => extension === 'webp')?.extension) ||
                        (!!formats?.length && formats?.[0]?.extension) ||
                        'jpeg'

                    // Finally, construct the image data
                    imageData = getImageData(
                        instances,
                        instanceSizes,
                        desiredFormat,
                        imageId,
                        url_template,
                        lqipInstanceId || optimalInstanceId,
                        optimalInstanceId,
                        formats
                    )

                    imageData.maxWidth = instanceMaxWidth
                    imageData.maxHeight = instanceMaxHeight
                }
            }
        }

        return {
            source: isBase64(image) || isLocalImageUrl(image) || isValidImageUrl(image) || imageData?.src,
            placeholder: isBase64(image) || isLocalImageUrl(image) || imageData?.lqip,
            ratio: imageRatio,
            width: typeof initialWidth === 'string' && initialWidth.toLowerCase() === 'auto' ? 'auto' : imageWidth,
            height: typeof initialWidth === 'string' && initialWidth.toLowerCase() === 'auto' ? 'auto' : imageHeight,
            srcSetData: isLocalImageUrl(image) ? [] : imageData?.srcSet,
            max_width: imageData?.maxWidth,
            max_height: imageData?.maxHeight
        }
    }, [image, imagesConfig, isLoaded])

    const handleCache = useCallback(() => {
        activateCacheForImage(image)
    }, [image])

    useEffect(() => {
        // console.log('source, image, seenBefore', source, image, seenBefore, imageCache)
        if (source && image && !seenBefore) {
            const imageInCache = inImageCache(image)
            seenBefore = imageInCache
        }
    }, [source, image, Object.keys(imageCache)?.length])

    // If it's been cached and seen before, or if we're in print mode, just straight away display it
    if (seenBefore || isPrint) {
        // console.log('seenBefore?', seenBefore)
        return (
            <ProgressiveImageStyled
                className={classNameProgressive}
                imageHeight={height}
                imageWidth={width}
                imageMaxWidth={max_width} // @NOTE: not used at the moment
                imageMaxHeight={max_height} // @NOTE: not used at the moment
                ratio={ratio}>
                <ImageStyled
                    imageWidth={width}
                    imageHeight={height}
                    className={className}
                    rounded={rounded}
                    isImageError={!source}
                    {...other}>
                    <>
                        {srcSetData && (
                            <>
                                {srcSetData?.webp && (
                                    <source type="image/webp" srcSet={srcSetData.webp} sizes={srcSetData?.sizes} />
                                )}
                                {srcSetData?.jpeg && (
                                    <source srcSet={srcSetData.jpeg} type="image/jpeg" sizes={srcSetData?.sizes} />
                                )}
                            </>
                        )}
                        <img src={placeholder} alt={alt || title || 'Image'} height={height} width={width} />
                    </>
                </ImageStyled>
            </ProgressiveImageStyled>
        )
    }

    // Else proceed with progressive loading
    return (
        <ProgressiveImage
            src={source}
            placeholder={placeholder}
            className={classNameProgressive}
            fullHeight={fullHeight}
            imageHeight={height}
            imageWidth={width}
            lazyLoad={lazyLoad}
            updateCache={handleCache}
            imageMaxWidth={max_width} // @NOTE: not used at the moment
            imageMaxHeight={max_height} // @NOTE: not used at the moment
            isDocbook={other?.isDocbook}
            ratio={ratio}>
            {({ src, loading: imageLoading, error }) => {
                /* eslint-disable no-nested-ternary */
                // If settings are loaded, set loading to imageLoading, else set loading to true
                const loading = false // isLoaded ? imageLoading : true

                // If no placeholder is provided, use the shimmering fallback to display skeleton state while loading
                if (!placeholder) {
                    return loading ? (
                        <picture>
                            <img
                                className="shimmerFallback"
                                src={shimmerFallbackBackground}
                                alt="shimmer fallback"
                                height={height}
                                width={width}
                            />
                        </picture>
                    ) : (
                        <ImageStyled
                            imageHeight={height}
                            imageWidth={width}
                            className={className}
                            rounded={rounded}
                            isImageError={!src || error}
                            placeholderBgColor={placeholderBgColor}
                            {...other}>
                            {src && (
                                <>
                                    {srcSetData && (
                                        <>
                                            {srcSetData?.webp && (
                                                <source
                                                    type="image/webp"
                                                    srcSet={srcSetData.webp}
                                                    sizes={srcSetData?.sizes}
                                                />
                                            )}
                                            {srcSetData?.jpeg && (
                                                <source
                                                    srcSet={srcSetData.jpeg}
                                                    type="image/jpeg"
                                                    sizes={srcSetData?.sizes}
                                                />
                                            )}
                                        </>
                                    )}
                                    <img
                                        src={placeholder || src}
                                        alt={alt || title || 'Image'}
                                        loading="lazy"
                                        height={height}
                                        width={width}
                                    />
                                </>
                            )}
                        </ImageStyled>
                    )
                }

                // Else display placeholder while loading and then blur into the real image
                return (
                    <ImageStyled
                        imageWidth={width}
                        imageHeight={height}
                        className={className}
                        rounded={rounded}
                        isImageLoading={loading}
                        isImageError={!src || error}
                        placeholderBgColor={placeholderBgColor}
                        withPlaceholder
                        transitionDuration={IMAGES_TRANSITION_DURATION_MS}
                        {...other}>
                        <>
                            {srcSetData && (
                                <>
                                    {srcSetData?.webp && (
                                        <source srcSet={srcSetData.webp} type="image/webp" sizes={srcSetData?.sizes} />
                                    )}
                                    {srcSetData?.jpeg && (
                                        <source srcSet={srcSetData.jpeg} type="image/jpeg" sizes={srcSetData?.sizes} />
                                    )}
                                </>
                            )}
                            <img
                                src={placeholder || src}
                                alt={alt || title || 'Image'}
                                loading={lazyLoad ? 'lazy' : undefined}
                                height={height}
                                width={width}
                            />
                        </>
                    </ImageStyled>
                )
            }}
        </ProgressiveImage>
    )
}

const imageRoundedVariants = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    ROUNDED: 'rounded'
})

const imageFillVariants = Object.freeze({
    FILL: 'fill',
    FIT: 'fit'
})

const imageTypeVariants = Object.freeze({
    JPG: 'jpg',
    WEBP: 'webp'
})

Image.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), () => null]),
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([...Object.values(imageTypeVariants)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    method: PropTypes.oneOf([...Object.values(imageFillVariants)]),
    alt: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    classNameProgressive: PropTypes.string,
    rounded: PropTypes.oneOf([...Object.values(imageRoundedVariants)]),
    lazyLoad: PropTypes.bool,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fullHeight: PropTypes.bool,
    placeholderBgColor: PropTypes.string,
    setMaxWidth: PropTypes.bool,
    setMaxHeight: PropTypes.bool,
    isPrint: PropTypes.bool
}

Image.defaultProps = {
    image: undefined,
    placeholder: undefined,
    type: undefined,
    width: '100%',
    height: '100%',
    method: 'fill',
    alt: undefined,
    title: undefined,
    className: undefined,
    classNameProgressive: undefined,
    rounded: undefined,
    lazyLoad: true,
    ratio: undefined,
    fullHeight: false,
    placeholderBgColor: undefined,
    setMaxWidth: false,
    setMaxHeight: false,
    isPrint: false
}

const imagePropsAreEqual = (prevImage, nextImage) =>
    prevImage?.image === nextImage?.image && prevImage?.ratio === nextImage?.ratio

export { imageRoundedVariants }

export default memo(Image, imagePropsAreEqual)
