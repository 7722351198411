import { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import Pluralize from 'pluralize'

import dataProvider from '@hmn/data-provider'

import { useOneElastic } from './useElastic'
import useUser from './useUser'

const MutationAction = Object.freeze({
    CREATE: 'CREATE',
    DELETE: 'DELETE'
})

const createTaxon = async ({ taxonomy, entity, entityId, bag, body, ...rest }) => {
    const { taxonId } = rest
    const result = await dataProvider.create(`taxonomies/${taxonomy}`, {
        entity: Pluralize(entity),
        entityId,
        bag: bag || taxonomy,
        data: { taxon: `api/taxonomies/${taxonomy}/taxons/${body?.id || taxonId}` }
    })

    const { data } = result

    return data
}

const deleteTaxon = async ({ taxonomy, entity, entityId, bag, contentTaxonId, ...rest }) => {
    const { taxonId } = rest
    const result = await dataProvider.delete(`taxonomies/${taxonomy}`, {
        entity: Pluralize(entity),
        entityId,
        bag: bag || taxonomy,
        id: taxonId || contentTaxonId
    })

    const { data } = result

    return data
}
/**
 * Provides taxon/content taxon based mutations
 *
 * It has integrated optimistic operations with
 * error handling and revert
 *
 * Custom onSuccess and onError can be provided through actions
 * - payload.options.onSuccess
 * - payload.options.onError
 *
 * You can also pass dependant queries which will be invalidated
 * after successful mutation.
 *
 * @example
 *
 * @TODO add examples when API stabilizes,
 * check other usages inside this project
 *
 * @param {Object} { entity, entityId, taxonId, taxonomy, bag = taxonomy, dependantQueries = [], optimisticDelay = 500, enabled = true } options
 * @return {Object}
 */
const useTaxonMutate = params => {
    const {
        entity,
        entityId,
        taxonId,
        taxonomy,
        bag = taxonomy,
        dependantQueries = [],
        optimisticDelay = 500,
        enabled = true,
        skipAuthorId = false
    } = params || {}
    const queryClient = useQueryClient()

    const { id: loggedInUserId } = useUser()
    const isEnabled = !!loggedInUserId && !!entityId && !!entity && !!bag && !!enabled

    // @TODO maybe optimize with params.data option so that hook does not need to
    // fetch data again but just use current data from list
    const {
        data = [],
        queryKey,
        ...restGetOne
    } = useOneElastic({
        resource: `taxons/${entity}`,
        id: entityId,
        endpoint: loggedInUserId,
        params: {
            query: {
                taxonomy,
                bag,
                skipAuthorId
            }
        },
        enabled: isEnabled
    })

    const taxons = useMemo(
        () =>
            (Array.isArray(data) &&
                data?.length &&
                data?.filter(item => (!skipAuthorId ? item.author_id === loggedInUserId : true))) ||
            [],
        [data, loggedInUserId]
    )

    const onSuccess = newData => {
        try {
            if (dependantQueries?.length > 0) {
                queryClient.invalidateQueries(dependantQueries)
            }

            if (newData.taxon) {
                queryClient.setQueryData(queryKey, [
                    ...data,
                    {
                        id: newData.taxon.id,
                        title: newData.taxon.title,
                        author_id: newData.author.id,
                        slug: newData.taxon.slug,
                        content_taxon_id: newData.id
                    }
                ])
            } else {
                queryClient.setQueryData(queryKey, data?.filter(item => item.content_taxon_id !== newData.id) || [])
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            // eslint-disable-next-line no-console
            console.warn('Invalid ContentTaxon data returned from mutation')
        }
    }

    const onError = (error, payload, optimisticData) => {
        if (optimisticData) {
            // do not revert optimistic data instantly
            setTimeout(() => {
                queryClient.setQueryData(
                    queryKey,
                    optimisticData.reduce((acc, item) => {
                        if (item.optimistic) {
                            return acc
                        }

                        if (item.deleted) {
                            acc.push(item.deleted)

                            return acc
                        }

                        acc.push(item)

                        return acc
                    }, [])
                )
            }, optimisticDelay)
        }

        // eslint-disable-next-line no-console
        console.error(error)
    }

    const { mutate, isLoading, ...restMutate } = useMutation(
        async payload => {
            const { mutateData, action } = payload || {}

            switch (action) {
                case MutationAction.CREATE:
                    return createTaxon({
                        taxonomy,
                        entity,
                        entityId,
                        bag,
                        body: {
                            id: taxonId
                        },
                        ...mutateData
                    })
                case MutationAction.DELETE:
                    return deleteTaxon({
                        taxonomy,
                        entity,
                        entityId,
                        bag,
                        contentTaxonId: taxons?.[0]?.content_taxon_id,
                        ...mutateData
                    })
                default:
                    return Promise.reject(
                        new Error(
                            `Invalid action '${action}' encountered, valid actions are: ${Object.values(
                                MutationAction
                            )}`
                        )
                    )
            }
        },
        {
            onMutate: payload => {
                const { mutateData, action } = payload || {}
                const newTaxonId = mutateData?.body?.id || taxonId

                switch (action) {
                    case MutationAction.CREATE: {
                        const optimisticData = [
                            ...data,
                            {
                                optimistic: true,
                                id: newTaxonId,
                                author_id: loggedInUserId
                            }
                        ]

                        queryClient.setQueryData(queryKey, optimisticData)

                        return optimisticData
                    }
                    case MutationAction.DELETE: {
                        const deleteId = mutateData?.contentTaxonId || taxons?.[0]?.content_taxon_id

                        const optimisticData =
                            data?.map(item => {
                                if (item.content_taxon_id === deleteId) {
                                    return {
                                        deleted: item
                                    }
                                }

                                return item
                            }) || []

                        queryClient.setQueryData(queryKey, optimisticData)

                        return optimisticData
                    }
                    default:
                        return Promise.reject(
                            new Error(
                                `Invalid action '${action}' encountered, valid actions are: ${Object.values(
                                    MutationAction
                                )}`
                            )
                        )
                }
            },
            onSuccess,
            onError
        }
    )

    return {
        ...restGetOne,
        ...restMutate,
        queryKey,
        data,
        taxons,
        isLoading,
        disabled: !isEnabled,
        actions: {
            create: payload => {
                const { data: mutateData, options: customMutateOptions } = payload || {}

                if (!isEnabled) {
                    return
                }

                const mutateOptions = {}

                if (typeof customMutateOptions?.onSuccess === 'function') {
                    mutateOptions.onSuccess = (...args) => {
                        onSuccess(...args)
                        customMutateOptions.onSuccess(...args)
                    }
                }

                if (typeof customMutateOptions?.onError === 'function') {
                    mutateOptions.onError = (...args) => {
                        onError(...args)
                        customMutateOptions.onError(...args)
                    }
                }

                mutate({ mutateData, action: MutationAction.CREATE }, mutateOptions)
            },
            delete: payload => {
                const { data: mutateData, options: customMutateOptions } = payload || {}

                if (!isEnabled) {
                    return
                }

                const mutateOptions = {}

                if (typeof customMutateOptions?.onSuccess === 'function') {
                    mutateOptions.onSuccess = (...args) => {
                        onSuccess(...args)
                        customMutateOptions.onSuccess(...args)
                    }
                }

                if (typeof customMutateOptions?.onError === 'function') {
                    mutateOptions.onError = (...args) => {
                        onError(...args)
                        customMutateOptions.onError(...args)
                    }
                }

                mutate({ mutateData, action: MutationAction.DELETE }, mutateOptions)
            },
            invalidate: () => {
                queryClient.invalidateQueries()
                queryClient.setQueryData(queryKey)
            }
        }
    }
}

export { MutationAction }
export default useTaxonMutate
