import { toRem, toRems } from '../../../../../helpers/theme'

const style = ({ theme, isActive }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minHeight: toRem(32),
    padding: toRems([6, 15, 8]),
    border: `1px solid ${isActive ? theme.colors.accentGreen : theme.colors.gray290}`,
    borderRadius: toRem(100),
    fontSize: toRem(14),
    lineHeight: 1.2,
    backgroundColor: isActive ? theme.colors.accentGreen : theme.colors.white,
    color: isActive ? theme.colors.white : theme.colors.black,
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
        borderColor: isActive ? theme.colors.accentGreen : theme.colors.gray400
    },

    '& .filterPill_input': {
        display: 'none'
    },

    '& .filterPill_label': {
        lineHeight: 'inherit'
    }
})

export default style
