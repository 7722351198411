/* eslint-disable no-console, camelcase */
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { signIn, signOut } from 'next-auth/client'
import { destroyCookie } from 'nookies'
import PropTypes from 'prop-types'

import dataProvider from '@hmn/data-provider'
import * as PodravkaClient from '@hmn/podravkaio'

import { datesAreOnSameDay } from '../../helpers'
import { useFetch, useLocalStorage, useNotification, useSession } from '../../hooks'
import { globalSettings } from '../../settings'
import useLoginAsUser from './useLoginAsUser'

const isDev = process.env.NODE_ENV !== 'production'
const { apiBaseUrl } = globalSettings
const podravkaioPasswordFlowProviderId = process.env.NEXT_PUBLIC_WEB_AUTH_PROVIDERS_PODRAVKAIO_PASSWORD_FLOW
const podravkaioAuthCodeFlowProviderId = process.env.NEXT_PUBLIC_WEB_AUTH_PROVIDERS_PODRAVKAIO_AUTHORIZATION_CODE_FLOW
const podravkaioTokenFlowProviderId = process.env.NEXT_PUBLIC_WEB_AUTH_PROVIDERS_PODRAVKAIO_TOKEN_FLOW

// List protected route pathnames here
const protectedRoutes = ['moj-profil']

const UserContext = createContext({})

const revalidateDelay = delay => new Promise(resolve => setTimeout(resolve, delay))

/**
 * Try to revalidate user session multiple times using existing access token, to avoid any sync issues and accidental premature logouts.
 *
 * @param {*} { times = 5, intervalMs = 5000, callback, onError }
 * @return {*}
 */
const revalidateSession = async ({ times = 5, intervalMs = 1000, callback, onError }) => {
    if (times < 1) {
        throw new Error(
            // eslint-disable-next-line max-len
            `[coolinarika][revalidate-session]: Bad argument, 'times' must be greater than 0, ${times} was used instead.`
        )
    }

    let attemptsCount = 0

    let result

    while (attemptsCount < times && !result?.id) {
        try {
            // eslint-disable-next-line no-await-in-loop
            result = await callback()

            if (result?.error) {
                throw new Error()
            }
        } catch (error) {
            attemptsCount += 1

            if (attemptsCount >= times) {
                onError(result || { status: error?.response?.status })
                break
            }
            // eslint-disable-next-line no-await-in-loop
            await revalidateDelay(intervalMs)
        }
    }

    return result
}

const extractProfileFromSession = session => ({
    id: session?.id,
    display_name: session?.user?.name,
    account: {
        attributes: null,
        email: session?.user?.email,
        id: session?.id,
        identities: []
    }
})

const queryKey = ['one', 'users', 'me']

const UserContextProvider = ({ children, session: ssrSession }) => {
    const [session, loading, { refetch: refreshSession }] = useSession()

    const { actions: fetch } = useFetch()
    const [lastLogin, setLastLogin] = useLocalStorage('lastLogin', null)

    const [error, setError] = useState(session?.error)
    const { pathname, query, replace } = useRouter()

    // A flag to ensure that user_id is sent to dataLayer only once on login
    const isLoggedInRef = useRef(false)

    // initialise isLoggedIn with undefined, this state will persist until we're confirm existence of accessToken
    const [isLoggedIn, setLoggedIn] = useState(undefined)
    // get initial profile from session
    const [profile, setProfile] = useState(extractProfileFromSession(session))
    const queryClient = useQueryClient()
    const invalidateUserData = () => queryClient.invalidateQueries(['one', 'users'])

    const { sendNotification } = useNotification({
        id: 'PLACEHOLDER_ID_FOR_LOGIN_NOTIFICATION',
        options: { type: 'error', duration: 5000 }
    })

    const sendLoggedInGamify = () => {
        // Send loggedin info to gamification
        const now = new Date()
        setLastLogin(now.getTime())
        const sameDayLogin = datesAreOnSameDay(now, lastLogin)

        if (!sameDayLogin) {
            fetch.create({
                data: {
                    endpoint: '/gamification/user/loggedin',
                    payload: {
                        action: 'increment',
                        uuid: profile?.id,
                        value: 1
                    }
                }
            })
        }
    }

    // Client side route protection
    useEffect(() => {
        if (loading) {
            // wait until logged in status is resolved
            return
        }

        const validSession = !!(isLoggedIn && !loading)

        if (typeof window !== 'undefined' && !validSession && typeof isLoggedIn !== 'undefined') {
            const protectedRoute = protectedRoutes.some(route => (pathname || '').toLowerCase().includes(route))
            if (protectedRoute) {
                replace('/')
            }
        }
    }, [pathname, loading, session, isLoggedIn])

    const signOutCallback = useCallback(
        async ({ redirect = false, debug } = {}) => {
            if (query && query?.code && !!query?.code?.length) {
                // Don't force logout if user has no valid session, but is registering an account currently
                return
            }

            dataProvider.setToken(null)
            await signOut({ redirect })
            setLoggedIn(false)
            setProfile()
            invalidateUserData()
            refreshSession()

            if (isDev && debug) {
                console.log('[DEBUG][UserContext] sign out callback', debug)
            }
        },
        [query]
    )

    // return empty profile if no session
    const { data } = useQuery(
        queryKey,
        async () =>
            revalidateSession({
                callback: async () => {
                    const ky = (await import('ky-universal')).default
                    const { accessToken } = session || {}

                    const response = await ky(`${apiBaseUrl}/podravkaio/users/me`, {
                        hooks: {
                            beforeRequest: [
                                request => {
                                    if (accessToken) {
                                        request.headers.set('x-user-token', `Bearer ${accessToken}`)
                                    }
                                }
                            ]
                        }
                    })

                    const rsp = (await response.json()) || {}

                    if (session?.id === rsp?.id || accessToken) {
                        dataProvider.setToken(accessToken)
                    }

                    rsp.status = response?.status

                    return rsp
                },
                onError: profileError => {
                    if (
                        (profileError?.status && (profileError?.status === 401 || profileError?.status === '401')) ||
                        profileError?.error === 'invalid_grant'
                    ) {
                        signOutCallback({
                            debug: `revalidateSession - profileError: ${JSON.stringify(profileError, null, 2)}`
                        })
                    }
                }
            }),
        {
            enabled: !!(process.browser && isLoggedIn && !loading),
            onError: (useQueryError = {}) => {
                signOutCallback({
                    debug: `revalidateSession - useQuery.onError: ${JSON.stringify(useQueryError, null, 2)}`
                })
            },
            refetchOnWindowFocus: typeof window !== 'undefined',
            refetchOnMount: true
        }
    )

    useEffect(() => {
        if (data) {
            if (data?.account?.id !== profile?.account?.id) {
                // possible leak
                Sentry.withScope(scope => {
                    scope.setUser(data)
                    scope.setLevel('warning')
                    scope.setTag('[coolinarika-web][auth][podravkaio][session]', true)
                    Sentry.captureException(
                        `suspicious session change: ${profile?.account?.id} -> ${data?.account?.id}`
                    )
                })
            }
            setProfile(data)
        }
    }, [data])

    useEffect(() => {
        if (loading) {
            return
        }

        const revalidateUserSession = async () => {
            const { accessToken } = session || {}

            if (!accessToken) {
                isLoggedInRef.current = false
                setLoggedIn(false)
            }

            if (!isLoggedInRef.current && isLoggedIn && accessToken && data) {
                window?.dataLayer?.push({ user_id: data?.id })
                isLoggedInRef.current = true
            }

            // IF user is not logged in, but has valid session, log user in
            if (!isLoggedIn && accessToken) {
                dataProvider.setToken(accessToken)
                setLoggedIn(true)
                sendLoggedInGamify()
                return
            }

            if (isLoggedIn && !accessToken) {
                // If user is logged in, but has invalid session, log user out
                signOutCallback({
                    debug: `useEffect - invalid condition, ${JSON.stringify(
                        {
                            sessionToken: `${accessToken}`,
                            pioClientBasePath: PodravkaClient.ApiClient.instance.basePath,
                            dataProviderToken: `${dataProvider.getToken()}`
                        },
                        null,
                        2
                    )}`
                })
                return
            }

            if (isLoggedIn && !session) {
                // Regardless of login state, if user has no session, log him out
                signOutCallback({
                    debug: 'useEffect - no session'
                })
            }
        }

        revalidateUserSession()
    }, [isLoggedIn, session, loading, data])

    const value = useMemo(
        () => ({
            profile,
            id: profile?.id,
            isLoggedIn,
            error: error || session?.error,
            logInWithCredentials: async options => {
                const response = await signIn(podravkaioPasswordFlowProviderId, {
                    ...options,
                    redirect: false
                })

                if (response?.error || response?.message) {
                    invalidateUserData()
                    setError(true)
                    sendNotification({ message: 'Pogrešno korisničko ime ili lozinka.', type: 'error' })
                    return { error: response?.error }
                }

                refreshSession()

                return response
            },
            logInWithOAuth: options => {
                signIn(podravkaioAuthCodeFlowProviderId, options)
                refreshSession()
            },
            logInWithFacebook: options => {
                signIn('facebook', { ...options, redirect: false })
                refreshSession()
            },
            loginWithApple: async options => {
                await signIn('apple', { ...options, redirect: false })
                refreshSession()
            },
            loginWithToken: async options => {
                await signIn(podravkaioTokenFlowProviderId, { ...options, redirect: false })
                refreshSession()
            },
            logOut: () => {
                destroyCookie(null, 'coolinarikaLoginAsUserActive')

                signOutCallback({
                    debug: 'useMemo - logOut function called manually'
                })

                refreshSession()
            }
        }),
        [
            profile,
            session,
            isLoggedIn,
            podravkaioPasswordFlowProviderId,
            podravkaioAuthCodeFlowProviderId,
            podravkaioTokenFlowProviderId,
            error
        ]
    )

    useLoginAsUser({
        onLogin: value.loginWithToken,
        enabled: !!podravkaioTokenFlowProviderId && !loading,
        session,
        sessionLoading: loading,
        isLoggedIn
    })

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserContextProvider.propTypes = {
    session: PropTypes.shape({
        error: PropTypes.string,
        id: PropTypes.string,
        accessToken: PropTypes.string
    })
}

UserContextProvider.defaultProps = {
    session: undefined
}

export { protectedRoutes, UserContextProvider }

export default UserContext
