import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../ErrorBoundary'
import { AccountMenu, AccountPanel, AccountUser, Menu } from './components'
import styles from './Desktop.style'

const NavigationDesktopStyled = styled.div(props => ({ ...styles(props) }))

const NavigationDesktop = ({ className, primaryData, secondaryData, tertiaryData, accountData, ...rest }) => (
    <NavigationDesktopStyled className={className} {...rest}>
        <Menu primaryData={primaryData} secondaryData={secondaryData} tertiaryData={tertiaryData} />
        <AccountUser />
        <AccountPanel isDesktop>
            <AccountMenu accountData={accountData} />
        </AccountPanel>
    </NavigationDesktopStyled>
)

NavigationDesktop.propTypes = {
    className: PropTypes.string,
    primaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.shape({}))]),
    secondaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.shape({}))]),
    tertiaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.shape({}))]),
    accountData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.shape({}))])
}

NavigationDesktop.defaultProps = {
    className: undefined,
    primaryData: undefined,
    secondaryData: undefined,
    tertiaryData: undefined,
    accountData: undefined
}

export default withErrorBoundary(NavigationDesktop)
