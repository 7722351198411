/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavTags = createSvgIcon(
    <>
        <path d="M23.75 12h-5.196c-.343 0-.824.199-1.066.442l-7.123 7.123a1.253 1.253 0 000 1.768l5.303 5.303c.486.486 1.281.486 1.767 0l7.123-7.122c.243-.243.442-.724.442-1.067V13.25c0-.687-.563-1.25-1.25-1.25zm0 6.554l-7.199 7.198-5.302-5.303 3.782-3.783c1.78-1.778 2.795-2.795 3.376-3.416h5.343v5.304z" />
        <path d="M21.25 14.5a1.25 1.25 0 10-.001 2.5 1.25 1.25 0 000-2.5z" />
    </>,
    'NavTags'
)

export default NavTags
