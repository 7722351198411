/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavMore = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.237 19.274a1.037 1.037 0 100-2.074 1.037 1.037 0 000 2.074zm0 1.2a2.237 2.237 0 100-4.474 2.237 2.237 0 000 4.474zM18.5 19.274a1.037 1.037 0 100-2.074 1.037 1.037 0 000 2.074zm0 1.2a2.237 2.237 0 100-4.474 2.237 2.237 0 000 4.474zM24.763 19.274a1.037 1.037 0 100-2.074 1.037 1.037 0 000 2.074zm0 1.2a2.237 2.237 0 100-4.474 2.237 2.237 0 000 4.474z"
    />,
    'NavMore'
)

export default NavMore
