// @TODO: Open/close transition animations

// @ui imports
import { convertHexToRGBA as rgba } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    zIndex: theme.zIndex.under,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    opacity: 1,
    backgroundColor: rgba(theme.colors.modal.background.default.backdrop, 0.5),
    pointerEvents: 'auto',
    WebkitTapHighlightColor: 'transparent',
    transitionDuration: theme.transitions.modal.default.duration,
    transitionTimingFunction: theme.transitions.modal.default.timingFunction,
    transitionDelay: theme.transitions.modal.default.delay,
    transitionProperty: 'opacity'
})

export default style
