import { toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, topShadow, bottomShadow, fullWidth, autoHide }) => ({
    width: '100%',
    height: '100%',
    '& .ScrollbarsCustom': {
        position: 'relative',
        width: '100%',
        height: '100%',
        ...(autoHide && {
            '&:hover': {
                '& .ScrollbarsCustom-Track': {
                    opacity: 1
                }
            }
        }),
        '&.trackYVisible': {
            '&::before, &::after': {
                display: 'block',
                pointerEvents: 'none',
                position: 'absolute',
                left: 0,
                width: `calc(100% - ${toRem(5)})`,
                height: toRem(15),
                zIndex: theme.zIndex.scrollbar.shadow,
                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
                [theme.breakpoints.up('md')]: {
                    height: toRem(35)
                }
            },
            '&::before': {
                content: topShadow ? '""' : 'none',
                transform: 'rotate(180deg)',
                top: 0
            },
            '&::after': {
                content: bottomShadow ? '""' : 'none',
                bottom: 0
            }
        },
        '& .ScrollbarsCustom-Wrapper': {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: toRem(5),
            width: fullWidth ? '100%' : `calc(100% - ${toRem(5)})`,
            height: '100%',
            overflow: 'hidden'
        },
        '& .ScrollbarsCustom-Scroller': {},
        '& .ScrollbarsCustom-Content': {
            boxSizing: 'border-box'
        },
        '& .ScrollbarsCustom-Track': {
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: 0,
            background: theme.colors.scrollbar.track,
            userSelect: 'none',
            ...(autoHide && {
                background: 'none',
                opacity: 0,
                transitionDuration: theme.transitions.default.duration,
                transitionTimingFunction: theme.transitions.default.timingFunction,
                transitionDelay: theme.transitions.default.delay,
                transitionProperty: 'opacity',
                '@media (hover: none) and (pointer: coarse)': {
                    opacity: 1
                }
            }),
            '&.ScrollbarsCustom-TrackY': {
                width: toRem(5),
                height: '100%',
                top: 0,
                right: 0
            },
            '&.ScrollbarsCustom-TrackX': {
                height: toRem(5),
                width: '100%',
                bottom: 0,
                left: 0
            }
        },
        '& .ScrollbarsCustom-Thumb': {
            cursor: 'pointer',
            borderRadius: 0,
            background: theme.colors.scrollbar.thumb,
            '&.ScrollbarsCustom-ThumbY': {
                width: '100%',
                height: 0
            },
            '&.ScrollbarsCustom-ThumbX': {
                height: '100%',
                width: 0
            }
        }
    },
    '@media print': {
        '& .ScrollbarsCustom-Wrapper': {
            position: 'static !important',
            height: 'auto !important'
        },
        '& .ScrollbarsCustom-Scroller': {
            position: 'static !important'
        },
        '& .ScrollbarsCustom': {
            overflow: 'visible !important'
        }
    }
})

export default style
