// @TODO: Active/current item detection logic (by route, if search active, if nav active, if account nav active - only one at a time can be active)

import { useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import {
    FiltersActions,
    NavigationAccountActions,
    NavigationActions,
    SearchActions
} from '@hmn/coolinarika-web-core/actions'
import { DRAWER_ACTIONS, useDrawerDispatch } from '@hmn/coolinarika-web-core/context/Drawer'
import { lg, useBreakpoints } from '@hmn/coolinarika-web-core/hooks'

import { withErrorBoundary } from '../../../ErrorBoundary'
import { WaveShortIcon } from '../../../Icon'
import { AccountUser, accountUserVariants } from '../Account'
import { NavBarItem } from './Item'
import styles from './NavBar.style'

const NavBarStyled = styled.div(props => ({ ...styles(props) }))

const NavBar = ({ className, ...rest }) => {
    const router = useRouter()
    const dispatch = useDispatch()
    const drawerDispatch = useDrawerDispatch()
    const [isDesktop] = useBreakpoints(lg)
    const { isOpen: isSearchOpen } = useSelector(state => state.search)
    const { isOpen: isNavigationOpen } = useSelector(state => state.navigation)

    const handleSearchToggle = useCallback(() => {
        batch(() => {
            dispatch(SearchActions.toggle())
            dispatch(FiltersActions.close())
            dispatch(NavigationActions.close())
            dispatch(NavigationAccountActions.closeAccount())
            drawerDispatch({
                type: DRAWER_ACTIONS.CLOSE_DRAWER,
                payload: {
                    drawerType: null
                }
            })
        })
    }, [dispatch])

    const handleNavigationToggle = useCallback(() => {
        batch(() => {
            dispatch(NavigationActions.toggle())
            if (!isDesktop) {
                dispatch(NavigationAccountActions.closeAccount())
            }
            dispatch(SearchActions.close())
            dispatch(FiltersActions.close())
            drawerDispatch({
                type: DRAWER_ACTIONS.CLOSE_DRAWER,
                payload: {
                    drawerType: null
                }
            })
        })
    }, [dispatch])

    const handleNavigationAccountToggle = useCallback(() => {
        batch(() => {
            dispatch(NavigationActions.close())
            dispatch(SearchActions.close())
            dispatch(FiltersActions.close())
        })
    }, [dispatch])

    return (
        <NavBarStyled className={className} {...rest}>
            <NavBarItem title="Naslovnica" href="/" icon="home" isActive={router.pathname === '/'} />
            <NavBarItem
                title="Inspiracija"
                href="/inspiracija"
                icon="inspiration"
                isActive={router.pathname === '/inspiracija'}
            />
            <NavBarItem
                title="Recepti"
                href="/recepti/novi"
                icon="recipes"
                isActive={router.pathname === '/recepti/novi'}
                isActiveOffset={-8}
            />
            <NavBarItem title="Pretraga" icon="search" size={40} onClick={handleSearchToggle} isActive={isSearchOpen} />
            <NavBarItem
                title="Izbornik"
                icon="hamburger"
                onClick={handleNavigationToggle}
                isActive={isNavigationOpen}
            />
            <div className="navBar_itemSeparator" aria-hidden="true">
                <WaveShortIcon viewBox="0 0 40 5" color="inherit" />
            </div>
            <AccountUser variant={accountUserVariants.NAVBAR} onClick={handleNavigationAccountToggle} />
        </NavBarStyled>
    )
}

NavBar.propTypes = {
    className: PropTypes.string
}

NavBar.defaultProps = {
    className: undefined
}

export default withErrorBoundary(NavBar)
