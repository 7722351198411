const layoutSpacings = {
    base: {
        xAxis: {
            xs: 20,
            sm: 20,
            md: 20,
            lg: 20,
            xl: 60,
            xxl: 80,
            xxxl: 100
        }
        // extend with yAxis if needed
    },
    topRow: {
        xs: 40,
        sm: 40,
        md: 40,
        lg: 40,
        xl: 136,
        xxl: 136,
        xxxl: 136
    },
    grid: {
        rowGap: {
            xs: 20,
            sm: 20,
            md: 20,
            lg: 25,
            xl: 25,
            xxl: 40,
            xxxl: 40
        },
        columnGap: {
            xs: 20,
            sm: 20,
            md: 20,
            lg: 25,
            xl: 25,
            xxl: 40,
            xxxl: 40
        }
    }
    // extend with other layout sections
}

export default {
    base: layoutSpacings.base,
    topRow: layoutSpacings.topRow,
    grid: layoutSpacings.grid
    // extend with other layout sections
}
