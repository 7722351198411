import { RecipeUploaderActionTypes } from '../../actions/recipeUploader'

const initialState = {
    isOpen: false,
    isAnimationComplete: true,
    recipeId: null,
    draftId: null,
    isNewRecipe: false
}

const recipeUploaderReducer = (state = initialState, { type, recipeId, draftId, isNewRecipe }) => {
    switch (type) {
        case RecipeUploaderActionTypes.CLOSE: {
            return {
                ...state,
                isOpen: false,
                isAnimationComplete: state.isOpen === false,
                recipeId: null,
                isNewRecipe: isNewRecipe || !recipeId
            }
        }

        case RecipeUploaderActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true,
                isAnimationComplete: state.isOpen === true,
                recipeId: recipeId || null,
                isNewRecipe: isNewRecipe || !recipeId
            }
        }

        case RecipeUploaderActionTypes.TOGGLE: {
            return {
                ...state,
                isOpen: !state.isOpen,
                isAnimationComplete: false
            }
        }

        case RecipeUploaderActionTypes.ANIMATION_COMPLETE: {
            return {
                ...state,
                isAnimationComplete: true
            }
        }

        case RecipeUploaderActionTypes.SET_RECIPE_ID: {
            return {
                ...state,
                recipeId,
                isNewRecipe: !recipeId
            }
        }

        case RecipeUploaderActionTypes.SET_DRAFT_ID: {
            if (typeof window !== 'undefined' && !draftId) {
                localStorage.removeItem('coolinarika-web-recipes-draft-id')
            }

            return {
                ...state,
                draftId
            }
        }

        case RecipeUploaderActionTypes.SET_IS_NEW_RECIPE: {
            return {
                ...state,
                isNewRecipe
            }
        }

        default:
            return state
    }
}

export { recipeUploaderReducer }
