// @ui imports
import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: toRems([30, theme.global.drawer.hSpacing.mobile, 20]),
    [theme.breakpoints.up('md')]: {
        margin: toRems([80, theme.global.drawer.hSpacing.desktop, 20])
    },
    '& .headInner': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        minHeight: toRem(30),
        paddingRight: toRem(46),
        [theme.breakpoints.up('md')]: {
            paddingRight: toRem(54)
        }
    },
    '& .headBack_btn': {
        margin: toRems([0, 12, 0, -8]),
        maxHeight: toRem(30)
    },
    '& .headClose_btn': {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('sm')]: {
            padding: toRem(4)
        }
    }
})

export default style
