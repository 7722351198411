import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { HtmlContent } from '../../../HtmlContent'
import { Image } from '../../../Image'
import styles from './NoResults.style'

const NoResultsStyled = styled.div(props => ({ ...styles(props) }))

const NoResults = ({
    title,
    text,
    isInSearchPanel,
    className,
    imageWidth,
    imageHeight,
    hasPresetHeight,
    placeholderImageSrc,
    ...props
}) => (
    <NoResultsStyled
        hasPresetHeight={hasPresetHeight}
        isInSearchPanel={isInSearchPanel}
        className={className}
        {...props}>
        <div className="searchNoResults_inner">
            <div className="searchNoResults_asset">
                <Image image={placeholderImageSrc} alt={title} height={imageHeight} width={imageWidth} />
            </div>
            {title && <HtmlContent content={title} className="searchNoResults_title" wrapperComponent="div" />}
            {text && <HtmlContent content={text} className="searchNoResults_text" wrapperComponent="div" />}
        </div>
    </NoResultsStyled>
)

NoResults.propTypes = {
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholderImageSrc: PropTypes.string,
    hasPresetHeight: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
    isInSearchPanel: PropTypes.bool
}

NoResults.defaultProps = {
    title: undefined,
    className: undefined,
    text: undefined,
    isInSearchPanel: false,
    hasPresetHeight: true,
    imageWidth: 599,
    imageHeight: 406,
    placeholderImageSrc: '/images/placeholder/page-not-found.svg'
}

export default NoResults
