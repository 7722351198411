/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    display: 'block',
    [theme.breakpoints.up('lg')]: {
        display: 'none'
    },
    '@media print': {
        display: 'none'
    }
})

export default style
