import { SearchActionTypes } from '../../actions/search'

const initialState = {
    isOpen: false,
    isManuallyClosed: false,
    current: {}
}

const searchReducer = (state = initialState, { type, search, isManuallyClosed: manual }) => {
    switch (type) {
        case SearchActionTypes.CLOSE: {
            return {
                ...state,
                isManuallyClosed: true,
                isOpen: false
            }
        }

        case SearchActionTypes.OPEN: {
            return {
                ...state,
                isOpen: true
            }
        }

        case SearchActionTypes.TOGGLE: {
            return {
                ...state,
                isManuallyClosed: true,
                isOpen: !state.isOpen
            }
        }

        case SearchActionTypes.SAVE: {
            return {
                ...state,
                current: search
            }
        }

        case SearchActionTypes.IS_MANUALLY_CLOSED: {
            return {
                ...state,
                isManuallyClosed: false
            }
        }

        default:
            return state
    }
}

export { searchReducer }
