import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import { SearchActions } from '@hmn/coolinarika-web-core/actions'
import { Media } from '@hmn/coolinarika-web-core/controllers'
import { lg, useBreakpoints, useSearch } from '@hmn/coolinarika-web-core/hooks'

import { FiltersContainer } from './FiltersContainer'
import { MultiList } from './MultiList'
// import { MultiRangeList } from './MultiRangeList'
import style from './Search.style'
import { SearchInput } from './SearchInput'
import { SearchResults } from './SearchResults'
import { SearchTopBar } from './SearchTopBar'
import { ToggleButtonList } from './ToggleButtonList'

const getSearchResultsTitle = (condition = false) =>
    condition
        ? "Recepti u <span class='accentSecondary'>kolekcijama</span>"
        : "<span class='accentSecondary'>Pronašli</span> smo…"

const SearchStyled = styled.aside(props => ({ ...style(props) }))

const Search = () => {
    const {
        filterData,
        searchRef,
        searchResults,
        activeFilters,
        activeFiltersLength,
        resultsLength,
        handleCloseSearchManually,
        handleClose,
        handleOpenSearch,
        handleToggleButtonListChange,
        handleMultilistChange,
        handleSelectChange,
        isSearchOpen,
        isSearchManuallyClosed,
        showNoResults,
        showResultsPanel
    } = useSearch()

    const [isDesktopView = true] = useBreakpoints(lg)
    const { beforePopState, query } = useRouter()
    const dispatch = useDispatch()
    const [value, setValue] = useState({ label: '', value: '' })
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        beforePopState(() => {
            if (!isSearchOpen && !isSearchManuallyClosed && !!activeFiltersLength) {
                handleOpenSearch()
            }

            return true
        })
    }, [activeFiltersLength, isSearchOpen, isSearchManuallyClosed, handleOpenSearch, beforePopState, handleClose])

    // Lock body when search is opened
    useEffect(() => {
        document.body.style.overflow = isSearchOpen && isDesktopView ? 'hidden' : ''
    }, [isSearchOpen, isDesktopView])

    // close on esc
    useEffect(() => {
        const keyListenersMap = new Map([[27, handleCloseSearchManually]])

        const keyListener = e => {
            const listener = keyListenersMap.get(e.keyCode)
            return listener && listener(e)
        }
        if (isSearchOpen) {
            document.addEventListener('keydown', keyListener)
        }
        return () => {
            if (isSearchOpen) {
                document.removeEventListener('keydown', keyListener)
            }
        }
    }, [isSearchOpen])

    useEffect(() => {
        if (activeFilters?.pojam && !query?.slug) {
            dispatch(SearchActions.open())
        }

        if (query.category && query.slug && activeFilters?.pojam && isSearchOpen) {
            dispatch(SearchActions.open())
        }

        setInputValue(activeFilters?.pojam || '')
        setValue({
            value: activeFilters?.pojam,
            label: activeFilters?.pojam
        })
    }, [activeFilters, query])

    const onInputChange = (inputVal, { action }) => {
        if (action === 'input-blur') {
            setInputValue(value ? value.label : '')
        }

        if (action === 'input-change') {
            setInputValue(inputVal)
        }

        if (action === 'set-value') {
            handleMultilistChange(null)
        }
    }

    const onChange = option => {
        setValue(option)
        setInputValue(option ? option.label : '')
        handleSelectChange(option)
    }

    return (
        <SearchStyled>
            <Media greaterThan="md">
                <SearchTopBar>
                    <SearchInput
                        value={value}
                        inputValue={inputValue}
                        componentId="pojam"
                        onChange={onChange}
                        onInputChange={onInputChange}
                        handleCloseSearch={handleCloseSearchManually}
                        selectedFiltersCount={activeFiltersLength}
                        handleInputFocus={handleOpenSearch}
                    />
                </SearchTopBar>
            </Media>

            {isSearchOpen && (
                <Media lessThan="lg">
                    <SearchTopBar>
                        <SearchInput
                            value={value}
                            inputValue={inputValue}
                            componentId="pojam"
                            onChange={onChange}
                            onInputChange={onInputChange}
                            handleCloseSearch={handleCloseSearchManually}
                            selectedFiltersCount={activeFiltersLength}
                            handleInputFocus={handleOpenSearch}
                        />
                    </SearchTopBar>
                </Media>
            )}

            <FiltersContainer
                numberOfResults={!!resultsLength && !isDesktopView && !!activeFiltersLength ? resultsLength : 0}>
                <ToggleButtonList
                    onChange={handleToggleButtonListChange}
                    data={filterData}
                    activeFilters={activeFilters}
                    componentIds={['by-coolinarika', 'imaju-video', 'moji-spremljeni']}
                    labels={{
                        'by-coolinarika': 'By Coolinarika',
                        'imaju-video': 'Imaju Video',
                        'moji-spremljeni': 'Moji spremljeni'
                    }}
                />
                <MultiList
                    onChange={handleMultilistChange}
                    data={filterData}
                    activeFilters={activeFilters}
                    componentId="tezina-pripreme"
                    title="Težina pripreme"
                />

                <MultiList
                    onChange={handleMultilistChange}
                    data={filterData}
                    activeFilters={activeFilters}
                    componentId="grupa-jela"
                    title="Grupa jela"
                />

                <MultiList
                    onChange={handleMultilistChange}
                    data={filterData}
                    activeFilters={activeFilters}
                    componentId="nacin-pripreme"
                    title="Način pripreme"
                />

                {/* <MultiRangeList
                    componentId="vrijeme-pripreme"
                    title="Vrijeme pripreme"
                    dataField="preparation_time"
                    data={preparationTimeData}
                /> */}
            </FiltersContainer>
            <SearchResults
                ref={searchRef}
                data={searchResults}
                onClick={handleClose}
                showResultsPanel={showResultsPanel}
                showNoResults={showNoResults}
                scrollTargetId="search-results-wrapper"
                searchResultsTitle={getSearchResultsTitle()}
            />
        </SearchStyled>
    )
}

export default memo(Search)
