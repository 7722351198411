// @ui imports
import { toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = () => ({
    position: 'relative',
    margin: toRems([40, 0, 10, 0]),
    padding: 0
})

export default style
