/**
 * @TODO: This HOC is only used due to Reflebox' ThemeProvider incompatibility with
 * Coolinarika's core ThemeProvider and various Emotion major version differences.
 *
 * We should keep track of this pull request and update when it's released:
 *  * https://github.com/rebassjs/rebass/pull/1051/files
 */
// import { Global } from '@emotion/core'
import { Global, withTheme } from '@emotion/react'

import style from './GlobalStyles.style'

const GlobalStyles = ({ theme }) => <Global styles={{ ...style(theme) }} />

export default withTheme(GlobalStyles)
