import { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'

import { useScript } from '@hmn/coolinarika-web-core/hooks'

import 'videojs-contrib-ads'
import 'videojs-contrib-eme'
import 'videojs-ima'
import 'videojs-youtube'
/**
 * Remove property from object
 * @param {Object} object
 * @param {string} prop
 * @return Object
 */
const removeObjectProp = (object = {}, prop = undefined) => {
    if (Object.keys(object).length && prop) {
        const { [prop]: value, ...res } = object
        return res
    }
    return object
}

/**
 * Parse and adjust video options depending on video source
 * @description Returns options object, hasOptions boolean, source string & sourceType string
 * @param {Object} videoOptions
 */
const getVideoData = (videoOptions = {}) => {
    let options = videoOptions
    const hasOptions = !!Object.keys(options).length

    const sources = options?.sources || []
    let source = sources?.length ? sources[0]?.src : undefined

    let sourceType

    if (source?.toLowerCase().includes('.ism/manifest')) {
        // remove `sources` Object.key from options
        // for manifest urls we have to initiate videojs-contrib-eme lib
        // if video.js receives a manifest url as a source, BEFORE videojs-contrib-eme init,
        // it will throw an unsupported video source error
        options = removeObjectProp(options, 'sources')
        source = `${source}(format=mpd-time-csf)`
        sourceType = 'application/dash+xml'
    }

    if (source?.toLowerCase().includes('youtu')) {
        options = removeObjectProp(options, 'sources')
        // regex remove everything before last slash
        source = source.replace(/^(.*[\\/])/, 'https://www.youtube.com/watch?v=').trim()

        sourceType = 'video/youtube'
    }

    return {
        options,
        hasOptions,
        source,
        sourceType,
        dataIsReady: true
    }
}

/**
 * React hooks wrapper for video.js
 * @param {Object} videoOptions video.js options {@link https://docs.videojs.com/tutorial-options.html}
 * @param {Object} adSettings "videojs-ima" ad settings {@link https://github.com/googleads/videojs-ima#additional-settings}
 * @description Player needs "//imasdk.googleapis.com/js/sdkloader/ima3.js" for "videojs-ima" lib to enable ads support
 * @description Returns - Video (element), ready (boolean) & player (object)
 * @description Video.js react support - {@link https://docs.videojs.com/tutorial-react.html}
 * @description Skinning - {@link https://docs.videojs.com/tutorial-skins.html}
 * @example
 * import useVideoJS from '../hooks/useVideoJS'
 * const { Video, ready, player } = useVideoJS({
 *     autoplay: false,
 *     muted: false,
 *     controls: true,
 *     fluid: true,
 *     poster: 'VIDEO_POSTER_URL'
 *     preload: 'auto|metadata|none'
 *     aspectRatio: '16:9',
 *     playbackRates: [0.5, 1, 1.25, 1.5, 2],
 *     language: 'hr',
 *     sources: [
 *          {
 *              src: VIDEO_URL,
 *              type: VIDEO_TYPE
 *          }
 *     ],
 *     ...
 * }, {
 *     adTagUrl: 'VAST_TAG_URL',
 *     adLabel: 'Advertisement',
 *     adLabelNofN: 'of',
 *     ...
 * })
 * if (ready){
 *      console.log(player)
 * }
 * return <Video />
 */
const VideoJS = ({ videoOptions, adSettings, onReady }) => {
    const { isReady: googleCheck } = useScript({
        src: '//imasdk.googleapis.com/js/sdkloader/ima3.js',
        options: {
            async: true,
            defer: true,
            readyCondition: () => !!(window && window.google?.ima && adSettings?.adTagUrl)
        }
    })
    const videoNodeRef = useRef()
    const playerRef = useRef()

    // check if google global variable is available (needed for `videojs-ima` lib)
    // const googleCheck = typeof window !== 'undefined' && !!window?.google?.ima

    const { options, hasOptions, source, sourceType, dataIsReady } = useMemo(
        () => getVideoData(videoOptions) || {},
        [videoOptions, adSettings]
    )

    useEffect(() => {
        if (videoNodeRef?.current && !playerRef.current && source && hasOptions && dataIsReady) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement('video-js')

            // videoElement.classList.add('vjs-big-play-centered')
            videoNodeRef.current.appendChild(videoElement)

            // init
            playerRef.current = videojs(videoElement, options, () => {
                if (onReady) {
                    onReady(playerRef.current)
                }
            })

            const player = playerRef.current

            // if source is manifest url
            if (sourceType === 'application/dash+xml') {
                player.eme()
                player.src({
                    src: source,
                    type: sourceType
                })
            }

            // if source is youtube url/embed
            if (sourceType === 'video/youtube') {
                player.src({
                    src: source,
                    type: sourceType
                })
            }

            // if video has ads
            if (googleCheck) {
                // @TODO: MAJOR ISSUE with initiating Google IMA SDK, it constantly appends new script tags to head without checking if they exist
                player.ima(adSettings)
            }
        }

        return () => {
            if (playerRef.current && !playerRef.current.isDisposed()) {
                playerRef.current.dispose()
                playerRef.current = null
            }
        }
    }, [options, hasOptions, source, sourceType, dataIsReady])

    return (
        <div data-vjs-player key={source}>
            <div ref={videoNodeRef} playsInline className="video-js" autoPlay={options.autoPlay} />
        </div>
    )
}

VideoJS.propTypes = {
    videoOptions: PropTypes.shape({}),
    adSettings: PropTypes.shape({
        adTagUrl: PropTypes.string
    }),
    onReady: PropTypes.func
}

VideoJS.defaultProps = {
    videoOptions: {},
    adSettings: {},
    onReady: undefined
}

export default VideoJS
