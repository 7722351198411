// @TODO: fix empty objects lead: {}, erase them from here and from card style

import { convertHexToRGBA as rgba, responsive, toRem } from '@hmn/coolinarika-web-ui/helpers/theme'
import colors from '@hmn/coolinarika-web-ui/theme/globals/colors'
import typography from '@hmn/coolinarika-web-ui/theme/globals/typography'

const basicSpacing = [
    { padding: toRem(30) },
    { padding: toRem(32) },
    { padding: toRem(34) },
    { padding: toRem(36) },
    { padding: toRem(38) },
    { padding: toRem(40) }
]

export const cardHoverSettings = {
    '@media (hover: hover)': {
        '.cardPicture': {
            img: {
                transition: 'transform 300ms cubic-bezier(0.6, 0.33, 0.43, 0.93)',
                willChange: 'transform'
            },
            '&_title': {
                transition: 'color 300ms cubic-bezier(0.6, 0.33, 0.43, 0.93)'
            }
        },
        '.cardContent_title': {
            span: {
                '&::before': {
                    transition: 'background-color 300ms cubic-bezier(0.6, 0.33, 0.43, 0.93)'
                }
            }
        },
        '&:hover': {
            '.cardPicture_title': {
                color: colors.coolDarkRed200
            },
            '.cardPicture': {
                img: {
                    transform: 'scale(1.0225) translateY(-2.5px)'
                }
            },
            '.cardContent_title': {
                span: {
                    '&::before': {
                        backgroundColor: colors.coolDarkRed200
                    }
                }
            }
        }
    }
}

export const cardStyleConfig = {
    '1x1': {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(18) },
                { fontSize: toRem(18) },
                { fontSize: toRem(19) },
                { fontSize: toRem(19) },
                { fontSize: toRem(20) },
                { fontSize: toRem(20) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        lead: {}
    },
    '1x2': {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(24) },
                { fontSize: toRem(24) },
                { fontSize: toRem(26) },
                { fontSize: toRem(26) },
                { fontSize: toRem(28) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        lead: {
            display: 'initial'
        }
    },
    '2x1': {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(18) },
                { fontSize: toRem(20) },
                { fontSize: toRem(22) },
                { fontSize: toRem(24) },
                { fontSize: toRem(26) },
                { fontSize: toRem(28) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        lead: {}
    },
    '2x2': {
        spacing: {
            ...responsive([
                { padding: toRem(30) },
                { padding: toRem(32) },
                { padding: toRem(50) },
                { padding: toRem(50) },
                { padding: toRem(50) },
                { padding: toRem(50) }
            ])
        },
        title: {
            ...responsive([
                { fontSize: toRem(28) },
                { fontSize: toRem(28) },
                { fontSize: toRem(32) },
                { fontSize: toRem(34) },
                { fontSize: toRem(34) },
                { fontSize: toRem(36) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.black
        },
        lead: {
            display: 'initial'
        }
    },
    fullWidth: {
        spacing: {
            ...responsive([
                { padding: toRem(30) },
                { padding: toRem(32) },
                { padding: toRem(50) },
                { padding: toRem(50) },
                { padding: toRem(50) },
                { padding: toRem(50) }
            ])
        },
        title: {
            ...responsive([
                { fontSize: toRem(28) },
                { fontSize: toRem(28) },
                { fontSize: toRem(32) },
                { fontSize: toRem(34) },
                { fontSize: toRem(34) },
                { fontSize: toRem(36) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.black
        },
        lead: {
            display: 'initial'
        }
    },
    SEEALL: {
        spacing: {
            ...responsive(basicSpacing)
        },
        title: {
            ...responsive([
                { fontSize: toRem(18) },
                { fontSize: toRem(18) },
                { fontSize: toRem(19) },
                { fontSize: toRem(19) },
                { fontSize: toRem(20) },
                { fontSize: toRem(20) }
            ])
        },
        label: {
            fontWeight: typography.fontWeight.semiBold
        },
        card: {
            background: `${rgba(colors.black, 0.5)}`,
            cursor: 'pointer'
        }
    }
}
