import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { HeadingSectionMain } from '../../../HeadingSection/components/Main'
import styles from './ResultsTitle.style'

const ResultsTitleStyled = styled.div(props => ({ ...styles(props) }))

const ResultsTitle = ({ title, titleDecoration, hideLogo, headingSize, className, ...props }) => (
    <ResultsTitleStyled className={className} {...props}>
        <HeadingSectionMain
            title={title}
            headingSize={headingSize}
            hideLogo={hideLogo}
            titleDecoration={titleDecoration}
        />
    </ResultsTitleStyled>
)

ResultsTitle.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    titleDecoration: PropTypes.bool,
    hideLogo: PropTypes.bool,
    headingSize: PropTypes.string
}

ResultsTitle.defaultProps = {
    title: undefined,
    className: undefined,
    titleDecoration: false,
    hideLogo: false,
    headingSize: undefined
}

export default ResultsTitle
