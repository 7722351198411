import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { HtmlContent } from '../HtmlContent'
import { Link } from '../Link'
import styles from './Logo.style'

const LogoStyled = styled.div(props => ({ ...styles(props) }))

const Logo = ({
    tagComponent: Tag,
    title,
    href,
    hrefAs,
    hrefTitle,
    className,
    decoration,
    byPodravka,
    rounded,
    ...rest
}) => (
    <LogoStyled className={className} decoration={decoration} rounded={rounded} {...rest}>
        <Tag className="coolLogo">
            <ConditionalWrapper condition={!!href} href={href} as={hrefAs} title={hrefTitle} Wrapper={Link}>
                <HtmlContent content={title} className="coolLogo_txt" wrapperComponent="span" />
                {byPodravka && <span className="coolLogo_by">by Podravka</span>}
            </ConditionalWrapper>
        </Tag>
        {decoration && <span aria-hidden role="presentation" className="coolLogo_wave" />}
    </LogoStyled>
)

Logo.propTypes = {
    tagComponent: PropTypes.elementType,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    hrefTitle: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    decoration: PropTypes.bool,
    byPodravka: PropTypes.bool,
    rounded: PropTypes.bool
}

Logo.defaultProps = {
    tagComponent: 'div',
    href: '/',
    hrefAs: undefined,
    hrefTitle: 'coolinarika',
    title: '<span>cool</span>inarika <p>®</p>',
    className: undefined,
    decoration: false,
    byPodravka: false,
    rounded: false
}

export default Logo
