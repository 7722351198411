// @ui imports
import { responsive, toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, inverted, size }) => {
    const fontSize = {
        small: [
            { fontSize: toRem(20) }, // xs
            { fontSize: toRem(20) }, // sm
            { fontSize: toRem(22) }, // md
            { fontSize: toRem(22) }, // lg
            { fontSize: toRem(24) } // xl
        ],
        smaller: [
            { fontSize: toRem(24) }, // xs
            { fontSize: toRem(24) }, // sm
            { fontSize: toRem(26) }, // md
            { fontSize: toRem(28) }, // lg
            { fontSize: toRem(30) }, // xl
            { fontSize: toRem(32) } // xxl
        ],
        heading_smaller: [
            { fontSize: toRem(24) }, // xs
            { fontSize: toRem(24) }, // sm
            { fontSize: toRem(26) }, // md
            { fontSize: toRem(26) }, // lg
            { fontSize: toRem(28) } // xl
        ],
        heading: [
            { fontSize: toRem(24) }, // xs
            { fontSize: toRem(24) }, // sm
            { fontSize: toRem(24) }, // md
            { fontSize: toRem(38) } // lg
        ],
        related: [
            { fontSize: toRem(28) }, // xs
            { fontSize: toRem(28) }, // sm
            { fontSize: toRem(42) }, // md
            { fontSize: toRem(42) } // lg
        ],
        related_smaller: [
            { fontSize: toRem(28) }, // xs
            { fontSize: toRem(28) }, // sm
            { fontSize: toRem(28) }, // md
            { fontSize: toRem(28) }, // lg
            { fontSize: toRem(30) }, // xl
            { fontSize: toRem(32) } // xxl
        ],
        normal: [
            { fontSize: toRem(32) }, // xs
            { fontSize: toRem(32) }, // sm
            { fontSize: toRem(42) }, // md
            { fontSize: toRem(42) } // lg
        ],
        larger: [
            { fontSize: toRem(32) }, // xs
            { fontSize: toRem(32) }, // sm
            { fontSize: toRem(32) }, // md
            { fontSize: toRem(32) }, // lg
            { fontSize: toRem(42) }, // xl
            { fontSize: toRem(52) } // xxl
        ],
        large: [
            { fontSize: toRem(32) }, // xs
            { fontSize: toRem(32) }, // sm
            { fontSize: toRem(42) }, // md
            { fontSize: toRem(42) }, // lg
            { fontSize: toRem(62) }, // xl
            { fontSize: toRem(62) } // xxl
        ],
        club: [
            { fontSize: toRem(24) }, // xs
            { fontSize: toRem(24) }, // sm
            { fontSize: toRem(36) }, // md
            { fontSize: toRem(36) }, // lg
            { fontSize: toRem(42) }, // xl
            { fontSize: toRem(42) } // xxl
        ],
        recipe_of_the_day: [
            { fontSize: toRem(32) }, // xs
            { fontSize: toRem(32) }, // sm
            { fontSize: toRem(30) }, // md
            { fontSize: toRem(30) }, // lg
            { fontSize: toRem(40) }, // xl
            { fontSize: toRem(52) } // xxl
        ]
    }

    return {
        position: 'relative',
        display: 'block',
        margin: 0,
        padding: 0,
        outline: 0,
        textDecoration: 'none',
        color: inverted ? theme.colors.heading.section.text.inverted : theme.colors.heading.section.text.default,
        fontWeight: theme.typography.fontWeight.black,
        lineHeight: 1.2,
        ...responsive(fontSize[size] || fontSize.large),
        // Accent colors for inner text
        '& .accentPrimary': {
            color: theme.colors.accent.text.primary
        },
        '& .accentSecondary': {
            color: theme.colors.accent.text.secondary
        },
        '& .accentTertiary': {
            color: theme.colors.accent.text.tertiary
        },
        '& .accentQuaternary': {
            color: theme.colors.accent.text.quaternary
        }
    }
}

export default style
