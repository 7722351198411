/**
 * @TODO: This HOC is only used due to Reflebox' ThemeProvider incompatibility with
 * Coolinarika's core ThemeProvider and various Emotion major version differences.
 *
 * We should keep track of this pull request and update when it's released:
 *  * https://github.com/rebassjs/rebass/pull/1051/files
 */
import { memo, useRef } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useMultiline } from '@hmn/coolinarika-web-core/hooks'

import styles from './Highlight.style'

const HighlightStyled = styled.h3(props => ({ ...styles(props) }))

const Highlight = ({ children, TagComponent: Tag, variant, size, hasHover, className, theme, ...rest }) => {
    const currentElement = useRef(null)

    const [multilineHtmlProps, isReady] = useMultiline({
        text: children,
        container: {
            elementRef: currentElement,
            fallbackFamily: theme.typography.font?.default,
            fallbackSize: theme.typography?.fontSizes?.h3?.[0],
            fallbackWeight: theme.typography?.fontWeight?.black
        }
    })

    if (children && typeof children !== 'string') {
        throw new Error(`Highlight component expected 'children' prop to be of string type.`)
    }

    if (!children?.length) {
        return null
    }

    return (
        <HighlightStyled
            as={Tag}
            ref={currentElement}
            className={className}
            variant={variant}
            size={size}
            hasHover={hasHover}
            isReady={isReady}
            {...rest}
            {...multilineHtmlProps}
        />
    )
}

const highlightVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
})

const highlightSizes = Object.freeze({
    XSMALL: 'xSmall',
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large',
    XLARGE: 'xLarge'
})

Highlight.propTypes = {
    className: PropTypes.string,
    TagComponent: PropTypes.elementType,
    variant: PropTypes.oneOf([...Object.values(highlightVariants)]),
    size: PropTypes.oneOf([...Object.values(highlightSizes)]),
    hasHover: PropTypes.bool
}

Highlight.defaultProps = {
    className: undefined,
    TagComponent: 'h3',
    variant: highlightVariants.PRIMARY,
    size: highlightSizes.NORMAL,
    hasHover: false
}

export { highlightVariants, highlightSizes }

export default withTheme(memo(Highlight))
