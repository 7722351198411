/* eslint-disable camelcase */

import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useAnalytics, useImageUrl, useOne } from '@hmn/coolinarika-web-core/hooks'

import { withErrorBoundary } from '../../../ErrorBoundary'
import styles from './Player.style'
import VideoJS from './VideoJS.component'

const VideoPlayerStyled = styled.div(props => ({ ...styles(props) }))

const VideoPlayer = ({
    videoId,
    videoSrc,
    autoPlay,
    controls,
    height,
    width,
    loop,
    muted,
    poster,
    preload,
    aspectRatio,
    fluid,
    fill,
    isCover,
    disableBorderRadius,
    additionalPlayerOptions,
    adTagUrl,
    additionalAdSettings,
    className,
    thumbnailFallback,
    nativeControlsForTouch,
    enableAnalytics,
    ...rest
}) => {
    const { data: video } = useOne('videos', { id: videoId }, {})
    const { manifest_url, thumbnail } = video || {}

    const videoPoster = useImageUrl(thumbnail?.id || thumbnailFallback, 'original', width || 960, height, 'webp')

    const { eventWithTrackingData } = useAnalytics('coolinarika.video')

    const playerRef = useRef(null)

    const [videoSource, setVideoSource] = useState()

    const videoOptions = {
        autoplay: autoPlay,
        controls,
        width,
        height,
        loop,
        muted,
        poster: poster || videoPoster,
        preload,
        aspectRatio: aspectRatio || undefined,
        fluid: fluid && !isCover,
        fill: fill || isCover,
        sources: [
            {
                src: videoSource
            }
        ],
        suppressNotSupportedError: true,
        nativeControlsForTouch,
        ...additionalPlayerOptions
    }

    const adSettings = { adTagUrl, adLabel: '', adLabelNofN: 'od', ...additionalAdSettings }

    const handlePlayerReady = player => {
        playerRef.current = player

        if (enableAnalytics) {
            player.on('play', e => {
                eventWithTrackingData({
                    elementCategory: 'video',
                    elementLabel: 'Play',
                    elementPageLocation: 'body',
                    videoName: video?.name,
                    videoStatus: 'play'
                })
            })

            player.on('stop', e => {
                eventWithTrackingData({
                    elementCategory: 'video',
                    elementLabel: 'Stop',
                    elementPageLocation: 'body',
                    videoName: video?.name,
                    videoStatus: 'stop'
                })
            })

            player.on('pause', e => {
                eventWithTrackingData({
                    elementCategory: 'video',
                    elementLabel: 'Pause',
                    elementPageLocation: 'body',
                    videoName: video?.name,
                    videoStatus: 'pause'
                })
            })
            player.on('ended', e => {
                eventWithTrackingData({
                    elementCategory: 'video',
                    elementLabel: 'Video',
                    elementPageLocation: 'body',
                    videoName: video?.name,
                    videoCompletion: '100%'
                })
            })

            const tracked = []
            const trackingThresholds = [25, 50, 75]
            player.on('timeupdate', e => {
                const currentTime = player.currentTime()
                const totalDuration = player.duration()
                const currentProgress = Math.round((currentTime / totalDuration) * 100)

                trackingThresholds.forEach(threshold => {
                    if (!tracked.includes(threshold) && threshold === currentProgress) {
                        tracked.push(currentProgress)
                        eventWithTrackingData({
                            elementCategory: 'video',
                            elementLabel: 'Video',
                            elementPageLocation: 'body',
                            videoName: video?.name,
                            videoCompletion: `${currentProgress}%`
                        })
                    }
                })
            })
        }
    }

    useEffect(() => {
        if (videoSource) {
            return
        }

        if (videoSrc || manifest_url) {
            setVideoSource(videoSrc || manifest_url)
        }
    }, [videoSrc, manifest_url])

    if (!videoSource) {
        return null
    }

    return (
        <VideoPlayerStyled className={className} isCover={isCover} disableBorderRadius={disableBorderRadius} {...rest}>
            <VideoJS videoOptions={videoOptions} adSettings={adSettings} onReady={handlePlayerReady} />
        </VideoPlayerStyled>
    )
}

VideoPlayer.propTypes = {
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    videoSrc: PropTypes.string,
    autoPlay: PropTypes.bool,
    controls: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    poster: PropTypes.string,
    preload: PropTypes.oneOf(['auto', 'metadata', 'true']),
    aspectRatio: PropTypes.string,
    fluid: PropTypes.bool,
    fill: PropTypes.bool,
    isCover: PropTypes.bool,
    disableBorderRadius: PropTypes.bool,
    additionalPlayerOptions: PropTypes.oneOfType([() => null, PropTypes.object]),
    adTagUrl: PropTypes.string,
    additionalAdSettings: PropTypes.oneOfType([() => null, PropTypes.object]),
    className: PropTypes.string,
    thumbnailFallback: PropTypes.string,
    nativeControlsForTouch: PropTypes.bool,
    enableAnalytics: PropTypes.bool
}

VideoPlayer.defaultProps = {
    videoId: undefined,
    videoSrc: undefined,
    autoPlay: false,
    controls: true,
    height: undefined,
    width: undefined,
    loop: false,
    muted: false,
    poster: undefined,
    preload: 'auto',
    aspectRatio: undefined,
    fluid: true,
    fill: false,
    isCover: false,
    disableBorderRadius: false,
    additionalPlayerOptions: undefined,
    adTagUrl: undefined,
    additionalAdSettings: undefined,
    className: undefined,
    thumbnailFallback: undefined,
    nativeControlsForTouch: true,
    enableAnalytics: false
}

export default withErrorBoundary(VideoPlayer)
