export const titles = {
    'edit-tag': {
        title: 'Uredivanje kolekcije'
    },
    'add-tag': {
        title: 'Tagovi'
    },
    'collections-overview': {
        title: 'Recept je spremljen'
    },
    'collections-edit': {
        title: 'Uredivanje kolekcije'
    },
    'drawer-auth-login': {
        // @TODO revert when read only mode is over
        title: 'Hej'
    },
    'drawer-auth-register': {
        title: 'Registracija'
    },
    'drawer-auth-reset-password': {
        title: 'Lozinka'
    },
    'drawer-auth-new-password': {
        title: 'Lozinka'
    },
    'drawer-edit-settings-other': {
        title: 'Ostalo'
    },
    'drawer-edit-settings-gamify': {
        title: 'Trofeji'
    },
    'drawer-edit-settings-basic-info': {
        title: 'Osnovni podaci'
    },
    'drawer-edit-settings-avatar': {
        title: 'Avatar'
    },
    'drawer-edit-settings-biography': {
        title: 'Biografija i interesi'
    },
    'drawer-edit-settings-newsletter': {
        title: 'Newsletter prijave'
    },
    'drawer-edit-settings-password': {
        title: 'Lozinka'
    },
    'drawer-edit-settings-privacy': {
        title: 'Privatnost'
    },
    'drawer-edit-settings-consent': {
        title: 'Privole'
    },
    'trophy-details': {
        title: 'O trofeju'
    },
    'dish-drawer-all': {
        title: 'Jela'
    },
    'drawer-image-uploader-gallery': {
        title: 'Dodaj sliku'
    },
    'drawer-shopping-list': {
        title: 'Popis za kupnju'
    },
    'drawer-image-uploader-editor': {
        title: 'Uredi sliku'
    },
    'drawer-recipe-uploader-tags': {
        title: 'Tagovi za ovaj recept'
    },
    'drawer-recipe-uploader-success': {
        title: 'Bravo'
    },
    'drawer-cool-food-entry': {
        title: 'Sudjeluj!'
    }
}
