import styled from '@emotion/styled'

// import PropTypes from 'prop-types'
// import { SearchInput } from '../../../../Search/components/SearchV2/SearchInput'
import style from './TopBar.style'

const TopBarStyled = styled.div(props => ({ ...style(props) }))

const TopBar = ({ children }) => (
    <TopBarStyled>
        <div className="topBar_search">{children}</div>
    </TopBarStyled>
)

// const TopBar = ({ searchVariant, ...rest }) => (
//     <TopBarStyled>
//         <div className="topBar_search">
//             <SearchInput variant={searchVariant} {...rest} />
//         </div>
//     </TopBarStyled>
// )

// TopBar.propTypes = {
//     searchVariant: PropTypes.string
// }

// TopBar.defaultProps = {
//     searchVariant: undefined
// }

export default TopBar
