import { NavigationAccountActionTypes } from '../../actions/navigationAccount'

const initialState = {
    isOpen: false
}

const navigationAccountReducer = (state = initialState, { type }) => {
    switch (type) {
        case NavigationAccountActionTypes.CLOSE_ACCOUNT: {
            return {
                ...state,
                isOpen: false
            }
        }

        case NavigationAccountActionTypes.OPEN_ACCOUNT: {
            return {
                ...state,
                isOpen: true
            }
        }

        case NavigationAccountActionTypes.TOGGLE_ACCOUNT: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }

        default:
            return state
    }
}

export { navigationAccountReducer }
