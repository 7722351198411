import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '../ErrorBoundary'
import { AccountMenu, AccountPanel, Menu, NavBar, Panel } from './components'
import styles from './Mobile.style'

const NavigationMobileStyled = styled.div(props => ({ ...styles(props) }))

const NavigationMobile = ({ className, primaryData, secondaryData, tertiaryData, accountData, ...rest }) => (
    <NavigationMobileStyled className={className} {...rest}>
        <NavBar />
        <Panel>
            <Menu primaryData={primaryData} secondaryData={secondaryData} tertiaryData={tertiaryData} />
        </Panel>
        <AccountPanel>
            <AccountMenu accountData={accountData} />
        </AccountPanel>
    </NavigationMobileStyled>
)

NavigationMobile.propTypes = {
    className: PropTypes.string,
    primaryData: PropTypes.oneOfType([() => null, PropTypes.object]),
    secondaryData: PropTypes.oneOfType([() => null, PropTypes.object]),
    tertiaryData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.shape({}))]),
    accountData: PropTypes.oneOfType([() => null, PropTypes.object])
}

NavigationMobile.defaultProps = {
    className: undefined,
    primaryData: undefined,
    secondaryData: undefined,
    tertiaryData: undefined,
    accountData: undefined
}

export default withErrorBoundary(NavigationMobile)
