import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './FilterPill.style'

const FilterPillStyled = styled.label(props => ({ ...style(props) }))

const FilterPill = ({ type, label, value, isActive, onChange, id, name }) => (
    <FilterPillStyled isActive={isActive} htmlFor={id}>
        <input
            className="filterPill_input"
            id={id}
            name={name}
            type={type}
            value={value}
            checked={isActive}
            onChange={onChange}
        />
        <span className="filterPill_label">{label}</span>
    </FilterPillStyled>
)

FilterPill.propTypes = {
    type: PropTypes.oneOf(['checkbox', 'radio']),
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    isActive: PropTypes.bool
}

FilterPill.defaultProps = {
    type: 'checkbox',
    label: undefined,
    value: undefined,
    id: undefined,
    onChange: undefined,
    name: undefined,
    isActive: false
}

export default FilterPill
