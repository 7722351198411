/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { DRAWER_ACTIONS, useDrawerDispatch, useDrawerState } from '@hmn/coolinarika-web-core/context/Drawer'
import { md, useAnalytics, useBreakpoints } from '@hmn/coolinarika-web-core/hooks'

import { DrawerContentController } from '../../controllers'
import { ContentSlider } from '../ContentSlider'
import { Backdrop, Content, Head } from './components'
import styles, { drawerMainVariant, drawerPanelVariant } from './Drawer.style'
import { titles } from './titles.constants'

const DrawerStyled = styled('div')(props => ({ ...styles(props) }))

function Drawer({ rootId = 'drawer-root' }) {
    // state
    const [animationStyles, setAnimationStyles] = useState({})
    // drawer state context
    const { isOpen, onOpen, onClose, drawerType, drawersOpen, isForward, title, gtmData } = useDrawerState()
    // drawer dispatch context
    const dispatch = useDrawerDispatch()

    const [isMobileUp] = useBreakpoints(md)

    // root element by id
    const rootElement = useMemo(() => {
        if (typeof document === 'undefined') {
            return null
        }

        return document.getElementById(rootId)
    }, [rootId, isOpen])

    const { eventWithTrackingData: pageView } = useAnalytics('coolinarika.drawerOpen', {
        trackingData: {
            elementCategory: 'drawer',
            elementLabel: titles?.[drawerType]?.title || title,
            elementPageLocation: 'body'
        }
    })

    const { eventWithTrackingData: clickTracking } = useAnalytics('coolinarika.itemClick', {
        trackingData: {
            elementCategory: 'drawer',
            elementLabel: gtmData?.level && `Lvl ${gtmData?.level}`,
            elementPageLocation: 'body'
        }
    })

    const handleAnalyticsClick = e => {
        if (e?.target?.classList?.contains('headClose_btn')) {
            return
        }

        if (e?.target?.tagName === 'BUTTON') {
            clickTracking()
        }
    }

    // handle close
    const handleDrawerClose = useCallback(
        () =>
            dispatch({
                type: DRAWER_ACTIONS.CLOSE_DRAWER,
                payload: {
                    drawerType: null
                }
            }),
        []
    )

    const animation = useMemo(() => {
        // @TODO: Fix initial styles
        const baseStylesMobile = {
            top: 0,
            right: 0
        }

        // @TODO: Fix initial styles
        const baseStylesDesktop = {
            top: 0,
            right: 0
        }

        // base styles and animation
        const baseStyles = {
            position: 'fixed',
            ...(isMobileUp ? baseStylesDesktop : baseStylesMobile)
        }

        if (isOpen) {
            return {
                ...baseStyles,
                opacity: 1,
                transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
                transform: isMobileUp ? 'translateX(0)' : 'translateY(0)'
            }
        }

        return {
            ...baseStyles,
            opacity: 0,
            transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
            transform: isMobileUp ? 'translateX(100%)' : 'translateY(100%)'
        }
    }, [isOpen, isMobileUp])

    const handleTransitionEnd = useCallback(() => {
        if (isOpen) {
            if (onOpen) {
                onOpen()
                pageView()
            }
            return
        }

        if (onClose) {
            // eslint-disable-next-line no-console
            console.log('CLOSING DRAWER', onClose)
            onClose()
        }
    }, [onClose, onOpen, isOpen])

    // handling scroll if its open
    React.useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isOpen])

    // handling animation
    React.useEffect(() => {
        if (isOpen) {
            setAnimationStyles(animation)
        } else {
            setTimeout(() => {
                setAnimationStyles(animation)
            }, 100)
        }
    }, [isOpen])

    // preventing showing if there is no root element or isnt opened
    if (!rootElement || !isOpen) {
        return null
    }

    return createPortal(
        <DrawerStyled variants={drawerMainVariant} initial="closed" onTransitionEnd={handleTransitionEnd}>
            <Backdrop onClick={handleDrawerClose} />
            <div
                className="drawerPanel"
                style={animationStyles}
                variants={drawerPanelVariant}
                onClick={handleAnalyticsClick}>
                <Head
                    title={titles?.[drawerType]?.title || title}
                    hasBackButton={drawersOpen.length > 1}
                    drawerType={drawerType}
                    onClose={handleDrawerClose}
                />
                <Content>
                    <ContentSlider key={drawerType} isForward={isForward}>
                        <DrawerContentController drawerType={drawerType} />
                    </ContentSlider>
                </Content>
            </div>
        </DrawerStyled>,
        rootElement
    )
}

export default memo(Drawer)
