import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant, inline, imageLabel, hasNotifications }) => {
    // variant variables
    const config = {
        profileFollowing: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1.2,
                usernameNameMargin: 0,
                userInfoBlockMargin: 5
            },
            xs: {
                imageSize: 60,
                fontSize: {
                    username: 18,
                    name: 14
                }
            },
            md: {
                imageSize: 80,
                fontSize: {
                    username: 20,
                    name: 16
                }
            }
        },
        recipe: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1.4,
                usernameNameMargin: 8,
                userInfoBlockMargin: 5
            },
            xs: {
                imageSize: 50,
                fontSize: {
                    username: 16,
                    name: 16
                }
            },
            md: {
                imageSize: 60,
                fontSize: {
                    username: 20,
                    name: 16
                }
            }
        },
        recipeOfDay: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1.3,
                usernameNameMargin: 0,
                userInfoBlockMargin: 0
            },
            xs: {
                imageSize: 50,
                fontSize: {
                    username: 16,
                    name: 16
                }
            },
            md: {
                imageSize: 80,
                fontSize: {
                    username: 22,
                    name: 22
                }
            }
        },
        navigation: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1,
                usernameNameMargin: 0,
                userInfoBlockMargin: 0
            },
            xs: {
                imageSize: 46,
                fontSize: {
                    username: 0,
                    name: 0
                },
                notification: {
                    offset: -6,
                    width: 22,
                    height: 22,
                    padding: [2, 4, 4, 4],
                    fontSize: 12
                }
            },
            md: {
                imageSize: 46,
                fontSize: {
                    username: 0,
                    name: 0
                },
                notification: {
                    offset: -6,
                    width: 22,
                    height: 22,
                    fontSize: 12
                }
            },
            xxxl: {
                imageSize: 60,
                notification: {
                    offset: -8,
                    width: 26,
                    height: 26,
                    fontSize: 14
                }
            }
        },
        navigationBar: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1.3,
                usernameNameMargin: 0,
                userInfoBlockMargin: 0
            },
            xs: {
                imageSize: 36,
                fontSize: {
                    username: 0,
                    name: 0
                },
                notification: {
                    offset: -4,
                    width: 18,
                    height: 18,
                    padding: [0, 3, 1, 3],
                    fontSize: 11
                }
            },
            md: {
                imageSize: 36,
                fontSize: {
                    username: 0,
                    name: 0
                },
                notification: {
                    offset: -4,
                    width: 18,
                    height: 18,
                    fontSize: 11
                }
            }
        },
        photo: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1.4,
                usernameNameMargin: 0,
                userInfoBlockMargin: 5
            },
            xs: {
                imageSize: 50,
                fontSize: {
                    username: 16,
                    name: 16
                }
            },
            md: {
                imageSize: 64,
                fontSize: {
                    username: 20,
                    name: 16
                }
            }
        },
        club: {
            default: {
                assetBorder: 3,
                usernameLineHeight: 1,
                usernameNameMargin: 0,
                userInfoBlockMargin: 0
            },
            xs: {
                imageSize: 70,
                fontSize: {
                    username: 16,
                    name: 14
                }
            },
            md: {
                imageSize: 108,
                fontSize: {
                    username: 20,
                    name: 14
                }
            }
        },
        coolChef: {
            default: {
                assetBorder: 3,
                usernameLineHeight: 1,
                usernameNameMargin: 0,
                userInfoBlockMargin: 0
            },
            xs: {
                imageSize: 100,
                fontSize: {
                    username: 16,
                    name: 14
                }
            },
            md: {
                imageSize: 140,
                fontSize: {
                    username: 20,
                    name: 14
                }
            }
        },
        notification: {
            default: {
                assetBorder: 0,
                usernameLineHeight: 1,
                usernameNameMargin: 0,
                userInfoBlockMargin: 0
            },
            xs: {
                imageSize: 50,
                fontSize: {
                    username: 0,
                    name: 0
                },
                notification: {
                    offset: -2,
                    width: 14,
                    height: 14
                }
            },
            md: {
                imageSize: 50,
                fontSize: {
                    username: 0,
                    name: 0
                },
                notification: {
                    offset: -2,
                    width: 14,
                    height: 14
                }
            }
        }
    }

    return {
        position: 'relative',
        display: inline ? 'inline-flex' : undefined,
        textAlign: 'center',
        a: {
            textDecoration: 'none'
        },
        '& .avatarInner': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: ['club', 'coolChef'].includes(variant) ? 'flex-start' : 'center',
            ...(['recipe', 'recipeOfDay', 'photo', 'profileFollowing', 'club', 'coolChef'].includes(variant) && {
                display: 'inline-flex',
                flexDirection: 'row',
                verticalAlign: 'middle'
            })
        },
        '& .userAssets': {
            flexShrink: 0,
            position: 'relative',
            width: toRem(config[variant]?.xs?.imageSize),
            height: toRem(config[variant]?.xs?.imageSize),
            border: `${toRem(config[variant]?.default?.assetBorder)} solid transparent`,
            borderRadius: '100%',
            background: theme.colors.avatar.background.image,
            ...(hasNotifications && {
                cursor: 'pointer'
            }),
            [theme.breakpoints.up('md')]: {
                width: toRem(config[variant]?.md?.imageSize),
                height: toRem(config[variant]?.md?.imageSize)
            },
            [theme.breakpoints.up('xxxl')]: {
                ...(['navigation'].includes(variant) && {
                    width: toRem(config[variant]?.xxxl?.imageSize),
                    height: toRem(config[variant]?.xxxl?.imageSize)
                })
            },
            '&::before': {
                ...(imageLabel && {
                    content: `"${imageLabel}"`,
                    zIndex: theme.zIndex.over,
                    position: 'absolute',
                    display: 'inline-block',
                    left: toRem(-8),
                    top: 0,
                    width: toRem(26),
                    height: toRem(26),
                    fontWeight: 'bolder',
                    borderRadius: '50%',
                    fontSize: toRem(14),
                    textAlign: 'center',
                    backgroundColor: theme.colors.accentYellow,
                    pointerEvents: 'none'
                })
            },
            '&_imageWrap': {
                width: '100%',
                height: '100%',
                borderRadius: '100%',
                overflow: 'hidden',
                backgroundImage: `url(/images/placeholder/placeholder-user-profile.svg)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }
        },
        '& .avatarNotifications': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: theme.zIndex.over,
            position: 'absolute',
            right: toRem(config[variant]?.xs?.notification?.offset),
            top: toRem(config[variant]?.xs?.notification?.offset),
            minWidth: toRem(config[variant]?.xs?.notification?.width),
            minHeight: toRem(config[variant]?.xs?.notification?.width),
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: theme.colors.notification.badge.border,
            borderRadius: '100%',
            background: theme.colors.notification.badge.background,
            color: theme.colors.notification.badge.text,
            fontSize: toRem(config[variant]?.xs?.notification?.fontSize),
            lineHeight: 1,
            fontWeight: theme.typography.fontWeight.black,
            pointerEvents: 'none',
            [theme.breakpoints.up('md')]: {
                right: toRem(config[variant]?.md?.notification?.offset),
                top: toRem(config[variant]?.md?.notification?.offset),
                minWidth: toRem(config[variant]?.md?.notification?.width),
                minHeight: toRem(config[variant]?.md?.notification?.width),
                fontSize: toRem(config[variant]?.md?.notification?.fontSize)
            },
            [theme.breakpoints.up('xxxl')]: {
                right: toRem(config[variant]?.xxxl?.notification?.offset),
                top: toRem(config[variant]?.xxxl?.notification?.offset),
                minWidth: toRem(config[variant]?.xxxl?.notification?.width),
                minHeight: toRem(config[variant]?.xxxl?.notification?.width),
                fontSize: toRem(config[variant]?.xxxl?.notification?.fontSize)
            },
            '& > span': {
                width: '100%',
                padding: toRems(config[variant]?.xs?.notification?.padding || [0]),
                textAlign: 'center',
                lineHeight: 'inherit'
            }
        },
        '& .userInfo': {
            display: 'flex',
            flexDirection: variant === 'profileFollowing' ? 'row' : 'column',
            alignItems: variant === 'photo' ? 'flex-start' : 'center',
            justifyContent: ['club', 'coolChef'].includes(variant) ? 'center' : null,
            textAlign: 'left',
            ...(variant === 'profileFollowing' && {
                flexWrap: 'wrap'
            }),
            margin: toRems([8, 0, 0, 0]),
            ...(['recipeOfDay'].includes(variant) && {
                margin: toRems([0, 10, 0, 10])
            }),
            ...(['recipe', 'photo', 'profileFollowing'].includes(variant) && {
                margin: toRems([0, 0, 0, 14])
            }),
            ...(['club', 'coolChef'].includes(variant) && {
                height: toRem(48),
                marginTop: 0,
                marginLeft: toRem(8),
                textAlign: 'left',
                [theme.breakpoints.up('md')]: {
                    height: toRem(67),
                    marginLeft: toRem(16)
                }
            }),
            '&_label': {
                marginBottom: toRem(3),
                fontSize: toRem(14),
                lineHeight: 1.2,
                color: theme.colors.avatar.text[variant]?.label
            },
            '&_username': {
                fontWeight: theme.typography.fontWeight.black,
                fontSize: toRem(config[variant]?.xs?.fontSize?.username),
                lineHeight: config[variant]?.default?.usernameLineHeight,
                color: theme.colors.avatar.text[variant]?.username,
                wordBreak: 'break-word',
                ...(['recipe', 'photo'].includes(variant) && {
                    color: theme.colors.avatar.text[variant]?.username
                }),
                [theme.breakpoints.up('md')]: {
                    fontSize: toRem(config[variant]?.md?.fontSize?.username)
                }
            },
            '&_name': {
                marginTop: toRem(config[variant]?.default?.usernameNameMargin),
                fontSize: toRem(config[variant]?.xs?.fontSize?.name),
                [theme.breakpoints.up('md')]: {
                    fontSize: toRem(config[variant]?.md?.fontSize?.name)
                },
                lineHeight: 1.19,
                color: theme.colors.avatar.text[variant]?.name
            },
            '&_following': {
                '&Label': {
                    display: 'inline-block',
                    padding: toRems([6, 8, 3]),
                    fontSize: toRem(13),
                    fontWeight: theme.typography.fontWeight.semiBold,
                    lineHeight: 1.2,
                    color: theme.colors.avatar.text[variant]?.label,
                    backgroundColor: theme.colors.avatar.text[variant]?.background
                }
            },
            '&_block': {
                '&:first-of-type': {
                    marginRight: toRem(config[variant]?.default?.userInfoBlockMargin)
                }
            },
            '&_date': {
                fontSize: toRem(14),
                color: theme.colors.text.default
            }
        }
    }
}

export default style
