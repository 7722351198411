const constants = {
    CLOSE: 'HMN/COOLINARIKA/WEB/SEARCH/CLOSE_SEARCH',
    OPEN: 'HMN/COOLINARIKA/WEB/SEARCH/OPEN_SEARCH',
    TOGGLE: 'HMN/COOLINARIKA/WEB/SEARCH/TOGGLE_SEARCH',
    SAVE: 'HMN/COOLINARIKA/WEB/SEARCH/SAVE_SEARCH',
    IS_MANUALLY_CLOSED: 'HMN/COOLINARIKA/WEB/SEARCH/IS_MANUALLY_CLOSED'
}

const actions = {
    open: () => ({ type: constants.OPEN }),
    close: () => ({ type: constants.CLOSE }),
    toggle: () => ({ type: constants.TOGGLE }),
    save: search => ({ type: constants.SAVE, search }),
    manuallyClosed: isManuallyClosed => ({ type: constants.IS_MANUALLY_CLOSED, isManuallyClosed })
}

export { constants as SearchActionTypes, actions as SearchActions }
