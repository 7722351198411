import { responsive, toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, active }) => ({
    display: 'flex',
    flexDirection: 'column',
    a: {
        textDecoration: 'none !important'
    },
    '& .subNavItem': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: toRems([5, 0]),
        fontWeight: theme.typography.fontWeight.medium,
        fontSize: toRem(16),
        lineHeight: 1.4,
        color: theme.colors.navigation.text.default,
        transitionDuration: theme.transitions.navigation.text.default.duration,
        transitionTimingFunction: theme.transitions.navigation.text.default.timingFunction,
        transitionDelay: theme.transitions.navigation.text.default.delay,
        transitionProperty: 'color',
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.navigation.text.active
        },
        ...(active && {
            color: theme.colors.navigation.text.active
        }),
        ...responsive([
            { fontSize: toRem(16) }, // xs
            { fontSize: toRem(16) }, // sm
            { fontSize: toRem(15) }, // md
            { fontSize: toRem(15) }, // lg
            { fontSize: toRem(15) }, // xl
            { fontSize: toRem(18) }, // xxl
            { fontSize: toRem(15) } // xxxl
        ])
    }
})

export default style
