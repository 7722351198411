/* eslint-disable import/no-absolute-path, import/extensions, quote-props, max-len */
import { convertHexToRGBA as rgba, toRem } from '@hmn/coolinarika-web-ui/helpers/theme'

import colors from './colors'

export default {
    deep: `0px 2.76726px 2.21381px ${rgba(colors.shadow.default, 0.0196802)}, 0px 6.6501px 5.32008px ${rgba(
        colors.shadow.default,
        0.0282725
    )}, 0px 12.5216px 10.0172px ${rgba(colors.shadow.default, 0.035)}, 0px 22.3363px 17.869px ${rgba(
        colors.shadow.default,
        0.0417275
    )}, 0px 41.7776px 33.4221px ${rgba(colors.shadow.default, 0.0503198)}, 0px 100px 80px ${rgba(
        colors.shadow.default,
        0.07
    )}`,
    subtle: `0 2px 6px ${rgba(colors.shadow.default, 0.06)}`,
    subtleDown: `0 6px 6px ${rgba(colors.shadow.default, 0.06)}`,
    navigation: {
        bar: `0 -2px 16px ${rgba(colors.shadow.default, 0.08)}`
    },
    pop: `0px 36px 80px ${rgba(colors.shadow.default, 0.59)}, 0px 15.0399px 33.4221px ${rgba(
        colors.shadow.default,
        0.424124
    )}, 0px 8.04107px 17.869px ${rgba(colors.shadow.default, 0.351703)}, 0px 4.50776px 10.0172px ${rgba(
        colors.shadow.default,
        0.295
    )}, 0px 2.39404px 5.32008px ${rgba(colors.shadow.default, 0.238297)}, 0px 0.996212px 2.21381px ${rgba(
        colors.shadow.default,
        0.165876
    )}`,
    input: {
        focus: `inset 0px ${toRem(4)} ${toRem(4)} ${rgba(colors.shadow.default, 0.02)}`
    },
    swiper: {
        navigation: `0px 8px 30px 8px ${rgba(colors.shadow.default, 0.35)}`
    }
}
