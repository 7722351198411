const constants = {
    CLOSE: 'HMN/COOLINARIKA/WEB/FILTERS/CLOSE_FILTERS',
    OPEN: 'HMN/COOLINARIKA/WEB/FILTERS/OPEN_FILTERS',
    TOGGLE: 'HMN/COOLINARIKA/WEB/FILTERS/TOGGLE_FILTERS'
}

const actions = {
    open: () => ({ type: constants.OPEN }),
    close: () => ({ type: constants.CLOSE }),
    toggle: () => ({ type: constants.TOGGLE })
}

export { constants as FiltersActionTypes, actions as FiltersActions }
