// @NOTE: Page/Section heading component

// Third party imports
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './Section.style'

const HeadingSectionStyled = styled.h1(props => ({ ...styles(props) }))

const HeadingSection = ({ children, TagComponent: Tag, inverted, className, size, ...rest }) => {
    if (!children) {
        return null
    }

    return (
        <HeadingSectionStyled as={Tag} inverted={inverted} size={size} className={className} {...rest}>
            {children}
        </HeadingSectionStyled>
    )
}

const headingSectionSizes = Object.freeze({
    SMALL: 'small',
    SMALLER: 'smaller',
    NORMAL: 'normal',
    LARGER: 'larger',
    LARGE: 'large',
    HEADING_SMALLER: 'heading_smaller',
    HEADING: 'heading',
    CLUB: 'club',
    RELATED: 'related',
    RELATED_SMALLER: 'related_smaller',
    RECIPE_OF_THE_DAY: 'recipe_of_the_day'
})

HeadingSection.propTypes = {
    className: PropTypes.string,
    TagComponent: PropTypes.elementType,
    inverted: PropTypes.bool,
    size: PropTypes.oneOf([...Object.values(headingSectionSizes)])
}

HeadingSection.defaultProps = {
    className: undefined,
    TagComponent: 'h1',
    inverted: false,
    size: headingSectionSizes.LARGE
}

export { headingSectionSizes }

export default HeadingSection
