/**
 * This hook covers both in-app notifications through Redux channel as well as native browser
 * notifications, which you can learn more about here:
 *     * https://developer.mozilla.org/en-US/docs/Web/API/notification
 *     * https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
 *
 * When used for native notifications, message is used as title.
 *
 * For hook to work properly, Redux actions & reducers have to be defined.
 *
 * @TODO: Migrate to Context API to remove Redux dependency.
 */

import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationActions } from '../actions/notifications'
import { globalSettings } from '../settings'

const { constants } = globalSettings
const { NOTIFICATIONS_DEFAULT_DURATION, NOTIFICATIONS_DEFAULT_DELAY } = constants

const validNotificationChannels = Object.freeze({
    APP: 'app',
    WINDOW: 'window',
    ALL: 'all'
})

const validNotificationTypes = Object.freeze({
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error'
})

/**
 * This is used if `showNotification` is used without callback, e.g.
 *
 *      <button onClick={showNotification} />
 *
 * to sanitize these Event types and only use valid notification types.
 */
const eventsToIgnore = [
    'click',
    'mousedown',
    'mouseup',
    'dblclick',
    'mousemove',
    'contextmenu',
    'touchstart',
    'touchmove',
    'touchend'
]

const useNotification = ({ id, message: defaultMessage, options = {} }) => {
    const { notifications = [] } = useSelector(state => state.notifications)
    const notification = notifications.find(stateNotification => stateNotification.id === id) || {}
    const isShown = Boolean(Object.keys(notification).length)

    const delayTimer = useRef()
    const durationTimer = useRef()

    const {
        duration = NOTIFICATIONS_DEFAULT_DURATION,
        delay = NOTIFICATIONS_DEFAULT_DELAY,
        type: defaultType = validNotificationTypes.INFO,
        channel = validNotificationChannels.APP,
        autoclose = true,
        actions,
        badge,
        body = defaultMessage,
        icon,
        image,
        requireInteraction = false,
        silent = true,
        title = 'Title: Coolinarika',
        onclick,
        onclose,
        onshow
    } = useMemo(() => options, [options])

    const dispatch = useDispatch()

    const showNotification = useCallback(
        newNotification => {
            // Show notification after default delay if it isn't already shown
            if (delay && !isShown) {
                if (typeof delay !== 'number') {
                    // eslint-disable-next-line no-console
                    console.error(
                        // eslint-disable-next-line max-len
                        `[useNotification]: Delay '${delay}' is not a valid number. Please use number value in miliseconds.`
                    )
                    return
                }
                delayTimer.current = setTimeout(() => {
                    dispatch(NotificationActions.show(newNotification))
                }, delay)
            } else {
                dispatch(NotificationActions.show(newNotification))
            }
        },
        [id, delay, isShown, dispatch]
    )

    const hideNotification = useCallback(
        oldNotification => dispatch(NotificationActions.hide(oldNotification)),
        [id, dispatch]
    )

    // Automatically close notification if it's shown, if autoclose is true and duration is set
    useEffect(() => {
        if (duration && typeof duration === 'number') {
            durationTimer.current = setTimeout(() => {
                if (autoclose && isShown) {
                    dispatch(NotificationActions.hide({ id }))
                }
            }, duration)
        } else {
            // eslint-disable-next-line no-console
            console.error(
                `[useNotification]: Duration '${duration}' is not a number. Please use number value in miliseconds.`
            )
            return
        }

        // Clear timeouts when components are unmounted, notifications are closed or the duration is changed
        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(durationTimer.current)
            clearTimeout(delayTimer.current)
        }
    }, [autoclose, isShown, duration])

    const sendWindowNotification = useCallback(
        ({ message = defaultMessage }) => {
            if (typeof message !== 'string') {
                // eslint-disable-next-line no-console
                console.error(`[useNotification]: Message must be a valid string.`)
                return
            }

            if (!('Notification' in window)) {
                return
            }

            if (Notification.permission !== 'granted') {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        // eslint-disable-next-line no-unused-vars
                        const windowNotification = new Notification(message, {
                            actions,
                            badge,
                            body: message || body,
                            icon,
                            image,
                            requireInteraction,
                            silent,
                            title,
                            onclick,
                            onclose,
                            onshow
                        })
                    }
                })
            } else {
                // eslint-disable-next-line no-unused-vars
                const windowNotification = new Notification(message, {
                    actions,
                    badge,
                    body: message || body,
                    icon,
                    image,
                    requireInteraction,
                    silent,
                    title,
                    onclick,
                    onclose,
                    onshow
                })
            }
        },
        [id, options]
    )

    const sendAppNotification = useCallback(
        (message, customOptions) => {
            showNotification({ id, message, options: { ...options, ...customOptions } })
        },
        [id, options]
    )

    const sendNotification = useCallback(
        ({ message = defaultMessage, type = defaultType, options: optionsProp = {} } = {}) => {
            if (typeof message !== 'string') {
                // eslint-disable-next-line no-console
                console.error(`[useNotification]: Message must be a valid string.`)
                return
            }

            if (type && !eventsToIgnore.includes(type) && !Object.values(validNotificationTypes).includes(type)) {
                // eslint-disable-next-line no-console
                console.error(
                    `[useNotification]: '${type}' is not a valid notification type. Please use one of: ${Object.values(
                        validNotificationTypes
                    ).join(', ')}.`
                )
                return
            }

            const customOptions = type ? { type, ...optionsProp } : { ...optionsProp }

            switch (channel) {
                case validNotificationChannels.APP:
                    sendAppNotification(message, customOptions)
                    break
                case validNotificationChannels.WINDOW:
                    sendWindowNotification(message, customOptions)
                    break
                case validNotificationChannels.ALL:
                    sendAppNotification(message)
                    sendWindowNotification(message, customOptions)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(`[useNotification]: '${channel}' is not a valid notification channel.`)
            }
        },
        [channel]
    )

    return {
        notification,
        isShown,
        sendNotification,
        hideNotification
    }
}

export { validNotificationTypes }
export default useNotification
