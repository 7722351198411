/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavUsersFollowing = createSvgIcon(
    <>
        <path d="M22.28 15.237a3.518 3.518 0 001.278-2.713A3.528 3.528 0 0020.034 9a3.528 3.528 0 00-3.446 4.265 3.528 3.528 0 00-4.142 3.47c0 1.09.498 2.066 1.277 2.713A5.98 5.98 0 0010 24.978h1.25a4.725 4.725 0 014.72-4.72 3.528 3.528 0 003.446-4.264c.2.036.407.054.618.054 1.533 0 2.898.736 3.76 1.873l.848-.944a6 6 0 00-2.362-1.74zm-6.31 3.772a2.276 2.276 0 01-2.274-2.274 2.276 2.276 0 012.274-2.274 2.277 2.277 0 012.274 2.274 2.276 2.276 0 01-2.274 2.274zm4.064-4.211a2.277 2.277 0 01-2.274-2.274 2.276 2.276 0 012.274-2.274 2.276 2.276 0 012.274 2.274 2.276 2.276 0 01-2.274 2.274z" />
        <path d="M25.07 18.372l-4.343 4.835-2.45-2.333-.862.906L20.798 25 26 19.207l-.93-.835z" />
    </>,
    'NavUsersFollowing'
)

export default NavUsersFollowing
