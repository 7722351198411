import { useEffect, useMemo, useRef, useState } from 'react'

const themeBreakpoints = {
    values: {
        // These default to pixels, type as String to override, e.g. '70em'
        xs: 0,
        sm: 360,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1600,
        bannerSmall: 1360,
        bannerLarge: 1650
    },
    /**
     *  Precision of measurement, it will be divided by 100, meaning we measure growth in 0.05
     *  pixels if step is equal to 5 and unit is equal to 'px'.
     */
    unit: 'px',
    step: 5
}

export const { xs, sm, md, lg, xl, xxl, xxxl, bannerSmall, bannerLarge } = themeBreakpoints.values

const toArray = list => (Array.isArray(list) ? list : [list])
const getMediaQueries = breakpoints => {
    const widths = toArray(breakpoints).map((breakpoint, index) => {
        const width = typeof breakpoint === 'string' ? themeBreakpoints.values[breakpoint] : breakpoint
        const query = window.matchMedia(`screen and (min-width: ${width}${themeBreakpoints.unit})`)
        return { index, width, query }
    })
    return widths.sort((a, b) => b.width - a.width)
}

const getActiveIndex = list => {
    const mediaQuery = list.find(({ query }) => query.matches)
    return mediaQuery ? mediaQuery.index : -1
}

/**
 * `useBreakpoints`
 *
 * Provides a value based of current breakpoint an up
 *
 * As well as function to update initial values
 *
 * ```js
 * import { useBreakpoints, sm, lg } from '@hmn/coolinarika-web-core/hooks'
 * const isDesktop = useBreakpoints(lg)
 * const columnNumber = useBreakpoints([lg, sm], [4, 2], 1)
 *
 * const [content, setContentValues] = useBreakpoints(
 *      'sm',
 *      { title: 'A', lead: 'B' },
 *      { title: 'a', lead: 'b' }
 * )
 * const onLoad = (data) => {
 *      setContentValues(
 *          { title: data.title, lead: data.lead },
 *          { title: data.title.toLowerCase(), lead: data.lead.toLowerCase() }
 *      )
 * }
 * ```
 */
const useBreakpoints = (breakpoints = [], values = [], defaultValue) => {
    const [localBreakpoints] = useState(toArray(breakpoints))
    const [localValues, setLocalValues] = useState(toArray(values))
    const [localDefault, setLocalDefault] = useState(defaultValue)

    const mediaQueries = useRef([])
    const [activeIndex, setActiveIndex] = useState(-1)

    const result = useMemo(() => {
        let value = typeof localDefault === 'undefined' ? false : localDefault
        let breakpoint = null

        if (activeIndex >= 0) {
            breakpoint = localBreakpoints[activeIndex]
            value = true
        }

        if (localValues.length > 0) {
            if (typeof localDefault !== 'undefined' && activeIndex < 0) {
                value = localDefault
            } else {
                value = localValues[Math.max(0, Math.min(activeIndex, localValues.length - 1))]
            }
        }

        return {
            value,
            breakpoint
        }
    }, [activeIndex, localBreakpoints, localValues, localDefault])

    useEffect(() => {
        if (!process.browser) {
            return () => {}
        }

        mediaQueries.current = getMediaQueries(localBreakpoints)
        const onMediaQuery = () => {
            setActiveIndex(getActiveIndex(mediaQueries.current))
        }

        onMediaQuery()

        mediaQueries.current.forEach(({ query }) => {
            if (typeof query.addEventListener === 'function') {
                query.addEventListener('change', onMediaQuery)
            } else if (typeof query.addListener === 'function') {
                query.addListener(onMediaQuery)
            }
        })

        return () => {
            mediaQueries.current.forEach(({ query }) => {
                if (typeof query.removeEventListener === 'function') {
                    query.removeEventListener('change', onMediaQuery)
                } else if (typeof query.removeListener === 'function') {
                    query.removeListener(onMediaQuery)
                }
            })
        }
    }, [localBreakpoints])
    const setValues = (newValues, newDefaultValue) => {
        setLocalValues(toArray(newValues))

        if (typeof newDefaultValue !== 'undefined') {
            setLocalDefault(newDefaultValue)
        }
    }
    return [result.value, setValues]
}

export default useBreakpoints
