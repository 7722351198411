import { delay, put, select, takeLatest } from 'redux-saga/effects'

import { CollectionActions, CollectionActionTypes } from '../../actions/collections'
import { DrawerActions } from '../../actions/drawer'

const handleAddToCollection = function* ({ collection }) {
    const { name } = collection
    // const { editorsOpen } = yield select(({ collections }) => collections)

    // Open editor every time we add to collection
    yield put(
        CollectionActions.openNotification({
            id: 'collections-notification',
            message: `Recept je dodan u kolekciju “${name}”`
        })
    )
    yield delay(200) // Open editor after short delay
    yield put(DrawerActions.openDrawer('collections-overview'))
    yield delay(5000) // Hide notification after 5 seconds
    yield put(CollectionActions.closeNotification())
}

const handleRemoveFromCollection = function* ({ collection }) {
    // const allCollections = yield select(({ collections }) => collections.collections)

    // If there is no collection ID, that means item belongs to multiple collections so Editor should be opened
    if (!collection.id) {
        yield delay(200)
        yield put(DrawerActions.openDrawer('collections-overview'))
    }
}

const handleCreateCollection = function* ({ collection }) {
    const { lastSavedItem } = yield select(({ collections }) => collections)
    // Add last added item to newly created collection
    if (lastSavedItem) {
        yield put(
            CollectionActions.add({
                ...collection,
                items: [...collection.items, lastSavedItem]
            })
        )
    }

    // yield delay(1000) // Close editor after short delay
    // yield put(DrawerActions.closeDrawer())
}

export const watchForAddToCollection = function* () {
    yield takeLatest(CollectionActionTypes.ADD_TO_COLLECTION, handleAddToCollection)
}

export const watchForRemoveFromCollection = function* () {
    yield takeLatest(CollectionActionTypes.REMOVE_FROM_COLLECTION, handleRemoveFromCollection)
}

export const watchForCreateCollection = function* () {
    yield takeLatest(CollectionActionTypes.CREATE_COLLECTION, handleCreateCollection)
}
