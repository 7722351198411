/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavUsersFollowers = createSvgIcon(
    <>
        <path d="M22.694 18.806l-.777.777A5.883 5.883 0 0018 18.093 5.913 5.913 0 0012.094 24H11c0-1.87.728-3.628 2.05-4.95a6.972 6.972 0 012.66-1.668A4.045 4.045 0 0118 10a4.045 4.045 0 012.29 7.382 6.955 6.955 0 012.404 1.424zm-7.647-4.76A2.956 2.956 0 0018 17a2.956 2.956 0 002.953-2.953A2.956 2.956 0 0018 11.094a2.956 2.956 0 00-2.953 2.953z" />
        <path d="M27.07 19.372l-4.343 4.835-2.45-2.333-.862.906L22.798 26 28 20.207l-.93-.835z" />
    </>,
    'NavUsersFollowers'
)

export default NavUsersFollowers
