import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import { NavigationAccountActions } from '@hmn/coolinarika-web-core/actions'

import { Button, buttonSizes, buttonVariants } from '../../../../Button'
import { withErrorBoundary } from '../../../../ErrorBoundary'
import { CloseIcon, Icon } from '../../../../Icon'
import styles, { motionVariants } from './Panel.style'

const AccountPanelStyled = styled.div(props => ({ ...styles(props) }))

const AccountPanel = ({ className, children, isDesktop, ...rest }) => {
    const dispatch = useDispatch()

    const handleAccountToggle = useCallback(() => dispatch(NavigationAccountActions.toggleAccount()), [dispatch])

    const { isOpen: isNavigationAccountOpen } = useSelector(state => state.navigationAccount)
    const [isMenuOpen, setIsMenuOpen] = useState(isNavigationAccountOpen)

    useEffect(() => {
        setIsMenuOpen(isNavigationAccountOpen)
    }, [isNavigationAccountOpen])

    return (
        <AccountPanelStyled className={className} isDesktop={isDesktop} {...rest}>
            <motion.div
                className="accountNavigationPanel"
                initial="closed"
                animate={isMenuOpen ? 'open' : 'closed'}
                variants={motionVariants()}>
                <div className="accountNavigationPanel_inner">{children}</div>
                {!isDesktop && (
                    <Button
                        className="accountNavigationPanel_close"
                        variant={buttonVariants.TERTIARY}
                        size={buttonSizes.NORMAL}
                        sizeMobile={buttonSizes.SMALL}
                        title="Zatvori"
                        onClick={handleAccountToggle}
                        isIconOnly>
                        <span className="showForSr">Zatvori</span>
                        <Icon icon={CloseIcon} size={32} />
                    </Button>
                )}
            </motion.div>
        </AccountPanelStyled>
    )
}

AccountPanel.propTypes = {
    isDesktop: PropTypes.bool,
    className: PropTypes.string
}

AccountPanel.defaultProps = {
    isDesktop: false,
    className: undefined
}

export default withErrorBoundary(AccountPanel)
