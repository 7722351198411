import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './TopLine.style'

const TopLineStyled = styled.div(props => ({ ...styles(props) }))

const TopLine = ({ className, layout, ...props }) =>
    layout !== 'embed' ? <TopLineStyled className={className} {...props} /> : null

TopLine.propTypes = {
    className: PropTypes.string,
    layout: PropTypes.string
}

TopLine.defaultProps = {
    className: undefined,
    layout: undefined
}

export default TopLine
