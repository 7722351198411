import { useCallback } from 'react'

import { DRAWER_ACTIONS, useDrawerDispatch, useDrawerState } from '../context/Drawer'

export default function useDrawer(drawerType, options = {}) {
    const drawerState = useDrawerState()
    const dispatch = useDrawerDispatch()

    const open = useCallback(
        (inlineOptions = {}) => {
            dispatch({
                type: DRAWER_ACTIONS.OPEN_DRAWER,
                payload: {
                    drawerType,
                    options: {
                        ...options,
                        ...inlineOptions
                    }
                }
            })
        },
        [drawerType, options, dispatch]
    )

    const close = useCallback(
        (inlineOptions = {}) => {
            dispatch({
                type: DRAWER_ACTIONS.CLOSE_DRAWER,
                payload: {
                    drawerType: null,
                    options: {
                        ...options,
                        ...inlineOptions
                    }
                }
            })
        },
        [options, dispatch]
    )

    return {
        isOpen: drawerState.isOpen,
        open,
        close,
        onOpen: drawerState.onOpen,
        onClose: drawerState.onClose
    }
}
