const style = () => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    margin: 0,
    position: 'relative',
    overflowY: 'auto'
})

export default style
