import { _AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

const enabled = false

const Switch = ({ children, exitBeforeEnter, initial }) => {
    if (!enabled) {
        return children
    }

    return children
}

Switch.propTypes = {
    exitBeforeEnter: PropTypes.bool,
    initial: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.shape({})])
}

Switch.defaultProps = {
    exitBeforeEnter: false, // We want them pages to overlap
    initial: true
}

export default Switch
