// @TODO: variable common dimensions, for example pt: toRems([40, 40, 40, 40, 142, 142], true)

import { breakpoints as globalBreakpoints, toRem, toRems } from '@hmn/coolinarika-web-ui/helpers/theme'

import layoutDimension from './dimensions'
import form from './form'
import grid from './grid'
import layoutSpacings from './spacings'

const { values: breakpointValuesArr, unit } = globalBreakpoints

const modifiedLayoutBreakpointsArr = Object.values(breakpointValuesArr).map(bp => (bp !== 0 ? `${bp}${unit}` : `${bp}`))

// Layout spacings
// @TODO: Optimize and cleanup, make it pretty
const spacing = {
    base: {
        xAxis: {
            main: Object.values(layoutSpacings?.base?.xAxis),
            xlUp: [layoutSpacings?.base?.xAxis?.xl, layoutSpacings?.base?.xAxis?.xxl, layoutSpacings?.base?.xAxis?.xxxl]
        }
    },
    topRow: {
        default: Object.values(layoutSpacings?.topRow),
        xlUp: [layoutSpacings?.topRow?.xl, layoutSpacings?.topRow?.xxl, layoutSpacings?.topRow?.xxxl]
    }
}

const layoutTheme = {
    breakpoints: modifiedLayoutBreakpointsArr,
    variants: {
        // Rows
        row: {
            top: {
                pt: toRems(spacing?.topRow.default, true),
                pb: toRem(80)
            }
        },
        // Filters
        filters: {
            row: {
                my: toRem(30),
                px: toRems(spacing?.base?.xAxis?.main, true),
                offset: {
                    mt: toRems([30, 30, 30, 30, 30, -56, -56], true),
                    mb: toRems([30, 30, 30, 30, 30, 30, 30], true),
                    px: toRems(spacing?.base?.xAxis?.main, true)
                }
            },
            column: {
                select: {
                    ml: 'auto',
                    pl: toRem(20)
                }
            }
        },
        // Card bar
        cardBar: {
            px: toRems(spacing?.base?.xAxis?.main, true)
        },
        footer: {
            separator: {
                pr: toRems(spacing?.base?.xAxis?.main, true),
                pt: toRem(20),
                pb: toRems([85, 85, 85, 80, 80, 80, 80], true)
            }
        },
        // Form variants
        ...form,
        // Grid variants
        ...grid,
        // Recipe single page layout
        recipe: {
            headerPrint: {
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: toRem(10)
            },
            head: {
                flexDirection: 'column',
                mb: toRems([20, 20, 20, 80, 80, 80, 120], true),
                pl: toRems([0, 0, 0, 20, ...spacing?.base?.xAxis?.xlUp], true),
                wrapper: {},
                wrapperPrint: { width: '100%' }
            },
            headPrint: {
                px: toRem(10),
                width: '100%'
            },
            main: {
                width: '100%',
                maxWidth: '100%',
                mb: toRems([50, 50, 50, 50, 100, 100, 100], true),
                px: toRems(spacing?.base?.xAxis?.main, true),
                display: 'flex',
                flexDirection: ['column', 'column', 'column', 'row', 'row', 'row']
            },
            mainPrint: {
                width: '100%',
                maxWidth: '100%',
                px: toRem(10),
                display: 'block !important'
            },
            coolFood: {
                width: '100%',
                maxWidth: '100%',
                pt: toRems([20, 20, 20, 0, 0, 0, 0], true),
                mb: toRems([20, 20, 20, 0, 0, 0, 0], true),
                px: toRems(spacing?.base?.xAxis?.main, true),
                display: 'flex',
                flexDirection: ['column', 'column', 'column', 'row', 'row', 'row']
            },
            sidebar: {
                width: '100%',
                // @TODO: fix (it does not follow recipe head structure)
                maxWidth: ['100%', '100%', '100%', toRem(390), toRem(360), toRem(460)],
                flexShrink: 0
            },
            sidebarPrint: {
                marginLeft: toRem(60),
                marginBottom: toRem(20),
                width: '40%',
                float: 'right'
            },
            separatorPrint: {
                my: toRem(40)
            },
            content: {
                width: '100%',
                maxWidth: '100%',
                pt: toRems([50, 50, 50, 0, 0, 0], true),
                pl: toRems([0, 0, 0, 60, 60, 110], true),
                column: {
                    mb: toRems([40, 40, 40, 60, 60, 60], true)
                },
                comments: {
                    mt: toRems([50, 50, 50, 50, 70, 70, 70], true)
                }
            },
            contentPrint: {
                column: {
                    mt: toRem(30)
                }
            },
            actionShots: {
                empty: {
                    mb: toRems([50, 50, 50, 50, 100, 100, 100], true)
                }
            },
            swiperSection: {
                flexDirection: 'column',
                mb: toRems([50, 50, 50, 50, 100, 100, 100], true),
                heading: {
                    width: '100%',
                    px: toRems(spacing?.base?.xAxis?.main, true),
                    mb: toRems([20, 20, 20, 40, 40, 40, 40], true)
                },
                footer: {
                    width: '100%',
                    mt: toRem(30),
                    px: toRems(spacing?.base?.xAxis?.main, true)
                },
                linker: {
                    width: '100%',
                    px: toRems(spacing?.base?.xAxis?.main, true),
                    mb: toRems([20, 20, 20, 40, 40, 40, 40], true)
                }
            }
        },
        // Recipes listing layout
        recipes: {
            hero: {
                mb: toRems([30, 30, 30, 30, 30, 60, 60], true)
            },
            heading: {
                px: toRems(spacing?.base?.xAxis?.main, true),
                py: toRems([20, 20, 20, 25, 30, 30], true)
            },
            listing: {
                px: toRems(spacing?.base?.xAxis?.main, true),
                py: toRems([20, 20, 20, 25, 30, 30], true)
            }
        },
        // Popular recipes layout
        popularRecipes: {
            main: {
                width: '100%',
                maxWidth: '100%',
                mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Article Layout
        article: {
            row: {
                px: toRems(spacing?.base?.xAxis?.main, true),
                pb: toRems([50, 50, 50, 50, 80, 80, 80], true)
            },
            main: {
                display: 'grid',
                gridTemplateColumns: [null, null, null, null, '1fr', `1fr ${toRem(345)}`],
                gridColumnGap: [null, null, null, null, null, toRem(100)],
                width: '100%',
                maxWidth: '100%'
            },
            heading: {
                width: '100%',
                mb: toRems([20, 20, 20, 20, 45], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            swiper: {
                mb: toRems([20, 20, 20, 20, 50], true)
            }
        },
        // Articles listing layout
        articles: {
            hero: {
                mb: toRems([30, 30, 30, 30, 30, 60, 60], true)
            }
        },
        // Inspiration layout
        inspiration: {
            heading: {
                pt: toRems(spacing?.topRow?.default, true),
                pb: toRems([30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            featured: {
                px: toRems([0, 0, 0, 0, ...spacing?.base?.xAxis?.xlUp], true),
                mb: toRems([20, 20, 20, 25, 25, 40], true)
            },
            main: {
                width: '100%',
                maxWidth: '100%',
                mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Cool chef layout
        coolChef: {
            heading: {
                pt: toRems(spacing?.topRow?.default, true),
                pb: toRems([30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Collections layout
        collections: {
            row: {
                py: toRems([20, 20, 20, 25, 30, 30], true),
                top: {
                    pt: toRems(spacing?.topRow?.default, true),
                    pb: toRems([20, 20, 20, 25, 30, 30], true)
                }
            },
            head: {
                pt: toRems([20, 20, 50, 100, 150, 200], true)
            },
            main: {
                width: '100%',
                maxWidth: '100%',
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Recipe of the day layout
        recipeOfTheDay: {
            heading: {
                pt: toRems(spacing?.topRow?.default, true),
                pb: toRems([30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            featured: {
                px: toRems([0, 0, 0, 0, ...spacing?.base?.xAxis?.xlUp], true),
                mb: toRems([20, 20, 20, 25, 25, 40], true)
            }
        },
        // Ingredient layout
        ingredient: {
            heading: {
                mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                mb: toRems([20, 20, 20, 20, 45], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            row: {
                px: toRems(spacing?.base?.xAxis?.main, true),
                pt: toRems([20, 20, 20, 20, 45], true),
                pb: toRem(80)
            },
            inline: {
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            main: {
                display: 'grid',
                gridTemplateColumns: [null, null, null, null, '1fr', `1fr ${toRem(345)}`],
                gridColumnGap: [null, null, null, null, null, toRem(100)],
                width: '100%',
                maxWidth: '100%'
            },
            content: {
                mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            listing: {
                my: toRems([40, 40, 50, 60, 70, 80], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Simple content page layout
        page: {
            basic: {
                head: {
                    display: 'flex',
                    flexDirection: ['column', 'column', 'column', 'column', 'column', 'row'],
                    width: '100%',
                    maxWidth: '100%',
                    px: toRems(spacing?.base?.xAxis?.main, true),
                    pt: toRems(spacing?.topRow?.default, true)
                },
                main: {
                    display: 'flex',
                    flexDirection: ['column', 'column', 'column', 'column', 'column', 'row'],
                    width: '100%',
                    maxWidth: '100%',
                    px: toRems(spacing?.base?.xAxis?.main, true),
                    pb: toRems([80, 80, 80, 80, 120, 120], true)
                },
                content: {
                    width: ['100%', '100%', '100%', '100%', '100%', `${layoutDimension?.main}%`]
                }
            }
        },
        // Photo layout
        photo: {
            heading: {
                mb: toRems([20, 20, 20, 20, 45], true),
                px: toRems(spacing?.base?.xAxis?.main, true),
                top: {
                    mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                    mb: toRems([20, 20, 20, 20, 45], true),
                    px: toRems(spacing?.base?.xAxis?.main, true)
                }
            },
            hero: {
                px: toRems([0, 0, 0, 20, ...spacing?.base?.xAxis?.xlUp], true),
                pt: toRems([0, 0, 0, 0, ...spacing?.topRow?.xlUp], true)
            },
            row: {
                px: toRems(spacing?.base?.xAxis?.main, true),
                pt: toRems([30, 30, 30, 30, 70, 70], true),
                pb: toRem(80)
            },
            main: {
                display: 'grid',
                gridTemplateColumns: [null, null, null, null, '1fr', `1fr ${toRem(345)}`],
                gridColumnGap: [null, null, null, null, null, toRem(100)],
                width: '100%',
                maxWidth: '100%'
            },
            related: {
                mt: toRem(10),
                mb: toRems([20, 20, 20, 30, 30], true),
                width: '100%',
                maxWidth: '100%'
            },
            swiper: {
                display: 'flex',
                flexDirection: ['column', 'column', 'column', 'column', 'column', 'row'],
                overflowX: 'hidden',
                width: '100%',
                maxWidth: '100%',
                mb: toRem(80)
            }
        },
        // Popular photos layout
        popularPhotos: {
            main: {
                width: '100%',
                maxWidth: '100%',
                mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Profile layout
        profile: {
            row: {
                top: {
                    pt: toRems(spacing?.topRow?.default, true),
                    pb: toRem(35)
                },
                switch: {
                    mb: toRem(40)
                },
                card: {
                    mb: toRem(45)
                }
            },
            column: {
                card: {
                    mb: toRems([15, 15, 25, 25, 50, 50], true)
                }
            }
        },
        // Dictionary layout
        dictionary: {
            heading: {
                flexDirection: ['column', 'column', 'column', 'column', 'row'],
                alignItems: 'flex-start',
                pt: toRems(spacing?.topRow?.default, true),
                pb: toRems([30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true),
                left: {
                    maxWidth: toRem(870)
                },
                right: {
                    width: '100%',
                    minWidth: toRem(200),
                    maxWidth: toRems([0, 0, 0, 0, 340], true),
                    pl: toRems([0, 0, 0, 0, 30, 40, 100], true),
                    pt: toRems([30, 30, 30, 30, 30, 40, 40], true)
                }
            },
            search: {
                mt: toRems([10, 10, 10, 10, 60, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            listing: {
                mt: toRems([30, 30, 30, 30, 50], true),
                pr: toRems([0, 0, 0, 0, 50, 70, 100], true)
            },
            emptyListing: {
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                pr: toRems([20, 20, 0, 0, 0, 0, 0], true),
                pl: toRems([20, 20, 0, 0, 0, 0, 0], true),
                pb: toRems([140, 140, 140, 140, 80, 80, 80], true)
            }
        },
        // user-profile-private tags page
        userTags: {
            heading: {
                mt: toRems([10, 10, 10, 10, 10, 60, 60], true),
                mb: toRems([30, 30, 30, 30, 45], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            search: {
                mt: toRems([30, 30, 30, 30, 60, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            },
            listing: {
                mt: toRems([30, 30, 30, 30, 50], true)
            },
            main: {
                width: '100%',
                maxWidth: '100%',
                mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                px: toRems(spacing?.base?.xAxis?.main, true)
            }
        },
        // Dish layout
        dish: {
            heading: {
                mb: toRems([20, 20, 20, 20, 45], true),
                px: toRems(spacing?.base?.xAxis?.main, true),
                page: {
                    mb: toRems([20, 20, 20, 20, 45], true),
                    pt: toRems(spacing?.topRow?.default, true),
                    px: toRems(spacing?.base?.xAxis?.main, true)
                },
                top: {
                    mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                    mb: toRems([20, 20, 20, 20, 45], true),
                    px: toRems(spacing?.base?.xAxis?.main, true)
                }
            },
            carousel: {
                px: toRems([0, 0, 0, 0, ...spacing?.base?.xAxis?.xlUp], true),
                mb: toRems([50, 50, 50, 50, 80], true)
            },
            slider: {
                mb: toRems([50, 50, 50, 50, 80], true)
            }
        },
        // Search layout
        search: {
            panel: {
                body: {
                    flexDirection: 'column'
                },
                title: {
                    width: '100%',
                    pl: toRems([20, 20, 20, 20, 50, 50], true),
                    pt: toRems([40, 40, 40, 40, 45, 45], true),
                    pb: toRems([20, 20, 20, 40, 40, 40], true)
                },
                results: {
                    width: '100%',
                    px: toRems([20, 20, 20, 20, 50, 50], true),
                    pb: toRems([20, 20, 20, 20, 50, 50], true)
                }
            },
            results: {
                row: {
                    px: toRems(spacing?.base?.xAxis?.main, true),
                    top: {
                        mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                        px: toRems(spacing?.base?.xAxis?.main, true)
                    }
                },
                column: {
                    width: '100%'
                }
            }
        },
        // Club layout
        club: {
            row: {
                px: toRems(spacing?.base?.xAxis?.main, true),
                pt: toRems([20, 20, 20, 45], true),
                pb: toRems([20, 20, 20, 45], true)
            },
            heading: {
                mb: toRems([20, 20, 20, 20, 45], true),
                px: toRems(spacing?.base?.xAxis?.main, true),
                top: {
                    mt: toRems([30, 30, 30, 30, 30, 60, 60], true),
                    mb: toRems([20, 20, 20, 20, 45], true),
                    px: toRems(spacing?.base?.xAxis?.main, true)
                }
            },
            slider: {
                position: 'relative',
                mb: toRems([50, 50, 50, 50, 80], true),
                '&::after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: toRem(20),
                    height: '100%',
                    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%)',
                    pointerEvents: 'none'
                }
            },
            banner: {
                my: toRems([30, 30, 30, 50, 70, 100], true)
            }
        },
        contentFeed: {
            top: {
                pt: toRems(spacing?.topRow.default, true),
                pb: toRems([25, 25, 25, 55, 55, 55, 55], true)
            },
            row: {
                pb: toRems(Object.values(layoutSpacings?.grid.rowGap), true)
            }
        },
        promotion: {
            banner: {
                pb: toRem(80)
            }
        },
        // Error page layout
        error: {
            top: {
                pt: toRems([40, 40, 40, 0], true),
                pb: toRems([100, 100, 100, 0], true)
            }
        },
        // Landing Lakše skuhat nego smisliti
        landing: {
            row: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                alignItems: 'center',
                gridColumnGap: toRems([20, 20, 20, 20, 60, 100], true),
                gridRowGap: toRems([0, 0, 0, 10, 100, 100], true),
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                pb: toRems([30, 30, 30, 70, 70, 70], true),
                width: '100%',
                maxWidth: '100%'
            },
            rowFirst: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                alignItems: 'center',
                gridColumnGap: toRems([20, 20, 20, 20, 60, 100], true),
                gridRowGap: toRems([10, 10, 10, 10, 100, 100], true),
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                pb: toRems([30, 30, 30, 20, 40, 40], true),
                width: '100%',
                maxWidth: '100%'
            },
            rowLast: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridColumnGap: toRems([20, 20, 20, 20, 60, 100], true),
                gridRowGap: toRems([10, 10, 10, 10, 100, 100], true),
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                pb: toRems([20, 10, 20, 60, 0, 0], true),
                width: '100%',
                maxWidth: '100%'
            },
            column1: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                order: [2, 2, 2, 1, 1, 1]
            },
            column2: {
                display: ['none', 'none', 'none', 'block', 'block', 'block'],
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                order: [1, 1, 1, 2, 2, 2]
            },
            column3: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                pl: toRems([20, 20, 20, 0, 0, 0], true),
                pr: toRems([20, 20, 20, 0, 0, 0], true),
                pt: toRems([20, 20, 20, 0, 0, 0], true)
            },
            column4: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7']
            },
            column5: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                order: [2, 2, 2, 1, 1, 1]
            },
            column6: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                order: [1, 1, 1, 2, 2, 2],
                pl: toRems([15, 15, 15, 0, 0, 0], true),
                pr: toRems([15, 15, 15, 0, 0, 0], true)
            },
            column7: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5']
            },
            column8: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7']
            },
            column9: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                order: [2, 2, 2, 1, 1, 1]
            },
            column10: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 4', 'span 4', 'span 4'],
                order: [1, 1, 1, 2, 2, 2],
                pl: toRems([15, 15, 15, 0, 0, 0], true),
                pr: toRems([15, 15, 15, 0, 0, 0], true)
            },
            column11: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                pl: toRems([15, 15, 15, 0, 0, 0], true),
                pr: toRems([15, 15, 15, 0, 0, 0], true)
            },
            column12: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                pt: toRems([15, 15, 15, 65, 65, 65], true),
                pb: toRems([15, 15, 15, 50, 50, 50], true)
            },
            column13: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                alignSelf: 'self-end',
                position: 'relative',
                left: toRems([-20, -40, -40, -40, -40, -100], true),
                transform: ['scale(1)', 'scale(1)', 'scale(1.1)', 'scale(1.2)', 'scale(1.35)', 'scale(1.35)'],
                transformOrigin: 'left bottom',
                order: [2, 2, 2, 1, 1, 1],
                pb: toRems([40, 40, 40, 0, 0, 0], true),
                ml: toRems([-20, -20, -20, 0, 0, 0], true),
                mr: toRems([-20, -20, -20, 0, 0, 0], true)
            },
            column14: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                pb: toRems([0, 0, 0, 140, 160, 160], true),
                order: [1, 1, 1, 2, 2, 2]
            },
            separator: {
                gridColumn: 'span 12',
                pb: toRems([0, 0, 0, 10, 10, 10], true)
            }
        },
        // Landing Cool Food
        coolFood: {
            row: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                alignItems: 'center',
                gridColumnGap: toRems([20, 20, 20, 20, 50, 50], true),
                gridRowGap: toRems([10, 10, 10, 10, 100, 100], true),
                position: 'relative',
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                width: '100%',
                maxWidth: '100%'
            },
            rowAlt: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                alignItems: 'center',
                gridColumnGap: toRems([20, 20, 20, 20, 50, 50], true),
                gridRowGap: toRems([10, 10, 10, 10, 100, 100], true),
                position: 'relative',
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                width: '100%',
                maxWidth: '100%',
                overflow: ['hidden', 'hidden', 'hidden', 'initial', 'initial', 'initial']
            },
            intro: {
                // pt: toRems([0, 0, 30, 60, 60, 60], true),
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 8', 'span 8', 'span 8'],
                order: [2, 2, 2, 1, 1, 1]
            },
            winnersTitle: {
                pb: toRems([10, 10, 30, 80, 68, 80], true),
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 12', 'span 12', 'span 12']
            },
            task: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 9', 'span 9', 'span 9'],
                order: [2, 2, 2, 1, 1, 1],
                pb: toRems([20, 20, 20, 50, 70, 70], true)
            },
            sponsor: {
                position: 'relative',
                top: toRems([0, 0, 0, -200, -200, -200], true),
                mt: toRems([-60, -60, -60, 0, 0, 0], true),
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 3', 'span 3', 'span 3'],
                order: [1, 1, 1, 2, 2, 2]
            },
            steps: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 12', 'span 12', 'span 12']
            },
            winners: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 12', 'span 12', 'span 12'],
                pb: toRems([20, 20, 20, 50, 130, 130], true)
            },
            entry: {
                pt: toRems([25, 25, 25, 120, 130, 130], true),
                pb: toRems([20, 20, 20, 100, 140, 140], true),
                ml: toRems([0, 0, 0, -30, -30, -30], true),
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 8']
            },
            nextChallenge: {
                ml: toRems([0, 0, 0, -30, -30, -30], true),
                pt: toRems([0, 0, 0, 50, 70, 70], true),
                pb: toRems([0, 0, 0, 50, 160, 160], true),
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 8', 'span 8', 'span 8']
            },
            decoration: {
                display: ['none', 'none', 'none', 'none', 'none', 'block'],
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: ['auto', 'auto', '440px', '440px', '340px', '500px'],
                zIndex: -1
            },
            productsImage: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                alignSelf: 'flex-start',
                position: 'relative',
                left: toRems([0, 0, 0, -40, -40, -100], true),
                order: [2, 2, 2, 1, 1, 1],
                pb: toRems([30, 30, 30, 0, 0, 0], true),
                ml: toRems([-20, -20, -20, 0, 0, 0], true),
                mr: toRems([-20, -20, -20, 0, 0, 0], true)
            },
            productsList: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                pb: toRems([0, 0, 0, 40, 60, 60], true),
                order: [1, 1, 1, 2, 2, 2]
            },
            listCta: {
                pt: toRems([20, 20, 20, 15, 15, 15], true),
                pb: toRems([20, 20, 20, 15, 15, 15], true)
            }
        },
        // lovie landing
        lovie: {
            row: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                alignItems: 'center',
                gridColumnGap: toRems([20, 20, 20, 20, 60, 100], true),
                gridRowGap: toRems([0, 0, 0, 10, 100, 100], true),
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                pb: toRems([30, 30, 30, 70, 70, 70], true),
                width: '100%',
                maxWidth: '100%'
            },
            rowFirst: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                alignItems: 'center',
                gridColumnGap: toRems([20, 20, 20, 20, 60, 100], true),
                gridRowGap: toRems([0, 0, 0, 10, 100, 100], true),
                pl: toRems([20, 20, 20, 20, 50, 100], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                pb: toRems([30, 30, 30, 70, 70, 70], true),
                pt: toRems([30, 30, 30, 70, 70, 70], true),
                width: '100%',
                maxWidth: '100%'
            },
            rowLast: {
                display: 'grid !important',
                gridTemplateColumns: 'repeat(12, 1fr)',
                gridColumnGap: toRems([20, 20, 20, 20, 60, 100], true),
                gridRowGap: toRems([10, 10, 10, 10, 100, 100], true),
                pl: toRems([20, 20, 20, 0, 0, 0], true),
                pr: toRems([20, 20, 20, 20, 50, 100], true),
                pb: toRems([20, 10, 20, 60, 0, 0], true),
                width: '100%',
                maxWidth: '100%'
            },
            column1: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                order: [2, 2, 2, 1, 1, 1]
            },
            column2: {
                display: ['none', 'none', 'none', 'block', 'block', 'block'],
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6'],
                order: [1, 1, 1, 2, 2, 2]
            },
            column3: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 12', 'span 12', 'span 12'],
                pl: toRems([20, 20, 20, 0, 0, 0], true),
                pr: toRems([20, 20, 20, 0, 0, 0], true),
                pt: toRems([20, 20, 20, 0, 0, 0], true)
            },
            column4: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                pl: toRems([20, 20, 20, 0, 0, 0], true),
                pr: toRems([20, 20, 20, 0, 0, 0], true),
                pt: toRems([20, 20, 20, 0, 0, 0], true)
            },
            column5: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7']
            },
            column6: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                order: [2, 2, 2, 1, 1, 1]
            },
            column7: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                order: [1, 1, 1, 2, 2, 2],
                pl: toRems([15, 15, 15, 0, 0, 0], true),
                pr: toRems([15, 15, 15, 0, 0, 0], true)
            },
            column8: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5']
            },
            column9: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7']
            },
            column10: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                order: [2, 2, 2, 1, 1, 1]
            },
            column11: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 4', 'span 4', 'span 4'],
                order: [1, 1, 1, 2, 2, 2],
                pl: toRems([15, 15, 15, 0, 0, 0], true),
                pr: toRems([15, 15, 15, 0, 0, 0], true)
            },
            column12: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                pl: toRems([15, 15, 15, 0, 0, 0], true),
                pr: toRems([15, 15, 15, 0, 0, 0], true)
            },
            column13: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                pt: toRems([15, 15, 15, 65, 65, 65], true),
                pb: toRems([15, 15, 15, 50, 50, 50], true)
            },
            column14: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5'],
                alignSelf: 'self-end',
                position: 'relative',
                left: toRems([-20, -40, -40, -40, -40, -100], true),
                transform: ['scale(1)', 'scale(1)', 'scale(1.1)', 'scale(1.2)', 'scale(1.35)', 'scale(1.35)'],
                transformOrigin: 'left bottom',
                order: [2, 2, 2, 1, 1, 1],
                pb: toRems([40, 40, 40, 0, 0, 0], true),
                ml: toRems([-20, -20, -20, 0, 0, 0], true),
                mr: toRems([-20, -20, -20, 0, 0, 0], true)
            },
            column15: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 7', 'span 7', 'span 7'],
                pb: toRems([0, 0, 0, 140, 160, 160], true),
                order: [1, 1, 1, 2, 2, 2]
            },
            separator: {
                gridColumn: 'span 12',
                pb: toRems([0, 0, 0, 10, 10, 10], true)
            },
            list: {
                gridColumn: 'span 12'
            },
            fullWidth: {
                gridColumn: 'span 12'
            },
            footerLeft: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 5', 'span 5', 'span 5']
            },
            footerMid: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 6', 'span 6', 'span 6']
            },
            footerRight: {
                gridColumn: ['span 12', 'span 12', 'span 12', 'span 1', 'span 1', 'span 1']
            }
        }
    }
}

export default {
    ...layoutTheme
}
