var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f1atnbd-U0hEIwlrX1oDY"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: `https://${process.env.NEXT_PUBLIC_SENTRY_DSN_URL}`,
    allowUrls: ['https://www.coolinarika.com', 'https://coolinarika.com', 'https://static-cdn.coolinarika.net'],
    autoSessionTracking: true,
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        // These are 3rd party errors coming from AdProviders
        `null is not an object (evaluating 'this.getContainer().ownerDocument')`,
        `null is not an object (evaluating 'window.top.document')`,
        'window.top is null',
        // Safari <12 Intl.PluralRules polyfill fails
        'NotFoundError: The object can not be found here.',
        'Non-Error exception captured with keys: error',
        `undefined is not a constructor (evaluating 'new Intl.PluralRules(t)')`
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Ignore Google flakiness
        /\/(gtm|ga|analytics)\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
})
