// @TODO: Revisit this, not sure if this is the most performant way of getting element's current font info :-/
import { useEffect, useState } from 'react'

import useInnerHtml from './useInnerHtml'
import useWindow from './useWindow'

const splitOnLineEndingsAndBreaks = html => html.replace(/(?:\r\n|\r|\n)/g, '<br />').split('<br />')

const stripHtml = html => html.replace(/<[^>]*>/gim, '')

const getWidth = (text, { fontFamily, fontSize, fontWeight }) => {
    // 2. Strip HTML from the text, so we can calculate the width of line. For example, <strong>some text</strong> should only be "some text" wide.
    const cleanText = stripHtml(text)
    // 3. Calculate the width of line using canvas and font for that line, based on wrapper element.
    // Re-use canvas for performance
    const canvas = getWidth.canvas || (getWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = `${fontWeight} ${fontSize} ${fontFamily}`
    const { width } = context.measureText(cleanText)
    return Math.floor(width)
}

const removeExtraWhitespace = line => line.replace(/\s\s+/g, ' ').trim()

const splitLineBasedOnWidth = (line, width, font, wrapper) => {
    const words = removeExtraWhitespace(line).split(' ')
    const lines = []
    let current = ''

    // Loop through words and build lines that are shorter than width
    words.forEach(word => {
        const temp = `${current}${current === '' ? '' : ' '}${word}`
        if (getWidth(temp, font) > width) {
            lines.push(`<${wrapper}>${current.trim()}</${wrapper}>`)
            current = word
        } else {
            current = temp
        }
    })

    // Handle remaining words to build last line
    if (current) {
        lines.push(`<${wrapper}>${current}</${wrapper}>`)
    }

    return lines
}

const getTextToSanitize = (text, width, font, wrapper) => {
    // 1. Split text into multiple lines of line breaks and <br> elements exist, before doing anything else.
    const originalLines = splitOnLineEndingsAndBreaks(text)
    const newLines = originalLines.map(line => {
        if (getWidth(line, font) <= width) {
            // If line is already shorter than allowed width, keep it, but wrap it
            return `<${wrapper}>${line}</${wrapper}>`
        }
        // 4. Else, if line is longer than maxWidth, break it into new line.
        return splitLineBasedOnWidth(line, width, font, wrapper)
    }, [])
    // 5. Finally, merge lines, sanitize them and return HTML.
    return newLines.flat().join('')
}

const getDOMInfo = (container, wrapper) => {
    const { element, elementRef, fallbackFamily, fallbackSize, fallbackWeight } = container
    const workingElement = element || elementRef.current
    const computedStyle = workingElement ? window.getComputedStyle(workingElement) : false

    let wrapperFont = {
        fontFamily: fallbackFamily,
        fontSize: fallbackSize,
        fontWeight: fallbackWeight
    }

    let wrapperWidth = 0

    if (computedStyle) {
        wrapperFont = {
            fontFamily: computedStyle.getPropertyValue('font-family') || fallbackFamily,
            fontSize: computedStyle.getPropertyValue('font-size') || fallbackSize,
            fontWeight: computedStyle.getPropertyValue('font-weight') || fallbackWeight
        }

        const paddingLeft = parseInt(computedStyle.getPropertyValue('padding-left'), 10)
        const paddingRight = parseInt(computedStyle.getPropertyValue('padding-right'), 10)
        const { width } = workingElement.getBoundingClientRect()
        const padding = Math.abs(paddingLeft) + Math.abs(paddingRight)

        if (width > padding) {
            wrapperWidth = Math.floor(width - padding)
        }
    }

    return {
        font: wrapperFont,
        width: wrapperWidth
    }
}

const useMultiline = ({ text = '', container = {}, wrapper = 'span' }) => {
    const [textToSanitize, setTextToSanitize] = useState(`<${wrapper}>${text}</${wrapper}>`)
    const [isReady, setIsReady] = useState(false)
    const { width: viewportWidth } = useWindow()

    useEffect(() => {
        if (viewportWidth) {
            const { font, width } = getDOMInfo(container, wrapper)

            if (font && width) {
                // Use smaller width, so even if wrapper width is initially high, break the text within window constraints
                const viewportWidthToUse = Math.floor(viewportWidth - 40)
                const widthToUse = Math.min(width, viewportWidthToUse)
                const readyText = getTextToSanitize(text, Math.floor(widthToUse), font, wrapper)
                setTextToSanitize(readyText)
                setIsReady(true)
            }
        } else {
            setTextToSanitize(text)
        }
    }, [text, viewportWidth, container, wrapper])

    // First return normal text for SSR, style it afterwards
    return [useInnerHtml(textToSanitize), isReady]
}

export default useMultiline
