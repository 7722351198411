/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const NavMenus = createSvgIcon(
    <>
        <path d="M22.606 11.297H20.8v-.368A.93.93 0 0019.87 10h-3.74a.93.93 0 00-.93.93v.367h-1.806c-.769 0-1.394.626-1.394 1.394v11.772c0 .768.625 1.393 1.394 1.393h9.212c.769 0 1.394-.625 1.394-1.393V12.69c0-.768-.625-1.394-1.394-1.394zm-6.476-.368h3.74v1.45h-3.74v-1.45zm6.94 13.534a.465.465 0 01-.464.464h-9.212a.465.465 0 01-.465-.464V15.38h10.142v9.082zm0-10.011H12.93v-1.76c0-.257.208-.465.465-.465H15.2v.152a.93.93 0 00.929.93h3.74a.93.93 0 00.93-.93v-.152h1.806c.256 0 .465.208.465.464v1.76z" />
        <path d="M19.05 17.18h-4.22a.465.465 0 100 .93h4.22a.465.465 0 000-.93zM19.05 19.546h-4.22a.465.465 0 100 .929h4.22a.464.464 0 100-.93zM19.05 21.91h-4.22a.465.465 0 100 .93h4.22a.465.465 0 000-.93zM21.149 17.18h-.565a.465.465 0 000 .93h.565a.465.465 0 100-.93zM21.149 19.546h-.565a.465.465 0 000 .929h.565a.465.465 0 100-.93zM21.149 21.91h-.565a.465.465 0 000 .93h.565a.465.465 0 100-.93z" />
    </>,
    'NavMenus'
)

export default NavMenus
